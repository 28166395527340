@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../css/e79bfd88537def476913f3ed52f4f4b3.eot);
    /* For IE6-8 */
    src: local("Material Icons"), local("MaterialIcons-Regular"), url(../css/570eb83859dc23dd0eec423a49e147fe.woff2) format("woff2"), url(../css/012cf6a10129e2275d79d6adac7f3b02.woff) format("woff"), url(../css/a37b0c01c0baf1888ca812cc0508f6e2.ttf) format("truetype");
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

body,
html {
    height: 100%;
}

body {
    font-family: 'Work Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #777;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    background: #fff;
    direction: ltr;
    letter-spacing: 0;
}

ul {
    list-style: none;
}

a:hover {
    color: #111;
    text-decoration: none;
}

p {
    font-size: 14px;
    color: #777777;
    font-weight: 400;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background: none;
}

.color,
.custom-checkbox input[type="checkbox"]+span.color {
    background-size: contain;
    border: 2px solid #e9e9e9;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    margin: 0;
    width: 25px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
}

.color.active,
.color:hover,
.custom-checkbox input[type="checkbox"]+span.color.active,
.custom-checkbox input[type="checkbox"]+span.color:hover {
    border: 1px solid #111;
}

.h1,
.h2,
.h3 {
    text-transform: uppercase;
    color: #111;
}

.h4 {
    font-weight: 700;
    color: #111;
}

.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-default {
    background: $orange;
    color: #fff;
    font-size: 13px;
    line-height: 24px;
    padding: 6px 20px;
    overflow: hidden;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
}

.btn-primary .material-icons,
.btn-secondary .material-icons,
.btn-tertiary .material-icons {
    font-size: 20px;
    margin-right: 5px;
}

.btn-tertiary {
    border: none;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin: 0.25rem 0;
    padding: 5px 15px;
    text-transform: lowercase;
}

.btn-tertiary .material-icons {
    font-size: 1rem;
}

label,
.label {
    color: #111;
    text-align: right;
    font-size: 0.875rem;
}

small.label,
small.value {
    font-size: 0.8125rem;
}

.form-control-label {
    padding-top: 0.625rem;
}

.form-control {
    background: #ffffff;
    color: #777777;
    border: 1px solid #e5e5e5;
    padding: 0.5rem 1rem;
}

input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
    box-shadow: none;
    /* margin-left: 15px;
    margin-right: 2px;*/
    vertical-align: top;
    margin-top: 2px;
    padding: 0;
    background: transparent;
    border: 1px solid #111;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:checked {
        &:after {
            content: '';
            height: 4px;
            width: 9px;
            display: inline-block;
            position: absolute;
            top: 3px;
            right: 2px;
            z-index: 2;
            border-bottom: 1px solid #111;
            border-left: 1px solid #111;
            transform: rotate(-45deg);
            box-shadow: none;
        }
    }
}

#newslatter {
    .checkbox-area-modal {
        color: #FFF;
        line-height: 20px;
        padding-top: 5px;

        input[type="checkbox"] {
            border: 1px solid #FFF;

            &:checked {
                &:after {
                    border-bottom: 1px solid #FFF;
                    border-left: 1px solid #FFF;
                }
            }
        }
    }
}

.form-control:focus {
    background-color: white;
    border-color: #cfcfcf;
}

.input-group .form-control:focus {
    outline: none;
}

.input-group .input-group-btn>.btn {
    border: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    color: #ffffff;
    font-size: 13px;
    font-weight: normal;
    margin-left: 0;
    padding: 0.65625rem 1rem;
    text-transform: capitalize;
}

#customer-form .input-group .input-group-btn>.btn {
    background: #878787 none repeat scroll 0 0;
}

.form-control-select {
    height: 2.625rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #ebebeb url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
    padding-right: 2rem;
    cursor: pointer;
}

.form-control-valign {
    padding-top: 0.5rem;
}

.form-control-comment {
    font-size: 12px;
    padding-top: 0.5rem;
    color: #878787;
}

.form-control-submit.disabled {
    background: #5bc0de;
    color: white;
}

.form-group.has-error input,
.form-group.has-error select {
    outline: 0.1875rem solid #ff4c4c;
}

.form-group.has-error .help-block {
    color: #ff4c4c;
}

.group-span-filestyle label {
    margin: 0;
}

.group-span-filestyle .btn-default,
.group-span-filestyle .bootstrap-touchspin .btn-touchspin,
.bootstrap-touchspin .group-span-filestyle .btn-touchspin {
    background: #111;
    color: white;
    text-transform: uppercase;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
}

.bootstrap-touchspin input:focus {
    outline: none;
}

.bootstrap-touchspin input.form-control {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.bootstrap-touchspin .btn-touchspin {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 1.3125rem;
}

.bootstrap-touchspin .btn-touchspin:hover {
    background-color: #ebebeb;
}

.bootstrap-touchspin .input-group-btn-vertical {
    color: #111;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .touchspin-up:after {
    content: "\E5CE";
}

.bootstrap-touchspin .input-group-btn-vertical .touchspin-down:after {
    content: "\E5CF";
}

.bootstrap-touchspin .input-group-btn-vertical i {
    top: 0.0625rem;
    left: 0.1875rem;
    font-size: 0.9375rem;
}

.custom-radio {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    border: #777777 2px solid;
    background: white;
    margin-right: 1.25rem;
}

.custom-radio input[type="radio"] {
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    cursor: pointer;
}

.custom-radio input[type="radio"]:checked+span {
    display: block;
    background-color: #111;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    position: absolute;
    left: 0.125rem;
    top: 0.125rem;
}

#search_filters_wrapper #search_filters ul li label>span span,
body#checkout .custom-checkbox span,
.custom-checkbox input[type="checkbox"]+span {
    -moz-box-align: center;
    -moz-box-pack: center;
    align-items: center;
    background: #fafafa none repeat scroll 0 0;
    border: medium none;
    display: flex;
    height: 20px;
    justify-content: center;
    margin-right: 10px;
    width: 20px;
    border: 1px solid #e5e5e5;
}

#search_filters_wrapper #search_filters ul li label>span:hover span,
body#checkout .custom-checkbox span:hover,
.custom-checkbox input[type="checkbox"]+span:hover {
    background: #f5f5f5 none repeat scroll 0 0;
    border-color: #dddddd;
}

#search_filters_wrapper #search_filters ul li label {
    -moz-box-align: center;
    align-items: center;
    display: flex;
    font-size: 12px;
    margin-bottom: 0.625rem;
}

#search_filters_wrapper #search_filters ul li label>span {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    cursor: pointer;
    text-align: center;
}

.custom-checkbox {
    position: relative;
}

.custom-checkbox input[type="checkbox"] {
    margin-top: 0.25rem;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    float: left;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
}

.custom-checkbox input[type="checkbox"]+span {
    margin-right: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
    border: 1px #e9e9e9 solid;
}

#authentication .custom-checkbox label {
    padding-left: 15px;
    width: calc(100% - 35px);
}

.custom-checkbox input[type="checkbox"]+span .checkbox-checked {
    display: none;
    font-size: 1.1rem;
    color: #111;
}

.custom-checkbox input[type="checkbox"]:checked+span .checkbox-checked {
    display: block;
}

.custom-checkbox label {
    text-align: left;
}

.text-muted {
    font-size: 0.875rem;
}

.done {
    color: #4cbb6c;
    display: inline-block;
    padding: 0 0.8125rem;
    margin-right: 1.5625rem;
    vertical-align: top;
}

.thumb-mask>.mask {
    position: relative;
    width: 70px;
    height: 91px;
    overflow: hidden;
    margin: 0.625rem 0;
}

.thumb-mask>.mask img {
    width: 70px;
    height: 91px;
    border: 1px solid #e5e5e5;
}

.definition-list dl {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.definition-list dl dt {
    font-weight: normal;
}

.definition-list dl dd,
.definition-list dl dt {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 45%;
    -moz-flex: 0 0 45%;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    background: #ebebeb;
    padding: 0.625rem;
    margin: 0.125rem;
}

.definition-list dl dd:nth-of-type(even),
.definition-list dl dt:nth-of-type(even) {
    background: #f5f5f5;
}

.help-block {
    margin-top: 0.625rem;
}

.btn.disabled,
.btn.disabled:hover {
    background: #777777;
    color: #fff;
}

.alert-warning .material-icons {
    color: #FF9A52;
    font-size: 2rem;
    margin-right: 0.625rem;
    padding-top: 0.3125rem;
}

.alert-warning .alert-text {
    font-size: 0.9375rem;
    padding-top: 0.625rem;
}

.alert-warning .alert-link {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
    border-width: 2px;
    margin-left: 0.625rem;
    padding: 0.3125rem 1.25rem;
    font-weight: 600;
    font-size: 0.8125rem;
    color: #6C868E;
}

.alert-warning ul li:last-child .alert-link {
    color: white;
}

.alert-warning .warning-buttons {
    margin-top: 0.3125rem;
}

.btn-tertiary-outline {
    color: #6C868E;
    background-image: none;
    background-color: transparent;
    border-color: #6C868E;
    border: 0.15rem solid #6C868E;
}

.btn-tertiary-outline:hover {
    border-color: #BBCDD2;
    color: #BBCDD2;
}

.alert {
    font-size: 0.8125rem;
}

.nav-item .nav-link,
.nav-item .nav-separtor {
    color: #777777;
    font-weight: normal;
}

.nav-item .nav-link.active,
.nav-item .nav-separtor.active {
    color: #111;
}

.dropdown {
    color: #777777;
}

.dropdown:hover .expand-more {
    color: #111;
}

.dropdown .expand-more {
    color: #888;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dropdown .active {
    max-height: 200px;
    overflow-y: hidden;
    visibility: visible;
}

.dropdown select {
    -moz-appearance: none;
    border: 0 none;
    outline: 0 none;
    color: #111;
    background: white;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background: none;
    text-decoration: none;
    color: #111;
}

.search-widget {
    float: right;
    padding: 0;
}

.search-widget form {
    position: relative;
}

.search-widget form input[type=text] {
    background: #ebebeb none repeat scroll 0 0;
    border: medium none;
    color: #777777;
    min-width: 255px;
    padding: 10px;
}

#search_widget form input {
    background: #ffffff none repeat scroll 0 0;
    border: medium none;
    min-width: 100%;
    outline: medium none;
    padding: 0;
    width: 100%;
}

.search-widget form button[type="submit"] {
    background: #ffde01 url("../../assets/img/sprite.png") no-repeat scroll -61px -232px;
    border: medium none;
    bottom: 0;
    color: #111;
    height: 40px;
    padding: 0;
    position: absolute;
    right: -1px;
    top: -1px;
    width: 45px;
}

.search-widget form input[type=text]:focus+button .search {
    color: #111;
}

.search-widget form input[type=text]::-webkit-input-placeholder {
    color: rgba(172, 170, 166, 0.5);
    text-indent: 0.625rem;
}

.search-widget form input[type=text]::-moz-placeholder {
    color: #111;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    text-indent: 0.625rem;
}

.search-widget form input[type=text]:-moz-placeholder {
    color: rgba(172, 170, 166, 0.5);
    text-indent: 0.625rem;
}

.search-widget form input[type=text]:-ms-input-placeholder {
    color: rgba(172, 170, 166, 0.5);
    text-indent: 0.625rem;
}

.search-widget .search_block_top_form>button[type="submit"] {
    background: rgba(0, 0, 0, 0) url("../img/search-sprite.png") no-repeat scroll -1px -83px;
    bottom: 0;
    color: #ffffff;
    display: block;
    height: 45px;
    margin: auto;
    padding: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 5px;
    width: 42px;
    border: none;
}

.search-widget form button[type=submit] .search:hover {
    color: #111;
}

.header-top .search-widget form input[type="text"] {
    min-width: inherit;
    width: 100%;
}

#pagenotfound .page-content #search_widget {
    width: 100%;
}


/*** Responsive part ***/

@media (max-width: 991px) {
    .search-widget form {
        width: 100%;
    }

    #header .header-nav .hidden-sm-down.top-nav {
        display: block !important;
    }

    .header-top .topmenu {
        border-top: none !important;
    }

    .header-nav .top-nav .right-nav .search-widget form {
        float: none;
    }

    .header-top .search-widget {
        float: none;
        padding: 0 0.9375rem;
    }

    .header-top .search-widget form {
        margin: 0 auto;
    }

    .header-top .search-widget form input[type="text"] {
        min-width: inherit;
        background: white;
    }
}

.top-menu[data-depth="1"] {
    margin: 0;
    padding: 0;
}

.top-menu a:not([data-depth="0"]) {
    display: block;
    padding: 0.625rem;
    color: #777777;
    font-weight: 400;
}

.top-menu a.dropdown-submenu {
    color: #111;
    text-transform: uppercase;
    font-weight: 600;
}

.top-menu a[data-depth="0"] {
    font-weight: 600;
    padding: 0.1875rem 0.625rem 0.375rem;
}

@media (min-width: 992px) {
    #header .top-menu li:hover a[data-depth="0"] {
        color: #111;
        text-decoration: none;
    }
}

.top-menu a[data-depth="1"],
.top-menu a[data-depth="2"] {
    padding: 0 0.625rem 0.625rem 0;
}

.top-menu .collapse {
    display: inherit;
}

.top-menu .sub-menu {
    border: 4px solid #e5e5e5;
    left: 100%;
    top: 0 !important;
    margin-left: 0;
    min-width: auto;
    padding: 15px;
    position: absolute;
    width: 420px;
    z-index: 18;
    background: #fff;
}

@media (max-width: 767px) {
    .top-menu .sub-menu {
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        border-right: none;
        border-left: none;
    }
}

.top-menu .sub-menu.collapse {
    display: none;
}

#_desktop_top_menu .top-menu li:hover .sub-menu {
    display: block !important;
}

.top-menu .sub-menu ul[data-depth="1"]>li {
    display: inline-block;
    float: left;
    padding: 10px 15px;
    text-align: left;
    vertical-align: top;
    width: 190px;
}

.header-top .topmenu ul li .top-menu>li>a.dropdown-submenu::before {
    border-top: 1px solid #e5e5e5;
    bottom: -4px;
    content: "";
    float: left;
    height: 1px;
    position: absolute;
    width: 90%;
}

.topmenu ul li .top-menu>li>a.dropdown-submenu {
    font: 400 13px/22px 'Work Sans', sans-serif;
    margin: 0 0 15px;
    position: relative;
    text-transform: uppercase;
    padding: 0;
    color: #777 !important;
}

.topmenu ul li .top-menu>li>a.dropdown-submenu:hover {
    color: #111 !important;
}

.topmenu ul li .top-menu>li:hover>a.dropdown-submenu {
    color: #111 !important;
}

.topmenu ul li .top-menu>li>a.dropdown-submenu::before {
    border-top: 1px solid #e5e5e5;
    bottom: -4px;
    content: "";
    float: left;
    height: 1px;
    position: absolute;
    width: 90%;
    left: 0;
    right: 0;
    top: auto;
}

.header-top .topmenu ul li .top-menu li a {
    font-size: 13px;
    padding: 0;
    white-space: normal;
}

#top-menu .menu-images-container {
    clear: both;
    float: left;
    padding: 10px 15px;
    width: 100%;
}

#top-menu .menu-images-container img {
    max-width: 100%;
}

.topmenu ul li .top-menu li a {
    color: #777 !important;
    font: 400 13px/24px 'Work Sans', sans-serif;
    padding: 0;
    text-align: left;
}

.top-menu .sub-menu a:hover {
    color: #111 !important;
}

.top-menu .popover {
    max-width: inherit;
}

.popover.bs-tether-element-attached-top {
    margin-top: 0;
}

#mobile_top_menu_wrapper {
    background: #ffffff;
    left: 0;
    padding-bottom: 0.625rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 100%;
    z-index: 2;
    padding: 0 0 10px;
    border: 1px solid #e5e5e5;
    margin: 0;
}

#mobile_top_menu_wrapper #_mobile_top_menu .material-icons {
    font-size: 20px;
}

#mobile_top_menu_wrapper #top-menu {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
}

#mobile_top_menu_wrapper #top-menu .float-xs-right {
    line-height: 0;
}

#mobile_top_menu_wrapper #top-menu .sub-menu .top-menu {
    padding: 0 0 0 15px;
}

#mobile_top_menu_wrapper #top-menu .sub-menu .top-menu .top-menu {
    padding: 0;
}

#mobile_top_menu_wrapper .js-top-menu-bottom {
    float: left;
    padding: 8px 0 0;
    width: 100%;
}

#mobile_top_menu_wrapper .top-menu {
    color: #111;
}

#mobile_top_menu_wrapper .top-menu .collapse-icons[aria-expanded="true"] .add {
    display: none;
}

#mobile_top_menu_wrapper .top-menu .collapse-icons[aria-expanded="true"] .remove {
    display: block;
}

#mobile_top_menu_wrapper .top-menu .collapse-icons .remove {
    display: none;
}

#mobile_top_menu_wrapper .top-menu .collapse-icons .add-down {
    display: none;
}

#mobile_top_menu_wrapper .top-menu .navbar-toggler {
    display: inline-block;
    padding: 0;
}

#mobile_top_menu_wrapper .top-menu a[data-depth="0"] {
    padding: 0.625rem;
}

#mobile_top_menu_wrapper .top-menu .collapse {
    display: none;
}

#mobile_top_menu_wrapper .top-menu .collapse.in {
    display: block;
}

#mobile_top_menu_wrapper .top-menu .sub-menu {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    z-index: inherit;
    display: block;
    position: static;
    overflow: hidden;
    margin-left: 0;
    width: 100%;
    min-width: 100%;
    background: #e9e9e9;
}

#mobile_top_menu_wrapper .top-menu .sub-menu.collapse {
    display: none;
}

#mobile_top_menu_wrapper .top-menu .sub-menu.collapse.in {
    display: block;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="0"]>li {
    border-bottom: 1px solid #777777;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="1"] {
    margin: 0;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="1"]>li {
    float: none;
    margin: 0;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="1"]>li a {
    text-transform: none;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul {
    padding: 0;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="2"] li a {
    padding-left: 1.25rem;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="3"] li a {
    padding-left: 2.5rem;
}

#mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="4"] li a {
    padding-left: 3.75rem;
}

#mobile_top_menu_wrapper .top-menu .popover {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
}

#mobile_top_menu_wrapper .js-top-menu-bottom a {
    color: #777777;
}

#mobile_top_menu_wrapper .js-top-menu-bottom .language-selector-wrapper {
    padding: 5px 0 0;
}

#mobile_top_menu_wrapper .js-top-menu-bottom .language-selector-wrapper .language-selector {
    display: inline;
}

#mobile_top_menu_wrapper .js-top-menu-bottom .currency-selector {
    padding: 5px 0 0;
}

#mobile_top_menu_wrapper .js-top-menu-bottom #contact-link {
    padding: 0.625rem;
}

#mobile_top_menu_wrapper .js-top-menu-bottom .user-info {
    padding: 0 0.625rem;
}

#mobile_top_menu_wrapper .js-top-menu-bottom .user-info a {
    padding: 0.625rem 0;
    display: block;
    width: 100%;
}

body#checkout {
    color: #111;
    padding: 0;
}

body#checkout [data-action="show-password"] {
    background: #878787;
    padding: 11px;
}

body#checkout .custom-checkbox {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
}

body#checkout section#content {
    margin-bottom: 1.5625rem;
    margin-top: 0;
}

body#checkout .container {
    min-height: 100%;
}

body#checkout section.checkout-step {
    border: 1px solid #e5e5e5;
    padding: 10px;
    float: left;
    width: 100%;
    margin: 0 0 20px;
    background: #fff;
}

body#checkout section.checkout-step.js-current-step {
    padding: 15px 10px 30px;
}

body#checkout section.checkout-step .step-title {
    cursor: pointer;
    float: left;
    font-weight: normal;
    margin: 0;
    text-transform: capitalize;
    width: 100%;
}

body#checkout section.checkout-step .content {
    padding: 0 2.3125rem;
}

body#checkout section.checkout-step .step-edit {
    text-transform: lowercase;
    font-weight: normal;
}

body#checkout section.checkout-step .step-edit .edit {
    font-size: 1rem;
}

body#checkout section.checkout-step .not-allowed {
    cursor: not-allowed;
    opacity: 0.5;
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    -webkit-opacity: 0.5;
}

body#checkout section.checkout-step .content,
body#checkout section.checkout-step .done,
body#checkout section.checkout-step .step-edit {
    display: none;
}

body#checkout section.checkout-step.-current .content {
    display: block;
    float: left;
    margin: 20px 0 0;
    width: 100%;
}

@media (max-width: 767px) {
    body#checkout section.checkout-step.-current .content {
        padding: 0;
    }
}

@media (max-width: 543px) {
    #checkout #checkout-login-form .form-footer .btn {
        margin: 15px 0 0;
    }
}

body#checkout section.checkout-step.-current.-reachable.-complete .done,
body#checkout section.checkout-step.-current.-reachable.-complete .step-edit {
    display: none;
}

body#checkout section.checkout-step.-current.-reachable.-complete .step-number {
    display: inline-block;
}

body#checkout section.checkout-step.-current.-reachable.-complete .content {
    display: block;
}

body#checkout section.checkout-step.-reachable.-complete h1 .done {
    display: inline-block;
}

body#checkout section.checkout-step.-reachable.-complete h1 .step-number {
    display: none;
}

body#checkout section.checkout-step.-reachable.-complete h1 .step-edit {
    cursor: pointer;
    display: block;
    float: right;
    margin: 4px 8px;
    color: #777777;
}

body#checkout section.checkout-step.-reachable.-complete .content {
    display: none;
}

body#checkout section.checkout-step small {
    color: #777777;
}

body#checkout section.checkout-step .default-input {
    min-width: 40%;
}

body#checkout section.checkout-step .default-input[name=address1],
body#checkout section.checkout-step .default-input[name=address2] {
    min-width: 60%;
}

body#checkout section.checkout-step .radio-field {
    margin-top: 1.875rem;
}

body#checkout section.checkout-step .radio-field label {
    display: inline;
}

body#checkout section.checkout-step .checkbox-field div {
    margin-top: 3.75rem;
}

body#checkout section.checkout-step .checkbox-field+.checkbox-field div {
    margin-top: 0;
}

body#checkout section.checkout-step .select-field div {
    background: #e9e9e9;
    padding: 0.625rem 3.125rem;
}

body#checkout section.checkout-step .form-footer {
    text-align: center;
}

body#checkout section.checkout-step .payment-options label {
    display: table-cell;
}

body#checkout section.checkout-step .payment-options .custom-radio {
    margin-right: 1.25rem;
}

body#checkout section.checkout-step .payment-options .payment-option {
    margin-bottom: 0.5rem;
}

body#checkout section.checkout-step .step-number {
    display: inline-block;
    padding: 5px 10px;
}

body#checkout section.checkout-step .address-selector {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify;
    -webkit-box-align: start;
    -moz-box-align: start;
    box-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    -o-align-items: flex-start;
    align-items: flex-start;
    -ms-flex-align: start;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

body#checkout section.checkout-step .address-item {
    background: #e9e9e9;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 0.625rem;
}

body#checkout section.checkout-step .address-item.selected {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
}

body#checkout section.checkout-step .address-alias {
    display: inline-block;
    font-weight: normal;
    margin: 0 0 20px;
}

#checkout-addresses-step #delivery-addresses br {
    margin: 0 0 15px;
}

body#checkout section.checkout-step .address {
    margin-left: 1.5625rem;
    font-weight: normal;
}

body#checkout section.checkout-step .radio-block {
    padding: 0.9375rem;
    text-align: left;
}

body#checkout section.checkout-step .custom-radio {
    margin-right: 0;
}

body#checkout section.checkout-step .custom-radio input[type="radio"] {
    height: 1.25rem;
    width: 1.25rem;
}

body#checkout section.checkout-step .delete-address,
body#checkout section.checkout-step .edit-address {
    color: #777777;
    display: inline-block;
    margin: 0 0.3125rem;
}

body#checkout section.checkout-step .delete-address .delete,
body#checkout section.checkout-step .delete-address .edit,
body#checkout section.checkout-step .edit-address .delete,
body#checkout section.checkout-step .edit-address .edit {
    font-size: 1rem;
}

body#checkout section.checkout-step hr {
    margin: 0;
}

body#checkout section.checkout-step .address-footer {
    text-align: center;
    padding: 0.625rem;
}

body#checkout section.checkout-step #delivery-addresses,
body#checkout section.checkout-step #invoice-addresses {
    margin-top: 1.25rem;
}

body#checkout section.checkout-step .add-address {
    margin-top: 1.25rem;
}

body#checkout section.checkout-step .add-address a {
    color: #111;
    text-decoration: underline;
}

body#checkout section.checkout-step .add-address a i {
    font-size: 0.9375rem;
}

body#checkout section.checkout-step .delivery-option {
    border-top: 1px solid #e5e5e5;
    float: left;
    margin: 0 0 20px;
    padding: 20px 0 0;
    width: 100%;
}

body#checkout section.checkout-step .delivery-option label {
    text-align: inherit;
}

body#checkout section.checkout-step .carrier-delay,
body#checkout section.checkout-step .carrier-name {
    display: inline-block;
    word-break: break-word;
    text-align: left;
}

body#checkout section.checkout-step #customer-form,
body#checkout section.checkout-step #delivery-address,
body#checkout section.checkout-step #invoice-address,
body#checkout section.checkout-step #login-form {
    margin-left: 0.3125rem;
    margin-top: 10px;
}

body#checkout section.checkout-step #customer-form .form-control-label,
body#checkout section.checkout-step #delivery-address .form-control-label,
body#checkout section.checkout-step #invoice-address .form-control-label,
body#checkout section.checkout-step #login-form .form-control-label {
    text-align: left;
}

body#checkout section.checkout-step #customer-form .radio-inline,
body#checkout section.checkout-step #delivery-address .radio-inline,
body#checkout section.checkout-step #invoice-address .radio-inline,
body#checkout section.checkout-step #login-form .radio-inline {
    padding: 0;
}

body#checkout section.checkout-step .sign-in {
    font-size: 0.875rem;
}

body#checkout section.checkout-step .forgot-password {
    text-align: center;
}

body#checkout .additional-information {
    font-size: 0.875rem;
    margin-left: 2.875rem;
    margin-top: 1.25rem;
}

body#checkout .condition-label {
    margin-left: 2.5rem;
    margin-top: 0.625rem;
}

body#checkout .condition-label label {
    text-align: inherit;
}

body#checkout .cancel-address {
    margin: 0.625rem;
    display: block;
    color: #878787;
    text-decoration: underline;
}

body#checkout .modal-content {
    padding: 1.25rem;
    background-color: #ebebeb;
}

body#checkout #cart-summary-product-list {
    font-size: 14px;
}

body#checkout #cart-summary-product-list img {
    border: 1px solid #e5e5e5;
}

#checkout .media-list .media {
    padding: 10px 0;
}

.media-list {
    padding: 0;
}

body#checkout #cart-summary-product-list .media-body {
    vertical-align: middle;
}

body#checkout #order-summary-content {
    padding-top: 0.9375rem;
}

body#checkout #order-summary-content h4.h4 {
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    color: #111;
}

body#checkout #order-summary-content h4.black {
    color: #111000;
}

body#checkout #order-summary-content h4.addresshead {
    margin-top: 0.1875rem;
}

body#checkout #order-summary-content .noshadow {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

body#checkout #order-summary-content #order-items {
    border-right: 0;
}

body#checkout #order-summary-content #order-items h3.h3 {
    color: #111;
    margin-top: 1.25rem;
}

body#checkout #order-summary-content #order-items table tr:first-child td {
    border-top: 0;
}

body#checkout #order-summary-content .order-confirmation-table {
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
}

body#checkout #order-summary-content .summary-selected-carrier {
    margin-bottom: 0.75rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    padding: 1rem;
}

body#checkout #order-summary-content .step-edit {
    display: inline;
    color: #777777;
}

body#checkout #order-summary-content .step-edit:hover {
    cursor: pointer;
}

body#checkout #order-summary-content a .step-edit {
    color: #777777;
}

body#checkout #gift_message {
    max-width: 100%;
    border-color: #111;
}

@media (max-width: 767px) {
    #password .form-group .email {
        padding: 5px 0 15px;
        width: 100%;
    }

    #password .form-group label {
        padding: 0;
        width: 100%;
        text-align: left;
    }
}


/**** ORDER CONFIRMATION *****/

#order-details {
    margin: 13px 0 0;
    padding-left: 15px;
}

#order-details>.card-title {
    margin-bottom: 1.875rem;
}

#order-details ul {
    margin-bottom: 1.25rem;
}

#order-details ul li {
    margin-bottom: 0.625rem;
}

#order-items {
    border-right: #dedede 1px solid;
}

#content-hook_order_confirmation .h1.card-title {
    font: 600 20px/25px 'Work Sans', sans-serif;
    text-transform: capitalize;
}

#order-confirmation #content-wrapper .products.row {
    //margin: 0;
}

#order-items hr {
    border-top-color: #e9e9e9;
}

#order-items table {
    width: 100%;
}

#order-items table tr {
    height: 1.875rem;
}

#order-items table tr td:last-child {
    text-align: right;
}

#order-items .order-line {
    margin-top: 1rem;
    //margin: 0;
}

#order-confirmation .order-confirmation-table .order-line .col-sm-2.col-xs-3 {
    padding: 0;
}

#order-items .image img {
    width: 100%;
    border: 1px solid #e5e5e5;
    margin-bottom: 1rem;
}

#order-confirmation #order-items {
    border: none;
}

#order-items .details {
    margin-bottom: 1rem;
}

#order-items .details .customizations {
    margin-top: 0.625rem;
}

#order-items .qty {
    margin-bottom: 1rem;
}

#order-confirmation #registration-form {
    width: 50%;
    margin: 0 auto 1rem;
}

#order-confirmation .col-md-12>p {
    float: left;
    width: 100%;
}

#order-confirmation .card.definition-list {
    float: left;
    margin: 0 0 60px;
    width: 100%;
}

#order-confirmation .card-block {
    float: left;
    width: 100%;
}

@media (max-width: 991px) {
    .done {
        margin: 0;
        padding: 0;
    }

    body#checkout section.checkout-step .address-item {
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        flex-grow: 1;
        -ms-flex-positive: 1;
    }

    body#checkout section.checkout-step .delivery-option-2 {
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        box-orient: vertical;
        -webkit-box-direction: normal;
        -moz-box-direction: normal;
        box-direction: normal;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        flex-direction: column;
        -ms-flex-direction: column;
    }

    .delivery-option {
        display: -webkit-box;
        display: -moz-box;
        display: box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        margin: auto;
    }

    .delivery-option .custom-radio {
        -webkit-box-flex: 0;
        -moz-box-flex: 0;
        box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .condition-label label[for="conditions_to_approve[terms-and-conditions]"] {
        text-align: left;
    }

    #order-confirmation #registration-form {
        width: 100%;
    }
}

@media (max-width: 767px) {
    body#checkout section.checkout-step.-reachable.-complete h1 .step-edit {
        float: none;
        margin-top: 0.25rem;
        margin-left: 1.25rem;
    }

    body#checkout section.checkout-step .content {
        padding: 0.9375rem;
    }

    body#checkout .form-group {
        margin-bottom: 0.5rem;
    }

    #order-items {
        border-right: 0;
        margin-bottom: 2.5rem;
    }

    #order-items .card-title {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }

    #order-items hr {
        border-top-color: #dedede;
    }

    .bold {
        font-weight: bold;
    }

    #order-details {
        padding-left: 0.9375rem;
    }

    #order-details .card-title {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }
}

@media (max-width: 543px) {
    body#checkout section.checkout-step .content {
        padding: 0.9375rem 0;
    }

    #payment-confirmation button {
        font-size: 0.875rem;
    }

    #payment-confirmation button.btn {
        white-space: normal;
    }

    #order-confirmation .card.definition-list {
        margin: 0 0 10px;
    }

    #content-hook_order_confirmation .h1.card-title {
        font-size: 16px;
    }
}

.js-payment-binary {
    display: none;
}

.js-payment-binary .accept-cgv {
    display: none;
}

.js-payment-binary.disabled {
    opacity: 0.6;
    -moz-opacity: 0.6;
    -khtml-opacity: 0.6;
    -webkit-opacity: 0.6;
    cursor: not-allowed;
}

.js-payment-binary.disabled::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.js-payment-binary.disabled .accept-cgv {
    display: block;
}


/*** SOME GENERIC STYLES ***/

.table-labeled th {
    vertical-align: middle;
    font-weight: normal;
}

.table-labeled td {
    vertical-align: middle;
}

.table-labeled .label {
    font-weight: 400;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    font-size: inherit;
    padding: 0.25rem 0.375rem;
    margin: 0.125rem;
    color: white;
    white-space: nowrap;
}

.page-order .table {
    margin-bottom: 0;
}

.page-order table th {
    padding: 0.5rem;
}

.page-order table td {
    padding: 0.5rem;
}

.page-order table thead th {
    text-align: center;
}

#authentication .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
}

#authentication .custom-checkbox {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    flex-wrap: wrap;
}

#authentication .custom-checkbox span {
    // flex: 0 0 0.9375rem;
}

#authentication .custom-checkbox label {
    padding-left: 15px;
    width: calc(100% - 35px);
}

#authentication [data-action="show-password"] {
    background: #878787;
}

#identity .radio-inline,
#authentication .radio-inline {
    padding: 0;
}

#identity .radio-inline .custom-radio,
#authentication .radio-inline .custom-radio {
    margin-right: 0;
}


/*** Most of the customer accpunt pages ***/

.page-customer-account #content {
    border: 1px solid #e5e5e5;
    background: white;
    padding: 1rem;
    font-size: 0.875rem;
    color: #878787;
}

.page-customer-account #content .order-actions a {
    padding: 0 0.125rem;
}

.page-customer-account #content .forgot-password {
    text-align: center;
    font-size: 0.875rem;
    margin-top: 1rem;
    padding-bottom: 0.9375rem;
}

.page-customer-account #content .no-account {
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 543px) {
    .page-customer-account #content {
        padding: 10px;
    }
}


/*** Login page ***/

.page-authentication #content {
    border: 1px solid #e5e5e5;
    background: white;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
}


/*** Addresses page ***/

.page-addresses #content .address {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
    color: #111;
    font-size: 14px;
    margin-bottom: 0;
}

.page-addresses .address .address-body {
    padding: 1rem;
}

.page-addresses .address .address-body h4 {
    border-bottom: 1px solid #e5e5e5;
    color: #111;
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 15px;
    padding: 0 0 10px;
}

.page-addresses .address .address-body address {
    min-height: 9rem;
}

.page-addresses .address .address-footer {
    border-top: 1px solid #e5e5e5;
    padding: 0.5rem 1rem;
}

.page-addresses .address .address-footer a {
    color: #878787;
    margin-right: 0.5rem;
}

.page-addresses .address .address-footer a:hover {
    color: #111;
}

.page-addresses .address .address-footer a i {
    font-size: 1rem;
}

.page-addresses .address .address-footer a span {
    font-size: 0.9375rem;
    vertical-align: middle;
}

.page-addresses .addresses-footer {
    margin: 10px 10px 0;
}

#addresses #main .page-content {
    padding: 25px 10px;
}

.page-addresses .addresses-footer a {
    color: #111;
}

.page-addresses .addresses-footer a:hover {
    color: #111;
}

.page-addresses .addresses-footer a i {
    font-size: 1rem;
}

.page-addresses .addresses-footer a span {
    font-size: 1rem;
    vertical-align: middle;
    margin-top: 0.625rem;
}


/*** Order details page ***/

#order-detail #order-infos strong {
    color: #111;
    font-weight: normal;
}

.page-order-detail {
    font-size: 0.875rem;
    color: #878787;
}

.page-order-detail .box {
    background: white;
    padding: 1rem;
    margin-bottom: 0;
}

.page-order-detail h3 {
    font-size: 1rem;
    font-weight: normal;
    color: #111;
    margin-bottom: 1rem;
}

.page-order-detail #order-infos ul {
    margin: 0;
}

.page-order-detail #order-history .history-lines .history-line {
    padding: 0.5rem 0;
    border-bottom: 1px solid #e5e5e5;
}

.page-order-detail #order-history .history-lines .history-line:last-child {
    border-bottom: 0;
}

.page-order-detail #order-history .history-lines .history-line .label {
    display: inline-block;
    margin: 0.25rem 0;
    padding: 0.25rem 0.375rem;
    color: white;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
}

.page-order-detail .addresses {
    margin: 0 -0.9375rem;
}

.page-order-detail .addresses h4 {
    font-size: 1rem;
    color: #111 !important;
    font-weight: normal;
}

#order-products strong {
    color: #111;
    font-weight: normal;
}

.page-order-detail #order-products.return {
    margin-bottom: 1rem;
}

.page-order-detail #order-products.return th.head-checkbox {
    width: 30px;
}

.page-order-detail #order-products.return td {
    padding: 1.375rem 0.75rem;
}

.page-order-detail #order-products.return td.qty {
    min-width: 125px;
}

.page-order-detail #order-products.return td.qty .current {
    width: 30%;
    float: left;
    text-align: right;
    padding-right: 0.5rem;
}

.page-order-detail #order-products.return td.qty .select {
    width: 70%;
    float: left;
    margin: -0.625rem 0;
    padding-left: 0.25rem;
}

.page-order-detail #order-products.return td.qty .select select {
    text-align: center;
}

.page-order-detail .order-items {
    padding: 0 !important;
}

.page-order-detail .order-items .order-item {
    padding: 1rem 1rem 0;
    border-bottom: 1px solid #e5e5e5;
}

.page-order-detail .order-items .order-item .checkbox {
    width: 30px;
    float: left;
    padding: 0 0.9375rem;
}

.page-order-detail .order-items .order-item .content {
    width: calc(100% - 30px);
    float: left;
    padding: 0 0.9375rem;
}

.page-order-detail .order-items .order-item .desc {
    margin-bottom: 1rem;
}

.page-order-detail .order-items .order-item .desc .name {
    font-weight: bold;
}

.page-order-detail .order-items .order-item .qty {
    margin-bottom: 1rem;
}

.page-order-detail .order-items .order-item .qty .q {
    margin-bottom: 0.25rem;
}

.page-order-detail .order-items .order-item .qty .s {
    margin-bottom: 0.25rem;
}

.page-order-detail .messages .message {
    margin-top: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
}

.page-order-detail .messages .message:last-child {
    border-bottom: 0;
}

.page-order-detail .messages .message>div {
    margin-bottom: 0.5rem;
}

.page-order-detail .customization {
    margin-top: 0.75rem;
}


/*** Order return page ***/

#order-return-infos .thead-default th {
    color: #111;
}

#order-return-infos .customization {
    margin-top: 0.75rem;
}


/*** My account landing page ***/

.page-my-account #content .links {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    -o-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: start;
}

.page-my-account #content .links a {
    color: #777777;
    display: inline-block;
    float: left;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 5px;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.page-my-account #content .links a:last-child {
    margin: 0;
}

.page-my-account #content .links a span.link-item {
    background: #f5f5f5 none repeat scroll 0 0;
    display: block;
    float: left;
    font-weight: normal;
    height: 100%;
    line-height: 35px;
    padding: 10px;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
}

.page-my-account #content .links a i {
    color: #777777;
    float: left;
    font-size: 36px;
    margin: 0 10px 0 0;
    padding: 0;
    vertical-align: top;
}

.page-my-account #content .links a:hover {
    color: #111;
}

.page-my-account #content .links a:hover i {
    color: #111000;
}


/*** History page ***/

#history .orders {
    margin: 0 -1rem;
}

#history .orders .order {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e5e5;
}

#history .orders .order a h3 {
    color: #878787;
}

#history .orders .order .label {
    display: inline-block;
    margin: 0.25rem 0;
    padding: 0.25rem 0.375rem;
    color: white;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
}

#history .orders .order:last-child {
    border-bottom: 0;
}

@media (max-width: 543px) {
    .carousel .carousel-item img {
        width: auto;
        margin-left: 0;
        position: absolute;
        top: 50%;
        height: 100% !important;
        max-width: inherit;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .carousel-item figure {
        margin: 0;
        min-height: 300px;
        position: relative;
    }
}


/*** FOOTER ***/

.page-footer .account-link {
    margin-right: 1rem;
}

.page-footer .account-link i {
    font-size: 1rem;
}

.page-footer .account-link span {
    font-size: 0.875rem;
    vertical-align: middle;
}

.carousel .direction {
    z-index: auto;
}

.carousel .carousel-item {
    height: 100%;
}

.carousel .carousel-item img {
    height: auto;
}

.carousel .carousel-item .caption {
    position: absolute;
    bottom: 28px;
    left: 90px;
    color: white;
    max-width: 340px;
    display: none;
}

.carousel .carousel-item .caption .caption-description p {
    color: white;
}

.carousel .carousel-control {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

.carousel .carousel-control .icon-next::before,
.carousel .carousel-control .icon-prev::before {
    content: "";
}

.carousel .carousel-control .icon-next i,
.carousel .carousel-control .icon-prev i {
    color: #111;
    display: inline-block;
    float: none;
    font-size: 28px;
    height: 100%;
    padding: 4px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.carousel .carousel-control .icon-next:hover i,
.carousel .carousel-control:focus .icon-next i,
.carousel .carousel-control .icon-prev:hover i,
.carousel .carousel-control:focus .icon-prev i {
    color: #111;
}

.carousel .carousel-control .icon-prev {
    left: 1rem;
}

.carousel .carousel-control .icon-next {
    right: 1rem;
}

@media (max-width: 543px) {

    .carousel .carousel-control .icon-next i,
    .carousel .carousel-control .icon-prev i {
        color: #fff;
    }

    .carousel .carousel-control .icon-next:hover i,
    .carousel .carousel-control:focus .icon-next i,
    .carousel .carousel-control .icon-prev:hover i,
    .carousel .carousel-control:focus .icon-prev i {
        color: #fff;
    }
}


/* ------------------------ Start Product Style ------------------------- */

.product-desc-short {
    display: none;
}

#js-product-list {
    text-align: center;
    float: left;
    width: 100%;
}

.featured-products,
.product-accessories,
.crossselling-product,
.category-products,
.view-product {
    float: left;
    width: 100%;
    margin: 20px 0;
    position: relative;
    text-align: center;
}

.ttspecial-products {
    float: left;
    width: 100%;
    margin: 0 0 45px;
    padding: 0;

    .product-description {
        min-height: 200px
    }
}

.ttspecial-products .ttspecial-list .product-title {
    margin: 5px 0 18px !important;
}

@media (max-width: 991px) {
    .ttspecial-products {
        margin: 0 0 35px;
    }
}

.featured-products .all-product-link {
    float: right;
    text-align: right;
    width: 100%;
    margin: 10px 0;
}

#products .products ul,
.featured-products .products ul,
.product-accessories .products ul,
.ttfeatured-products .products ul,
.crossselling-product .products ul,
.category-products .products ul,
.ttbestseller-products .products ul,
.ttnew-products .products ul,
.ttspecial-products .products ul,
.view-product .products ul {
    /*float: left;
    width: 100%;
    margin: 0;*/
}

#products .product-thumbnail,
.products .product-thumbnail,
.featured-products .product-thumbnail,
.product-accessories .product-thumbnail,
.ttfeatured-products .product-thumbnail,
.crossselling-product .product-thumbnail,
.category-products .product-thumbnail,
.ttbestseller-products .product-thumbnail,
.ttnew-products .product-thumbnail,
.ttspecial-products .product-thumbnail,
.view-product .product-thumbnail {
    display: block;
    text-align: center;
    position: relative;
}

#products .product-title a,
.products .product-title a,
.featured-products .product-title a,
.product-accessories .product-title a,
.ttfeatured-products .product-title a,
.crossselling-product .product-title a,
.category-products .product-title a,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.view-product .product-title a,
#product_comparison .product-miniature .product-description .product-title>a {
    color: #111;
    font: 400 14px/18px 'Work Sans', sans-serif;
    text-decoration: none;
    text-transform: initial;
    display: inline-block;
}

#products .product-title a:first-letter,
.products .product-title a:first-letter,
.featured-products .product-title a:first-letter,
.product-accessories .product-title a:first-letter,
.ttfeatured-products .product-title a:first-letter,
.crossselling-product .product-title a:first-letter,
.category-products .product-title a:first-letter,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a:first-letter,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a:first-letter,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a:first-letter,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a:first-letter,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a:first-letter,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a:first-letter,
.view-product .product-title a:first-letter,
#product_comparison .product-miniature .product-description .product-title>a:first-letter {
    text-transform: uppercase;
}

#products .product-title a:hover,
.products .product-title a:hover,
.featured-products .product-title a:hover,
.product-accessories .product-title a:hover,
.ttfeatured-products .product-title a:hover,
.crossselling-product .product-title a:hover,
.category-products .product-title a:hover,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a:hover,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a:hover,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a:hover,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a:hover,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a:hover,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a:hover,
.view-product .product-title a:hover,
#product_comparison .product-miniature .product-description .product-title>a:hover {
    color: #111000;
}

#products .thumbnail-container,
.products .thumbnail-container,
.featured-products .thumbnail-container,
.product-accessories .thumbnail-container,
.ttfeatured-products .thumbnail-container,
.crossselling-product .thumbnail-container,
.ttbestseller-products .thumbnail-container,
.ttnew-products .thumbnail-container,
.ttspecial-products .thumbnail-container,
.category-products .thumbnail-container,
.view-product .thumbnail-container {
    float: left;
    position: relative;
    width: 100%;
    height: 100% !important;
    text-align: left;
    border: 1px solid #e5e5e5;
    padding: 15px;
}

#products .thumbnail-container .ttproduct-image,
.products .thumbnail-container .ttproduct-image,
.featured-products .thumbnail-container .ttproduct-image,
.ttfeatured-products .thumbnail-container .ttproduct-image,
.product-accessories .thumbnail-container .ttproduct-image,
.ttbestseller-products .thumbnail-container .ttproduct-image,
.ttnew-products .thumbnail-container .ttproduct-image,
.ttspecial-products .thumbnail-container .ttproduct-image,
.crossselling-product .thumbnail-container .ttproduct-image,
.category-products .thumbnail-container .ttproduct-image,
.view-product .thumbnail-container .ttproduct-image {
    position: relative;
    overflow: hidden;
}

#products .thumbnail-container .ttproduct-image .ttproducthover,
.products .thumbnail-container .ttproduct-image .ttproducthover,
.featured-products .thumbnail-container .ttproduct-image .ttproducthover,
.ttfeatured-products .thumbnail-container .ttproduct-image .ttproducthover,
.product-accessories .thumbnail-container .ttproduct-image .ttproducthover,
.ttbestseller-products .thumbnail-container .ttproduct-image .ttproducthover,
.ttnew-products .thumbnail-container .ttproduct-image .ttproducthover,
.ttspecial-products .thumbnail-container .ttproduct-image .ttproducthover,
.crossselling-product .thumbnail-container .ttproduct-image .ttproducthover,
.category-products .thumbnail-container .ttproduct-image .ttproducthover,
.view-product .thumbnail-container .ttproduct-image .ttproducthover {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
}

#products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.featured-products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.ttfeatured-products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.product-accessories .thumbnail-container:hover .ttproduct-image .ttproducthover,
.ttbestseller-products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.ttnew-products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.ttspecial-products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.crossselling-product .thumbnail-container:hover .ttproduct-image .ttproducthover,
.category-products .thumbnail-container:hover .ttproduct-image .ttproducthover,
.view-product .thumbnail-container:hover .ttproduct-image .ttproducthover {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    bottom: 10px;
}

@media(max-width:767px) {

    #products .thumbnail-container .ttproduct-image .ttproducthover,
    .products .thumbnail-container .ttproduct-image .ttproducthover,
    .featured-products .thumbnail-container .ttproduct-image .ttproducthover,
    .ttfeatured-products .thumbnail-container .ttproduct-image .ttproducthover,
    .product-accessories .thumbnail-container .ttproduct-image .ttproducthover,
    .ttbestseller-products .thumbnail-container .ttproduct-image .ttproducthover,
    .ttnew-products .thumbnail-container .ttproduct-image .ttproducthover,
    .ttspecial-products .thumbnail-container .ttproduct-image .ttproducthover,
    .crossselling-product .thumbnail-container .ttproduct-image .ttproducthover,
    .category-products .thumbnail-container .ttproduct-image .ttproducthover,
    .view-product .thumbnail-container .ttproduct-image .ttproducthover {
        opacity: 1;
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
        position: static;
    }
}

#products .thumbnail-container .ttproducthover div .btn,
.products .thumbnail-container .ttproducthover div .btn,
.featured-products .thumbnail-container .ttproducthover div .btn,
.ttfeatured-products .thumbnail-container .ttproducthover div .btn,
.product-accessories .thumbnail-container .ttproducthover div .btn,
.ttbestseller-products .thumbnail-container .ttproducthover div .btn,
.ttnew-products .thumbnail-container .ttproducthover div .btn,
.ttspecial-products .thumbnail-container .ttproducthover div .btn,
.crossselling-product .thumbnail-container .ttproducthover div .btn,
.category-products .thumbnail-container .ttproducthover div .btn,
.view-product .thumbnail-container .ttproducthover div .btn {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    float: left;
    line-height: 36px;
    padding: 0;
    position: relative;
    text-align: center;
    height: 36px;
    width: 36px;
    background: #f5f5f5 url("../img/product-sprite.png") no-repeat scroll 0 0;
}


/*#products .thumbnail-container .ttproducthover div .add-to-cart {
    background: $orange url(../img/product-sprite.png) no-repeat scroll 0 0;
}*/


/*
button.button.ajax_add_to_cart_button.add-to-cart.btn.btn-default,
#products button.button.ajax_add_to_cart_button.add-to-cart.btn.btn-default {
    background: $orange url(../img/product-sprite.png) no-repeat scroll 0 0;
}*/

#products .thumbnail-container .ttproducthover div .btn:hover,
.products .thumbnail-container .ttproducthover div .btn:hover,
.featured-products .thumbnail-container .ttproducthover div .btn:hover,
.ttfeatured-products .thumbnail-container .ttproducthover div .btn:hover,
.product-accessories .thumbnail-container .ttproducthover div .btn:hover,
.ttbestseller-products .thumbnail-container .ttproducthover div .btn:hover,
.ttnew-products .thumbnail-container .ttproducthover div .btn:hover,
.ttspecial-products .thumbnail-container .ttproducthover div .btn:hover,
.crossselling-product .thumbnail-container .ttproducthover div .btn:hover,
.category-products .thumbnail-container .ttproducthover div .btn:hover,
.view-product .thumbnail-container .ttproducthover div .btn:hover {
    background-color: #111;
}

#products .thumbnail-container .ttproducthover div .btn span,
.products .thumbnail-container .ttproducthover div .btn span,
.featured-products .thumbnail-container .ttproducthover div .btn span,
.ttfeatured-products .thumbnail-container .ttproducthover div .btn span,
.product-accessories .thumbnail-container .ttproducthover div .btn span,
.ttbestseller-products .thumbnail-container .ttproducthover div .btn span,
.ttnew-products .thumbnail-container .ttproducthover div .btn span,
.ttspecial-products .thumbnail-container .ttproducthover div .btn span,
.crossselling-product .thumbnail-container .ttproducthover div .btn span,
.category-products .thumbnail-container .ttproducthover div .btn span,
.view-product .thumbnail-container .ttproducthover div .btn span {
    display: none;
}

#products .thumbnail-container .ttproducthover div .btn i,
.products .thumbnail-container .ttproducthover div .btn i,
.featured-products .thumbnail-container .ttproducthover div .btn i,
.ttfeatured-products .thumbnail-container .ttproducthover div .btn i,
.product-accessories .thumbnail-container .ttproducthover div .btn i,
.ttbestseller-products .thumbnail-container .ttproducthover div .btn i,
.ttnew-products .thumbnail-container .ttproducthover div .btn i,
.ttspecial-products .thumbnail-container .ttproducthover div .btn i,
.crossselling-product .thumbnail-container .ttproducthover div .btn i,
.category-products .thumbnail-container .ttproducthover div .btn i,
.view-product .thumbnail-container .ttproducthover div .btn i {
    display: none;
}

#products .thumbnail-container .ttproducthover div,
.products .thumbnail-container .ttproducthover div,
.featured-products .thumbnail-container .ttproducthover div,
.ttfeatured-products .thumbnail-container .ttproducthover div,
.product-accessories .thumbnail-container .ttproducthover div,
.ttbestseller-products .thumbnail-container .ttproducthover div,
.ttnew-products .thumbnail-container .ttproducthover div,
.ttspecial-products .thumbnail-container .ttproducthover div,
.crossselling-product .thumbnail-container .ttproducthover div,
.category-products .thumbnail-container .ttproducthover div,
.view-product .thumbnail-container .ttproducthover div {
    display: inline-block;
    vertical-align: top;
    float: left;
    margin: 0 1px;
}

#products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.featured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.product-accessories .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttfeatured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.crossselling-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttbestseller-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttnew-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttspecial-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.category-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.view-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button {
    background: $orange url(../img/product-sprite.png) no-repeat scroll -38px -40px;
}

@media (min-width: 544px) {
    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button {
        background: $orange url(../img/product-sprite.png) no-repeat scroll -30px -40px;
    }
}

#products .product-grid .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.featured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.product-accessories .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttfeatured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.crossselling-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttbestseller-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttnew-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttspecial-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.category-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.view-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover {
    background-position: -38px -40px;
    background: black url(../img/product-sprite.png) no-repeat scroll -38px -40px;
}

#products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.featured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.product-accessories .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttfeatured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.crossselling-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttbestseller-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttnew-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttspecial-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.category-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.view-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover {
    // background-position: -30px -41px;
}

#products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.featured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.product-accessories .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttfeatured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.crossselling-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttbestseller-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttnew-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.ttspecial-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.category-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
.view-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover {
    background-position: -38px -40px;
}

@media (max-width: 545px) {

    #products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .featured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .product-accessories .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .ttfeatured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .crossselling-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .ttbestseller-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .ttnew-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .ttspecial-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .category-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover,
    .view-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover {
        background-position: -38px -40px;
    }
}

#products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.featured-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttfeatured-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.product-accessories .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttbestseller-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttnew-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttspecial-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.crossselling-product .thumbnail-container .ttproducthover div.quick-view-block .btn,
.category-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.view-product .thumbnail-container .ttproducthover div.quick-view-block .btn {
    background-position: 6px -129px;
}

#products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.featured-products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.ttfeatured-products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.product-accessories .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.ttbestseller-products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.ttnew-products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.ttspecial-products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.crossselling-product .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.category-products .thumbnail-container .ttproducthover div.quick-view-block .btn:hover,
.view-product .thumbnail-container .ttproducthover div.quick-view-block .btn:hover {
    background-position: -37px -129px;
}

#products .thumbnail-container .ttproducthover div.compare .btn,
.products .thumbnail-container .ttproducthover div.compare .btn,
.featured-products .thumbnail-container .ttproducthover div.compare .btn,
.ttfeatured-products .thumbnail-container .ttproducthover div.compare .btn,
.product-accessories .thumbnail-container .ttproducthover div.compare .btn,
.ttbestseller-products .thumbnail-container .ttproducthover div.compare .btn,
.ttnew-products .thumbnail-container .ttproducthover div.compare .btn,
.ttspecial-products .thumbnail-container .ttproducthover div.compare .btn,
.crossselling-product .thumbnail-container .ttproducthover div.compare .btn,
.category-products .thumbnail-container .ttproducthover div.compare .btn,
.view-product .thumbnail-container .ttproducthover div.compare .btn {
    background-position: 6px -88px;
}

#products .thumbnail-container .ttproducthover div.compare .btn:hover,
.products .thumbnail-container .ttproducthover div.compare .btn:hover,
.featured-products .thumbnail-container .ttproducthover div.compare .btn:hover,
.ttfea tured-products .thumbnail-container .ttproducthover div.compare .btn:hover,
.product-accessories .thumbnail-container .ttproducthover div.compare .btn:hover,
.ttbestseller-products .thumbnail-container .ttproducthover div.compare .btn:hover,
.ttnew-products .thumbnail-container .ttproducthover div.compare .btn:hover,
.ttspecial-products .thumbnail-container .ttproducthover div.compare .btn:hover,
.crossselling-product .thumbnail-container .ttproducthover div.compare .btn:hover,
.category-products .thumbnail-container .ttproducthover div.compare .btn:hover,
.view-product .thumbnail-container .ttproducthover div.compare .btn:hover {
    background-position: -37px -88px;
}

#products .thumbnail-container .ttproducthover div.wishlist .btn,
.products .thumbnail-container .ttproducthover div.wishlist .btn,
.featured-products .thumbnail-container .ttproducthover div.wishlist .btn,
.ttfeatured-products .thumbnail-container .ttproducthover div.wishlist .btn,
.product-accessories .thumbnail-container .ttproducthover div.wishlist .btn,
.ttbestseller-products .thumbnail-container .ttproducthover div.wishlist .btn,
.ttnew-products .thumbnail-container .ttproducthover div.wishlist .btn,
.ttspecial-products .thumbnail-container .ttproducthover div.wishlist .btn,
.crossselling-product .thumbnail-container .ttproducthover div.wishlist .btn,
.category-products .thumbnail-container .ttproducthover div.wishlist .btn,
.view-product .thumbnail-container .ttproducthover div.wishlist .btn {
    background-position: 6px 4px;
}

#products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.featured-products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.ttfeatured-products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.product-accessories .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.ttbestseller-products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.ttnew-products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.ttspecial-products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.crossselling-product .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.category-products .thumbnail-container .ttproducthover div.wishlist .btn:hover,
.view-product .thumbnail-container .ttproducthover div.wishlist .btn:hover {
    background-position: -37px 4px;
}


/*------------ Start cart disabled-------------*/

#products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
#product_comparison div.tt-button-container .btn.add-to-cart-disable {
    display: inline-block;
    opacity: 0.8;
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    -webkit-opacity: 0.8;
    cursor: default;
    padding: 9px 15px;
}

#product_comparison .product-miniature .product-description .tt-button-container .btn.add-to-cart-disable {
    background: #ffde01;
    color: #111;
    width: auto;
    opacity: 0.5;
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    -webkit-opacity: 0.5;
    padding: 6px 15px;
}

#products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable:hover,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable:hover {
    background: #f5f5f5 url("../img/product-sprite.png") no-repeat scroll 6px -40px;
}

@media (max-width: 1199px) {

    #products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
    .products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
    #product_comparison div.tt-button-container .btn.add-to-cart-disable {
        background: #f5f5f5 url("../img/product-sprite.png") no-repeat scroll 5px -41px !important;
        height: 36px;
        padding: 0;
        width: 36px;
    }
}


/*------------ End cart disabled-------------*/

#products .thumbnail-container:hover .highlighted-informations,
.products .thumbnail-container:hover .highlighted-informations,
.featured-products .thumbnail-container:hover .highlighted-informations,
.product-accessories .thumbnail-container:hover .highlighted-informations,
.ttbestseller-products .thumbnail-container:hover .highlighted-informations,
.ttnew-products .thumbnail-container:hover .highlighted-informations,
.ttspecial-products .thumbnail-container:hover .highlighted-informations,
.ttfeatured-products .thumbnail-container:hover .highlighted-informations,
.crossselling-product .thumbnail-container:hover .highlighted-informations,
.category-products .thumbnail-container:hover .highlighted-informations,
.view-product .thumbnail-container:hover .highlighted-informations {
    bottom: 5.625rem;
}

#products .thumbnail-container:hover .highlighted-informations::after,
.products .thumbnail-container:hover .highlighted-informations::after,
.featured-products .thumbnail-container:hover .highlighted-informations::after,
.product-accessories .thumbnail-container:hover .highlighted-informations::after,
.ttfeatured-products .thumbnail-container:hover .highlighted-informations::after,
.ttbestseller-products .thumbnail-container:hover .highlighted-informations::after,
.ttnew-products .thumbnail-container:hover .highlighted-informations::after,
.ttspecial-products .thumbnail-container:hover .highlighted-informations::after,
.crossselling-product .thumbnail-container:hover .highlighted-informations::after,
.category-products .thumbnail-container:hover .highlighted-informations::after,
.view-product .thumbnail-container:hover .highlighted-informations::after {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

#products .thumbnail-container:hover .highlighted-informations.no-variants,
.products .thumbnail-container:hover .highlighted-informations.no-variants,
.featured-products .thumbnail-container:hover .highlighted-informations.no-variants,
.product-accessories .thumbnail-container:hover .highlighted-informations.no-variants,
.ttfeatured-products .thumbnail-container:hover .highlighted-informations.no-variants,
.crossselling-product .thumbnail-container:hover .highlighted-informations.no-variants,
.category-products .thumbnail-container:hover .highlighted-informations.no-variants,
.ttbestseller-products .thumbnail-container:hover .highlighted-informations.no-variants,
.ttnew-products .thumbnail-container:hover .highlighted-informations.no-variants,
.ttspecial-products .thumbnail-container:hover .highlighted-informations.no-variants,
.view-product .thumbnail-container:hover .highlighted-informations.no-variants {
    bottom: 4.375rem;
}

#products .products-section-title,
.products .products-section-title,
.featured-products .products-section-title,
.ttbestseller-products .products-section-title,
.ttnew-products .products-section-title,
.ttspecial-products .products-section-title,
.product-accessories .products-section-title,
.ttfeatured-products .products-section-title,
.crossselling-product .products-section-title,
.category-products .products-section-title,
.view-product .products-section-title {
    color: #111;
    font: 700 30px/40px 'Work Sans', sans-serif;
    margin: 30px auto 20px;
    position: relative;
    text-align: center;
    text-transform: capitalize !important;
}

#products .product-title,
.products .product-title,
.featured-products .product-title,
.product-accessories .product-title,
.ttfeatured-products .product-title,
.crossselling-product .product-title,
.ttbestseller-products .product-title,
.ttnew-products .product-title,
.ttspecial-products .product-title,
.category-products .product-title,
.view-product .product-title {
    float: right;
    font-weight: normal;
    margin-bottom: 8px;
    margin-top: 5px;
    //overflow: hidden;
    //text-overflow: ellipsis;
    text-transform: capitalize;
    width: 100%;
    height: 40px;

    @media (max-width: 375px) {
        height: 70PX;
    }
}

#products .product-price-and-shipping,
.products .product-price-and-shipping,
.featured-products .product-price-and-shipping,
.product-accessories .product-price-and-shipping,
.ttfeatured-products .product-price-and-shipping,
.crossselling-product .product-price-and-shipping,
.category-products .product-price-and-shipping,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
.view-product .product-price-and-shipping,
#product_comparison .product-miniature .product-price-and-shipping,
#cart .product-line-info .price {
    color: $orange;
    font: 500 19px/18px 'Work Sans', sans-serif;
    float: left;
    width: 100%;
    padding: 10px 0 10px;
    min-height: 63px;
}

@media (max-width: 1299px) {

    #products .product-price-and-shipping,
    .products .product-price-and-shipping,
    .featured-products .product-price-and-shipping,
    .product-accessories .product-price-and-shipping,
    .ttfeatured-products .product-price-and-shipping,
    .crossselling-product .product-price-and-shipping,
    .category-products .product-price-and-shipping,
    .ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
    .ttnew-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
    .ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
    .ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
    .ttnew-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
    .ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping,
    .view-product .product-price-and-shipping,
    #product_comparison .product-miniature .product-price-and-shipping {
        font-size: 19px;
        line-height: 18px;
    }

    .products .product-miniature.product-grid .discount-amount.discount-product {
        font-size: 12px;
    }
}

#products .variant-links,
.products .variant-links,
.featured-products .variant-links,
.product-accessories .variant-links,
.ttfeatured-products .variant-links,
.crossselling-product .variant-links,
.category-products .variant-links,
.ttbestseller-products .variant-links,
.ttnew-products .variant-links,
.ttspecial-products .variant-links,
.view-product .variant-links {
    position: relative;
    text-align: center;
    float: left;
    width: 100%;
    margin: 5px 0 9px;
}

#products .highlighted-informations,
.products .highlighted-informations,
.featured-products .highlighted-informations,
.product-accessories .highlighted-informations,
.ttfeatured-products .highlighted-informations,
.crossselling-product .highlighted-informations,
.ttbestseller-products .highlighted-informations,
.ttnew-products .highlighted-informations,
.ttspecial-products .highlighted-informations,
.category-products .highlighted-informations,
.view-product .highlighted-informations {
    z-index: 0;
    text-align: center;
    width: 100%;
    float: left;
    display: none;
    height: auto !important;
}

#products .product-description,
.products .product-description,
.featured-products .product-description,
.product-accessories .product-description,
.ttfeatured-products .product-description,
.crossselling-product .product-description,
.category-products .product-description,
.ttbestseller-products .product-description,
.ttnew-products .product-description,
.ttspecial-products .product-description,
.view-product .product-description {
    z-index: 1;
    float: left;
    width: 100%;
    height: 100% !important;
    margin-top: 15px;
    min-height: 60px;
}

#products img,
.products img,
.featured-products img,
.product-accessories img,
.ttbestseller-products img,
.ttnew-products img,
.ttspecial-products img,
.ttfeatured-products img,
.crossselling-product img,
.category-products img,
.view-product img {
    height: auto;
    max-width: 100%;
}

#products .product-miniature .discount,
.products .product-miniature .discount,
.featured-products .product-miniature .discount,
.product-accessories .product-miniature .discount,
.ttbestseller-products .product-miniature .discount,
.ttnew-products .product-miniature .discount,
.ttspecial-products .product-miniature .discount,
.ttfeatured-products .product-miniature .discount,
.crossselling-product .product-miniature .discount,
.category-products .product-miniature .discount,
.view-product .product-miniature .discount {
    left: 10px;
    top: 0;
    vertical-align: top;
    font-weight: 700;
    background: $orange;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
}

#products .product-miniature .product-flags .new,
#products .product-miniature .online-only,
#products .product-miniature .on-sale,
#products .product-miniature .discount-percentage,
.products .product-miniature .product-flags .new,
.products .product-miniature .online-only,
.products .product-miniature .on-sale,
.products .product-miniature .discount-percentage,
.featured-products .product-miniature .product-flags .new,
.featured-products .product-miniature .online-only,
.featured-products .product-miniature .on-sale,
.featured-products .product-miniature .discount-percentage,
.product-accessories .product-miniature .product-flags .new,
.product-accessories .product-miniature .online-only,
.product-accessories .product-miniature .on-sale,
.product-accessories .product-miniature .discount-percentage,
.ttfeatured-products .product-miniature .product-flags .new,
.ttfeatured-products .product-miniature .online-only,
.ttfeatured-products .product-miniature .on-sale,
.ttfeatured-products .product-miniature .discount-percentage,
.view-product .product-miniature .product-flags .new,
.view-product .product-miniature .online-only,
.view-product .product-miniature .on-sale,
.view-product .product-miniature .discount-percentage,
.crossselling-product .product-miniature .product-flags .new,
.crossselling-product .product-miniature .online-only,
.crossselling-product .product-miniature .on-sale,
.crossselling-product .product-miniature .discount-percentage,
.category-products .product-miniature .product-flags .new,
.category-products .product-miniature .online-only,
.category-products .product-miniature .on-sale,
.category-products .product-miniature .discount-percentage,
.ttbestseller-products .product-miniature .product-flags .new,
.ttbestseller-products .product-miniature .online-only,
.ttbestseller-products .product-miniature .on-sale,
.ttbestseller-products .product-miniature .discount-percentage,
.ttnew-products .product-miniature .product-flags .new,
.ttnew-products .product-miniature .online-only,
.ttnew-products .product-miniature .on-sale,
.ttnew-products .product-miniature .discount-percentage,
.ttbestseller-products .product-miniature .product-flags .new,
.ttbestseller-products .product-miniature .online-only,
.ttbestseller-products .product-miniature .on-sale,
.ttbestseller-products .product-miniature .discount-percentage,
.ttnew-products .product-miniature .product-flags .new,
.ttnew-products .product-miniature .online-only,
.ttnew-products .product-miniature .on-sale,
.ttnew-products .product-miniature .discount-percentage,
.ttspecial-products .product-miniature .product-flags .new,
.ttspecial-products .product-miniature .online-only,
.ttspecial-products .product-miniature .on-sale,
.ttspecial-products .product-miniature .discount-percentage,
.ttspecial-products .product-miniature .product-flags .new,
.ttspecial-products .product-miniature .online-only,
.ttspecial-products .product-miniature .on-sale,
.ttspecial-products .product-miniature .discount-percentage {
    display: block;
    left: 10px;
    font-weight: 700;
    background: $green;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
}

#products .product-miniature .product-flags .new.discount-percentage,
#products .product-miniature .online-only.discount-percentage,
#products .product-miniature .on-sale.discount-percentage,
#products .product-miniature .discount-percentage.discount-percentage,
.products .product-miniature .product-flags .new.discount-percentage,
.products .product-miniature .online-only.discount-percentage,
.products .product-miniature .on-sale.discount-percentage,
.products .product-miniature .discount-percentage.discount-percentage,
.featured-products .product-miniature .product-flags .new.discount-percentage,
.featured-products .product-miniature .online-only.discount-percentage,
.featured-products .product-miniature .on-sale.discount-percentage,
.featured-products .product-miniature .discount-percentage.discount-percentage,
.product-accessories .product-miniature .product-flags .new.discount-percentage,
.product-accessories .product-miniature .online-only.discount-percentage,
.product-accessories .product-miniature .on-sale.discount-percentage,
.product-accessories .product-miniature .discount-percentage.discount-percentage,
.ttfeatured-products .product-miniature .product-flags .new.discount-percentage,
.ttfeatured-products .product-miniature .online-only.discount-percentage,
.ttfeatured-products .product-miniature .on-sale.discount-percentage,
.ttfeatured-products .product-miniature .discount-percentage.discount-percentage,
.view-product .product-miniature .product-flags .new.discount-percentage,
.view-product .product-miniature .online-only.discount-percentage,
.view-product .product-miniature .on-sale.discount-percentage,
.view-product .product-miniature .discount-percentage.discount-percentage,
.crossselling-product .product-miniature .product-flags .new.discount-percentage,
.crossselling-product .product-miniature .online-only.discount-percentage,
.crossselling-product .product-miniature .on-sale.discount-percentage,
.crossselling-product .product-miniature .discount-percentage.discount-percentage,
.category-products .product-miniature .product-flags .new.discount-percentage,
.category-products .product-miniature .online-only.discount-percentage,
.category-products .product-miniature .on-sale.discount-percentage,
.category-products .product-miniature .discount-percentage.discount-percentage,
.ttbestseller-products .product-miniature .product-flags .new.discount-percentage,
.ttbestseller-products .product-miniature .online-only.discount-percentage,
.ttbestseller-products .product-miniature .on-sale.discount-percentage,
.ttbestseller-products .product-miniature .discount-percentage.discount-percentage,
.ttnew-products .product-miniature .product-flags .new.discount-percentage,
.ttnew-products .product-miniature .online-only.discount-percentage,
.ttnew-products .product-miniature .on-sale.discount-percentage,
.ttnew-products .product-miniature .discount-percentage.discount-percentage,
.ttbestseller-products .product-miniature .product-flags .new.discount-percentage,
.ttbestseller-products .product-miniature .online-only.discount-percentage,
.ttbestseller-products .product-miniature .on-sale.discount-percentage,
.ttbestseller-products .product-miniature .discount-percentage.discount-percentage,
.ttnew-products .product-miniature .product-flags .new.discount-percentage,
.ttnew-products .product-miniature .online-only.discount-percentage,
.ttnew-products .product-miniature .on-sale.discount-percentage,
.ttnew-products .product-miniature .discount-percentage.discount-percentage,
.ttspecial-products .product-miniature .product-flags .new.discount-percentage,
.ttspecial-products .product-miniature .online-only.discount-percentage,
.ttspecial-products .product-miniature .on-sale.discount-percentage,
.ttspecial-products .product-miniature .discount-percentage.discount-percentage,
.ttspecial-products .product-miniature .product-flags .new.discount-percentage,
.ttspecial-products .product-miniature .online-only.discount-percentage,
.ttspecial-products .product-miniature .on-sale.discount-percentage,
.ttspecial-products .product-miniature .discount-percentage.discount-percentage,
#product_comparison .product-miniature .product-flags .new.discount-percentage,
#product_comparison .product-miniature .online-only.discount-percentage,
#product_comparison .product-miniature .on-sale.discount-percentage,
#product_comparison .product-miniature .discount-percentage.discount-percentage,
.products .discount-amount.discount-product {
    background: none;
    color: $orange;
    display: inline-block;
    float: none;
    font: 600 13px/14px 'Work Sans', sans-serif;
    padding: 3px 0;
    position: static;
    vertical-align: middle;
}

#products .product-miniature .product-flags .new.on-sale,
#products .product-miniature .online-only.on-sale,
#products .product-miniature .on-sale.on-sale,
#products .product-miniature .discount-percentage.on-sale,
.products .product-miniature .product-flags .new.on-sale,
.products .product-miniature .online-only.on-sale,
.products .product-miniature .on-sale.on-sale,
.products .product-miniature .discount-percentage.on-sale,
.featured-products .product-miniature .product-flags .new.on-sale,
.featured-products .product-miniature .online-only.on-sale,
.featured-products .product-miniature .on-sale.on-sale,
.featured-products .product-miniature .discount-percentage.on-sale,
.view-product .product-miniature .product-flags .new.on-sale,
.view-product .product-miniature .online-only.on-sale,
.view-product .product-miniature .on-sale.on-sale,
.view-product .product-miniature .discount-percentage.on-sale,
.crossselling-product .product-miniature .product-flags .new.on-sale,
.crossselling-product .product-miniature .online-only.on-sale,
.crossselling-product .product-miniature .on-sale.on-sale,
.crossselling-product .product-miniature .discount-percentage.on-sale,
.category-products .product-miniature .product-flags .new.on-sale,
.category-products .product-miniature .online-only.on-sale,
.category-products .product-miniature .on-sale.on-sale,
.category-products .product-miniature .discount-percentage.on-sale,
.ttbestseller-products .product-miniature .product-flags .new.on-sale,
.ttbestseller-products .product-miniature .online-only.on-sale,
.ttbestseller-products .product-miniature .on-sale.on-sale,
.ttbestseller-products .product-miniature .discount-percentage.on-sale,
.ttnew-products .product-miniature .product-flags .new.on-sale,
.ttnew-products .product-miniature .online-only.on-sale,
.ttnew-products .product-miniature .on-sale.on-sale,
.ttnew-products .product-miniature .discount-percentage.on-sale,
.ttspecial-products .product-miniature .product-flags .new.on-sale,
.ttspecial-products .product-miniature .online-only.on-sale,
.ttspecial-products .product-miniature .on-sale.on-sale,
.ttspecial-products .product-miniature .discount-percentage.on-sale,
.product-accessories .product-miniature .product-flags .new.on-sale,
.product-accessories .product-miniature .online-only.on-sale,
.product-accessories .product-miniature .on-sale.on-sale,
.product-accessories .product-miniature .discount-percentage.on-sale,
.ttfeatured-products .product-miniature .product-flags .new.on-sale,
.ttfeatured-products .product-miniature .online-only.on-sale,
.ttfeatured-products .product-miniature .on-sale.on-sale,
.ttfeatured-products .product-miniature .discount-percentage.on-sale {
    right: 10px;
    top: 10px;
    vertical-align: top;
    left: auto;
    background: $orange;
}

#products .product-miniature .product-flags .new.online-only,
#products .product-miniature .online-only.online-only,
#products .product-miniature .on-sale.online-only,
#products .product-miniature .discount-percentage.online-only,
.products .product-miniature .product-flags .new.online-only,
.products .product-miniature .online-only.online-only,
.products .product-miniature .on-sale.online-only,
.products .product-miniature .discount-percentage.online-only,
.featured-products .product-miniature .product-flags .new.online-only,
.featured-products .product-miniature .online-only.online-only,
.featured-products .product-miniature .on-sale.online-only,
.featured-products .product-miniature .discount-percentage.online-only,
.view-product .product-miniature .product-flags .new.online-only,
.view-product .product-miniature .online-only.online-only,
.view-product .product-miniature .on-sale.online-only,
.view-product .product-miniature .discount-percentage.online-only,
.crossselling-product .product-miniature .product-flags .new.online-only,
.crossselling-product .product-miniature .online-only.online-only,
.crossselling-product .product-miniature .on-sale.online-only,
.crossselling-product .product-miniature .discount-percentage.online-only,
.category-products .product-miniature .product-flags .new.online-only,
.category-products .product-miniature .online-only.online-only,
.category-products .product-miniature .on-sale.online-only,
.category-products .product-miniature .discount-percentage.online-only,
.product-accessories .product-miniature .product-flags .new.online-only,
.product-accessories .product-miniature .online-only.online-only,
.product-accessories .product-miniature .on-sale.online-only,
.product-accessories .product-miniature .discount-percentage.online-only,
.ttbestseller-products .product-miniature .product-flags .new.online-only,
.ttbestseller-products .product-miniature .online-only.online-only,
.ttbestseller-products .product-miniature .on-sale.online-only,
.ttbestseller-products .product-miniature .discount-percentage.online-only,
.ttnew-products .product-miniature .product-flags .new.online-only,
.ttnew-products .product-miniature .online-only.online-only,
.ttnew-products .product-miniature .on-sale.online-only,
.ttnew-products .product-miniature .discount-percentage.online-only,
.ttspecial-products .product-miniature .product-flags .new.online-only,
.ttspecial-products .product-miniature .online-only.online-only,
.ttspecial-products .product-miniature .on-sale.online-only,
.ttspecial-products .product-miniature .discount-percentage.online-only,
.ttfeatured-products .product-miniature .product-flags .new.online-only,
.ttfeatured-products .product-miniature .online-only.online-only,
.ttfeatured-products .product-miniature .on-sale.online-only,
.ttfeatured-products .product-miniature .discount-percentage.online-only {
    font-size: 13px;
    left: auto;
    right: 10px;
    top: 30px !important;
    color: #111;
}

#products .product-miniature .product-flags .new.online-only::before,
#products .product-miniature .online-only.online-only::before,
#products .product-miniature .on-sale.online-only::before,
#products .product-miniature .discount-percentage.online-only::before,
.products .product-miniature .product-flags .new.online-only::before,
.products .product-miniature .online-only.online-only::before,
.products .product-miniature .on-sale.online-only::before,
.products .product-miniature .discount-percentage.online-only::before,
.featured-products .product-miniature .product-flags .new.online-only::before,
.featured-products .product-miniature .online-only.online-only::before,
.featured-products .product-miniature .on-sale.online-only::before,
.featured-products .product-miniature .discount-percentage.online-only::before,
.view-product .product-miniature .product-flags .new.online-only::before,
.view-product .product-miniature .online-only.online-only::before,
.view-product .product-miniature .on-sale.online-only::before,
.view-product .product-miniature .discount-percentage.online-only::before,
.crossselling-product .product-miniature .product-flags .new.online-only::before,
.crossselling-product .product-miniature .online-only.online-only::before,
.crossselling-product .product-miniature .on-sale.online-only::before,
.crossselling-product .product-miniature .discount-percentage.online-only::before,
.category-products .product-miniature .product-flags .new.online-only::before,
.category-products .product-miniature .online-only.online-only::before,
.category-products .product-miniature .on-sale.online-only::before,
.category-products .product-miniature .discount-percentage.online-only::before,
.product-accessories .product-miniature .product-flags .new.online-only::before,
.product-accessories .product-miniature .online-only.online-only::before,
.product-accessories .product-miniature .on-sale.online-only::before,
.product-accessories .product-miniature .discount-percentage.online-only::before,
.ttbestseller-products .product-miniature .on-sale.online-only::before,
.ttbestseller-products .product-miniature .online-only.online-only::before,
.ttbestseller-products .product-miniature .product-flags .new.online-only::before,
.ttbestseller-products .product-miniature .discount-percentage.online-only::before,
.ttnew-products .product-miniature .on-sale.online-only::before,
.ttnew-products .product-miniature .online-only.online-only::before,
.ttnew-products .product-miniature .product-flags .new.online-only::before,
.ttnew-products .product-miniature .discount-percentage.online-only::before,
.ttspecial-products .product-miniature .on-sale.online-only::before,
.ttspecial-products .product-miniature .online-only.online-only::before,
.ttspecial-products .product-miniature .product-flags .new.online-only::before,
.ttspecial-products .product-miniature .discount-percentage.online-only::before,
.ttfeatured-products .product-miniature .product-flags .new.online-only::before,
.ttfeatured-products .product-miniature .online-only.online-only::before,
.ttfeatured-products .product-miniature .on-sale.online-only::before,
.ttfeatured-products .product-miniature .discount-percentage.online-only::before {
    content: "\E30A";
    font-family: 'Material Icons';
    vertical-align: middle;
    margin: 0.3125rem;
}

#products .product-grid .thumbnail-container .hook-reviews,
.products .product-grid .thumbnail-container .hook-reviews,
.featured-products .product-grid .thumbnail-container .hook-reviews,
.product-accessories .product-grid .thumbnail-container .hook-reviews,
.crossselling-product .product-grid .thumbnail-container .hook-reviews,
.ttbestseller-products .product-grid .thumbnail-container .hook-reviews,
.ttnew-products .product-grid .thumbnail-container .hook-reviews,
.category-products .product-grid .thumbnail-container .hook-reviews,
.view-product .product-grid .thumbnail-container .hook-reviews,
.ttfeatured-products .product-grid .thumbnail-container .hook-reviews {
    color: #777777;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    top: auto;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}


/*
@media (min-width: 768px) {
    #products .product-grid .thumbnail-container:hover .hook-reviews,
    .products .product-grid .thumbnail-container:hover .hook-reviews,
    .featured-products .product-grid .thumbnail-container:hover .hook-reviews,
    .product-accessories .product-grid .thumbnail-container:hover .hook-reviews,
    .crossselling-product .product-grid .thumbnail-container:hover .hook-reviews,
    .ttbestseller-products .product-grid .thumbnail-container:hover .hook-reviews,
    .ttnew-products .product-grid .thumbnail-container:hover .hook-reviews,
    .category-products .product-grid .thumbnail-container:hover .hook-reviews,
    .view-product .product-grid .thumbnail-container:hover .hook-reviews,
    .ttfeatured-products .product-grid .thumbnail-container:hover .hook-reviews {
        opacity: 0;
        -moz-opacity: 0;
        -khtml-opacity: 0;
        -webkit-opacity: 0;
    }
}
*/

@media (max-width: 767px) {

    #products .product-grid .thumbnail-container .hook-reviews,
    .products .product-grid .thumbnail-container .hook-reviews,
    .featured-products .product-grid .thumbnail-container .hook-reviews,
    .product-accessories .product-grid .thumbnail-container .hook-reviews,
    .crossselling-product .product-grid .thumbnail-container .hook-reviews,
    .ttbestseller-products .product-grid .thumbnail-container .hook-reviews,
    .ttnew-products .product-grid .thumbnail-container .hook-reviews,
    .category-products .product-grid .thumbnail-container .hook-reviews,
    .view-product .product-grid .thumbnail-container .hook-reviews,
    .ttfeatured-products .product-grid .thumbnail-container .hook-reviews {
        bottom: 42px;
    }
}

#products .regular-price,
.products .regular-price,
.featured-products .regular-price,
.product-accessories .regular-price,
.ttfeatured-products .regular-price,
.crossselling-product .regular-price,
.view-product .regular-price,
.category-products .regular-price,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping .regular-price,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping .regular-price,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping .regular-price,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping .regular-price,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping .regular-price,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-price-and-shipping .regular-price,
#product_comparison .product-miniature .product-description .product-price-and-shipping .regular-price {
    color: #777777;
    font: 400 14px/16px 'Work Sans', sans-serif;
    text-decoration: line-through;
}

#products .count,
.products .count,
.featured-products .count,
.category-products .count,
.crossselling-product .count,
.ttbestseller-products .count,
.ttnew-products .count,
.ttspecial-products .count,
.product-accessories .count,
.ttfeatured-products .count {
    color: #878787;
    font-weight: normal;
    position: relative;
    bottom: 0.5rem;
}

#products .all-product-link,
.products .all-product-link,
.featured-products .all-product-link,
.product-accessories .all-product-link,
.crossselling-product .all-product-link,
.category-products .all-product-link,
.ttbestseller-products .all-product-link,
.ttnew-products .all-product-link,
.ttspecial-products .all-product-link,
.ttfeatured-products .all-product-link {
    clear: both;
    color: #878787;
    font-weight: normal;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pack {
    color: #008000;
    font-size: 13px;
    left: 10px;
    position: absolute;
    top: 30px;
}

.cat-name {
    color: #a7a7a7;
    font-size: 13px;
    font-weight: normal;
    margin-top: 10px;
    text-transform: capitalize;
    margin-bottom: 0;
    text-align: center;
    display: none;
}


/* ------------------------ End Product Style ------------------------- */

#custom-text {
    background: #ffffff none repeat scroll 0 0;
    border: 10px solid #f5f5f5;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
    float: left;
    margin: 30px 0 30px;
    padding: 60px 50px;
    text-align: center;
    width: 100%;
}

#custom-text h3 {
    color: #111;
    font-size: 32px;
    font-weight: 500;
    line-height: 26px;
    margin: 0 auto;
    padding: 0 0 20px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}

#custom-text p {
    color: #111;
    font-weight: 400;
    font-size: 1.1em;
}

#custom-text p .dark {
    color: #777777;
    font-weight: 400;
}

.page-content.page-cms {
    background: white;
    padding: 15px;
    /* text-align: justify; */
    border: 1px solid #e5e5e5;
}

.page-content.page-cms .cms-box img {
    max-width: 100%;
}

@media (max-width: 991px) {
    #block-cmsinfo {
        padding: 1.25rem 1.875rem;
    }
}

#products {
    color: #878787;
}

#products .products-select {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: baseline;
    -moz-box-align: baseline;
    box-align: baseline;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    -o-align-items: baseline;
    align-items: baseline;
    -ms-flex-align: baseline;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify;
}

#products .up {
    margin-bottom: 1rem;
}

#products .up .btn-secondary,
#products .up .btn-tertiary {
    color: #777777;
    text-transform: inherit;
}

#products .up .btn-secondary .material-icons,
#products .up .btn-tertiary .material-icons {
    margin-right: 0;
}

.block-category #category-description p,
.block-category #category-description strong {
    font-weight: 400;
    color: #777777;
}

.block-category #category-description p {
    color: #111;
    margin-bottom: 0;
}

.products-selection .sort-by {
    padding-top: 10px;
    text-align: right;
    white-space: nowrap;
    float: left;
}

@media (max-width: 991px) {
    .products-selection .sort-by {
        padding: 10px 5px;
    }
}

.products-selection .total-products {
    padding: 10px 0 0;
}

.products-selection .total-products p {
    margin: 0;
}

.products-selection h1 {
    padding-top: 0.625rem;
}

.products-sort-order {
    color: #878787;
    padding: 0;
    position: relative;
}

.products-sort-order .select-title {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
    color: #111;
    line-height: 24px;
    cursor: pointer;
    display: inline-block;
    margin-left: 0;
    padding: 8px 10px;
    width: 100%;
    text-align: left;
}

.products-sort-order .select-list {
    display: block;
    color: #111;
    padding: 10px;
}

.products-sort-order .select-list:hover {
    background: #111000;
    color: white;
    text-decoration: none;
}

.products-sort-order .dropdown-menu {
    left: 0;
    right: 0;
    width: 100%;
    background: #e9e9e9;
    border: none;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
}

#search_filters .facet {
    padding: 5px 15px !important;
}

#search_filters .facet .collapse {
    display: block;
    margin: 0;
    padding: 0;
}

#search_filters .facet .facet-title {
    color: #111;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
}

#search_filters .facet .facet-label {
    margin-bottom: 1px;
}

#search_filters .facet .facet-label a {
    color: #888888;
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
}

#search_filters .facet .facet-label a:hover {
    color: #111;
}


/* -------------- Pagination(CategoryPage) ------------------- */

.pagination {
    width: 100%;
}

.pagination>div:first-child {
    line-height: 34px;
}

.pagination-left {
    float: left;
    padding: 0;
    text-align: left;
}

@media (max-width: 543px) {
    .pagination-left {
        text-align: center;
        width: 100%;
    }
}

.pagination-right {
    float: right;
    padding: 0;
}

@media (max-width: 543px) {
    .pagination-right {
        width: 100%;
    }
}

.pagination .page-list {
    background: white;
    padding: 0;
    margin-bottom: 0;
    float: right;
}

@media (max-width: 543px) {
    .pagination .page-list {
        float: none;
    }
}

.pagination .page-list li {
    display: inline-block;
    float: none;
    vertical-align: middle;
}

.pagination a {
    color: #111;
    display: block;
    height: 34px;
    padding: 6px;
    width: 34px;
    line-height: 22px;
}

.pagination a {
    text-decoration: none;
}

.pagination a i {
    font-size: 22px;
}

.pagination a span {
    display: none;
}

.pagination .previous {
    float: left;
    font-size: 0;
    line-height: 0;
    margin: 0 !important;
}

.pagination .js-search-link {
    background: #fff;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
}

.pagination .js-search-link:hover {
    background: #111;
    border-color: #111;
    color: #ffffff;
}

@media (max-width: 767px) {
    .pagination .previous {
        margin-right: 10px;
    }
}

.pagination .next {
    float: right;
    font-size: 0;
    margin: 0 !important;
}

@media (max-width: 767px) {
    .pagination .next {
        margin-left: 10px;
    }
}

.pagination .disabled {
    color: #777777;
}

.pagination .current a {
    background: $orange;
    border-color: $orange;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
}

.active_filters {
    background: #f5f5f5;
    padding: 10px;
    margin-bottom: 1.25rem;
    float: left;
    width: 100%;
}

.active_filters .active-filter-title {
    display: inline;
    margin-right: 0.625rem;
    font-weight: 600;
    margin-bottom: 0;
    vertical-align: middle;
}

.active_filters ul {
    display: inline;
}

.active_filters .filter-block {
    color: #111;
    margin-right: 0.625rem;
    margin-bottom: 0;
    background: white;
    padding: 0.625rem;
    display: inline-block;
    font-size: 0.8125rem;
}

.active_filters .filter-block .close {
    color: #111;
    font-size: 0.9375rem;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    margin-top: 0.1875rem;
    margin-left: 0.3125rem;
}

.block-categories ul {
    margin: 0;
    padding: 0;
}

.block-categories .category-sub-menu {
    display: inline-block;
    float: none;
    margin-top: 10px;
    vertical-align: top;
    width: 100%;
    padding: 0 !important;
}

.block-categories .category-sub-menu .category-sub-link {
    font-size: 0.875rem;
    color: #888888;
}

.block-categories .category-sub-menu .category-sub-link:hover {
    color: #111;
}

.block-categories .category-sub-menu li a {
    color: #888 !important;
    font-size: 14px !important;
    line-height: 28px !important;
    text-transform: uppercase;
}

.block-categories .category-sub-menu li a:hover {
    color: #111 !important;
}

.block-categories .category-sub-menu li {
    padding: 0 15px;
    position: relative;
}

.block-categories .category-sub-menu li[data-depth="1"] {
    margin-bottom: 0;
}

.block-categories .category-sub-menu li[data-depth="0"]>a {
    width: 100%;
    display: inline-block;
}

.block-categories .category-sub-menu li:not([data-depth="0"]):not([data-depth="1"]) {
    padding-left: 0.3125rem;
}

.block-categories .category-sub-menu li:not([data-depth="0"]):not([data-depth="1"])::before {
    content: "-";
    margin-right: 0.3125rem;
}

.block-categories a {
    color: #111;
}

.block-categories .category-sub-menu .collapse-icons {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 0;
    cursor: pointer;
}

.block-categories .category-sub-menu .collapse-icons .material-icons {
    font-size: 15px;
}

.block-categories .collapse-icons[aria-expanded="true"] .add {
    display: none;
}

.block-categories .collapse-icons[aria-expanded="true"] .remove {
    display: inline-block;
}

.block-categories .collapse-icons .add:hover,
.block-categories .collapse-icons .remove:hover {
    color: #111000;
}

.block-categories .collapse-icons .remove {
    display: none;
}

.block-categories .arrows .arrow-right,
.block-categories .arrows .arrow-down {
    font-size: 0.875rem;
    cursor: pointer;
    margin-left: 2px;
    float: right;
}

.block-categories .arrows .arrow-right:hover,
.block-categories .arrows .arrow-down:hover {
    color: #111000;
}

.block-categories .arrows .arrow-down {
    display: none;
}

.block-categories .arrows[aria-expanded="true"] .arrow-right {
    display: none;
}

.block-categories .arrows[aria-expanded="true"] .arrow-down {
    display: inline-block;
}

.facets-title {
    color: #111;
}

.products-selection .filter-button .btn-secondary,
.products-selection .filter-button .btn-tertiary {
    padding: 8px 15px;
}


/* faceted*/

#category #left-column #search_filters .facet ul,
#category #right-column #search_filters .facet ul {
    margin-bottom: 0;
    max-height: 132px;
    overflow: auto;
    padding-left: 0;
}


/*** Responsive part ***/

@media (max-width: 991px) {
    #search_filters {
        margin: 0 !important;

        .title.clearfix {
            background: #f1f1f1;
        }
    }

    #category #left-column #search_filter_controls,
    #category #right-column #search_filter_controls {
        text-align: center;
        margin-bottom: 1rem;
    }

    #category #left-column #search_filter_controls button,
    #category #right-column #search_filter_controls button {
        margin: 0rem 3px;
        padding: 7px 15px;
    }

    .block-categories ul {
        padding: 0 15px !important;
    }

    #category #left-column #search_filters,
    #category #right-column #search_filters {
        margin-bottom: 0;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        padding: 0;
        border-top: 1px solid #e5e5e5;
    }

    #category #left-column #search_filters .facet,
    #category #right-column #search_filters .facet {
        padding: 10px !important;
        border-bottom: 1px solid #e5e5e5;
    }

    .block-categories .title.clearfix,
    #ttcmstestimonial .title.clearfix,
    #left-column .clearfix .title,
    #right-column .clearfix .title {
        background: #f1f1f1;
    }

    #category #left-column #search_filters .facet:last-child,
    #category #right-column #search_filters .facet:last-child {
        border-bottom: medium none;
    }

    #category #left-column #search_filters .facet .title,
    #category #right-column #search_filters .facet .title {
        cursor: pointer;
        background: none;
    }

    #category #left-column #search_filters .facet .title[aria-expanded="true"] .collapse-icons .add,
    #category #right-column #search_filters .facet .title[aria-expanded="true"] .collapse-icons .add {
        display: none;
    }

    #category #left-column #search_filters .facet .title[aria-expanded="true"] .collapse-icons .remove,
    #category #right-column #search_filters .facet .title[aria-expanded="true"] .collapse-icons .remove {
        display: block;
    }

    #category #left-column #search_filters .facet .facet-title,
    #category #right-column #search_filters .facet .facet-title {
        color: #111;
        text-transform: capitalize;
    }

    #category #left-column #search_filters .facet .h6,
    #category #right-column #search_filters .facet .h6 {
        margin-bottom: 0;
        padding: 0;
        display: inline-block;
        text-transform: uppercase;
        color: #111;
        font-weight: 700;
    }

    #category #left-column #search_filters .facet .navbar-toggler,
    #category #right-column #search_filters .facet .navbar-toggler {
        display: inline-block;
        padding: 0;
    }

    #category #left-column #search_filters .facet .collapse,
    #category #right-column #search_filters .facet .collapse {
        display: none;
    }

    #category #left-column #search_filters .facet .collapse.in,
    #category #right-column #search_filters .facet .collapse.in {
        display: block;
        overflow: hidden;
    }

    #category #left-column #search_filters .facet .facet-label a,
    #category #right-column #search_filters .facet .facet-label a {
        margin-top: 0;
    }

    #category #left-column #search_filters .facet ul li,
    #category #right-column #search_filters .facet ul li {
        padding: 5px;
    }

    #search_filters_wrapper #search_filters ul li label {
        margin: 0;
    }

    #category #search_filter_toggler {
        background: $orange;
        color: #fff;
        width: 100%;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        font-size: 16px;
    }

    #category #search_filter_toggler:hover {
        background: #111;
        color: #fff;
    }

    .products-sort-order .select-title {
        margin-left: 0;
    }

    .products-selection h1 {
        padding-top: 0;
        text-align: center;
        margin-bottom: 1rem;
    }

    .products-selection .showing {
        padding-top: 1rem;
        float: left;
        width: 100%;
    }

    #prices-drop #content-wrapper,
    #new-products #content-wrapper,
    #best-sales #content-wrapper {
        width: 100%;
    }
}

@media (max-width: 543px) {
    .products-selection .filter-button {
        padding: 0 0 0 10px;
    }
}

#product #content {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

@media (min-width: 544px) and (max-width: 767px) {
    #product #content {
        float: none;
        max-width: 80%;
        text-align: center;
    }
}

#product-description-short {
    color: #111;
    float: left;
    width: 100%;
}

.product-information {
    font-size: 0.9375rem;
    color: #111;
    float: left;
    width: 100%;
}

.product-information .manufacturer-logo {
    height: 35px;
}

.input-color {
    position: absolute;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
}

.input-container {
    position: relative;
}

.input-radio {
    position: absolute;
    top: 0;
    cursor: pointer;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    width: 100%;
    height: 100%;
}

.input-color:checked+span,
.input-color:hover+span,
.input-radio:checked+span,
.input-radio:hover+span {
    border: 2px solid #111;
}

.radio-label {
    box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
    background: white;
    display: inline-block;
    padding: 0.125rem 0.625rem;
    font-weight: 600;
    border: 2px solid white;
}

@media (max-width: 991px) {
    .ttproductpagetab {
        margin: 50px 0 40px;
    }
}

@media (max-width: 543px) {
    .ttproductpagetab {
        margin: 50px 0 30px;
    }
}

.tab-content .product-manufacturer {
    margin: 0 0 20px;
}

.tabs {
    background: #ffffff none repeat scroll 0 0;
    padding: 10px 0;
    margin: 0 0 10px;
}

.tabs .nav-tabs .nav-item {
    float: left;
    margin-bottom: -0.125rem;
}

#product .tab-content {
    border: 1px solid #e5e5e5;
    float: left;
    padding: 20px;
    width: 100%;
    border-top: none;
}

#product #tab-content .product-description>p {
    margin: 0;
}

@media (max-width: 543px) {
    .tabs .nav-tabs .nav-item {
        width: 100%;
        margin: 0;
    }

    .tabs .nav-tabs .nav-link {
        margin: 0 0 10px;
        border-bottom: 2px solid #777;
    }
}

.product-cover {
    margin-bottom: 1.25rem;
    position: relative;
    float: left;
    width: 100%;
    border: 1px solid #e5e5e5;

    &:hover {
        cursor: crosshair;
    }
}

.product-cover img {
    background: white;
}


/*
.product-cover .layer:hover {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

.product-cover .layer .zoom-in {
    font-size: 6.25rem;
    color: white;
}

#product-modal .modal-content {
    background: transparent;
    border: none;
    padding: 0;
}

#product-modal .modal-content .modal-body {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    margin-left: -30%;
}

#product-modal .modal-content .modal-body .product-cover-modal {
    background: white;
}

#product-modal .modal-content .modal-body .image-caption {
    background: white;
    width: 800px;
    padding: 0.625rem 1.25rem;
}

#product-modal .modal-content .modal-body .image-caption p {
    margin-bottom: 0;
}

#product-modal .modal-content .modal-body .thumbnails {
    position: relative;
}

#product-modal .modal-content .modal-body .mask {
    margin: 50px 0;
    max-height: 740px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

#product-modal .modal-content .modal-body .mask.nomargin {
    margin-top: 0;
}

#product-modal .modal-content .modal-body .product-images {
    margin-left: 2.5rem;
}

#product-modal .modal-content .modal-body .product-images img {
    width: 9.25rem;
    cursor: pointer;
    background: white;
}

#product-modal .modal-content .modal-body .arrows {
    color: #111000;
    cursor: pointer;
    height: 100%;
    text-align: center;
    width: 100%;
    z-index: 1;
}

#product-modal .modal-content .modal-body .arrows i {
    display: inline-block;
    font-size: 6.25rem;
    left: 20px;
    margin: auto;
    position: absolute;
    right: 0;
}

#product-modal .modal-content .modal-body .arrows .arrow-up {
    opacity: 0.2;
    -moz-opacity: 0.2;
    -khtml-opacity: 0.2;
    -webkit-opacity: 0.2;
    position: absolute;
    top: -40px;
}

#product-modal .modal-content .modal-body .arrows .arrow-down {
    bottom: -20px;
    position: absolute;
}*/

#blockcart-modal {
    color: #111;
}

#blockcart-modal .modal-header {
    background: #4cbb6c;
}

#blockcart-modal .modal-body {
    background: #ffffff;
    padding: 3.125rem 1.875rem;
}

#blockcart-modal .modal-body .divide-right span {
    display: inline-block;
    margin-bottom: 0.3125rem;
}

#blockcart-modal .modal-dialog {
    max-width: 1140px;
    width: 100%;
}

#blockcart-modal .product-image {
    width: 13.125rem;
    border: 1px solid #e5e5e5;
}

#blockcart-modal .modal-title {
    font-weight: 600;
    color: white;
    font-size: 1.125rem;
}

#blockcart-modal .modal-title i.material-icons {
    margin-right: 1.875rem;
}

#blockcart-modal .cart-products-count {
    font-size: 1.125rem;
}

#blockcart-modal .cart-content {
    padding-left: 2.5rem;
}

#blockcart-modal .cart-content button {
    margin-right: 0.3125rem;
}

#blockcart-modal .divide-right {
    border-right: #e9e9e9 1px solid;
}

.product-images .thumb-container {
    display: inline-block;
}

.product-images .thumb-container .thumb {
    cursor: pointer;
    margin-bottom: 0.625rem;
}

.images-container .product-images .thumb-container .thumb {
    margin: 0 5px;
    border: 1px solid #e5e5e5;
}

.images-container .product-images .thumb-container .thumb:hover,
.images-container .product-images .thumb-container .thumb.selected {
    border-color: #ccc;
}

#main .images-container .js-qv-mask {
    white-space: nowrap;
}

#main .images-container .js-qv-mask.scroll {
    width: calc(100% - 60px);
    margin: 0 auto;
}

.scroll-box-arrows {
    display: none;
}

.scroll-box-arrows.scroll {
    display: none;
}

.scroll-box-arrows i {
    border: 1px solid #e5e5e5;
    bottom: 55px;
    color: #111;
    cursor: pointer;
    font-size: 20px;
    height: 27px;
    line-height: 22px;
    overflow: hidden;
    padding: 2px;
    position: absolute;
    width: 27px;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
}

.scroll-box-arrows i:hover {
    background: #ee0000;
    border-color: #ee0000;
    color: #111;
}

.scroll-box-arrows .left {
    left: 0;
}

.scroll-box-arrows .right {
    right: 0;
}

#product-availability {
    display: inline-block;
    font-weight: normal;
    margin: 10px 0 13px 32px;
    float: right;
    display: none;
}

.product-minimal-quantity {
    float: left;
    width: 100%;
}

#product-availability .material-icons {
    line-height: inherit;
}

#product-availability .product-available {
    color: #4cbb6c;
}

#product-availability .product-unavailable {
    color: #ff9a52;
}

#product-availability .product-last-items {
    color: #ff9a52;
}

#product-details .label {
    color: #111111;
    font-size: 14px;
    font-weight: 500;
}

.product-features {
    margin-top: 1.25rem;
    margin-left: 0.3125rem;
}

.product-features>dl.data-sheet {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -moz-box-align: start;
    box-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    -o-align-items: flex-start;
    align-items: flex-start;
    -ms-flex-align: start;
    border: 1px solid #e5e5e5;
    border-bottom: none;
}

.product-features>dl.data-sheet dd.value,
.product-features>dl.data-sheet dt.name {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 40%;
    -moz-flex: 1 0 40%;
    -ms-flex: 1 0 40%;
    flex: 1 0 40%;
    font-weight: normal;
    background: #ebebeb;
    padding: 0.625rem;
    margin-right: 0;
    min-height: 2.5rem;
    word-break: break-all;
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    background: #ffffff;
    margin-bottom: 0;
}

.product-features>dl.data-sheet dd.value:nth-of-type(even),
.product-features>dl.data-sheet dt.name:nth-of-type(even) {
    background: #f5f5f5;
}

.product-features>dl.data-sheet dt.name {
    text-transform: capitalize;
}

.product-variants>.product-variants-item {
    float: left;
    margin: 0 0 15px;
    width: 100%;
}

.product-variants>.product-variants-item select {
    border: 1px solid #e5e5e5;
    height: 2.5rem;
    width: 3.75rem;
    cursor: pointer;
}

.product-features>dl.data-sheet dd.value {
    border-right: none;
}

.product-variants>.product-variants-item ul li {
    margin-right: 0.625rem;
}

.product-variants>.product-variants-item .color {
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
}

.product-flags {
    position: absolute;
    top: 0;
    left: 0;
}

span.discount-in-top-product {
    background: $orange;
    color: white;
    text-transform: none;
    padding: 16px 5px 0px 5px;
    width: 50px;
    height: 50px;
    border-radius: 45px;
    text-align: center;
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
}

.products .thumbnail-container .ttproduct-image .product-flags {
    /* display: none;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;*/
}

.products .thumbnail-container:hover .ttproduct-image .product-flags {
    display: block;
}

@media (max-width: 767px) {
    .products .thumbnail-container .ttproduct-image .product-flags {
        display: block;
    }
}

li.product-flag {
    display: block;
    position: relative;
    z-index: 1;
    background: $orange;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
}

li.product-flag.online-only {
    color: #111;
    font-size: 0.8125rem;
    left: auto;
    right: 15px;
    top: 33px;
}

li.product-flag.online-only::before {
    content: "\E30A";
    font-family: 'Material Icons';
    vertical-align: middle;
    margin: 0.3125rem;
}

li.product-flag.new {
    margin: 0;
    text-align: center;
    background: $green;
    top: 0 !important;
}

li.product-flag.on-sale {
    // color: #111000;
    margin: 0;
    // right: 15px;
    text-align: center;
    // top: 10px;
    //left: auto;   
}

.product-customization {
    margin: 0 0 15px;
    float: left;
    width: 100%;
}

#product .product-customization .card {
    margin: 0;
}

.product-customization .card-title {
    font-size: 16px;
    font-weight: 500;
    color: #111;
}

.product-customization .product-customization-item {
    margin: 10px 0;
}

.product-customization .product-message {
    background: #ebebeb;
    border: none;
    width: 100%;
    height: 40px;
    resize: none;
    padding: 0.625rem;
}

.product-customization .product-message::-webkit-input-placeholder {
    color: #777777;
}

.product-customization .product-message::-moz-placeholder {
    color: #777777;
}

.product-customization .product-message:-moz-placeholder {
    color: #777777;
}

.product-customization .product-message:-ms-input-placeholder {
    color: #777777;
}

.product-customization .file-input {
    width: 100%;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 2.625rem;
    overflow: hidden;
    position: absolute;
}

.product-customization .custom-file {
    position: relative;
    background: #ebebeb;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    text-indent: 0.625rem;
    display: block;
    color: #777777;
    margin-top: 1.25rem;
}

.product-customization .custom-file button {
    z-index: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.product-customization small {
    color: #777777;
}

.product-pack {
    float: left;
    margin-top: 15px;
    width: 100%;
}

.product-pack .tt-producttitle {
    border: medium none;
}

.product-pack .h4 {
    font-weight: normal;
    margin: 5px 0 15px;
}

.product-pack .pack-product-container {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    justify-content: space-around;
}

.product-pack .pack-product-price strong {
    color: #111;
    font-weight: 500;
}

.product-pack .pack-product-container .pack-product-name {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    font-size: 0.875rem;
    color: #777777;
}

@media (max-width: 543px) {
    .product-pack .pack-product-container .pack-product-name {
        -webkit-flex: 0 0 30%;
        -moz-flex: 0 0 30%;
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
    }
}

.product-pack .pack-product-container .pack-product-quantity {
    border-left: #ebebeb 1px solid;
    padding-left: 0.625rem;
}

.product-pack .pack-product-container .pack-product-name,
.product-pack .pack-product-container .pack-product-price,
.product-pack .pack-product-container .pack-product-quantity {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    align-items: center;
    line-height: 24px;
}

.product-refresh {
    margin-top: 1.25rem;
}

.social-sharing {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    margin-top: 0;
}

.product-additional-info {
    // float: left;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    span:first-child {
        margin-right: 20px;
    }
}

.social-sharing ul {
    margin-bottom: 0;
}

.social-sharing li {
    box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    display: inline-block;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: 0.25rem 0.25rem;
    cursor: pointer;
    margin-left: 0.5rem;
}

.social-sharing li a {
    display: block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
}

.social-sharing li a:hover {
    color: transparent;
}

.products-selection {
    border: 1px solid #e5e5e5;
    float: left;
    margin-bottom: 30px;
    padding: 10px;
    width: 100%;
}

.products-selection .title {
    color: #878787;
}

#blockcart-modal .cart-content .btn {
    border: none;
    margin-bottom: 0.625rem;
    margin-top: 20px;
}

@media (max-width: 991px) {
    #blockcart-modal .cart-content .btn {
        margin-top: 0;
    }
}

#blockcart-modal .cart-content .btn.btn-secondary {
    background: #111;
    border: none;
    color: #fff;
}

#blockcart-modal .cart-content .btn.btn-secondary:hover,
#blockcart-modal .cart-content .btn.btn-secondary:focus {
    background: $orange;
    color: #fff;
}

@media (max-width: 991px) {
    .product-cover img {
        width: 100%;
    }

    #product-modal .modal-content .modal-body {
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        box-orient: vertical;
        -webkit-box-direction: normal;
        -moz-box-direction: normal;
        box-direction: normal;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        flex-direction: row;
        -ms-flex-direction: row;
        margin-left: 0;
    }

    #product-modal .modal-content .modal-body img.product-cover-modal {
        width: 100%;
    }

    #product-modal .modal-content .modal-body .image-caption {
        width: 100%;
    }

    #blockcart-modal .modal-dialog {
        width: calc(100% - 20px);
    }

    #blockcart-modal .modal-body {
        padding: 1.875rem;
    }
}

@media (max-width: 767px) {
    #blockcart-modal .divide-right {
        border-right: none;
    }

    #blockcart-modal .modal-body {
        padding: 1rem;
    }
}

.cart-grid {
    margin-bottom: 1rem;
}

.cart-items {
    margin-bottom: 0;
}

.cart-item {
    padding: 1rem 0;
}

.cart-summary-line {
    clear: both;
}

.cart-summary-line::after {
    content: "";
    display: table;
    clear: both;
}

.cart-summary-line .label {
    padding-left: 0;
    font-weight: normal;
    white-space: inherit;
}

.cart-summary-line .value {
    color: #111;
    float: right !important;
}

.cart-summary-line.cart-summary-subtotals .label,
.cart-summary-line.cart-summary-subtotals .value {
    font-weight: normal;
}


/** CART BODY **/

.cart-grid-body {
    margin-bottom: 0.75rem;
}

.cart-grid-body a.label:hover {
    color: #111000;
}

.cart-grid-body .card-block {
    padding: 1rem;
}

.cart-grid-body .card-block h1 {
    color: #111;
    font-weight: normal;
    margin-bottom: 0;
    text-transform: capitalize;
}

.cart-grid-body hr {
    margin: 0;
}

.cart-grid-body .cart-overview {
    padding: 1rem;
}

#cart .product-price strong {
    color: #111;
    font: 500 18.12px/20px 'Work Sans', sans-serif;
}

.card.cart-summary {
    background: #f5f5f5;
}

.card.cart-container .cart-item {
    border-bottom: 1px solid #e5e5e5;
}

.card.cart-container .cart-item:last-child {
    border: none;
}

#content-hook_payment_return .card-block dl {
    float: left;
    width: 100%;
    display: block;
}


/** CART RIGHT **/

#cart .product-line-grid .product-line-info {
    margin: 0 0 5px;
}

#cart .product-line-grid .product-line-info.has-discount .discount {
    font-size: 13px;
    margin: 5px 0;
    padding: 5px 7px;
}

.cart-grid-right hr {
    margin: 0;
}

.cart-grid-right .promo-discounts {
    margin-bottom: 0;
}

.cart-grid-right .promo-discounts .cart-summary-line .label {
    color: #878787;
}

.cart-grid-right .promo-discounts .cart-summary-line .label .code {
    text-decoration: underline;
    cursor: pointer;
}

.block-promo .promo-code {
    padding: 1.60rem;
    background: #ebebeb;
}

.block-promo .promo-code .alert-danger {
    position: relative;
    margin-top: 1.25rem;
    background: #ff4c4c;
    color: white;
    display: none;
}

.block-promo .promo-code .alert-danger::after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #ff4c4c;
    border-width: 10px;
    margin-left: -10px;
}

.block-promo .promo-input {
    color: #111;
    border: #777777 1px solid;
    height: 2.5rem;
    text-indent: 0.625rem;
    width: 60%;
}

.block-promo .promo-input+button {
    margin-top: -4px;
    text-transform: capitalize;
}

.block-promo .cart-summary-line .label,
.block-promo .promo-name {
    color: #ff9a52;
    font-weight: 600;
}

.block-promo .cart-summary-line .label a,
.block-promo .promo-name a {
    font-weight: normal;
    color: #111;
    display: inline-block;
}

.block-promo .promo-code-button {
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
    display: inline-block;
}

.block-promo.promo-highlighted {
    padding: 1.25rem;
    padding-bottom: 0;
    margin-bottom: 0;
}


/** CONTENT LEFT **/

.product-line-grid-left img {
    max-width: 100%;
    border: 1px solid #e5e5e5;
}


/** CONTENT BODY **/

.product-line-grid-body>.product-line-info>.label {
    padding: 0;
    line-height: inherit;
    text-align: left;
    white-space: inherit;
}

.product-line-grid-body>.product-line-info>.out-of-stock {
    color: red;
}

.product-line-grid-body>.product-line-info>.available {
    color: #4cbb6c;
}

.product-line-grid-body>.product-line-info>.unit-price-cart {
    padding-left: 0.3125rem;
    font-size: 0.875rem;
    color: #878787;
}


/** CONTENT LEFT **/

.product-line-grid-right .bootstrap-touchspin {
    width: 4.25rem;
    float: left !important;
}

.product-line-grid-right .bootstrap-touchspin>.form-control,
.product-line-grid-right .bootstrap-touchspin>.input-group {
    color: #111;
    background-color: white;
    height: 2.5rem;
    padding: 0.175rem 0.5rem;
    width: 3rem;
}

.product-line-grid-right .bootstrap-touchspin>.input-group-btn-vertical {
    width: auto;
}

.product-line-grid-right .cart-line-product-actions,
.product-line-grid-right .product-price {
    color: #111;
    line-height: 36px;
}

.product-line-grid-right .cart-line-product-actions .remove-from-cart,
.product-line-grid-right .product-price .remove-from-cart {
    color: #111;
    display: inline-block;
    margin-top: 0.3125rem;
}

.product-line-grid-right .cart-line-product-actions .remove-from-cart:hover,
.product-line-grid-right .product-price .remove-from-cart:hover {
    color: #777777;
}


/*** Responsive part ***/

@media (max-width: 767px) {
    .product-line-grid-body {
        margin-bottom: 1rem;
    }
}

@media (max-width: 543px) {
    .cart-items {
        padding: 1rem 0;
    }

    .cart-item {
        border-bottom: 1px solid #e5e5e5;
    }

    .cart-item:last-child {
        border-bottom: 0;
    }

    .cart-grid-body .cart-overview {
        padding: 0;
    }

    .cart-grid-body .no-items {
        padding: 1rem;
        display: inline-block;
    }

    .product-line-grid-left {
        padding-right: 0 !important;
    }
}

@media (max-width: 360px) {
    .product-line-grid-right .qty {
        width: 100%;
    }

    .product-line-grid-right .price {
        width: 100%;
    }
}

#block-reassurance {
    margin-top: 15px;
    border: 1px solid #e5e5e5;
    float: left;
    width: 100%;
}

#block-reassurance>ul {
    margin: 0;
    padding: 0;
}

#block-reassurance img {
    opacity: .7;
    width: 1.5625rem;
    margin-right: 0.625rem;
}

#block-reassurance li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#block-reassurance li:last-child {
    border: 0;
}

#block-reassurance li .block-reassurance-item {
    padding: 10px;
}

.block-reassurance-item .h6 {
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
}

#product #block-reassurance {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
}

#product #block-reassurance ul {
    margin: 0;
}

#product #block-reassurance span {
    font-weight: 400;
    font-size: 14px;
}

.quickview .modal-dialog {
    width: calc(100% - 30px);
    max-width: 64rem;
}

.quickview .social-sharing {
    margin-top: 0;
}

.quickview .arrows .material-icons {
    height: 30px;
    width: 30px;
    border: 1px solid #e5e5e5;
    padding: 4px;
    font-size: 20px;
    left: 0;
    right: 0;
    color: #111;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
}

.quickview .arrows .material-icons:hover {
    background: $orange;
    border-color: $orange;
    color: #fff;
}

.quickview .modal-content {
    float: left;
    width: 100%;
    border: none;
}

.quickview .modal-header {
    border: none;
    padding: 0.625rem;
}

.quickview .modal-body {
    float: left;
    width: 100%;
    margin: 30px 0;
}

@media (max-width: 991px) {
    .quickview .modal-body {
        margin: 10px 0;
    }
}

@media (max-width: 767px) {
    .quickview .modal-body {
        margin: 0;
    }
}

.quickview .modal-footer {
    // border-top: 1px solid rgba(172, 170, 166, 0.3);
    float: left;
    width: 100%;
}

.modal-footer {
    border-top: none;
}

.quickview .layer {
    display: none;
}

.quickview .images-container {
    position: static;
    z-index: 1;
    float: left;
    width: 100%;
}

.quickview .images-container .ttproduct-images>li.thumb-container>.thumb {
    width: 4.9375rem;
    margin-bottom: 0.8125rem;
    background: white;
}

.quickview .mask {
    height: 415px;
    margin: 25px 0;
    overflow: hidden;
    padding: 0 0 0 20px;
    width: 25%;
}

.quickview .mask ul li img {
    margin: 5px 0 !important;
}

.quickview .mask ul li:last-child img {
    margin: 0;
}

.quickview .arrows {
    position: absolute;
    top: 0;
    height: 450px;
    right: 50px;
    z-index: 99;
}

.quickview .arrows .arrow-up {
    margin-top: -10px;
    cursor: pointer;
}

.quickview .arrows .arrow-down {
    position: absolute;
    bottom: -1.875rem;
    cursor: pointer;
}

#stores .page-stores {
    width: 100%;
    margin: 0 auto;
}

#stores .page-stores .store-item {
    padding-left: 0.75rem;
    margin: 0 0 30px;
    padding-right: 0.75rem;
}

#stores .page-stores .store-picture img {
    max-width: 100%;
}

#stores .page-stores .store-item-container {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: distribute;
    -moz-box-pack: distribute;
    box-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    -o-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: distribute;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    padding: 1.875rem 0;
}

#stores .page-stores .store-item-container ul {
    margin-bottom: 0;
    font-size: 0.9375rem;
}

#stores .page-stores .store-item-container .divide-left {
    border-left: #ebebeb 1px solid;
}

#stores .page-stores .store-item-container .divide-left tr {
    height: 1.5625rem;
}

#stores .page-stores .store-item-container .divide-left td {
    padding-left: 0.375rem;
}

#stores .page-stores .store-item-container .divide-left th {
    color: #111;
    font-weight: 500;
    text-align: right;
}

#stores .page-stores .store-item-container .store-description {
    font-size: 1rem;
}

#stores .page-stores .store-item-footer {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: distribute;
    -moz-box-pack: distribute;
    box-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    -o-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: distribute;
}

#stores .page-stores .store-item-footer.divide-top {
    border-top: #ebebeb 1px solid;
}

#stores .page-stores .store-item-footer div:first-child {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 65%;
    -moz-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
}

#stores .page-stores .store-item-footer i.material-icons {
    margin-right: 0.625rem;
    color: #777777;
    font-size: 1rem;
}

#stores .page-stores .store-item-footer li {
    margin-bottom: 0.625rem;
}

.h3.card-title {
    color: #111;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 14px;
    text-transform: capitalize;
}


/*** Responsive part ***/

@media (max-width: 767px) {
    #stores .page-stores {
        width: 100%;
    }

    #stores .page-stores .store-item-container {
        padding: 1rem 0;
    }
}

@media (max-width: 543px) {
    #stores .page-stores .store-item-container {
        display: block;
    }

    #stores .page-stores .store-item-container .divide-left {
        border-left: none;
    }

    #stores .page-stores .store-item-container .store-description a {
        margin-bottom: 0.5rem;
    }

    #stores .page-stores .store-item-container .store-description address {
        margin-bottom: 0.5rem;
    }

    #stores .page-stores .store-item-footer {
        display: block;
    }

    #stores .page-stores .store-item-footer.divide-top {
        border-top: #ebebeb 1px solid;
    }

    #stores .page-stores .store-item-footer li {
        margin-bottom: 0.625rem;
    }

    #stores .page-stores .store-item-footer .card-block {
        padding: 0.75rem 0.75rem 0;
    }
}

.block_newsletter {
    padding: 0;
}

.block_newsletter form {
    position: relative;
}

.block_newsletter form input[type=text] {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #ffffff;
    border-radius: 0 25px 25px 0;
    -moz-border-radius: 0 25px 25px 0;
    -webkit-border-radius: 0 25px 25px 0;
    -khtml-border-radius: 0 25px 25px 0;
    float: left;
    padding: 0 125px 0 20px;
    width: 100%;
}

@media (max-width: 543px) {
    .block_newsletter form input[type=text] {
        padding: 0 70px 0 15px;
    }
}

.block_newsletter form input[type=text]:focus+button .search {
    color: #111;
}

.block_newsletter form input[type=text]::-webkit-input-placeholder {
    color: #111;
}

.block_newsletter form input[type=text]::-moz-placeholder {
    color: #111;
}

.block_newsletter form input[type=text]:-ms-input-placeholder {
    color: #111;
    // text-indent: 0.625rem;
}

.block_newsletter form button[type=submit] {
    position: absolute;
    background: none;
    border: none;
    bottom: 0.3125rem;
    right: 0.125rem;
    color: #878787;
}

.block_newsletter form button[type=submit] .search:hover {
    color: #111000;
}

.block_newsletter p {
    padding: 10px 15px;
    font-size: 13px;
    margin: 5px 0 0;
}

.block_newsletter form .input-wrapper {
    overflow: hidden;
    border-radius: 20px;
}

.block_newsletter form input {
    height: 42px;
}

.block-contact {
    color: #777777;
}

.block-contact .block-contact-title {
    color: #111;
}

.contact-form .form-control-label {
    text-align: left;
}

.linklist .blockcms-title a {
    color: #111;
}

.account-list a {
    color: #777777;
}

.account-list a:hover {
    color: #111000;
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
    font-weight: 700;
    font-size: 1rem;
}

.social-sharing .facebook {
    background: url("../img/social-sprite.png");
}

.social-sharing.facebook::before {
    content: "";
    background: url("../img/social-sprite.png");
}

.social-sharing .facebook.icon-gray {
    background-image: url(../css/d3fbae74a276a9f6cf1d6241f29b61a3.svg);
}

.social-sharing .facebook.icon-gray:hover {
    background-image: url(../css/8b05d51ede908907d65695558974d86f.svg);
}

.social-sharing .twitter {
    background-image: url(../css/a67175d1ea11389d9107bd8c08f9d7d7.svg);
}

.social-sharing .twitter::before {
    content: "";
    background-image: url(../css/ffddcb3736980b23405b31142a324b62.svg);
}

.social-sharing .twitter.icon-gray {
    background-image: url(../css/d1a435fd2571e67858a31ee954b52278.svg);
}

.social-sharing .twitter.icon-gray:hover {
    background-image: url(../css/ffddcb3736980b23405b31142a324b62.svg);
}

.social-sharing .rss {
    background-image: url(../css/cf2f3c1d5c7cb02c29f61964313148e1.svg);
}

.social-sharing .youtube {
    background-image: url(../css/22c0528acb6d9cd5bf4c8f96381bc05c.svg);
}

.social-sharing .googleplus {
    background-image: url(../css/36bff72dcba3098b4b70b482b22c29ab.svg);
}

.social-sharing .googleplus::before {
    content: "";
    background-image: url(../css/c1a65805f759901a39d10eb854c1dcf2.svg);
}

.social-sharing .googleplus.icon-gray {
    background-image: url(../css/7c24d4275efcbe8891f78378b7c808e0.svg);
}

.social-sharing .googleplus.icon-gray:hover {
    background-image: url(../css/c1a65805f759901a39d10eb854c1dcf2.svg);
}

#block_myaccount_infos .myaccount-title a {
    color: #111;
}

.pinterest::before {
    content: "";
    background-image: url(../css/b1db819132e64a3e01911a1413c33acf.svg);
}

.pinterest.icon-gray {
    background-image: url(../css/7988be8b63edf9c4accebf94d5db7927.svg);
}

.pinterest.icon-gray:hover {
    background-image: url(../css/b1db819132e64a3e01911a1413c33acf.svg);
}

.block-social.header {
    padding: 10px 0 5px;
    float: right;
}

@media (max-width: 991px) {
    .block-social.header {
        width: 100%;
        text-align: center;
    }
}

#footer {
    .block-social {
        float: left;
        padding: 10px 0 10px;
        text-align: center;
        width: 100%;
    }
}

#footer .block-social ul {
    display: inline-block;
    float: none;
    margin: 0 auto;
    padding: 0 !important;
    vertical-align: top;
    width: auto;
}

.block-social ul li {
    float: left;
    height: auto;
    margin: 0 0 0 12px !important;
    width: auto;
    cursor: pointer;
}

.block-social ul li a {
    display: block;
    height: 30px;
    width: 25px;
    float: left;
}

.block-social ul li a p {
    color: #fff;
    font: 400 14px/32px 'Work Sans', sans-serif;
    margin: 0 0 0 10px;
    float: right;
    display: none;
}

@media (max-width: 991px) {
    .block-social ul li {
        cursor: pointer;
        display: inline-block;
        float: none;
        height: auto;
        vertical-align: top;
        margin: 0 5px 0 0 !important;
    }
}

@media (max-width: 767px) {
    .block-social {
        padding: 0 0 5px;
        text-align: center;
        width: 100%;
    }
}

.block-social ul li.facebook a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -25px;
}

.block-social ul li.facebook a:hover:before {
    background-position: -107px -25px;
}

.block-social ul li.twitter a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -105px;
}

.block-social ul li.twitter a:hover:before {
    background-position: -107px -105px;
}

.block-social ul li.rss a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -185px;
}

.block-social ul li.rss a:hover:before {
    background-position: -107px -185px;
}

.block-social ul li.youtube a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -265px;
}

.block-social ul li.youtube a:hover:before {
    background-position: -107px -265px;
}

.block-social ul li.linkedin a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -665px;
}

.block-social ul li.linkedin a:hover:before {
    background-position: -107px -665px;
}

.block-social ul li.googleplus a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -345px;
}

.block-social ul li.googleplus a:hover:before {
    background-position: -107px -345px;
}

.block-social ul li.pinterest a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -425px;
}

.block-social ul li.pinterest a:hover:before {
    background-position: -107px -425px;
}

.block-social ul li.vimeo a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -505px;
}

.block-social ul li.vimeo a:hover:before {
    background-position: -107px -505px;
}

.block-social ul li.instagram a::before {
    background: transparent url("../img/social-sprite.png") no-repeat scroll -27px -585px;
}

.block-social ul li.instagram a:hover:before {
    background-position: -107px -585px;
}

.block-social ul li a::before {
    content: "";
    float: left;
    height: 30px;
    width: 25px;
}

.footer-container li a {
    color: #777;
    cursor: pointer;
    font: 400 13px/24px 'Work Sans', sans-serif;
}

.footer-container li a:focus,
.footer-container li a:hover {
    color: #111;
}

.footer-container .links .collapse {
    display: inherit;
    padding: 0;
    margin: 0;
}

@media (max-width: 991px) {
    .footer-container .links .collapse {
        float: left;
        width: 100%;
    }

    .footer-container {
        margin-top: 0;
    }

    .footer-container .wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .footer-container .links .title[aria-expanded="true"] .collapse-icons .add {
        display: none;
    }

    .footer-container .links .title[aria-expanded="true"] .collapse-icons .remove {
        display: block;
    }

    .footer-container .links .navbar-toggler {
        display: inline-block;
        padding: 0;
    }

    .footer-container .links ul {
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .block-contact {
        padding-left: 1.5rem;
    }

    .footer-container .links .title {
        padding: 0.625rem;
        cursor: pointer;
        float: left;
        width: 100%;
    }

    .footer-container .links .h3,
    #ttcmspaymentlogo .h3 {
        color: #111;
        font: 400 16px/22px 'Work Sans', sans-serif;
        margin: 0 !important;
        text-transform: capitalize;
    }
}

.contact-rich {
    color: #878787;
    margin-bottom: 1rem;
    float: left;
}

#page {
    float: left;
    width: 100%;
    position: relative;
}

#content-wrapper>section#main {
    float: left;
    width: 100%;
}

#left-column .contact-rich,
#right-column .contact-rich {
    padding: 15px;
}

.contact-rich h4,
.contact-form h3 {
    color: #111;
    font-weight: normal;
    margin-bottom: 20px;
}

.contact-rich hr {
    display: none;
}

.contact-rich .block:last-child {
    border: none;
    display: none;
}

.contact-rich .block .icon {
    float: left;
    margin: 0 10px 0 0;
    width: 22px;
}

.contact-rich .block .icon i {
    font-size: 18px;
    color: $orange;
}

.contact-rich .block .data {
    color: #777;
    font: 400 13px/20px 'Work Sans', sans-serif;
    margin: 0 0 5px;
    overflow: hidden;
    width: auto;
}

.contact-rich .block .data.email {
    padding-top: 0.375rem;
}

.contact-form {
    color: #777777;
    width: 100%;
}

#contact .contact-form span.group-span-filestyle .btn {
    padding: 6px 7px;
}

#contact .contact-rich .block .data {
    color: #777777;
}

@media (max-width: 991px) {

    #contact #left-column,
    #contact #right-column {
        margin-bottom: 20px;
        padding: 0;
        width: 100%;
    }

    #left-column .contact-rich .block,
    #right-column .contact-rich .block {
        display: block;
    }

    #contact #content-wrapper {
        width: 100%;
        padding: 0;
    }
}

#products #main .page-header,
#pagenotfound #main .page-header {
    margin: 2rem 0 3rem;
}

#products #main .page-content,
#pagenotfound #main .page-content {
    margin-bottom: 10rem;
}

#products .page-not-found,
#pagenotfound .page-not-found {
    border: 1px solid #e5e5e5;
    color: #878787;
    font-size: 0.875rem;
    margin: 0 auto;
    max-width: 100%;
    padding: 1rem;
}

#products .page-not-found h4,
#pagenotfound .page-not-found h4 {
    color: #111;
    font-weight: normal;
    margin: 0 0 20px;
}

#products .page-not-found .search-widget,
#pagenotfound .page-not-found .search-widget {
    float: none;
    padding: 0;
    position: static;
    border: none;
}

#products .page-not-found #search_widget .ttsearch-content,
#pagenotfound .page-not-found #search_widget .ttsearch-content {
    display: none;
}

.page-content .search-widget .ttsearch_button {
    display: none;
}

#products .page-not-found #search_widget .ttsearchtoggle,
#pagenotfound .page-not-found #search_widget .ttsearchtoggle {
    border: 1px solid #e5e5e5;
    display: block !important;
    height: 40px;
    position: static;
    width: 100%;
}

#products .page-not-found .search-widget form,
#pagenotfound .page-not-found .search-widget form {
    display: block;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

#products .page-not-found .search-widget form .search_block_top_form,
#pagenotfound .page-not-found .search-widget form .search_block_top_form {
    position: relative;
    top: 0;
}

#products .page-not-found .search-widget input,
#pagenotfound .page-not-found .search-widget input {
    width: 100%;
    bottom: auto;
    height: 40px;
    font-size: 16px;
    color: #666;
    border: none;
    padding: 0 55px 0 10px;
}

#products .page-not-found #search_widget .search_block_top_form>button,
#pagenotfound .page-not-found #search_widget .search_block_top_form>button {
    background: none;
    color: #111;
    right: 0;
    top: 0;
}

.customization-modal .modal-content {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    border: 1px solid #e5e5e5;
}

.customization-modal .modal-content .modal-body {
    padding-top: 0;
}

.customization-modal .modal-content .modal-body .product-customization-line {
    padding-bottom: 0.9375rem;
    padding-top: 0.9375rem;
    border-bottom: 1px solid #e5e5e5;
}

.customization-modal .modal-content .modal-body .product-customization-line .label {
    font-weight: bold;
    text-align: right;
}

.customization-modal .modal-content .modal-body .product-customization-line:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}


/*** WRAPPER ***/

#wrapper .banner {
    margin-bottom: 1.5rem;
    display: block;
}

#wrapper .banner img {
    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
}

#wrapper .breadcrumb {
    margin: 0 0 30px;
    //text-align: center;
    padding: 18px 10px;
    float: left;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
}

@media (max-width: 991px) {
    #wrapper .breadcrumb {
        margin: 0 0 15px;
    }
}

#index #wrapper .breadcrumb[data-depth="1"] {
    display: none !important;
}

#wrapper .breadcrumb[data-depth="1"] {
    display: block;
}

#wrapper .breadcrumb ol {
    padding: 0;
    margin-bottom: 0;
}

#wrapper .breadcrumb li {
    display: inline-block;
}

#wrapper .breadcrumb ol li:last-child {
    text-transform: lowercase;
}

#wrapper .breadcrumb ol li:last-child:first-letter {
    text-transform: uppercase;
}

#wrapper .breadcrumb li::after {
    color: #111;
    content: "/";
    margin: 7px;
}

body.category-id-parent-1 #wrapper .breadcrumb li::after {
    content: "";
}

#wrapper .breadcrumb li:last-child::after {
    content: "";
    display: none;
}

#wrapper .breadcrumb li a {
    color: #111;
    font: 400 12px/14px 'Work Sans', sans-serif;
}

@media (max-width: 543px) {
    #wrapper .breadcrumb li a {
        font-size: 13px;
        line-height: 14px;
    }
}

#wrapper .columns_inner .category-cover {
    float: left;
    width: 100%;
}

#wrapper .columns_inner .breadcrumb-img {
    float: left;
    width: 100%;
}

#wrapper .columns_inner .breadcrumb-img img {
    max-width: 100%;
}

#index #wrapper .breadcrumb-img {
    display: none;
}


/*** MAIN ***/

#main .page-header {
    float: left;
    margin-bottom: 10px;
    width: 100%;
}

#main .page-content {
    margin-bottom: 1.5625rem;
}

#main .page-content h6 {
    margin-bottom: 1.125rem;
    font-weight: normal;
}

#main .page-footer {
    margin-bottom: 0;
    float: left;
    width: 100%;
}

#main .page-footer .text-sm-center a {
    padding: 8px 20px;
}

#notifications ul {
    float: left;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}


/*** FOOTER ***/


/*** Responsive part ***/

@media (max-width: 991px) {
    #checkout-cart-summary {
        float: none;
        width: 100%;
        margin-top: 1rem;
    }

    #header .header-top a[data-depth="0"] {
        color: #111;
    }

    section.checkout-step {
        width: 100%;
    }

    .default-input {
        min-width: 100%;
    }

    label {
        clear: both;
    }

    .block-contact {
        padding-left: 0.9375rem;
        border: none;
    }

    .menu,
    .dropdown-item {
        padding-left: 0;
    }

    .sub-menu {
        left: 0;
        min-width: 100%;
    }

    #blockcart-modal .ttproduct-image {
        width: 100%;
        display: block;
        max-width: 250px;
        margin: 0 auto 0.9375rem;
    }

    #blockcart-modal .cart-content {
        padding-left: 0;
    }

    #blockcart-modal .product-name,
    #product-availability {
        margin-top: 0.625rem;
    }

    #search_filters .facet .facet-label {
        text-align: left;
    }

    .block-category .category-cover {
        position: relative;
        text-align: center;
    }

    .block-category {
        padding-bottom: 0;
    }
}


/*# sourceMappingURL=theme.css.map*/


/* ---------------------- TemplateTrip - goToTop --------------------------*/

#goToTop {
    position: fixed;
    bottom: 45px;
    right: 30px;
    z-index: 1;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    background: $orange;
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    -ms-transition: opacity 0.2s ease-out;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
}

@media (max-width: 767px) {
    #goToTop {
        right: 20px;
    }
}

#goToTop i {
    float: left;
    font-size: 22px;
    height: 100%;
    padding: 8px;
    width: 100%;
}

#goToTop:hover {
    color: #fff;
    background: #111;
}

#goToTop.show {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

#index #content {
    text-align: center;
}

#content {
    float: left;
    width: 100%;
}

@media (max-width: 543px) {
    #goToTop {
        bottom: 50px;
    }
}


/* ---------------------- End TemplateTrip - goToTop --------------------------*/


/*-------------- Start Search css -------------*/

.search-widget {
    float: right;
    position: relative;
    z-index: 3;
    margin: 5px 0;
}

.search-widget .ttsearch_button {
    background: rgba(0, 0, 0, 0) url("../../assets/img/sprite.png") no-repeat scroll -60px -228px;
    cursor: pointer;
    float: right;
    height: 50px;
    width: 50px;
    z-index: 1;
    display: none;
}

.search-widget.active .ttsearch_button {
    z-index: 1;
    cursor: pointer;
    background: none;
}


/*.search-widget .ttsearch_button:hover {
    background: rgba(0, 0, 0, 0) url("../../assets/img/sprite.png") no-repeat scroll -60px -227px;
}*/

.search-widget.active .ttsearch_button::before {
    color: #fff;
    content: "î";
    float: left;
    font-family: "Material Icons";
    font-size: 25px;
    height: 50px;
    line-height: 42px;
    padding: 5px 10px;
    width: 50px;
}

.search-widget.active .ttsearch_button:hover {
    background: none;
}

.ttsearch_button .material-icons.search {
    display: none;
}

#search_widget .ttsearchtoggle {
    background: #fff;
    height: 40px;
    width: 320px;
    z-index: 1;
    display: block;
    border: 1px solid #fff;
}

@media(min-width: 992px) {
    #search_widget .ttsearchtoggle {
        display: block !important;
    }
}

.search-widget #search_query_top {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #111 !important;
    display: inline;
    outline: medium none;
    padding: 5px 55px 5px 10px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 500ms ease-in-out 0s;
    -webkit-transition: all 500ms ease-in-out 0s;
    -moz-transition: all 500ms ease-in-out 0s;
    -o-transition: all 500ms ease-in-out 0s;
    -ms-transition: all 500ms ease-in-out 0s;
    width: 100%;
    height: 40px;
}

.search-widget form button[type="submit"] .material-icons.search {
    display: none;
}

.ttsearch_button .material-icons.search {
    display: none;
}

@media (max-width: 991px) {
    .search-widget {
        bottom: 0;
        margin: 0 auto;
        position: absolute;
        right: 90px;
    }

    .search-widget .ttsearch_button {
        display: block;
    }

    #search_widget .ttsearchtoggle {
        display: none;
        padding: 0;
        position: absolute;
        right: 0;
        top: 50px;
        border: 1px solid #e5e5e5;
    }
}

@media (max-width: 543px) {
    #search_widget .ttsearchtoggle {
        width: 300px;
        right: -95px;
    }
}


/*-------------- End Search css -------------*/

#_desktop_logo,
#_mobile_logo {
    float: left;
    margin: 25px 0;
    padding: 0;
    //width: auto;
    text-align: left;
}

#_mobile_logo {
    display: none;
}

@media (min-width: 992px) and (max-width: 1199px) {

    #_desktop_logo,
    #_mobile_logo {
        width: 220px;
    }
}

@media (max-width: 991px) {
    #_mobile_logo {
        display: inline-block;
        float: none;
        margin: 25px 0;
        text-align: center;
        vertical-align: top;
        width: 100%;
    }
}


/*---------------Start Top menu dropdown icon css-----------------------*/

#_desktop_top_menu .top-menu .float-xs-right.hidden-md-up {
    display: block !important;
}

#_desktop_top_menu .top-menu .float-xs-right .navbar-toggler .material-icons {
    font-size: 15px;
    vertical-align: top;
}

#_desktop_top_menu .top-menu li:hover a[data-depth="0"] .material-icons.remove {
    display: none;
}

#_desktop_top_menu .top-menu li:hover a[data-depth="0"] .material-icons.add {
    display: none;
}

#_desktop_top_menu .top-menu .material-icons.add-remove {
    display: none;
}

#_desktop_top_menu .top-menu .material-icons.add,
#_desktop_top_menu .top-menu .material-icons.remove {
    display: none;
}

#_desktop_top_menu .sub-menu .top-menu .float-xs-right.hidden-md-up {
    display: none !important;
}

#_desktop_top_menu .top-menu li.more_menu:hover a[data-depth="0"] .material-icons.remove {
    display: none;
}


/*---------------End Top menu dropdown icon css-----------------------*/

#header .header-top .row {
    margin: 0;
}

.header-top .top-menu {
    display: inline-block;
    float: left;
    margin: 0;
    width: 100%;
    padding: 0;
    position: relative;
}

.header-top .topmenu {
    background: #ff4661;
    float: left;
    position: relative;
    width: 100%;
    margin: 0 0 20px;
}

@media (max-width: 767px) {
    .header-top .topmenu {
        margin: 0;
    }
}

.header-top .topmenu ul li a {
    font: 400 15px/22px 'Work Sans', sans-serif;
}

#top-menu>li>a {
    color: #111;
    font: 400 13px/24px 'Work Sans', sans-serif;
    padding: 12px 20px;
    position: relative;
    text-transform: capitalize;
}

#top-menu>li>a::after {
    border-bottom: 1px solid #eee;
    bottom: 0;
    content: "";
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 90%;
}

@media (max-width: 991px) {
    #top-menu>li>a::after {
        display: none;
    }
}


/*--------------------------------contact info css --------------------------*/

#_desktop_contact_link {
    float: right;
}

#contact-link>ul {
    margin: 0;
    padding: 0;
    float: left;
    width: 100%;
}

#contact-link li {
    cursor: pointer;
    float: left;
    padding: 12px 0 12px 20px;
    position: relative;
    text-align: left;
}

#contact-link li .material-icons {
    float: left;
    font-size: 15px;
    height: 20px;
    margin: 0 6px 0 0;
    padding: 3px 0;
    width: 15px;
}

#contact-link li .service-title {
    color: #111000;
    font: 400 12px/24px 'Work Sans', sans-serif;
    width: 100%;
}

#contact-link span {
    color: #111;
    float: left;
    font: 400 13px/20px 'Work Sans', sans-serif;
}

#contact-link li:hover {
    color: #111;
}

#contact-link li:hover span {
    color: #111;
}

@media (max-width: 991px) {
    #_mobile_contact_link {
        float: left;
        text-align: center;
        width: 100%;
    }

    #contact-link li {
        padding: 0 0 0 20px;
    }

    #_desktop_contact_link {
        display: none;
    }

    #contact-link li .material-icons {
        padding: 8px 0;
    }

    #mobile_top_menu_wrapper .js-top-menu-bottom #contact-link {
        padding: 0;
    }

    #contact-link>ul {
        display: inline-block;
        float: none;
        text-align: center;
        vertical-align: top;
        width: auto;
    }

    #contact-link span {
        float: none;
        display: inline-block;
        vertical-align: top;
        margin: 5px 0 2px;
    }
}

@media (max-width: 767px) {
    #contact-link li {
        display: inline-block;
        float: none;
        padding: 0;
        text-align: center;
        width: 100%;
        vertical-align: top;
    }

    #contact-link li .material-icons {
        display: inline-block;
        float: none;
        padding: 9px 0;
        vertical-align: top;
    }
}


/*--------------------------------contact inf css ---------------------------*/

#_desktop_language_selector,
#_desktop_currency_selector {
    cursor: pointer;
    float: right;
    position: relative;
    text-align: center;
}

.user-info #_desktop_language_selector {
    float: right;
    width: 48%;
}

#_desktop_currency_selector {
    width: 48%;
}

@media (max-width: 991px) {

    #_desktop_language_selector,
    #_desktop_currency_selector {
        //  display: none !important;
    }
}

.category-cover>img {
    height: auto;
    max-width: 100%;
}

.currency-selector>span.currency-label {
    display: none;
}

.ttfeatured-products {
    float: left;
    position: relative;
    width: 100%;
}

.block-category .h1.title-category {
    color: #111;
    float: left;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px;
    text-transform: capitalize;
}

.tt-title {
    color: #111;
    float: left;
    font: 500 18px/34px 'Work Sans', sans-serif;
    margin: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase !important;
    width: auto;
    padding: 5px 0;
}

.homebg {
    float: left;
    width: 100%;
    position: relative;
    text-align: center;
}

.tthometab-title {
    color: #111;
    display: inline-block;
    float: left;
    font: 500 18px/34px 'Work Sans', sans-serif;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    width: auto;
    padding: 6px 0;
}

.tthometab-title::after,
#ttcategorytabs .tt-title::after,
.tt-title::after {
    border-bottom: 3px solid #ffde01;
    bottom: -2px;
    content: "";
    left: 0;
    position: absolute;
    right: auto;
    width: 100%;
}

@media (max-width: 1199px) {

    .tthometab-title,
    #ttcategorytabs .tt-title {
        margin: 0 0 10px;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .tt-title::after {
        margin: 0 auto;
        right: 0;
    }

    .tt-title {
        float: none;
        display: inline-block;
        vertical-align: top;
    }

    #index .tt-title {
        float: left;
        width: auto;
    }

    #index .tt-title::after {
        right: auto;
    }
}

@media (min-width: 544px) and (max-width: 1199px) {
    .tthometab-title {
        width: 100%;
    }

    .tthometab-title::after,
    #ttcategorytabs .tt-title::after {
        border: none;
    }
}

@media (max-width: 767px) {

    .tt-title,
    .tthometab-title,
    #ttcategorytabs .tt-title {
        font-size: 17px;
        text-align: center;
    }
}

@media (max-width: 543px) {
    #index .tt-title {
        display: inline-block;
        float: none;
        vertical-align: top;
    }

    #product .tt-title {
        font-size: 15px;
        line-height: 20px;
        padding: 10px 0 2px;
    }

    .tthometab-title,
    #ttcategorytabs .tt-title {
        width: 100%;
        margin: 0;
    }

    .tthometab-title::before,
    #ttcategorytabs .tt-title::before {
        border-bottom: 1px solid #eaeaea;
        bottom: -2px;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: auto;
        width: 100%;
    }

    .tthometab-title::after,
    #ttcategorytabs .tt-title::after {
        width: 140px;
        right: 0;
        margin: 0 auto;
    }
}

.tt-innerpagetitle {
    color: #111;
    font: 400 22px/30px 'Work Sans', sans-serif;
    text-align: left;
    text-transform: capitalize;
    float: left;
    width: 100%;
}

.page-subheading {
    color: #111;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 auto 15px;
    padding: 0;
    position: relative;
    text-align: left;
    text-transform: capitalize;
}

@media (max-width: 543px) {
    .tt-innerpagetitle {
        font-size: 20px;
        line-height: 30px;
    }

    .tt-producttitle {
        font-size: 16px;
    }
}

.page-heading {
    display: none;
}


/*
.pb-right-column .current-price {
    line-height: 28px;
	margin: 0;
}*/

#product .pb-right-column .product-discount {
    float: left;
    line-height: 28px;
    margin: 0;
    width: auto;
}

.footer-container .links h3,
.footer-container #block_myaccount_infos h3 a,
.footer-container .contact-rich h4,
#ttcmspaymentlogo .h3 {
    color: #111000;
    cursor: pointer;
    font: 400 18px/22px 'Work Sans', sans-serif;
    margin: 0 0 20px;
    text-align: left;
    text-transform: capitalize !important;
}

@media (min-width:992px) {
    #ttcmspaymentlogo .h3 {
        display: none;
    }
}

@media (max-width: 1199px) {

    .footer-container .links h3,
    .footer-container #block_myaccount_infos h3 a,
    .footer-container .contact-rich h4,
    #ttcmspaymentlogo .h3 {
        font-size: 15px;
        line-height: 18px;
    }
}

.bottom-footer {
    float: left;
    width: 100%;
    background: #fff;
}

.bottom-footer .ttcontent {
    float: left;
    width: 100%;
    text-align: center;
    border-top: 1px solid #d9d9d9;
}

.bottom-footer p {
    margin: 0;
    padding: 15px 0 10px;
}

.bottom-footer p a {
    color: #777;
    font-size: 14px;
    line-height: 18px;
}

.bottom-footer p a:hover {
    color: #111;
}

@media (max-width: 767px) {
    .bottom-footer p {
        padding: 10px 0;
    }

    .bottom-footer .ttcontent {
        text-align: center;
        width: 100%;
    }
}

.quick-view-block {
    text-align: center;
}

.newsletter-desc {
    display: none;
}

.quickview-title.tt-title {
    margin: 0;
    padding: 0;
    text-align: left;
}

#ttbestseller-carousel {
    .carousel-inner {
        >.item {
            transition: 500ms ease-in-out left;
            -webkit-transition: 500ms ease-in-out left;
            -moz-transition: 500ms ease-in-out left;
            -o-transition: 500ms ease-in-out left;
            -ms-transition: 500ms ease-in-out left;
        }

        .active {
            &.left {
                left: -33%;
            }

            &.right {
                left: 33%;
            }
        }

        .next {
            left: 33%;
        }

        .prev {
            left: -33%;
        }

        @media all and (transform-3d),
        (-webkit-transform-3d) {
            >.carousel-item {
                // use your favourite prefixer here
                transition: 500ms ease-in-out all;
                -webkit-transition: 500ms ease-in-out all;
                -moz-transition: 500ms ease-in-out all;
                -o-transition: 500ms ease-in-out all;
                -ms-transition: 500ms ease-in-out all;
                backface-visibility: visible;
                transform: none !important;
                -webkit-transform: none !important;
                -moz-transform: none !important;
                -o-transform: none !important;
                -ms-transform: none !important;
            }
        }
    }

    .carouse-control {

        &.left,
        &.right {
            background-image: none;
        }
    }
}


/* ------------------------- Start TT - Grid/List ----------------------- */

.grid-list {
    padding: 0;
    margin-right: 10px;
}

.grid-list #ttgrid,
.grid-list #ttlist {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;
    text-align: center;
    color: #808080;
    cursor: pointer;
    font-size: 0;
    height: 24px;
    line-height: 14px;
    width: 19px;
}

.grid-list #ttgrid {
    background: url("../img/sprite.png") no-repeat scroll -11px -20px transparent;
}

.grid-list #ttlist {
    background: url("../img/sprite.png") no-repeat scroll -70px -20px transparent;
}

.grid-list #ttgrid:hover,
.grid-list #ttgrid.active {
    background-position: -11px -69px;
}

.grid-list #ttlist:hover,
.grid-list #ttlist.active {
    background-position: -70px -69px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .grid-list {
        width: 20%;
    }
}


/* ------------------------- End TT - Grid/List ----------------------- */


/* --------------------------- Start TT - List Layout(Category Page) -------------------------- */

.products .product-list.product-miniature {
    border: none;
    margin-bottom: 15px;

    @media (min-width: 992px) {
        border: none;
        padding: 30px 0;
    }
}

#products .product-list .product-price-and-shipping {
    padding: 3px 0 7px;
    // position: absolute;
    color: $orange;
    font-size: 20px;
}

.product-list .ttproduct-desc .product-description {
    border-right: 1px solid #e5e5e5;
    padding-right: 10px;
}

.product-thumbs .product-list .thumbnail-container .ttproduct-image {
    padding: 0;
}

.product-thumbs .product-list .thumbnail-container .ttproduct-image .product-thumbnail {
    border-right: 1px solid #e5e5e5;
}

.product-list .ttproduct-desc .product-description,
.product-list .ttproduct-desc .product-flags,
.product-list .ttproduct-desc .highlighted-informations .variant-links,
.product-list .ttproduct-desc .product-description .product-title,
.product-list .ttproduct-desc .product-description .product-price-and-shipping {
    text-align: left !important;
}

.product-list #product-desc-short {
    display: block !important;
    float: left;
    width: 100%;
}

.product-list .ttproduct-desc .highlighted-informations {
    display: block !important;
}

#products .product-list .product-title {
    margin: 0;
    height: initial;

    @media (min-width: 992px) {
        margin: 0 0 7px;
    }
}

@media (max-width: 992px) {
    #products .product-list .product-description {
        min-height: initial;
        margin: 0;
    }
}

#products .product-list .product-title a {
    font-size: 16px;
    line-height: 20px;
}

.product-list .product-desc-short {
    display: block;
    float: left;
    margin: 5px 0 11px;
    width: 100%;
}

.product-list .cat-name {
    text-align: left;
    margin-bottom: 10px;
}

.product-list .cat-name {
    text-align: left;
    margin-bottom: 10px;
    display: none;
}

#products .product-list .hook-reviews {
    float: left;
    width: 100%;
    margin: 5px 0 4px;
}

#products .product-list .thumbnail-container .ttproducthover {
    float: left;
    width: auto;

    @media (min-width: 545px) {
        padding: 10px 0 0;
    }
}

#products .product-list .product-price-and-shipping {
    padding: 3px 0 7px;
    min-height: initial;
}

#products .product-list .ttproducthover div {
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: top;
    width: auto;
}

@media (min-width: 545px) {
    #products .product-list .thumbnail-container .tt-button-container .btn.ajax_add_to_cart_button {
        background: $orange;
        border-radius: 25px;
        -moz-border-radius: 25px;
        -webkit-border-radius: 25px;
        -khtml-border-radius: 25px;
        color: #fff;
        line-height: normal;
        padding: 8px 13px 9px 42px;
        position: relative;
        width: auto;
        line-height: 18px;
    }

    #products .product-list .thumbnail-container .ttproducthover div .btn.ajax_add_to_cart_button.add-to-cart.btn-default span {
        display: block;
    }

    #products .product-list .thumbnail-container .tt-button-container .btn.ajax_add_to_cart_button.add-to-cart.btn-default::before {
        background: transparent url("../img/product-sprite.png") no-repeat scroll -30px -40px;
        content: "";
        height: 40px;
        left: 0;
        position: absolute;
        top: 0;
        width: 40px;
    }

    #products .product-list .thumbnail-container .tt-button-container .btn.ajax_add_to_cart_button.add-to-cart.btn-default:focus,
    #products .product-list .thumbnail-container .tt-button-container .btn.ajax_add_to_cart_button.add-to-cart.btn-default:hover {
        background-color: #111;
        color: #fff;
    }

    #products .product-list .thumbnail-container .tt-button-container .btn.ajax_add_to_cart_button.add-to-cart.btn-default:focus:before,
    #products .product-list .thumbnail-container .tt-button-container .btn.ajax_add_to_cart_button.add-to-cart.btn-default:hover:before {
        background-position: -30px -41px;
    }

    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable {
        background: #ffde01 !important;
        border-radius: 25px;
        -moz-border-radius: 25px;
        -webkit-border-radius: 25px;
        -khtml-border-radius: 25px;
        color: #111;
        line-height: normal;
        padding: 7px 20px;
        position: relative;
        width: 100%;
        opacity: 0.5;
        -moz-opacity: 0.5;
        -khtml-opacity: 0.5;
        -webkit-opacity: 0.5;
    }

    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable span {
        display: block;
    }
}

/*@media (min-width: 1600px) {
    .product-thumbs .product-list .thumbnail-container .ttproduct-image {
        width: auto;
    }
}*/

@media (max-width: 767px) {
    .product-list .ttproduct-desc .product-description {
        border-right: none;
    }

    .product-list .ttproduct-desc .product-description,
    .product-list .ttproduct-desc .product-flags,
    .product-list .ttproduct-desc .highlighted-informations .variant-links,
    .product-list .ttproduct-desc .product-description .product-title,
    .product-list .ttproduct-desc .product-description .product-price-and-shipping {
        width: 100% !important;
    }
}

@media (max-width: 543px) {
    .product-thumbs .product-list .thumbnail-container {
        text-align: center !important;
    }

    .product-thumbs .product-list .thumbnail-container .ttproduct-image .product-thumbnail {
        border-right: none;
    }

    .product-thumbs .product-list .thumbnail-container .ttproduct-image {
        display: inline-block;
        float: none;
        text-align: center;
        width: auto;
    }

    .product-thumbs .product-list .thumbnail-container .ttproduct-desc {
        width: 100%;
    }

    .product-list .cat-name {
        text-align: center;
    }

    .product-list .ttproduct-desc .product-description,
    .product-list .ttproduct-desc .product-flags,
    .product-list .ttproduct-desc .highlighted-informations .variant-links,
    .product-list .ttproduct-desc .product-description .product-title,
    .product-list .ttproduct-desc .product-description .product-price-and-shipping {
        text-align: center !important;
    }

    #products .product-list .thumbnail-container .ttproducthover {
        display: inline-block;
        float: none;
        text-align: center;
        width: auto;
        vertical-align: top;
        margin-top: 5px;
    }

    .product-list .product-desc-short {
        padding: 0 15px;
    }
}


/* --------------------------- End TT - List Layout(Category Page) -------------------------- */


/* ---------------------- Start TT - Homeslider ------------------------ */

.homeslider .direction .carousel-control {
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
}

.homeslider:hover .direction .carousel-control {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

.homeslider:hover .direction .carousel-control.left {
    left: 50px;
}

.homeslider:hover .direction .carousel-control.right {
    right: 50px;
}

@media (max-width: 767px) {
    .homeslider .direction .carousel-control {
        opacity: 1;
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
    }

    .carousel-control.left {
        left: 30px;
    }

    .carousel-control.right {
        right: 30px;
    }

    .homeslider:hover .direction .carousel-control.left {
        left: 30px;
    }

    .homeslider:hover .direction .carousel-control.right {
        right: 30px;
    }
}

@media (max-width: 543px) {
    .carousel-control.left {
        left: 10px;
    }

    .carousel-control.right {
        right: 10px;
    }

    .homeslider:hover .direction .carousel-control.left {
        left: 10px;
    }

    .homeslider:hover .direction .carousel-control.right {
        right: 10px;
    }
}


/* ---------------------- End TT - Homeslider ------------------------ */


/* --------------------- Start All Product Button ---------------------- */

.allproduct {
    float: left;
    text-align: center;
    display: none;
    width: 100%;
}

.allproduct>a {
    background: #ff4661;
    color: #ffffff;
    display: inline-block;
    float: none;
    padding: 8px 25px;
    vertical-align: top;
}

.allproduct>a:hover {
    background: #111;
    color: #ffffff;
}


/* --------------------- End All Product Button ---------------------- */


/* --------------------------- Start Left Column/Right Column ----------------------------- */

#left-column .text-uppercase,
#right-column .text-uppercase {
    border: medium none;
    float: left;
    color: #111;
    font: 400 18px/20px 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    position: relative;
    width: 100%;
}

#right-column>div#search_filters_wrapper #search_filters {
    float: left;
    padding: 20px;
    margin: 0 0 20px;
    width: 100%;
}

#left-column>div#search_filters_wrapper,
#right-column>div#search_filters_wrapper {
    border: medium none;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
}

#left-column>div:last-child,
#right-column>div:last-child {
    margin: 0;
}


/* --------------------------- End Left Column/Right Column ----------------------------- */

#cms .page-content>h2,
#cms .page-content>h3 {
    color: #111;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 6px;
}

#cms .page-content>p {
    margin: 5px 0 20px;
}


/* -------------------------- Start Sitemap page ------------------------------ */

#sitemap .container-fluid {
    border: 1px solid #e5e5e5;
    padding: 25px 0;
    float: left;
    width: 100%;
}

.sitemap ul li a {
    line-height: 22px;
}

.sitemap ul li ul.nested {
    padding: 0 15px !important;
}

@media (max-width: 991px) {
    .sitemap .col-md-3 {
        width: 100%;
    }
}

@media (max-width: 543px) {
    #sitemap .container-fluid {
        padding: 15px 0;
    }
}


/* -------------------------- End Sitemap page ------------------------------ */


/* -----------------------------Start Owl-carousel ------------------------- */

.owl-wrapper-outer {
    float: left;
    width: 100%;
    overflow: hidden;
}

.owl-wrapper-outer .owl-wrapper {
    position: relative;
}

.owl-wrapper-outer .owl-wrapper:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

.owl-wrapper-outer .owl-wrapper .owl-item {
    float: left;
}

.owl-wrapper-outer .owl-wrapper .owl-item .product-miniature {
    width: 100%;
}

.owl-stage {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%
}

.owl-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto !important;
    /*  border: 1px solid;*/
}

.owl-buttons {
    float: none;
    padding: 0;
    text-align: center;
    vertical-align: top;
    display: inline-block;
}

.owl-buttons div {
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    cursor: pointer;
    float: left;
    font-size: 0;
    height: 31px;
    line-height: 40px;
    margin: 0 2px;
    padding: 0;
    position: relative;
    vertical-align: top;
    width: 31px;
}

.owl-buttons div i {
    display: none;
}

.owl-buttons div:hover {
    border-color: #111;
    background: #111;
}

.owl-buttons div:hover i {
    color: #ffffff;
}


/* -----------------------------End Owl-carousel ------------------------- */

#top_column {
    float: left;
    width: 100%;

    @media (min-width: 992px) {
        margin: 0 0 75px;
    }
}

.slider-services {
    float: left;
    width: 100%;
    text-align: center;
}


/*--------------------------- Start Homepage tab --------------------------*/

#hometab {
    float: none;
    margin: 0 auto 35px;
    position: relative;
    width: 100%;
    background: #fff;
    padding: 0;
}

#hometab .home-tab-content .tab-pane.active {
    display: block;
}

#hometab .home-tab-content .tab-pane {
    display: none;
}

#hometab .tab-title,
#ttcategory-tabs .nav-link .tab-title,
.tabs .nav-link .tab-title {
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    color: #777;
    font: 500 14px/20px 'Work Sans', sans-serif;
    margin: 0;
    outline: medium none;
    padding: 12px 20px;
    position: relative;
    text-transform: uppercase;
}

#hometab .ttfeatured-products,
#hometab .ttbestseller-products,
#hometab .ttnew-products {
    float: left;
    width: 100%;
    padding: 0;
}

#hometab .tabs .nav-tabs,
#ttcategory-tabs {
    border: medium none;
    float: right;
    padding: 0 80px 0 0 !important;
    text-align: center;
}

#hometab .tabs .nav-tabs .nav-item,
#ttcategory-tabs.nav-tabs .nav-item,
.tabs .nav-tabs .nav-item {
    float: left;
    position: relative;
}

#hometab .tabs .nav-tabs .nav-link,
#ttcategorytabs .nav-tabs .nav-link,
.tabs .nav-tabs .nav-link {
    margin: 0;
    padding: 0;
    border: none;
    line-height: 32px;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    position: relative;
}

#hometab .tabs .nav-tabs .nav-link.active .tab-title,
#ttcategorytabs.tabs .nav-tabs .nav-link.active .tab-title,
.tabs .nav-tabs .nav-link.active .tab-title {
    border-color: $orange #eaeaea #ffffff;
    border-style: solid;
    border-width: 2px 1px 1px;
    color: #111000;
}

#hometab .tabs .nav-tabs .nav-link:hover .tab-title,
#ttcategorytabs.tabs .nav-tabs .nav-link:hover .tab-title,
.tabs .nav-tabs .nav-link:hover .tab-title {
    color: #111;
}

#hometab .tabs .nav-tabs .nav-item:last-child .nav-link::after,
#ttcategory-tabs.nav-tabs .nav-item:last-child .nav-link::after {
    display: none;
}

.tt-titletab {
    border-bottom: 1px solid #eaeaea;
    float: left;
    margin: 0 0 20px;
    width: 100%;
}

#hometab .tabs {
    padding: 0;
    background: transparent;
    float: right;
    margin: 0;
    border: none;
}

.featureli,
.newli,
.bestsellerli {
    list-style: outside none none;
}

#hometab .tabs .nav-tabs .nav-item:hover .nav-link,
#hometab .tabs .nav-tabs .nav-item .nav-link.active,
#ttcategorytabs.tabs .nav-tabs .nav-item:hover .nav-link,
#ttcategorytabs.tabs .nav-tabs .nav-item .nav-link.active,
.tabs .nav-tabs .nav-item:hover .nav-link,
.tabs .nav-tabs .nav-item .nav-link.active {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    margin: 0 0 1px;
}

@media (max-width: 1199px) {

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        width: 100%;
    }

    #hometab .tabs {
        width: 100%;
    }

    .tt-titletab {
        text-align: center;
    }
}

@media (max-width: 1199px) {
    #hometab {
        margin: 0 auto 20px;
    }
}

@media (max-width: 767px) {

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        display: inline-block;
        float: none;
        padding: 0 !important;
        text-align: center;
        vertical-align: top;
        width: auto;
    }

    #hometab {
        margin: 0 auto 10px;
    }
}

@media (max-width: 543px) {

    #hometab .tabs .nav-tabs .nav-item:first-child::before,
    #hometab .tabs .nav-tabs .nav-item:last-child::after {
        border: none;
    }

    #home-tab-content .owl-buttons,
    #ttcategorytabs .owl-buttons {
        left: 0;
        right: 0;
        margin: 0;
    }

    #hometab .tabs .nav-tabs .nav-item,
    #ttcategory-tabs.nav-tabs .nav-item {
        display: inline-block;
        float: none;
        vertical-align: top;
        width: 32%;
    }

    #home-tab-content .owl-buttons div,
    #ttcategorytabs .owl-buttons div {
        float: none;
        display: inline-block;
    }

    #hometab {
        padding: 0;
    }

    #hometab {
        margin: 0 auto 5px;
    }

    #hometab .tab-title,
    #ttcategory-tabs .nav-link .tab-title {
        border-bottom: 1px solid transparent;
    }

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        margin: 20px 0 0 0;
    }

    #hometab .tabs .nav-tabs .nav-item:hover .nav-link,
    #hometab .tabs .nav-tabs .nav-item .nav-link.active,
    #ttcategorytabs.tabs .nav-tabs .nav-item:hover .nav-link,
    #ttcategorytabs.tabs .nav-tabs .nav-item .nav-link.active,
    .tabs .nav-tabs .nav-item:hover .nav-link,
    .tabs .nav-tabs .nav-item .nav-link.active {
        margin: 0;
    }

    #hometab .tabs .nav-tabs .nav-link.active .tab-title,
    #ttcategorytabs.tabs .nav-tabs .nav-link.active .tab-title,
    .tabs .nav-tabs .nav-link.active .tab-title {
        border-bottom: 1px solid #eaeaea;
    }
}

#lets-outlet-products {
    float: left;
    vertical-align: top;
    position: relative;
    margin: 0 0 40px;
    padding: 0;
}


/*--------------------------- End Homepage tab --------------------------*/

.home-tab-content {
    float: left;
    text-align: center;
    width: 100%;
}

#home-tab-content.row {
    // margin: 0;
}

.home-tab-content .owl-buttons div.owl-prev,
.featured-products .owl-buttons div.owl-prev,
.product-accessories .owl-buttons div.owl-prev,
.crossselling-product .owl-buttons div.owl-prev,
.category-products .owl-buttons div.owl-prev,
.view-product .owl-buttons div.owl-prev,
.ttspecial-products .owl-buttons div.owl-prev,
.brands .owl-buttons div.owl-prev {
    background: rgba(0, 0, 0, 0) url("../../assets/img/sprite.png") no-repeat scroll -9px -353px;
}

.home-tab-content .owl-buttons div.owl-next,
.featured-products .owl-buttons div.owl-next,
.product-accessories .owl-buttons div.owl-next,
.crossselling-product .owl-buttons div.owl-next,
.category-products .owl-buttons div.owl-next,
.view-product .owl-buttons div.owl-next,
.ttspecial-products .owl-buttons div.owl-next,
.brands .owl-buttons div.owl-next {
    background: rgba(0, 0, 0, 0) url("../../assets/img/sprite.png") no-repeat scroll -11px -386px;
}

.home-tab-content .owl-buttons div.owl-prev::before,
.featured-products .owl-buttons div.owl-prev::before,
.product-accessories .owl-buttons div.owl-prev::before,
.crossselling-product .owl-buttons div.owl-prev::before,
.category-products .owl-buttons div.owl-prev::before,
.view-product .owl-buttons div.owl-prev::before,
.ttspecial-products .owl-buttons div.owl-prev::before {
    border-top: 1px solid #d6d6d6;
    content: "";
    float: left;
    height: 1px;
    left: -61px;
    position: absolute;
    text-align: left;
    top: 10px;
    width: 60px;
}

.home-tab-content .owl-buttons div.owl-prev::after,
.featured-products .owl-buttons div.owl-prev::after,
.product-accessories .owl-buttons div.owl-prev::after,
.crossselling-product .owl-buttons div.owl-prev::after,
.category-products .owl-buttons div.owl-prev::after,
.view-product .owl-buttons div.owl-prev::after,
.ttspecial-products .owl-buttons div.owl-prev::after {
    border-bottom: 1px solid #d6d6d6;
    content: "";
    height: 1px;
    left: -41px;
    position: absolute;
    top: 15px;
    width: 40px;
}

.home-tab-content .owl-buttons div.owl-next::before,
.featured-products .owl-buttons div.owl-next::before,
.product-accessories .owl-buttons div.owl-next::before,
.crossselling-product .owl-buttons div.owl-next::before,
.category-products .owl-buttons div.owl-next::before,
.view-product .owl-buttons div.owl-next::before,
.ttspecial-products .owl-buttons div.owl-next::before {
    border-top: 1px solid #d6d6d6;
    content: "";
    height: 1px;
    position: absolute;
    right: -61px;
    top: 10px;
    width: 60px;
}

.home-tab-content .owl-buttons div.owl-next::after,
.featured-products .owl-buttons div.owl-next::after,
.product-accessories .owl-buttons div.owl-next::after,
.crossselling-product .owl-buttons div.owl-next::after,
.category-products .owl-buttons div.owl-next::after,
.view-product .owl-buttons div.owl-next::after,
.ttspecial-products .owl-buttons div.owl-next::after {
    border-bottom: 1px solid #d6d6d6;
    content: "";
    height: 1px;
    position: absolute;
    right: -41px;
    top: 15px;
    width: 40px;
}

.home-tab-content .owl-buttons div.owl-prev:hover,
.featured-products .owl-buttons div.owl-prev:hover,
.product-accessories .owl-buttons div.owl-prev:hover,
.crossselling-product .owl-buttons div.owl-prev:hover,
.category-products .owl-buttons div.owl-prev:hover,
.view-product .owl-buttons div.owl-prev:hover,
.ttspecial-products .owl-buttons div.owl-prev:hover,
.brands .owl-buttons div.owl-prev:hover {
    background: #111000 url("../../assets/img/sprite.png") no-repeat scroll -67px -353px;
    border-color: #111000;
}

.home-tab-content .owl-buttons div.owl-next:hover,
.featured-products .owl-buttons div.owl-next:hover,
.product-accessories .owl-buttons div.owl-next:hover,
.crossselling-product .owl-buttons div.owl-next:hover,
.category-products .owl-buttons div.owl-next:hover,
.view-product .owl-buttons div.owl-next:hover,
.ttspecial-products .owl-buttons div.owl-next:hover,
.brands .owl-buttons div.owl-next:hover {
    background: #111000 url("../../assets/img/sprite.png") no-repeat scroll -69px -386px;
    border-color: #111000;
}


/*--------------------------- Start Homepage  --------------------------*/


/* -----------Start roll-over css ---------*/

.product-miniature .thumbnail-container .ttproduct-image .second_image {
    bottom: 0;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}

.product-miniature .thumbnail-container:hover .ttproduct-image .second_image {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}


/* -----------End roll-over css ---------*/


/* --------Start search drop-down css---------*/

#ui-id-1 {
    border: 1px solid #e5e5e5;
    z-index: 999;
    background: #ffffff none repeat scroll 0 0;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}

.ui-menu .ui-menu-item {
    margin: 0 0 10px !important;
}

.ui-menu .ui-menu-item .product-img {
    float: left;
    height: 80px;
    width: 80px;
    border: 1px solid #e5e5e5;
}

.ui-menu .ui-menu-item a {
    min-height: 80px;
}

.ui-widget.ui-widget-content {
    padding: 10px;
    width: 320px !important;
}

@media (max-width: 543px) {
    .ui-widget.ui-widget-content {
        width: 300px !important;
    }
}

.ui-front.ui-menu .ui-menu-item a {
    min-height: 100%;
    overflow: hidden;
    padding: 2px 0 2px 8px !important;
    width: 194px !important;
    float: left;
}

@media (max-width: 543px) {
    .ui-front.ui-menu .ui-menu-item a {
        width: 165px !important;
    }
}

.ui-front.ui-menu .ui-menu-item a span {
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
}

.ui-front.ui-menu .ui-menu-item a span.category {
    color: #777;
    float: left;
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
}

@media(max-width:543px) {
    .ui-front.ui-menu .ui-menu-item a span.category {
        font-size: 13px;
    }
}

.ui-front.ui-menu .ui-menu-item a span.separator {
    display: none;
}

.ui-front.ui-menu .ui-menu-item a span.product {
    float: left;
    text-transform: capitalize;
    width: 100%;
}

.ui-front.ui-menu .ui-menu-item a.ui-state-focus,
.ui-front.ui-menu .ui-menu-item a.ui-state-active {
    background: none;
    border: none;
    margin: 0;
}

.ui-front.ui-menu .ui-menu-item a .price {
    color: #111;
    float: left;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}

.ui-front.ui-menu .ui-menu-item a .price {
    float: left;
    width: 100%;
}

.ui-front.ui-menu .ui-menu-item {
    padding: 10px;
    margin: 0 !important;
    float: left;
    width: 100%;
    cursor: pointer;
}

.ui-front.ui-menu .ui-menu-item:hover {
    background: #f5f5f5 none repeat scroll 0 0;
}

ui-front.ui-menu .ui-menu-item a {
    font-size: 14px;
}


/* --------End search drop-down css---------*/


/*--------------------------- Start Slider --------------------------*/

.ttloading-bg {
    background: #f7f7f7 url("../img/ajax-loader.gif") no-repeat scroll center center;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

@media (max-width: 543px) {
    .homeslider .direction .carousel-control {
        height: 40px;
        width: 40px;
        position: absolute;
    }

    .homeslider .carousel-control .icon-prev,
    .homeslider .carousel-control .icon-next {
        float: left;
        width: 100%;
        height: 100%;
        position: static;
        margin: 0;
    }
}


/*--------------------------- End Slider --------------------------*/


/*--------------------------- Start contact-info footer --------------------------*/

.contact-rich .block .data.email {
    padding: 0;
}

.contact-rich .block .data.contact {
    display: none;
}


/*--------------------------- End contact-info footer --------------------------*/


/*--------------------------- Start newsletter --------------------------*/

.footer-container {
    float: left;
    position: relative;
    width: 100%;
    padding: 20px 0;
}

#footer .footer-container .links {
    margin: 50px 0;
}

#newslatter {
    float: left;
    padding: 20px 100px;
    width: 100%;
}

.block_newsletter .tt-title {
    color: #fff;
    float: left;
    font: 500 22px/55px 'Work Sans', sans-serif;
    margin: 0;
    padding: 0 0 0 70px;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
}

.block_newsletter .tt-title::after {
    display: none;
}

.block_newsletter .tt-title::before {
    background: rgba(0, 0, 0, 0) url("../img/sprite.png") no-repeat scroll -24px -541px;
    content: "";
    height: 55px;
    left: 0;
    position: absolute;
    top: 0;
    width: 60px;
}

#footer .footer-container #newslatter .block_newsletter .tt-content {
    float: left;
    width: 100%;
}

#newslatter .block_newsletter .block_content .row {
    margin: 0;
}

#newslatter .block_newsletter .tt-content {
    float: left;
}

#newslatter .block_newsletter .block_content {
    float: right;
    max-width: 640px;
    margin: 6px 0;
}

#newslatter .block_newsletter .ttinput_newsletter {
    float: left;
    width: 100%;
    position: relative;
}

#newslatter .block_newsletter .block_content .btn {
    background: $orange;
    color: #fff;
    font: 400 14px/16px 'Work Sans', sans-serif;
    height: 42px;
    padding: 5px 20px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 20px 20px 0;
}

#newslatter .block_newsletter .tt-content .tt-desc {
    color: #777;
    float: left;
    font: 400 15px/22px 'Work Sans', sans-serif;
    text-align: left;
    width: 100%;
    margin: 0 0 10px;
}

#newslatter .block_newsletter .block_content .btn:focus,
#newslatter .block_newsletter .block_content .btn:hover {
    background: #111;
    border-color: #111;
    color: #fff;
}

#newslatter .block_newsletter .block_content .col-xs-12 {
    padding: 0;
}

@media (max-width: 1200px) {
    #newslatter {
        padding: 20px 0;
    }
}

@media (max-width: 991px) {
    #footer .footer-container .links {
        margin: 0 !important;
        padding: 0;
    }

    .footer-container .row {
        margin: 0;
    }

    #newslatter {
        padding: 15px 0 20px;
        text-align: center;
    }

    #newslatter .block_newsletter .tt-content {
        width: 100%;
        margin: 0 0 7px;
    }

    .block_newsletter .tt-title {
        display: inline-block;
        float: none !important;
        font-size: 20px;
        vertical-align: top;
        width: auto;
    }

    #newslatter .block_newsletter .block_content {
        display: inline-block;
        float: none;
        vertical-align: top;
        width: auto;
        text-align: center;
    }

    #footer .footer-container .block-social.links {
        padding: 20px 0 0;
    }
}

@media (max-width: 767px) {
    .block_newsletter .tt-title {
        text-align: center;
    }

    .block_newsletter .ttinput_newsletter {
        float: none;
        width: 100%;
    }

    #index #wrapper .breadcrumb {
        display: none !important;
    }
}

@media (max-width: 543px) {
    #newslatter {
        padding: 65px 0 15px;
    }

    .block_newsletter .tt-title {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
        padding: 0;
    }

    .block_newsletter .tt-title::before {
        left: 0;
        margin: 0 auto;
        right: 0;
        top: -55px;
    }

    #newslatter .block_newsletter .block_content {
        width: 100%;
    }
}


/*---------------------------- Newsletter CSS End -------------------------------------*/


/*--------------------------- Start menu  --------------------------*/

@media (max-width: 991px) {
    #mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="1"]>li a:hover {
        color: #111 !important;
    }

    #top-menu>li>.dropdown-item {
        font: 400 14px/20px 'Work Sans', sans-serif;
        padding: 4px 20px !important;
        text-align: left;
        color: #111;
        text-transform: capitalize;
    }

    #top-menu>li>.dropdown-item:hover {
        color: #111;
    }

    #mobile_top_menu_wrapper .top-menu .sub-menu {
        background: none;
        border: none;
    }

    .top-menu .sub-menu ul>li {
        width: 100% !important;
    }

    #mobile_top_menu_wrapper .top-menu .sub-menu {
        padding: 0 20px;
    }

    #mobile_top_menu_wrapper .top-menu .sub-menu li>a,
    #mobile_top_menu_wrapper .top-menu .sub-menu ul>li {
        padding: 0;
    }

    #mobile_top_menu_wrapper .top-menu .sub-menu ul[data-depth="2"] {
        padding: 5px 0;
    }

    #mobile_top_menu_wrapper .top-menu .collapse.in {
        overflow: hidden;
    }

    #mobile_top_menu_wrapper .top-menu .sub-menu li>a,
    #mobile_top_menu_wrapper .top-menu .sub-menu ul>li {
        line-height: 24px;
        font-weight: normal;
    }

    #mobile_top_menu_wrapper #_mobile_top_menu #top-menu .sub-menu .menu-images-container {
        float: left;
        margin: 15px 0;
        padding: 0;
        width: 100%;
    }
}


/*--------------------------- End menu --------------------------*/


/*--------------------------- Start Footer toggle --------------------------*/

@media (max-width: 991px) {
    .footer-container .links .row {
        margin: 0;
    }

    .footer-container .links .float-xs-right {
        height: 25px;
    }

    .contact-rich {
        margin: 0;
    }

    .footer-container .links .title:hover {
        background: #e5e5e5;
    }

    .footer-container .links .float-xs-right .navbar-toggler i {
        color: #111;
        font-size: 20px;
        padding: 2px 0;
    }

    .footer-container .links ul {
        padding: 0 10px !important;
    }

    .footer-container .block-social.links ul {
        padding: 0;
    }

    .footer-container .links {
        width: 100%;
        float: left;
    }

    .footer-container .links .wrapper {
        width: 100%;
        padding: 0;
    }

    .footer-container .links .hidden-md-up {
        display: block !important;
    }

    .footer-container .links h3,
    .footer-container #block_myaccount_infos h3 a,
    .footer-container .contact-rich h4 {
        display: none;
    }

    .footer-container .links .collapse {
        display: none !important;
    }

    .footer-container .links .collapse.in {
        display: block !important;
    }

    .footer-container .links .float-xs-right .navbar-toggler i.remove {
        display: none;
    }
}


/*--------------------------- End Footer  --------------------------*/


/*--------------------------- Start user-info  --------------------------*/

.mb-50 {
    margin-bottom: 50px;
}

#desktop_country_selector {
    padding-top: 6px;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;

    .flag {
        margin-left: 5px;
        max-width: 100%;
        height: 18px;
    }

    i.material-icons.expand-more {
        font-size: 13px;
        color: #888;
    }
}

#_desktop_language_selector,
#_desktop_currency_selector {
    cursor: pointer;
    float: right;
    margin: 0;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.language {
    padding-top: 6px;
    font-size: 12px;
}

#_desktop_user_info {
    float: right;
    position: relative;
    margin: 35px 25px;
}

.user-info {
    float: right;
    margin: 0;
    padding: 10px 0;
}

.user-info li {
    cursor: pointer;
    float: left;
    line-height: 20px;
    padding: 0;
    width: 100%;
}

.user-info>li a {
    color: #888;
}

.ttuserheading::after {
    background: transparent url("../img/sprite.png") no-repeat scroll -4px -276px;
    left: 0;
    right: 0;
    content: "";
    margin: 0 auto;
    position: absolute;
    height: 40px;
    width: 40px;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

.ttuserheading:hover::after {
    background-position: -4px -276px;
    transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
}

.ttuserheading {
    cursor: pointer;
    float: left;
    height: 40px;
    width: 40px;
    background: #111;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    position: relative;
}

.user-info {
    background: #fff;
    border: 1px solid #e5e5e5;
    display: none;
    float: right;
    left: auto;
    padding: 10px 15px 12px;
    position: absolute;
    right: -50px;
    text-align: left;
    top: 75px;
    width: 140px;
    z-index: 2;
}

.user-info::after {
    color: #fff;
    content: "\E5C7";
    font-family: "Material Icons";
    font-size: 45px;
    right: 45px;
    position: absolute;
    top: -13px;
    left: auto;
}

.user-info>li a {
    color: #888;
    cursor: pointer;
}

.user-info>li a:hover {
    color: #111;
}

.user-info span.hidden-sm-down {
    font-weight: normal;
}

.user-info span.hidden-sm-down:hover {
    color: #111;
}

.user-info .material-icons.user {
    display: none;
}

@media (max-width: 991px) {

    #notifications,
    #wrapper,
    #footer {
        display: block !important;
    }

    .header-nav .user-info .logged {
        display: none;
    }

    #_mobile_user_info {
        display: inline-block !important;

        .globe {
            width: 40px;
            height: 40px;
            float: left;

            .zmdi-globe {
                padding: 9px;
                font-size: 23px;
                color: #111;
            }
        }

        .loup {
            width: 40px;
            height: 40px;
            float: left;

            .zmdi-search {
                padding: 9px;
                font-size: 23px;
                color: #111;
            }
        }
    }

    .ttuserheading::after {
        background: transparent url("../img/sprite.png") no-repeat scroll -64px -275px;
    }

    .ttuserheading:hover::after {
        background: transparent url("../img/sprite.png") no-repeat scroll -64px -275px;
    }

    #_desktop_user_info {
        margin: 0;
        padding: 0;
        border: none;
        display: none;
    }

    .ttuserheading {
        background: none;
    }

    .user-info {
        top: 50px;
        width: 170px;
    }

    .user-info>li {
        width: 100%;
    }
}


/*--------------------------- End user-info  --------------------------*/


/*--------------------------- Start block-cart  --------------------------*/

@media (max-width: 767px) {
    #mobile_top_menu_wrapper .js-top-menu-bottom .language-selector-wrapper {
        padding: 5px 0;
    }
}


/*--------------------------- End block-cart  --------------------------*/


/*---header---------*/

@media (max-width: 991px) {
    #header .header-nav .text-sm-center.mobile {
        float: left;
        width: 100%;
    }

    #mobile_menu {
        float: left;
        width: 100%;
    }

    #menu-icon {
        cursor: pointer;
        display: block;
        float: left;
        margin: 13px 0;
        position: relative;
        text-align: left;
        display: none;
    }

    #menu-icon i {
        color: #111;
        font-weight: bold;
    }

    #_mobile_user_info {
        position: relative;
        padding: 6px 0 0;
    }

    #_mobile_cart {
        cursor: pointer;
        position: relative;
    }

    .hidden-md-up.text-sm-center.mobile {
        float: left;
        width: 100%;
        position: relative;
    }

    .logo-mobile {
        float: inherit;
        display: none;
    }

    .header-top.fixed .logo-mobile {
        display: block;
        -webkit-animation: fadein 2s;
        /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s;
        /* Firefox < 16 */
        -ms-animation: fadein 2s;
        /* Internet Explorer */
        -o-animation: fadein 2s;
        /* Opera < 12.1 */
        animation: fadein 2s;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 375px) {
    .header-top.fixed .logo-mobile {
        width: 148px;
        padding-top: 4px;
    }
}

@media (max-width: 320px) {
    .header-top.fixed .logo-mobile {
        width: 93px;
        padding-top: 9px;
    }
}


/*--------------------------- End Homepage  --------------------------*/


/*------quickview alert--------*/

.quickview .ttproductcountdown.buttons_bottom_block {
    display: none;
}

.quick-view .material-icons.search {
    display: none;
}

.quickview .product-cover {
    width: 75%;
}

@media (max-width: 1199px) {
    .quickview .mask {
        height: 280px;
        margin: 71px 0;
    }

    .quickview .product-cover {
        width: 72%;
    }

    .quickview .mask {
        width: 28%;
    }

    .quickview .arrows {
        height: 385px;
        right: 60px;
    }

    .quickview .arrows .arrow-up {
        margin-top: 10px;
    }

    .quickview-title {
        font-size: 28px;
    }
}

@media (max-width: 991px) {
    .quickview .modal-dialog {
        width: 720px;
    }

    .quickview .mask {
        height: 560px;
        margin: 70px 0;
        width: 20%;
    }

    .quickview .images-container {
        position: static;
    }

    .quickview.in .modal-content .modal-body .col-md-6 {
        width: 100%;
        float: left;
    }

    .quickview .product-cover {
        width: 80%;
    }

    .quickview .modal-body .col-md-6.col-sm-6 {
        width: 100%;
    }

    .quickview .arrows {
        height: 665px;
        right: 70px;
    }
}

@media (max-width: 767px) {
    .quickview .modal-dialog {
        width: 500px;
    }

    .quickview .mask {
        height: 280px;
        margin: 77px 0;
        width: 26%;
    }

    .quickview-title .tt-producttitle {
        font-size: 24px;
    }

    .quickview .product-cover {
        width: 74%;
    }

    .quickview .arrows {
        height: 400px;
        right: 50px;
    }
}

@media (max-width: 543px) {
    .quickview.in .modal-content .modal-body .col-md-6 {
        display: block !important;
    }

    .quickview .modal-dialog {
        width: 300px;
        margin: 10px auto;
    }

    .quickview .mask {
        height: 110px;
        width: 40%;
        margin: 20px auto;
        padding: 0;
        display: none;
    }

    .quickview .arrows {
        height: auto;
        right: 50px;
        position: static;
        display: none;
    }

    .quickview .images-container {
        min-height: 200px;
    }

    .quickview-title.tt-producttitle {
        font-size: 18px;
    }

    .quickview .social-sharing {
        margin: 0;
    }

    .quickview .product-cover {
        width: 100%;
    }

    .quickview .arrows .arrow-up {
        margin-top: -10px;
        left: 40px;
    }

    .quickview .arrows .material-icons {
        position: absolute;
        bottom: 60px;
    }

    .quickview .arrows .arrow-down {
        right: 40px;
    }
}


/*--------------------------- Start Category page  --------------------------*/

#content-wrapper .block-category .category-cover {
    margin: 0 0 10px;
}

#left-column .block-categories .text-uppercase,
#right-column .block-categories .text-uppercase {
    margin: 0;
}

#content-wrapper #main .block-category {
    border: medium none;
    display: block !important;
    padding: 0;
}

#js-active-search-filters.hide .active-filter-title {
    display: none;
}

#left-column .tt-title,
#left-column .text-uppercase,
#right-column .tt-title,
#right-column .text-uppercase {
    background: #f1f1f1;
    color: #111;
    font: 500 15px/25px 'Work Sans', sans-serif;
    margin: 0;
    padding: 10px 15px;
    margin: 0 0 10px;
    width: 100%;
    text-align: left;
    position: relative;
    text-transform: uppercase !important;
}

#left-column {
    padding-left: 0;
}

#right-column {
    padding-right: 0;
}

@media (min-width: 1600px) {

    #left-column,
    #right-column {
        width: 20%;
    }
}

@media (max-width: 1200px) {
    #js-product-list .product-miniature.product-grid {
        width: 33.33%;
    }

    #product #content-wrapper .products.row {
        margin: 0;
    }
}

@media (max-width: 767px) {
    #js-product-list .product-miniature.product-grid {
        width: 50%;
    }
}

@media (max-width: 543px) {
    #products .ttproducthover .wishlist {
        display: none !important;
    }

    .wishlist {
        display: none !important;
    }

    #js-product-list .product-miniature.product-grid {
        //  width: 100%;
    }
}

@media (max-width: 991px) {

    #left-column,
    #right-column {
        width: 100%;
    }

    .left-column {
        padding: 0;
    }

    #left-column {
        padding-right: 0;
    }

    #right-column {
        padding-left: 0;
    }

    #left-column ul,
    #right-column ul {
        padding: 0;
    }

    #wrapper .breadcrumb {
        display: block !important;
        //text-align: center;
    }

    #content-wrapper #main .text-sm-center h1 {
        display: none;
    }

    .products-selection .total-products {
        display: block !important;
        padding: 0 0 10px;
    }

    .products-selection .col-md-6 {
        float: left;
        width: 100%;
    }

    .products-selection .total-products p {
        float: right;
    }

    #products #js-product-list .up {
        display: none;
    }

    #content-wrapper.left-column {
        width: 100%;
    }
}

@media (max-width: 543px) {

    #products .thumbnail-container,
    .featured-products .thumbnail-container,
    .product-accessories .thumbnail-container,
    .ttfeatured-products .thumbnail-container,
    .crossselling-product .thumbnail-container,
    .ttbestseller-products .thumbnail-container,
    .ttnew-products .thumbnail-container,
    .ttspecial-products .thumbnail-container,
    .category-products .thumbnail-container,
    .view-product .thumbnail-container {
        float: none;
        display: inline-block;
        width: auto;
        vertical-align: top;
    }
}


/*--------------------------- End Category page  --------------------------*/

@media (max-width: 1199px) {
    #search_widget form input {
        float: right;
    }
}


/*-----------alert add to cart---------*/

@media (max-width: 991px) {
    #blockcart-modal .modal-body .divide-right {
        border: none;
        width: 100%;
        margin: 0 0 20px;
    }

    #blockcart-modal .modal-body .divide-left {
        float: left;
        width: 100%;
    }

    #blockcart-modal .modal-body .divide-right .product-image-container {
        width: auto;
    }

    #blockcart-modal .modal-body .divide-right .product-info {
        width: auto;
    }
}

@media (max-width: 543px) {
    #blockcart-modal .modal-body .divide-right .product-info {
        width: 100%;
    }

    #blockcart-modal .modal-body .divide-right .product-image-container {
        width: 100%;
        text-align: center;
    }
}


/* -------------------- modal -------------------------- */

#product-modal .modal-dialog {
    max-width: 800px;
}

#product-modal .modal-content .modal-body {
    background: #ffffff;
    float: left;
    margin: 0;
    width: 100%;
}

#product-modal .modal-content .modal-body figure {
    width: 80%;
}

#product-modal .modal-content .modal-body .thumbnails {
    float: left;
    position: relative;
    width: 20%;
}

#product-modal .modal-content .modal-body .product-cover-modal {
    background: #ffffff none repeat scroll 0 0;
    height: auto;
    max-width: 100%;
    border: 1px solid #e5e5e5;
}

#product-modal .modal-content .modal-body .image-caption {
    background: #ffffff none repeat scroll 0 0;
    float: left;
    padding: 20px 0 0;
    width: 100%;
}

#product-modal .modal-content .modal-body .product-images {
    float: left;
    padding: 0 5px 0 20px;
    margin: 0;
    width: 100%;
}

#product-modal .modal-content .modal-body .product-images li {
    display: inline;
    float: left;
    margin: 0 0 10px;
    width: 100%;
}

#product-modal .modal-content .modal-body .product-images img {
    background: #ffffff none repeat scroll 0 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    cursor: pointer;
    width: 100%;
    border: 1px solid #e5e5e5;
}

#product-modal .modal-content .modal-body .product-images img:hover,
#product-modal .modal-content .modal-body .product-images img.selected {
    border-color: #ccc;
}

@media (max-width: 991px) {
    #product-modal .modal-dialog {
        max-width: 650px;
    }

    #product-modal .modal-content .modal-body .mask {
        max-height: 580px;
    }
}

@media (max-width: 543px) {
    #product-modal .modal-content .modal-body figure {
        width: 60%;
    }

    #product-modal .modal-content .modal-body .thumbnails {
        width: 35%;
    }
}


/*------------ start language,currency icon-------------*/

.language-selector .material-icons,
.currency-selector .material-icons {
    font-size: 13px;
    color: #888;
}

.language-selector .material-icons.expand-less,
.currency-selector .material-icons.expand-less {
    display: none;
}

.language-selector.open .material-icons.expand-less,
.currency-selector.open .material-icons.expand-less {
    display: inline-block;
}

.language-selector.open .material-icons.expand-more,
.currency-selector.open .material-icons.expand-more {
    display: none;
}


/*------------ End labguage,currency icon-------------*/

#js-product-list .container {
    padding: 0 15px;
}

#product .pb-left-column {
    padding-left: 0;
}

#product .pb-right-column {
    padding-right: 0;
}

#index .header-top .topmenu {
    margin: 0;
}

@media (max-width: 767px) {
    #product .pb-left-column {
        padding-right: 0;
        margin: 30px 0 0;
    }

    #product .pb-right-column {
        padding-left: 0;
    }
}


/*------------ Start subcategories--------------*/

.category-depth-level-3 #subcategories {
    display: none;
}

#subcategories {
    float: left;
    padding: 0;
    width: 100%;

    li {
        h5 {
            // height: 40px
            padding: 10px;
        }
    }
}

#subcategories p.subcategory-heading {
    color: #111;
    float: left;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 10px;
}

#subcategories ul li {
    float: left;
    //  margin: 5px 15px 5px 0;
    text-align: center;
    /* width: 290px;*/
}

#subcategories ul li .subcategory-image {
    //padding: 0 0 8px 0;
}

#subcategories ul li .subcategory-image a {
    //  border: 1px solid #e5e5e5;
    display: block;
    overflow: hidden;
    padding: 10px;

    &:hover {
        color: none
    }
}

#subcategories ul li .subcategory-image a img {
    max-width: 100%;
    vertical-align: top;
    width: 150px;
}

#subcategories ul li .subcategory-name {
    color: #111;
    font: 300 14px/22px 'Work Sans', sans-serif;
    text-transform: uppercase;
}

#subcategories ul li:hover .subcategory-name {
    color: #111;
}

#subcategories ul li .subcategory-name:hover {
    color: $orange;
}

#subcategories ul li .cat_desc {
    display: none;
}


/*
#subcategories ul li:hover .subcategory-image a {
    border: 2px solid #111;
    padding: 5px;
}*/

@media (max-width: 543px) {
    #subcategories ul li {
        margin: 5px 10px 5px 0;
        /*width: 135px;*/
    }
}


/*------------ End subcategories--------------*/


/* ------------Start left column/Right column product css -----------*/

#search_filters #_desktop_search_filters_clear_all .btn.btn-tertiary.js-search-filters-clear-all {
    margin: 8px 0 6px 15px;
}

#left-column>div,
#right-column>div,
#left-column>section,
#right-column>section,
#left-column>div#search_filters_wrapper #search_filters,
#right-column>div#search_filters_wrapper #search_filters {
    border: 1px solid #e5e5e5;
    float: left;
    margin: 0 0 30px;
    padding: 5px;
    width: 100%;
    margin: 0 0 20px;
}

#left-column .clearfix,
#right-column .clearfix {
    float: left;
    width: 100%;
    padding: 5px 5px 15px;
}

#left-column .clearfix .products-section-title,
#right-column .clearfix .products-section-title {
    margin: 0 0 10px;
}

#left-column .clearfix .products,
#right-column .clearfix .products {
    padding: 0 15px;
    float: left;
    width: 100%;
    margin: 0 0 15px;
}

#left-column .products .product-miniature,
#right-column .products .product-miniature {
    padding: 0;
    width: 100%;
    margin: 0 !important;
    border: none;
}

#left-column .products .product-miniature .thumbnail-container,
#right-column .products .product-miniature .thumbnail-container {
    padding: 20px 0;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    margin: 0;
    float: left;
    width: 100%;
}

#left-column .products .product-miniature:last-child .thumbnail-container,
#right-column .products .product-miniature:last-child .thumbnail-container {
    border: none;
    padding-bottom: 0;
    margin: 0;
}

#left-column .products .product-miniature:first-child .thumbnail-container,
#right-column .products .product-miniature:first-child .thumbnail-container {
    margin: 0;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-image,
#right-column .products .product-miniature .thumbnail-container .ttproduct-image {
    float: left;
    margin-right: 10px;
}

#left-column .products .product-miniature .thumbnail-container .quick-view-block,
#right-column .products .product-miniature .thumbnail-container .quick-view-block {
    display: none;
}

#left-column .products .product-miniature .thumbnail-container .ttproducthover,
#right-column .products .product-miniature .thumbnail-container .ttproducthover {
    display: none;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-image .hook-reviews,
#right-column .products .product-miniature .thumbnail-container .ttproduct-image .hook-reviews {
    display: none;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-image .product-flags,
#right-column .products .product-miniature .thumbnail-container .ttproduct-image .product-flags {
    display: none;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-image a,
#right-column .products .product-miniature .thumbnail-container .ttproduct-image a {
    border: 1px solid #e5e5e5;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-image a img,
#right-column .products .product-miniature .thumbnail-container .ttproduct-image a img {
    height: 91px;
    width: 91px;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description,
#right-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description {
    padding: 0;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-price-and-shipping .discount-percentage,
#right-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-price-and-shipping .discount-percentage {
    position: static;
    vertical-align: middle;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-desc,
#right-column .products .product-miniature .thumbnail-container .ttproduct-desc {
    overflow: hidden;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-title,
#right-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-title {
    margin: 0 0 7px;
    text-align: left;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-title a,
#right-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-title a {
    float: left;
    font-size: 14px;
    white-space: normal;
    width: auto;
}

#left-column .products .product-miniature:hover .thumbnail-container,
#right-column .products .product-miniature:hover .thumbnail-container {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

#left-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-price-and-shipping,
#right-column .products .product-miniature .thumbnail-container .ttproduct-desc .product-description .product-price-and-shipping {
    text-align: left;
    font-size: 14px;
}

#left-column .all-product-link,
#right-column .all-product-link,
#left-column .all-product-link i,
#right-column .all-product-link i {
    margin: 0;
    color: #111;
    font-size: 13px;
    font-weight: normal;
}

#left-column .all-product-link:hover,
#right-column .all-product-link:hover {
    color: #111000;
    text-decoration: underline;
}

#left-column .all-product-link:hover,
#right-column .all-product-link:hover {
    color: #111000;
    text-decoration: underline;
}

#left-column .collapse,
#right-column .collapse {
    display: block;
}

#left-column .category-sub-menu .collapse,
#right-column .category-sub-menu .collapse {
    display: none;
}

#left-column .category-sub-menu .collapse.in,
#right-column .category-sub-menu .collapse.in {
    display: block;
}

@media (max-width: 991px) {
    #left-column ul#categories li:first-child {
        // display: none;
    }

    #left-column .collapse,
    #right-column .collapse {
        display: none;
    }

    #left-column .clearfix,
    #right-column .clearfix {
        padding: 5px;
    }

    #left-column .collapse.in,
    #right-column .collapse.in {
        display: block;
    }

    #left-column .products-section-title.text-uppercase,
    #right-column .products-section-title.text-uppercase {
        display: none;
    }

    #left-column .title .float-xs-right .material-icons.remove,
    #right-column .title .float-xs-right .material-icons.remove {
        display: none;
    }

    #left-column>div,
    #right-column>div,
    #left-column>section,
    #right-column>section {
        cursor: pointer;
        margin: 0 0 15px !important;
    }

    #left-column .title[aria-expanded="true"] .float-xs-right .material-icons.add,
    #right-column .title[aria-expanded="true"] .float-xs-right .material-icons.add {
        display: none;
    }

    .toggle {
        float: left;
        width: 100%;
    }

    #left-column .title[aria-expanded="true"] .float-xs-right .material-icons.remove,
    #right-column .title[aria-expanded="true"] .float-xs-right .material-icons.remove {
        display: inline-block;
    }

    #products #js-product-list .up {
        display: none;
    }

    #left-column .text-uppercase,
    #right-column .text-uppercase {
        margin: 0;
        padding: 5px;
        width: auto;
    }

    #left-column .title .float-xs-right .navbar-toggler,
    #right-column .title .float-xs-right .navbar-toggler {
        padding: 4px 0;
    }

    #left-column .title .float-xs-right .navbar-toggler i,
    #right-column .title .float-xs-right .navbar-toggler i {
        color: #111;
        font-size: 20px;
        line-height: 29px;
    }
}


/* ------------End left column/Right column product css -----------*/


/*--------------Start ttcmsleftbanner--------------*/

#left-column .ttleftbanner-img.ttimg img,
#right-column .ttleftbanner-img.ttimg img {
    max-width: 100%;
}

@media (max-width: 991px) {
    #ttcmsleftbanner {
        width: auto !important;
    }
}


/*--------------End ttcmsleftbanner--------------*/


/*------------ Start TT-Brandlogo ---------------*/

.brands {
    display: inline-block;
    float: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0 0 30px;
    vertical-align: top;
}

.brands .products-section-title a {
    color: #111111;
}

.brands #ttbrandlogo-carousel {
    float: left;
    width: 100%;
    margin: 0;
    position: relative;
}

.brands #ttbrandlogo-carousel li {
    float: left;
    padding: 15px;
    text-align: center;
    width: 100% !important;
}

.brands #ttbrandlogo-carousel li .brand-image img {
    max-width: 100%;
    opacity: 0.6;
    -moz-opacity: 0.6;
    -khtml-opacity: 0.6;
    -webkit-opacity: 0.6;
    transition: all 500ms ease 0s;
    -webkit-transition: all 500ms ease 0s;
    -moz-transition: all 500ms ease 0s;
    -o-transition: all 500ms ease 0s;
    -ms-transition: all 500ms ease 0s;
    width: auto;
}

.brands #ttbrandlogo-carousel li:hover .brand-image img {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

.brands #ttbrandlogo-carousel .owl-buttons {
    display: none;
}

.brands:hover #ttbrandlogo-carousel .owl-buttons {
    display: block;
}

.brands #ttbrandlogo-carousel .owl-buttons div {
    bottom: 50px;
    position: absolute;
    text-align: center;
}

.brands #ttbrandlogo-carousel .owl-buttons div.owl-prev {
    left: 0;
    right: auto;
}

.brands #ttbrandlogo-carousel .owl-buttons div.owl-next {
    right: 0;
    left: auto;
}

@media (max-width: 767px) {
    .brands {
        margin: 0 0 15px;
    }
}

@media (max-width: 543px) {
    .brands #ttbrandlogo-carousel .owl-buttons div {
        bottom: 40px;
    }
}


/*------------ End TT-Brandlogo ---------------*/

#main ul {
    padding: 0;
}

.js-qv-mask ul {
    padding: 0;
}

.featured-products {
    float: left;
    width: 100%;
    text-align: center;
}

.custom-radio:hover {
    border-color: #111000;
}


/*--------------------Header---------------------------*/

.header-nav .top-nav .left-nav {
    float: left;
    width: 100%;
    background: #f1f1f1;
}

#header .right-nav {
    float: left;
    width: 100%;
    text-align: center;
    background: #111;
}

.topmenu .title_block i {
    cursor: pointer;
    float: right;
    margin: 0;
    width: auto;
}

#_desktop_top_menu {
    float: left;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    display: none;
    z-index: 2;
    top: 55px;
    background: #fff;
    padding: 0;
    box-shadow: 0 1px 2px 1px #eee;
    -moz-box-shadow: 0 1px 2px 1px #eee;
    -webkit-box-shadow: 0 1px 2px 1px #eee;
}

#index #_desktop_top_menu {
    display: block;
}

#_desktop_top_menu::after {
    background: rgba(0, 0, 0, 0) url("../img/line-patterm.png") repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: auto;
    width: 100%;
}

#header .position-static {
    float: left;
    position: relative;
    text-align: center;
    width: 100%;
}

#index .full-header.fixed #_desktop_top_menu {
    display: none;
}

#_desktop_cart {
    cursor: pointer;
    display: block;
    float: right;
    width: auto;
}

@media (max-width: 991px) {
    #_desktop_cart {
        display: none;
    }
}

.topmenu {
    cursor: pointer;
    float: left;
    padding: 0;
    width: 300px;
}

@media (max-width: 1599px) {
    .topmenu {
        width: 240px;
    }
}

@media (max-width: 1299px) {
    .topmenu {
        width: 220px;
    }
}

@media (max-width: 1200px) {
    .topmenu {
        width: 293px;
    }
}

@media (max-width: 1199px) {
    .topmenu {
        width: 240px;
    }
}

@media (max-width: 991px) {
    .topmenu {
        display: none;
    }
}

.top-menu {
    display: inline-block;
    float: left;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 100%;
}

#header .menu>ul>li {
    float: left;
    width: 100%;
    position: relative;
}

#footer {
    float: left;
    width: 100%;
}

.footer-top {
    background: #111;
    float: left;
    width: 100%;
    margin: 30px 0 0;
}

#index .footer-top {
    margin: 0;
}

.full-header {
    float: left;
    position: relative;
    width: 100%;
    z-index: 2;
    background: #fff;
}

.full-header::after {
    background: rgba(0, 0, 0, 0) url("../img/line-patterm.png") repeat scroll 0 0;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    z-index: -1px;
}

.menu-banner {
    float: left;
    margin: 3px 0 30px;
    width: 100%;
}

.menu-banner img {
    max-width: 100%;
}

#top-menu .sub-menu .menu-banner {
    display: none;
}

@media (max-width: 991px) {
    .footer-top {
        padding: 0;
    }

    .menu-banner {
        margin: 10px 0 5px;
        text-align: center;
    }

    .full-header::after {
        display: none;
    }
}


/* ------------------------------- Start TT - Category More Menu Css ------------------------- */

#top-menu li .more-menu .categories i {
    float: right;
    font-size: 15px;
    position: absolute;
    right: 20px;
    top: 15px;
}

@media (max-width: 991px) {
    .category {
        display: block !important;
    }

    #mobile_top_menu_wrapper .more {
        display: none;
    }
}


/* ------------------------------- End TT - Category More Menu Css ------------------------- */


/* ------ header fixed css ------ */

#header .full-header.fixed {
    // animation: 1s ease 0s normal both 1 running fixedmenu;
    float: left;
    left: 0;
    margin: 0;
    position: fixed !important;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    border: none;

    .cart_block {
        top: 76px;
    }

    .cart_block::after {
        color: #f1f1f1;
    }
}

@-webkit-keyframes fixedmenu {
    0% {
        top: -100%;
    }

    100% {
        top: 0;
    }
}

@-moz-keyframes fixedmenu {
    0% {
        top: -100%;
    }

    100% {
        top: 0;
    }
}

@keyframes fixedmenu {
    0% {
        top: -100%;
    }

    100% {
        top: 0;
    }
}


/*---------------------- Start product customNavigation css --------------------*/

.customNavigation {
    bottom: auto;
    float: right;
    left: auto;
    position: absolute;
    right: 0;
    top: 5px;
    width: auto;
}

.customNavigation a {
    float: left;
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    border: 1px solid #e5e5e5;
    color: #777777;
    position: relative;
    width: 30px;
    height: 30px;
    padding: 0;
}

.customNavigation a.prev {
    background: #ffffff url("../img/sprite.png") no-repeat scroll -10px -353px;
    margin: 0 8px 0 0;
    padding: 0;
}

.customNavigation a.next {
    background: #ffffff url("../img/sprite.png") no-repeat scroll -10px -386px;
}

.customNavigation a:hover {
    border-color: #111;
    background-color: #111;
    color: #fff;
}

.customNavigation a:hover.prev {
    background-position: -69px -353px;
}

.customNavigation a:hover.next {
    background-position: -69px -386px;
}

.ttfeatured-products .customNavigation,
.ttbestseller-products .customNavigation,
.ttnew-products .customNavigation {
    top: -60px;
}

.ttcategory-testi .customNavigation {
    right: 15px;
}

@media (max-width: 991px) {
    .customNavigation {
        display: inline-block;
        float: none !important;
        padding: 0 0 20px;
        position: static;
        vertical-align: top;
    }

    #index .customNavigation {
        position: absolute;
    }
}

@media (max-width: 767px) {

    .ttfeatured-products .customNavigation,
    .ttbestseller-products .customNavigation,
    .ttnew-products .customNavigation,
    #ttcategorytabs .customNavigation {
        position: static !important;
    }
}

@media (max-width: 543px) {
    #index .customNavigation {
        position: static;
    }
}


/*---------------------- End product customNavigation css --------------------*/

.featured-products .all-product-link {
    float: right;
    font-size: 16px;
    margin: 10px 0;
    text-align: right;
    width: 100%;
}

.language-selector-wrapper button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 6px 4px;
}

.currency-selector button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 9px 6px;
}

.block-contact .block {
    margin: 0 0 5px;
}

.footer-container .block-contact li a::before {
    display: none;
}

.block-contact .block .data .title {
    display: none;
}

.currency-selector #currency-selector-label {
    display: none;
}

#delivery textarea {
    border: 1px solid #e5e5e5;
    float: left;
    margin: 0 0 10px;
    width: 100%;
}

@media(max-width: 1200px) {
    #index .columns_inner.container {
        padding: 0 15px;
    }

    .columns_inner.container {
        padding: 0;
    }
}


/*---------------------------- Start TT - CMS Header block ----------------------------*/

#ttcmsheader {
    float: left;
    padding: 10px 0;
}

#ttcmsheader .ttheader-service {
    color: #111;
    font: 300 13px/22px 'Work Sans', sans-serif;
}

#ttcmsheader .ttheader-service::before {
    background: rgba(0, 0, 0, 0) url("../img/sprite.png") repeat scroll -36px -486px;
    content: "";
    float: left;
    height: 24px;
    width: 30px;
}

@media (max-width: 991px) {
    #ttcmsheader {
        width: 100%;
        text-align: center;
        padding: 5px 0;
    }

    #ttcmsheader .ttheader-service {
        display: inline-block;
        float: none;
        vertical-align: top;
        padding: 5px 0;
    }
}

@media (max-width: 543px) {
    #ttcmsheader .ttheader-service {
        padding: 0;
    }

    #ttcmsheader .ttheader-service::before {
        float: none;
        display: inline-block;
        vertical-align: middle;
    }
}


/*---------------------------- End TT - CMS Header block ----------------------------*/


/*------------ Start TT-top-link-block--------------*/

#tttop-link-block {
    float: left;
    position: relative;
}

#tttop-link-block .container {
    padding: 0;
}

#tttop-link-block .block_content {
    float: left;
    margin: 0;
    padding: 0;
}

#tttop-link-block ul li {
    float: left;
    margin: 0;
    position: relative;
    z-index: 1;
}

#tttop-link-block .more_menu ul li {
    width: 100%;
}

#tttop-link-block .title_block {
    color: #111;
    font-size: 20px;
    text-transform: uppercase;
    float: left;
    margin: 0;
    padding: 13px 20px 13px 0;
    position: relative;
    display: none;
}

#tttop-link-block .title_block::after {
    content: ":";
    font-family: Material Icons;
    line-height: normal;
    position: absolute;
    right: 0;
    top: 5px;
}

#tttop-link-block ul li a {
    color: #111;
    display: block;
    float: left;
    font: 500 14px/15px 'Work Sans', sans-serif;
    overflow: hidden;
    padding: 23px 30px;
    position: relative;
    text-transform: uppercase;
}

#tttop-link-block ul li.active a {
    color: $orange;
    font-weight: bold;
}

#tttop-link-block ul li:hover a {
    color: #ee0000;
}

#tttop-link-block li.more_menu ul li a {
    color: #111;
    padding: 6px 20px;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
    line-height: 12px;
}

#tttop-link-block li.more_menu ul li a:hover {
    background: none;
    color: #111 !important;
}

#tttoplink .top-link {
    background: #fff;
    border: 1px solid #e5e5e5;
    display: none;
    left: 0;
    min-width: 185px;
    padding: 12px 0;
    position: absolute;
    right: 0;
    top: 57px;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    z-index: 1;
}

#tttop-link-block ul li.more_menu {
    position: relative;
}

#tttoplink .top-link.shown-link {
    display: block;
}

#tttop-link-block .tttoplink .material-icons.icon {
    font-size: 16px;
    vertical-align: top;
}

@media (min-width: 992px) and (max-width: 1199px) {
    #tttop-link-block ul li a {
        padding: 22px 20px;
    }
}

@media (max-width: 991px) {
    #tttop-link-block ul li {
        background: none;
    }

    #top-menu li .more-menu .categories {
        text-align: left;
    }

    #tttop-link-block {
        display: none;
    }

    .tttoplink>a {
        color: #111;
        font: 400 13px/21px 'Work Sans', sans-serif;
        padding: 6px 15px;
        text-align: left;
        text-transform: capitalize;
        width: 100%;
        float: left;
    }

    #tttoplink {
        padding: 0;
    }

    #tttoplink .more_menu .level-top {
        display: none;
    }

    #tttoplink .top-link {
        border: medium none;
        display: block;
        position: static;
        padding: 0;
    }

    .tttoplink>a:hover {
        color: #111 !important;
    }
}

#tttop-link-block ul li:last-child a:before {
    display: none;
}


/*------------ End TT-top-link-block--------------*/


/* ------------------ Start TT - CMS top-banner block --------------------- */

#ttcmstopbanners {
    float: left;
    position: relative;
    width: 100%;
    background: #fff;
    margin: 0 0 65px;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner {
    float: left;
    width: 100%;
    padding: 0;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner-img {
    float: left;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner-img a img {
    max-width: 100%;
    width: 100%;
    border: 8px solid #f9f9f9;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner-img a {
    display: block;
    overflow: hidden;
    position: relative;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner-img a::after {
    bottom: 50%;
    content: "";
    left: 0;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner-img a::before {
    bottom: 0;
    content: "";
    left: 50%;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 51%;
    top: 0;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner-img:hover a::after {
    bottom: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    top: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

#ttcmstopbanners .ttcmstopbanner .tttopbanner-img:hover a::before {
    left: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    right: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

@media (max-width: 1200px) {
    #ttcmstopbanners {
        margin: 0 0 45px;
    }
}

@media (max-width: 991px) {
    #ttcmstopbanners .ttcmstopbanner .row {
        margin: 0 -7px;
    }

    #ttcmstopbanners .ttcmstopbanner .tttopbanner-img {
        padding: 0 7px;
    }
}

@media (max-width: 767px) {
    #ttcmstopbanners {
        margin: 0 0 30px;
    }

    #ttcmstopbanners .ttcmstopbanner .tttopbanner-img a img {
        border: 5px solid #f9f9f9;
    }
}

@media (max-width: 543px) {
    #ttcmstopbanners {
        margin: 0 0 15px;
    }

    #ttcmstopbanners .ttcmstopbanner .tttopbanner-img {
        margin: 0 0 15px;
        width: 50%;
    }
}


/* ------------------ End TT - CMS top-banner block --------------------- */


/* ----------------------- Start TT - CMS banner block --------------------- */

#ttcmsbanner {
    float: left;
    position: relative;
    width: 100%;
    background: #fff;
    margin: 0 0 60px;
}

#ttcmsbanner .ttcmsbanner .ttbanner {
    float: left;
    width: 100%;
    padding: 0;
}

#ttcmsbanner .ttcmsbanner .ttbanner-img {
    float: left;
}

#ttcmsbanner .ttcmsbanner .ttbanner-img a img {
    max-width: 100%;
    width: 100%;
}

#ttcmsbanner .ttcmsbanner .ttbanner-img a {
    display: block;
    overflow: hidden;
    position: relative;
}

#ttcmsbanner .ttcmsbanner .ttbanner-img a::after {
    bottom: 50%;
    content: "";
    left: 0;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmsbanner .ttcmsbanner .ttbanner-img a::before {
    bottom: 0;
    content: "";
    left: 50%;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 51%;
    top: 0;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmsbanner .ttcmsbanner .ttbanner-img:hover a::after {
    bottom: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    top: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

#ttcmsbanner .ttcmsbanner .ttbanner-img:hover a::before {
    left: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    right: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

@media (max-width: 1200px) {
    #ttcmsbanner .container {
        padding: 0;
    }
}

@media (max-width:1199px) {
    #ttcmsbanner {
        margin: 0 0 30px;
    }
}

@media (max-width: 991px) {
    #ttcmsbanner .ttcmsbanner .row {
        margin: 0 -7px;
    }

    #ttcmsbanner .ttcmsbanner .ttbanner-img {
        padding: 0 7px;
    }
}

@media (max-width: 543px) {
    #ttcmsbanner {
        margin: 0 0 15px;
    }

    #ttcmsbanner .ttcmsbanner .ttbanner-img {
        margin: 0 0 15px;
        width: 100%;
    }
}


/* ----------------------- End TT - CMS banner block ------------------------ */


/* ------------------------------------ Start TT - CMS testimonial ------------------------------- */

#left-column .tt-title::after,
#right-column .tt-title::after {
    display: none;
}

#left-column #tttestimonial-carousel,
#right-column #tttestimonial-carousel {
    float: left;
    margin: 0;
    overflow: hidden;
    padding: 10px 15px;
    width: 100%;
}

#left-column #ttcmstestimonial .tt-titletab,
#right-column #ttcmstestimonial .tt-titletab {
    border: none;
    margin: 0;
}

#ttcmstestimonial {
    display: inline-block;
    float: none;
    text-align: center;
    padding: 0;
    width: 100%;
}

#ttcmstestimonial .customNavigation {
    display: inline-block;
    float: none;
    margin: 0 0 25px;
    padding: 0;
    position: static;
    vertical-align: top;
}

#ttcmstestimonial .tttestimonial-content.container {
    padding: 0;
}

#ttcmstestimonial .title_block .ttblock-heading {
    border: medium none;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 600;
    padding: 8px 20px;
    margin: 0;
}

#testimonial .tttestimonial-inner .testimonial-desc {
    float: left;
    margin: 20px 0 0;
    text-align: left;
    width: 100%;
}

#ttcmstestimonial .title_block .ttblock-heading:before {
    display: none;
}

@media (max-width: 991px) {

    #ttcmstestimonial #tttestimonial-carousel.block_content,
    #ttcmstestimonial #testimonial .tt-title {
        display: none;
    }
}

#ttcmstestimonial .tttestimonial-subtitle {
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: 14px;
    color: #888888;
    float: left;
    width: 100%;
    margin: 0;
    text-transform: capitalize;
    padding-bottom: 10px;
    position: relative;
}

#ttcmstestimonial ul li {
    float: left;
    width: 100%;
}

#ttcmstestimonial ul li .testimonial-image {
    height: 90px;
    width: 90px;
    float: left;
    margin: 0;
}

#ttcmstestimonial ul li .testimonial-image img {
    border: 1px solid #f0f0f0;
    float: left;
    width: 100%;
    height: 100%;
}

#ttcmstestimonial ul li .testimonial-user-title {
    float: left;
    text-align: left;
    padding: 10px 0 0 20px;
}

#ttcmstestimonial ul li .testimonial-user-title h4 {
    color: #111;
    font: 400 16px/18px 'Work Sans', sans-serif;
    margin-bottom: 5px;
}

#ttcmstestimonial ul li .testimonial-content {
    margin: 20px 0 00px;
    float: left;
    width: 100%;
    text-align: left;
}

@media (min-width: 1200px) and (max-width: 1299px) {
    #ttcmstestimonial ul li .testimonial-image {
        height: 85px;
        width: 85px;
    }

    #ttcmstestimonial ul li .testimonial-user-title {
        padding: 10px 0 0 9px;
    }
}

@media (max-width: 1199px) {
    #ttcmstestimonial ul li .testimonial-user-title {
        padding: 20px 0 0 10px;
    }
}

@media (max-width: 767px) {
    #ttcmstestimonial ul li .testimonial-content {
        margin: 20px 0 30px;
    }
}

#ttcmstestimonial ul li .testimonial-content .testimonial-desc {
    float: left;
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
}

#ttcmstestimonial ul li .testimonial-content .testimonial-desc>p {
    color: #888888;
    font: 400 14px/22px 'Work Sans', sans-serif;
    padding: 0 25px 0 0;
}

@media (min-width: 992px) and (max-width: 1400px) {
    #ttcmstestimonial ul li .testimonial-content .testimonial-desc {
        margin: 0 0 20px;
        padding: 0;
    }
}

#ttcmstestimonial .owl-buttons {
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: top;
    width: auto;
    position: static;
}

#ttcmstestimonial .owl-buttons>div {
    height: 30px;
    width: 30px;
    font-size: 0;
    float: left;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    position: relative;
}


/* ------------------------------------ End TT - CMS testimonial ------------------------------- */


/* ---------------------- Start TT - CMS Bottom Banner block --------------------------*/

#ttcmsbottombanner {
    float: left;
    width: 100%;
    margin: 0 0 45px;
}

#ttcmsbottombanner .ttbanner {
    float: left;
}

#ttcmsbottombanner .ttbanner .ttbottombanner-img img {
    max-width: 100%;
}

#ttcmsbottombanner .ttbanner .ttbottombanner-img a {
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
}

#ttcmsbottombanner .ttbanner .ttbottombanner-img a::after {
    bottom: 50%;
    content: "";
    left: 0;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmsbottombanner .ttbanner .ttbottombanner-img a::before {
    bottom: 0;
    content: "";
    left: 50%;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 51%;
    top: 0;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmsbottombanner .ttbanner .ttbottombanner-img:hover a::after {
    bottom: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    top: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

#ttcmsbottombanner .ttbanner .ttbottombanner-img:hover a::before {
    left: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    right: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

@media (max-width: 991px) {
    #ttcmsbottombanner {
        margin: 0 0 30px;
    }
}


/* ---------------------- End TT - CMS Bottom Banner block  --------------------------*/


/* ---------------------- Start TT - CMS Sub Banner block --------------------------*/

#ttcmssubbanner {
    float: left;
    width: 100%;
    margin: 0 0 50px;
}

#ttcmssubbanner .ttsubbannerblock {
    padding: 0;
}

#ttcmssubbanner .ttbanner {
    float: left;
}

#ttcmssubbanner .ttbanner .ttsubbanner-img img {
    max-width: 100%;
    width: 100%;
}

#ttcmssubbanner .ttbanner .ttsubbanner-img a {
    display: block;
    overflow: hidden;
    position: relative;
}

#ttcmssubbanner .ttbanner .ttsubbanner-img a::after {
    bottom: 50%;
    content: "";
    left: 0;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmssubbanner .ttbanner .ttsubbanner-img a::before {
    bottom: 0;
    content: "";
    left: 50%;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    right: 51%;
    top: 0;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}

#ttcmssubbanner .ttbanner .ttsubbanner-img:hover a::after {
    bottom: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    top: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

#ttcmssubbanner .ttbanner .ttsubbanner-img:hover a::before {
    left: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    right: 0;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

@media (max-width: 991px) {
    #ttcmssubbanner {
        margin: 0 0 35px;
    }

    #ttcmssubbanner .ttbanner {
        padding: 0 7px;
    }

    #ttcmssubbanner .row {
        margin: 0 -7px;
    }
}

@media (max-width: 543px) {
    #ttcmssubbanner .ttbanner {
        margin: 0 0 15px;
        width: 100%;
    }

    #ttcmssubbanner {
        margin: 0 0 10px;
    }
}


/* ---------------------- End TT - CMS  Sub Banner block  --------------------------*/


/*---------------------------- Start TT - CMS Services block ----------------------------*/

#ttcmsservices {
    display: inline-block;
    float: none;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
}

#ttcmsservices .ttcmsservice {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
    float: right;
    padding: 20px 0;
    vertical-align: top;
    margin: -177px 0 0;
}

#ttcmsservices .ttcmsservice::after {
    background: rgba(0, 0, 0, 0) url("../img/line-patterm.png") repeat scroll 0 0;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    z-index: -1px;
}

#ttcmsservices .ttcmsservice .ttservicecontent {
    display: inline-block;
    padding: 0;
    vertical-align: top;
    cursor: pointer;
}

#ttcmsservices .ttcmsservice .owl-item:last-child {
    border: none;
}

#ttcmsservices .ttcmsservice .ttservicecontent .service-icon {
    color: white;
    display: inline-block;
    float: none;
    font-size: 30px;
    height: 62px;
    line-height: 60px;
    margin: 0;
    position: relative;
    text-align: left;
    width: 50px;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    vertical-align: top;
}

#ttcmsservices .ttcmsservice .ttservicecontent .service-content {
    display: inline-block;
    float: none;
    margin: 0 0 0 10px;
    padding: 10px 0;
    text-align: left;
    vertical-align: top;
}

#ttcmsservices .ttcmsservice .ttservicecontent .service-content .service-title {
    color: #111000;
    font: 500 16px/22px 'Work Sans', sans-serif;
    text-transform: uppercase;
}

#ttcmsservices .ttcmsservice .ttservicecontent .service-content .service-desc {
    color: #777777;
    font: 400 13px/20px 'Work Sans', sans-serif;
    text-transform: capitalize;
    word-wrap: break-word;
}

#ttcmsservices .ttcmsservice .owl-item:hover .service-title {
    color: #ee0000;
}

#ttcmsservices .ttcmsservice .ttdelivery .service-icon.ttdelivery_img {
    background: transparent url("../img/services-icon.png") repeat scroll -6px 7px;
}

#ttcmsservices .ttcmsservice .ttdelivery:hover .service-icon.ttdelivery_img {
    background-position: 53px 7px;
}

#ttcmsservices .ttcmsservice .ttexchange .service-icon.ttexchange_img {
    background: transparent url("../img/services-icon.png") repeat scroll -6px -73px;
}

#ttcmsservices .ttcmsservice .ttexchange:hover .service-icon.ttexchange_img {
    background-position: 53px -73px;
}

#ttcmsservices .ttcmsservice .ttsupport .service-icon.ttsupport_img {
    background: transparent url("../img/services-icon.png") repeat scroll -6px -153px;
}

#ttcmsservices .ttcmsservice .ttsupport:hover .service-icon.ttsupport_img {
    background-position: 53px -153px;
}

#ttcmsservices .ttcmsservice .ttshopping .service-icon.ttshopping_img {
    background: transparent url("../img/services-icon.png") repeat scroll -6px -233px;
}

#ttcmsservices .ttcmsservice .ttshopping:hover .service-icon.ttshopping_img {
    background-position: 53px -233px;
}

@media (min-width: 1199px) {
    #ttcmsservices .ttcmsservice .owl-item {
        border-right: 1px solid #e5e5e5;
    }
}

@media (max-width: 1599px) {
    #ttcmsservices .ttcmsservice {
        padding: 15px;
        margin: -215px 0 0;
    }

    #ttcmsservices .ttcmsservice .ttservicecontent .service-content {
        margin: 0;
        text-align: center;
        width: 100%;
        padding-top: 0;
    }

    #ttcmsservices .ttcmsservice .ttservicecontent .service-content .service-title {
        font-size: 14px;
        line-height: 22px;
    }
}

@media (max-width: 1200px) {
    #ttcmsservices .ttcmsservice {
        margin: -180px 0 0;
    }
}

@media (max-width: 1199px) {
    #ttcmsservices {
        padding: 0 15px;
    }

    #ttcmsservices .ttcmsservice {
        margin: -175px 0 0;
    }
}

@media (max-width: 991px) {
    #ttcmsservices .ttcmsservice {
        margin: 25px 0 0;
        width: 100%;
    }
}

@media (max-width: 767px) {
    #ttcmsservices .ttcmsservice {
        padding: 15px 0;
        margin: 15px 0 0;
    }

    #ttcmsservices .ttcmsservice .ttservicecontent .service-content .service-title {
        font-size: 12px;
    }

    #ttcmsservices .ttcmsservice .ttservicecontent .service-content .service-desc {
        font-size: 11px;
    }
}

@media (max-width: 543px) {
    #ttcmsservices .ttcmsservice {
        padding: 10px;
    }

    #ttcmsservices .ttcmsservice .ttservicecontent .service-content .service-desc {
        font-size: 12px;
        line-height: 14px;
    }
}


/*---------------------------- End TT - CMS Services block ----------------------------*/


/*---------------------------- Start TT - CMS Header Services block ----------------------------*/

#ttcmsheaderservices {
    float: right;
    margin: 35px 15px;
}

#ttcmsheaderservices .ttheaderservice-content {
    color: #fff;
    font: 500 14px/40px 'Work Sans', sans-serif;
}

#ttcmsheaderservices .ttheaderservice-content::before {
    background: rgba(0, 0, 0, 0) url("../img/sprite.png") repeat scroll -33px -425px;
    content: "";
    float: left;
    height: 40px;
    width: 50px;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

#ttcmsheaderservices .ttheaderservice-content:hover::before {
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
}

@media (max-width: 991px) {
    #ttcmsheaderservices {
        display: inline-block;
        float: none;
        margin: 27px 15px;
        text-align: center;
    }
}

@media (max-width: 543px) {
    #ttcmsheaderservices {
        margin: 0 0 15px;
        text-align: center;
        width: 100%;
    }

    #ttcmsheaderservices .ttheaderservice-content::before {
        float: none;
        display: inline-block;
        vertical-align: middle;
    }
}


/*---------------------------- End TT - CMS Header Services block ----------------------------*/


/*---------------------------- Start TT - CMS Footer Services block ----------------------------*/

#ttcmsfooterservice {
    float: left;
    padding: 40px 0;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}

#ttcmsfooterservice .ttcmsservice {
    float: left;
    text-align: center;
    width: 100%;
}

#ttcmsfooterservice .ttcmsservice>div {
    display: inline-block;
    padding: 0;
    vertical-align: top;
    cursor: pointer;
}

#ttcmsfooterservice .ttcmsservice>div .service-icon {
    color: white;
    display: inline-block;
    float: none;
    height: 50px;
    line-height: 60px;
    margin: 0;
    position: relative;
    text-align: left;
    width: 50px;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    vertical-align: top;
}

#ttcmsfooterservice .ttcmsservice>div .service-content {
    display: inline-block;
    float: none;
    margin: 0 0 0 10px;
    padding: 5px 0;
    text-align: left;
    vertical-align: top;
    width: 50%;
}

#ttcmsfooterservice .ttcmsservice>div .service-content .service-title {
    color: #111;
    font: 500 12px/18px 'Work Sans', sans-serif;
    text-transform: capitalize;
}

#ttcmsfooterservice .ttcmsservice>div:hover .service-content .service-title {
    color: #111;
}

#ttcmsfooterservice .ttcmsservice .ttawards .service-icon.ttawards_img {
    background: transparent url("../img/footer-services-icon.png") repeat scroll -4px 2px;
}

#ttcmsfooterservice .ttcmsservice .ttawards:hover .service-icon.ttawards_img {
    background-position: 56px 2px;
}

#ttcmsfooterservice .ttcmsservice .ttoffer .service-icon.ttoffer_img {
    background: transparent url("../img/footer-services-icon.png") repeat scroll -5px -78px;
}

#ttcmsfooterservice .ttcmsservice .ttoffer:hover .service-icon.ttoffer_img {
    background-position: 56px -78px;
}

#ttcmsfooterservice .ttcmsservice .ttservice .service-icon.ttservice_img {
    background: transparent url("../img/footer-services-icon.png") repeat scroll -5px -158px;
}

#ttcmsfooterservice .ttcmsservice .ttservice:hover .service-icon.ttservice_img {
    background-position: 55px -158px;
}

#ttcmsfooterservice .ttcmsservice .ttquality .service-icon.ttquality_img {
    background: transparent url("../img/footer-services-icon.png") repeat scroll -5px -239px;
}

#ttcmsfooterservice .ttcmsservice .ttquality:hover .service-icon.ttquality_img {
    background-position: 55px -239px;
}

#ttcmsfooterservice .ttcmsservice .ttsupport .service-icon.ttsupport_img {
    background: transparent url("../img/footer-services-icon.png") repeat scroll -5px -321px;
}

#ttcmsfooterservice .ttcmsservice .ttsupport:hover .service-icon.ttsupport_img {
    background-position: 55px -321px;
}

#ttcmsfooterservice .ttcmsservice .ttpayment .service-icon.ttpayment_img {
    background: transparent url("../img/footer-services-icon.png") repeat scroll -5px -401px;
}

#ttcmsfooterservice .ttcmsservice .ttpayment:hover .service-icon.ttpayment_img {
    background-position: 55px -401px;
}

@media (max-width: 991px) {
    #ttcmsfooterservice {
        padding: 20px 0;
        margin: 0 0 20px;
    }

    #ttcmsfooterservice .ttcmsservice>div .service-content {
        margin: 0 !important;
        text-align: center !important;
        width: 100%;
        padding-top: 0;
    }
}

@media (max-width: 767px) {
    #ttcmsfooterservice .ttcmsservice>div {
        float: left;
        width: 50%;
        padding: 5px 0;
    }

    #ttcmsfooterservice .ttcmsservice>div .service {
        text-align: center;
    }

    #ttcmsfooterservice .ttcmsservice>div .service-icon {
        text-align: center;
    }
}

@media (max-width: 543px) {
    #ttcmsfooterservice .ttcmsservice {
        padding: 10px 0;
    }

    #ttcmsfooterservice .ttcmsservice>div .service-content .service-desc {
        font-size: 12px;
        line-height: 14px;
    }
}


/*---------------------------- End TT - CMS Footer Services block ----------------------------*/


/*--------------- Start TT - CMS Footer --------------*/

#ttcmsfooter .title.clearfix.hidden-md-up {
    display: block !important;
}

#ttcmsfooter #ttfooter-desc {
    color: #777;
    float: left;
    margin: 0 auto;
    font: 400 13px/22px 'Work Sans', sans-serif;
    text-align: left;
}

#ttcmsfooter .payment-list {
    padding: 0 !important;
    margin: 0;
    float: left;
    width: 100%;
}

#ttcmsfooter ul li {
    float: left;
    margin: 0 15px 0 0;
    width: auto;
}

#ttcmsfooter ul li:last-child {
    margin: 0;
}

#ttcmsfooter .payment_logo_block {
    float: left;
    width: 100%;
    margin: 15px 0 0;
}

@media(min-width:992px) {
    #ttcmsfooter .title.clearfix.hidden-md-up .float-xs-right {
        display: none;
    }
}

@media (max-width: 991px) {
    #ttcmsfooter {
        width: 100%;
        min-height: auto;
        margin: 0;
    }

    #footer .footer-container ul {
        margin: 0 0 10px;
    }

    #ttcmsfooter .h3 {
        display: inline-block;
    }

    #ttcmsfooter #ttfooter-desc {
        width: 80%;
        padding: 0 10px;
    }
}

@media (max-width: 543px) {
    #ttcmsfooter #ttfooter-desc {
        width: 100%;
    }
}


/*--------------- End TT - CMS Footer --------------*/


/*--------------------- start Product Comaprasion Css ---------------------------*/

#module-ttcompare-compare .ttproductcountdown {
    margin: 0 0 10px;
}

.modal-header .modal-msg div {
    line-height: 28px;
}

#module-blockwishlist-mywishlist .page-content {
    padding: 0;
    border: none;
}

#module-ttproductwishlist-mywishlist th {
    text-align: left;
}

#module-ttproductwishlist-mywishlist .product-container .form-group input {
    width: auto;
    display: inline;
}

#product_comparison .tt_empty.compare_extra_information {
    vertical-align: bottom;
}

#product_comparison .tt_empty.compare_extra_information>span {
    font-weight: bold;
}

table#product_comparison {
    border: 1px solid #e5e5e5;
}

table#product_comparison th,
table#product_comparison td {
    border-color: #eeeeee;
}

table#product_comparison tbody tr td.product-block {
    vertical-align: top;
    padding: 15px;
    text-align: center;
}

table#product_comparison tbody tr td.product-block .product-title a {
    white-space: normal !important;
}

table#product_comparison tbody tr td.td_empty,
table#product_comparison tbody tr td.feature-name,
table#product_comparison tbody tr.comparison_header {
    background: #f6f6f6;
    color: #808080;
}

table#product_comparison tbody tr td.comparison_infos {
    text-align: center;
    background: transparent;
}

table#product_comparison tbody tr td.td_empty {
    vertical-align: bottom;
}

table#product_comparison tbody tr td.td_empty>span {
    font: 600 18px/22px 'Work Sans', sans-serif;
    text-transform: uppercase;
    color: #555454;
}

table#product_comparison .remove {
    padding: 0 0 15px 0;
}

table#product_comparison .remove a {
    font-size: 23px;
    line-height: 23px;
    color: #666;
}

table#product_comparison .remove a:hover {
    color: #111;
}

table#product_comparison .thumbnail-container {
    display: inline-block;
    position: relative;
}

table#product_comparison .thumbnail-container .product-thumbnail {
    padding: 5px;
    border: 1px solid #e5e5e5;
    display: inline-block;
    margin: 0 0 10px 0;
}

table#product_comparison .thumbnail-container .product-thumbnail img {
    width: 100%;
    max-width: 250px;
}

table#product_comparison .product-desc,
table#product_comparison .highlighted-informations {
    padding: 0 0 10px 0;
    margin: 0;
}

table#product_comparison .product-rating {
    margin: 0 auto;
    overflow: hidden;
}

.footer_link {
    margin: 0 0 20px 0;
}

#tdcompare-modal .modal-dialog .modal-header .modal-msg {
    font-size: 16px;
    line-height: 20px;
    color: #111000;
}

#tdcompare-modal .modal-dialog .modal-footer {
    text-align: center;
}

#tdcompare-modal .modal-dialog .modal-footer button {
    margin: 0 5px;
}

@media (max-width: 991px) {
    table#product_comparison tbody tr td.td_empty span {
        font-size: 14px;
    }

    table#product_comparison tbody tr td.product-block {
        padding: 10px;
    }
}

@media (max-width: 767px) {

    #product_comparison table,
    #product_comparison thead,
    #product_comparison tbody,
    #product_comparison th,
    #product_comparison td,
    #product_comparison tr {
        // display: block;
    }

    table#product_comparison tbody tr td.product-block {
        width: 100%;
    }

    #product_comparison thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    #product_comparison tr {
        overflow: hidden;
    }

    #product_comparison tr:last-child {
        border-bottom: none;
    }

    #product_comparison tr:last-child td {
        border-bottom: none;
    }

    #product_comparison td {
        border: none;
        position: relative;
        white-space: normal;
        float: left;
        width: 33.33%;
    }

    #product_comparison td.feature-name {
        width: 100%;
        text-align: center;
        margin: 15px 0 0;
    }

    #product_comparison .tt_empty.compare_extra_information {
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
    }

    #product_comparison td.td_empty {
        width: 100% !important;
        clear: both;
    }

    #product_comparison td:last-child {
        border-bottom: none;
    }

    #product_comparison .comparison_feature .feature-name {
        width: 100%;
        float: none;
        clear: both;
    }
}

@media (max-width: 543px) {
    #product_comparison td {
        float: none;
        text-align: center;
        width: 100%;
    }

    #product_comparison .comparison_feature td {
        float: left;
        width: 50%;
    }

    #product_comparison .comparison_feature .feature-name {
        width: 100%;
        clear: both;
    }

    #product_comparison .comparison_feature .product-name {
        width: 50%;
        float: left;
        display: block;
    }
}


/*--------------------- End Product Comaprasion Css ---------------------------*/


/*--------------------- start Block cart Drop-Down Css ---------------------------*/

.cart_block {
    background: white none repeat scroll 0 0;
    border: 1px solid #eee;
    height: auto;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 110px;
    transform: translate(0px, 20px);
    -webkit-transform: translate(0px, 20px);
    -moz-transform: translate(0px, 20px);
    -o-transform: translate(0px, 20px);
    -ms-transform: translate(0px, 20px);
    transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    visibility: hidden;
    width: 300px;
    z-index: 2;
}

@media (max-width: 991px) {
    .cart_block {
        top: 50px;
    }
}

.cart_block::after {
    color: #fff;
    content: "\E5C7";
    font-family: "Material Icons";
    font-size: 45px;
    right: 0;
    position: absolute;
    top: -13px;
    left: auto;
}

.blockcart:hover .cart_block {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    visibility: visible;
}

.blockcart .cart_block p {
    margin: 0;
}

.cart_block .products .img {
    float: left;
    margin: 0 15px 0 0;
}

.cart_block .products .img .product-thumbnail {
    border: 1px solid #e5e5e5;
}

.cart_block .products .cart-info {
    text-align: left;
    color: #111000;
}

.cart-info .productname>a {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: normal;
}

.cart_block .products .cart-info .product-line-info {
    display: inline-block;
    float: none;
    margin: 0 5px 0 0;
}

.cart_block .products {
    float: left;
    margin: 0 0 15px;
    width: 100%;
    position: relative;
}

.cart_block .products .cart-info .ttPrice {
    margin: 0 0 5px;
}

.cart_block .products .quantity {
    font-size: 12px;
    color: #999;
}

.cart_block .cart-prices .total.pull-left {
    float: left;
    margin: 15px 0;
}

.cart_block .cart-prices .amount.pull-right {
    float: right;
    margin: 15px 0;
}

.cart_block .cart-buttons {
    float: left;
    width: 100%;
}

.cart_block .cart-buttons {
    float: left;
    margin: 15px 0 0;
    width: 100%;
    text-align: center;
}

.cart_block .cart-buttons .btn-primary {
    float: left;
    width: 100%;
}

.cart_block .products .remove_link {
    position: absolute;
    right: 0;
    top: 2px;
    margin: 0;
}

.cart_block .products .remove_link i {
    font-size: 16px;
}

@media (max-width: 991px) {
    .cart_block::after {
        right: 2px;
    }

    .user-info::after {
        //right: -3px;
        right: 46px;
    }
}


/*--------------------- End Block cart Drop-Down Css ---------------------------*/


/*---------------- Start Product page wishlist-Compare Css -------------------*/

.product-actions .product-add-to-cart .wishlist,
.product-actions .product-add-to-cart .compare {
    float: left;
    padding: 0 10px 10px 0;
    width: auto;
}

.product-actions .product-add-to-cart .compare .add_to_compare {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #666;
    padding: 0;
    width: auto;
    position: relative;
}

.product-actions .product-add-to-cart .compare .add_to_compare::before {
    content: "\E863";
    font-family: "Material Icons";
    font-size: 16px;
    line-height: inherit;
    vertical-align: top;
    float: left;
}

.product-actions .product-add-to-cart .compare .add_to_compare:hover {
    color: #111;
}

.product-actions .product-add-to-cart .wishlist a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #666;
    padding: 0 0 0 30px;
    width: auto;
    position: relative;
}

.product-actions .product-add-to-cart .wishlist a::before {
    color: #666;
    content: "\E87D";
    position: absolute;
    left: 10px;
    top: 0;
    font-family: "Material Icons";
}

.product-actions .product-add-to-cart .wishlist a::after {
    background: #666666 none repeat scroll 0 0;
    content: "";
    height: 11px;
    left: 0;
    position: absolute;
    top: 7px;
    width: 1px;
}

.product-actions .product-add-to-cart .wishlist a:hover {
    color: #111;
}

.product-actions .product-add-to-cart .wishlist a:hover::before {
    color: #111;
}

#mywishlist .products {
    float: left;
    width: 100%;
}

#mywishlist .wishlistLinkTop #showSendWishlist {
    float: left;
    margin: 0 0 15px;
    width: 100%;
}

@media (max-width: 543px) {

    .product-actions .product-add-to-cart .wishlist,
    .product-actions .product-add-to-cart .compare {
        padding: 0;
        width: 100%;
    }

    .product-actions .product-add-to-cart .wishlist a::after {
        display: none;
    }

    .product-actions .product-add-to-cart .wishlist a {
        padding: 0 0 10px 15px;
    }

    .product-actions .product-add-to-cart .wishlist a::before {
        left: 0;
    }
}


/*---------------- End Product page wishlist-Compare Css -------------------*/


/*---------------- Start Product Comment Block Css -------------------*/

#ttcomment-modal .product img {
    border: 1px solid #e5e5e5;
    max-width: 100%;
}

#new_comment_form_error.error {
    float: left;
    width: 100%;
}

#new_comment_form_error>ul {
    float: left;
    margin: 0;
    padding: 0;
}

#new_comment_form_error li {
    color: #a94442;
    font-size: 13px;
}

.star {
    cursor: pointer;
    display: inline-block;
    float: none;
    font-size: 16px;
    margin: 0 -2px;
    vertical-align: top;
}

.star a {
    display: none;
}

.star::after {
    content: "\E838";
    font-family: "Material Icons";
    color: #d9d9d9;
}

.star_on::after {
    content: "\E838";
    font-family: "Material Icons";
    color: #fbaf5d;
}

.star_hover::after {
    color: #fbaf5d;
    content: "\E838";
    font-family: "Material Icons";
}

#id_new_comment_form {
    background: #f8f8f8 none repeat scroll 0 0;
    float: left;
    padding: 15px;
    width: 100%;
}

#ttcomment-modal .modal-dialog {
    max-width: 700px;
}

#ttcomment-modal .modal-dialog .modal-header h2 {
    font-size: 16px;
    line-height: 20px;
    color: #111000;
}

#ttcomment-modal .modal-dialog .modal-body .product .product_desc {
    line-height: 18px;
    color: #808080;
}

#ttcomment-modal .modal-dialog .modal-body .product .product_desc .product_name {
    padding: 10px 0 5px;
    color: #111000;
    margin: 0;
}

#ttcomment-modal .modal-dialog .modal-body #criterions_list {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 15px;
    margin-bottom: 15px;
    list-style-type: none;
    padding: 0;
}

#ttcomment-modal .modal-dialog .modal-body #criterions_list li {
    margin-bottom: 10px;
}

#ttcomment-modal .modal-dialog .modal-body #criterions_list label {
    float: left;
    margin: 0 10px 0 0;
    min-width: 100px;
    text-align: left;
}

#ttcomment-modal .modal-dialog .modal-body #criterions_list .star_content {
    float: left;
}

#ttcomment-modal .modal-dialog .modal-footer {
    text-align: center;
}

#ttcomment-modal .modal-dialog .modal-footer button {
    margin: 0 5px;
}

#product_comments_block_tab .comment_helpful button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    padding: 0;
    border: none;
    line-height: normal;
    color: grey;
}

#product_comments_block_tab .comment_helpful .usefulness_btn.usefull::before {
    content: "\E8DC";
    font-family: "Material Icons";
    vertical-align: middle;
}

#product_comments_block_tab .comment_helpful .usefulness_btn.notusefull::before {
    content: "\E8DB";
    font-family: "Material Icons";
    vertical-align: middle;
}

#product_comments_block_tab .comment_helpful .usefulness_btn.usefull:hover {
    color: #008000;
}

#product_comments_block_tab .comment_helpful .usefulness_btn.notusefull:hover {
    color: #ff0000;
}

#product_comments_block_tab .report_btn {
    cursor: pointer;
    float: right;
}

#product_comments_block_tab .comment_helpful {
    float: left;
}

#product-comment .comment .comment_details>ul {
    float: left;
    margin: 0;
    width: 100%;
}

#product_comments_block_tab .report_btn:hover {
    text-decoration: underline;
    color: #111000;
}

#product-comment #new_comment_tab_btn {
    white-space: normal;
}

.comments_advices i {
    font-size: 16px;
    line-height: normal;
    vertical-align: top;
}

#product_comments_block_extra .comments_note {
    border-right: 1px solid #cccccc;
    display: inline-block;
    float: none;
    padding: 0 10px 0 0;
    vertical-align: top;
}

#product_comments_block_extra .comments_advices {
    display: inline-block;
    float: none;
    vertical-align: top;
}

#product_comments_block_extra .comments_advices a {
    border-right: 1px solid #cccccc;
    float: left;
    padding: 0 10px
}

#product_comments_block_extra .comments_advices a:last-child {
    border: medium none;
}

@media (max-width: 543px) {
    .comments_advices .reviews {
        float: left;
        margin: 0 0 5px;
        width: 100%;
    }

    #product_comments_block_extra .comments_note {
        border-right: none;
        padding: 0;
        margin: 0 0 5px;
        width: 100%;
    }

    #product_comments_block_extra .comments_advices a {
        border-right: none;
        padding: 0;
    }

    #product-comment #new_comment_tab_btn {
        font-size: 12px;
        padding: 6px 12px;
    }
}


/*---------------- End Product Comment Block Css -------------------*/


/*---------------- Start Special Product Block Css -------------------*/

.ttspecial-products .products .product-miniature {
    border: none !important;
    margin: 0 !important;
}

.ttspecial-products .thumbnail-container {
    padding: 15px 0;
    margin: 0 0 5px;
    border: 1px solid #eee;
}

.ttspecial-products .thumbnail-container .ttproduct-image {
    border-right: 1px solid #eee;
    text-align: center;
}

.ttspecial-products .thumbnail-container .ttproduct-image .product-thumbnail {
    border: none;
}

.ttspecial-products .thumbnail-container .ttproduct-desc {
    text-align: left;
    float: left;
}

.ttspecial-products .thumbnail-container .product-title>a {
    border-bottom: 1px solid #e5e5e5;
    color: #111000;
    float: left;
    font: 500 18px/18px 'Work Sans', sans-serif;
    padding: 10px 0;
    width: 100%;
}

.ttspecial-products .thumbnail-container .product-desc-short {
    float: left;
    width: 100%;
    margin: 0 0 15px;
}

.ttspecial-products .thumbnail-container .product-price-and-shipping {
    text-align: left !important;
}

.ttspecial-products .thumbnail-container .highlighted-informations {
    display: block !important;
    text-align: left;
}

.ttspecial-products .thumbnail-container .variant-links {
    text-align: left;
    margin: 7px 0 9px;
}

.ttspecial-products .thumbnail-container .hook-reviews {
    float: left;
    position: static !important;
    width: 100%;
    margin: 0 0 9px;
}

.ttspecial-products .thumbnail-container:hover .hook-reviews {
    opacity: 1 !important;
    -moz-opacity: 1 !important;
    -khtml-opacity: 1 !important;
    -webkit-opacity: 1 !important;
}

.ttspecial-products .product-miniature .ttproductcountdown {
    display: block;
    float: left;
}

.ttspecial-products .thumbnail-container .ttproducthover {
    float: left;
    width: 100%;
    margin: 8px 0 0;
}

.ttspecial-products .scroll-box-arrows.scroll {
    display: block;
}

.ttspecial-products .product-images>.thumb-container>.thumb {
    border: 1px solid #e5e5e5;
    height: 80px;
    margin: 0 5px;
    width: 80px;
}

.ttspecial-products .product-images>.thumb-container>.thumb:hover {
    border-color: #ccc;
}

.ttspecial-products .thumbnail-container .js-qv-mask.mask.scroll {
    margin: 20px auto 10px;
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
    position: relative;
}

.ttspecial-products .scroll-box-arrows .left {
    left: 40px;
}

.ttspecial-products .scroll-box-arrows i {
    bottom: 35px;
}

.ttspecial-products .scroll-box-arrows .right {
    right: 40px;
}

.ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default span {
    display: block;
}

.ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default {
    background: $orange;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    color: #fff;
    float: left;
    font: 400 13px/18px 'Work Sans', sans-serif;
    padding: 8px 10px 9px 35px;
    position: relative;
    width: 100%;
}

.ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default::before {
    background: rgba(0, 0, 0, 0) url("../img/product-sprite.png") no-repeat scroll -35px -41px;
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
}

.ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default:hover {
    background: #111;
    color: #fff;
}

.ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default:hover::before {
    background: rgba(0, 0, 0, 0) url("../img/product-sprite.png") no-repeat scroll -35px -41px;
}

.ttspecial-products .product-grid .thumbnail-container .ttproducthover .add-to-cart-disable span {
    display: block !important;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
}

.ttspecial-products .product-grid .thumbnail-container .ttproducthover .add-to-cart-disable {
    background: #ffde01;
    color: #111;
    float: left;
    height: auto;
    padding: 11px 15px;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    width: auto;
    opacity: 0.5 !important;
    -moz-opacity: 0.5 !important;
    -khtml-opacity: 0.5 !important;
    -webkit-opacity: 0.5 !important;
}

.ttspecial-products .product-grid .thumbnail-container .ttproducthover .add-to-cart-disable:hover {
    background: #ffde01 !important;
}

#index .ttspecial-products .product-miniature.product-grid .thumbnail-container:hover .ttproductcountdown {
    display: block;
}

#index .ttspecial-products .product-miniature.product-grid .thumbnail-container .ttproductcountdown {
    float: left;
    position: static;
    width: auto;
    margin: 8px 0 15px;
}

@media (max-width: 1400px) {
    .ttspecial-products .thumbnail-container .js-qv-mask.mask.scroll {
        width: 90px;
    }
}

@media (max-width: 1299px) {
    .ttspecial-products .thumbnail-container .hook-reviews {
        margin: 0 0 2px;
    }

    .ttspecial-products .ttspecial-list .product-title {
        margin: 0 0 10px !important;
    }

    .ttspecial-products .thumbnail-container .product-desc-short {
        margin: 0 0 12px;
    }

    .ttspecial-products .thumbnail-container .variant-links {
        margin: 0 0 2px;
    }

    #index .ttspecial-products .product-miniature.product-grid .thumbnail-container .ttproductcountdown {
        margin: 7px 0 9px;
    }
}

@media (max-width: 1199px) {
    .ttspecial-products .thumbnail-container .js-qv-mask.mask.scroll {
        width: 180px;
    }

    .ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default span,
    .ttspecial-products .product-grid .thumbnail-container .ttproducthover .add-to-cart-disable span {
        display: none !important;
    }

    .ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default::before {
        background: $orange url("../img/product-sprite.png") no-repeat scroll -38px -40px !important;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -khtml-border-radius: 50%;
    }

    .ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default:hover::before {
        background: #111 url("../img/product-sprite.png") no-repeat scroll -37px -41px !important;
    }

    .ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default {
        background: none;
        padding: 0;
        width: 36px;
    }

    .ttspecial-products .product-grid .thumbnail-container .ttproducthover .add-to-cart-disable:hover {
        background: #f5f5f5 !important;
    }

    .ttspecial-products .thumbnail-container .ttproducthover {
        margin: 2px 0 0;
    }
}

@media (max-width: 991px) {
    .ttspecial-products .thumbnail-container .js-qv-mask.mask.scroll {
        margin: 10px auto;
    }
}

@media (max-width: 767px) {
    .ttspecial-products .thumbnail-container .js-qv-mask.mask.scroll {
        width: 90px;
    }

    .ttspecial-products .thumbnail-container .product-title>a {
        font-size: 14px !important;
        padding: 0 0 10px;
    }
}

@media (max-width: 543px) {
    .ttspecial-products .thumbnail-container .ttproduct-desc {
        margin: 25px 0 0;
    }

    .ttspecial-products .thumbnail-container .ttproduct-image {
        border: none !important;
    }

    .ttspecial-products .thumbnail-container {
        margin: 0;
    }
}


/*---------------- End Special Product Block Css -------------------*/


/*------------- Start Special-additional-Product btn Css ------------*/

.ttspecial-products .product-images .thumb-container .thumb {
    border: 1px solid #e5e5e5;
    height: 70px;
    margin: 0;
    width: 60px;
}

.ttspecial-products .product-images .thumb-container .thumb:hover {
    border-color: #ccc;
}

.ttspecial-products .js-qv-mask.mask {
    width: 130px !important;
    margin: 0 auto;
    position: relative;
    float: none;
    display: inline-block;
    vertical-align: top;
    text-align: center;
}

#wrapper .ttspecial-products ul.product-images {
    float: left;
    width: 100%;
}

#wrapper .ttspecial-products .js-qv-mask.mask .owl-buttons div {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    float: left;
    height: 25px;
    margin: 0;
    position: absolute;
    top: 25px;
    vertical-align: top;
    width: 25px;
}

#wrapper .ttspecial-products .js-qv-mask.mask .owl-buttons div:hover {
    background: #ffde01;
    border-color: #ffde01;
}

#wrapper .ttspecial-products .js-qv-mask.mask .owl-buttons div.owl-prev {
    left: -30px;
}

#wrapper .ttspecial-products .js-qv-mask.mask .owl-buttons div.owl-next {
    right: -30px;
}

.ttspecial-products .js-qv-mask.mask .owl-buttons div.owl-prev::before,
.ttspecial-products .js-qv-mask.mask .owl-buttons div.owl-prev::after,
.ttspecial-products .js-qv-mask.mask .owl-buttons div.owl-next::before,
.ttspecial-products .js-qv-mask.mask .owl-buttons div.owl-next::after {
    display: none;
}

#wrapper .ttspecial-products .js-qv-mask.mask .owl-buttons div i {
    color: #111000;
    font-size: 18px;
    height: 25px;
    line-height: 23px;
    padding: 0;
    vertical-align: middle;
    width: 25px;
}

#wrapper .ttspecial-products .js-qv-mask.mask .owl-buttons div:hover i {
    color: #111;
}

@media (max-width: 1200px) {
    .ttspecial-products .js-qv-mask.mask {
        width: 140px !important;
    }
}

@media (max-width: 1199px) {
    .ttspecial-products .js-qv-mask.mask {
        width: 100px !important;
    }
}

@media (max-width: 991px) {
    .ttspecial-products .js-qv-mask.mask {
        width: 150px !important;
    }
}

@media (max-width: 767px) {
    .ttspecial-products .js-qv-mask.mask {
        width: 130px !important;
    }
}


/*------------- End Special-additional-Product btn Css ------------*/

.slider-banner-services {
    padding: 0;
}

.products .product-miniature.product-grid {
    margin: 0 0 30px;

    @media (min-width: 767px) {
        margin: 0 0 50px;
    }
}

.products .product-miniature {
    // border-right: 1px solid #e5e5e5;
    // margin: 0 0 30px 0;
}

#category .products .product-miniature.product-grid,
#new-products .products .product-miniature.product-grid,
#best-sales .products .product-miniature.product-grid,
#prices-drop .products .product-miniature.product-grid,
#search .products .product-miniature.product-grid {
    //  border-bottom: 1px solid #e5e5e5;
    //  padding-top: 20px;
    //  margin-bottom: 50px;
}

#category .products .product-miniature:last-child,
#new-products .products .product-miniature:last-child,
#best-sales .products .product-miniature:last-child,
#prices-drop .products .product-miniature:last-child,
#search .products .product-miniature:last-child {
    // border: none!important;
}

@media (min-width: 1700px) {

    #category .products .product-grid:nth-of-type(1n),
    #new-products .products .product-grid:nth-of-type(5n),
    #best-sales .products .product-grid:nth-of-type(5n),
    #prices-drop .products .product-grid:nth-of-type(5n),
    #search .products .product-grid:nth-of-type(5n) {
        //  border: none!important;
    }
}

@media (max-width: 1699px) {

    #category .products .product-miniature.product-grid,
    #new-products .products .product-miniature.product-grid,
    #best-sales .products .product-miniature.product-grid,
    #prices-drop .products .product-miniature.product-grid,
    #search .products .product-miniature.product-grid {
        // width: 33.2%;
    }
}

@media (min-width: 768px) and (max-width: 1699px) {

    #category .products .product-grid:nth-of-type(3n),
    #new-products .products .product-grid:nth-of-type(3n),
    #best-sales .products .product-grid:nth-of-type(3n),
    #prices-drop .products .product-grid:nth-of-type(3n),
    #search .products .product-grid:nth-of-type(3n) {
        // border: none!important;
    }
}

@media (min-width: 544px) and (max-width: 767px) {

    #category .products .product-grid:nth-of-type(2n),
    #new-products .products .product-grid:nth-of-type(2n),
    #best-sales .products .product-grid:nth-of-type(2n),
    #prices-drop .products .product-grid:nth-of-type(2n),
    #search .products .product-grid:nth-of-type(2n) {
        border: none !important;
    }

    #category .products .product-miniature.product-grid,
    #new-products .products .product-miniature.product-grid,
    #best-sales .products .product-miniature.product-grid,
    #prices-drop .products .product-miniature.product-grid,
    #search .products .product-miniature.product-grid {
        width: 49.8%;
    }
}

@media (max-width: 543px) {

    #category .products .product-miniature.product-grid,
    #new-products .products .product-miniature.product-grid,
    #best-sales .products .product-miniature.product-grid,
    #prices-drop .products .product-miniature.product-grid,
    #search .products .product-miniature.product-grid {
        // width: 100%;
    }

    .products .product-miniature {
        border: none !important;
    }
}


/* ------------------ Start Manufacture block --------------------- */

#manufacturer .brand {
    border-bottom: 1px solid #e5e5e5;
    float: left;
    margin: 0 0 10px;
    width: 100%;
}

#manufacturer .brand:last-child {
    border: medium none;
}

#manufacturer .brand .brand-img {
    margin: 0 0 10px;
    padding: 0;
    width: auto;
}

#manufacturer .brand .brand-img img {
    border: 1px solid #e5e5e5;
}

#manufacturer .brand .brand-infos a {
    color: #111000;
    font-size: 14px;
    font-weight: normal;
}

#manufacturer .brand .brand-desc {
    float: left;
}

@media (max-width: 543px) {
    #manufacturer .brand {
        text-align: center;
    }

    #manufacturer .brand .brand-img {
        float: none;
        display: inline-block;
        vertical-align: top;
    }

    #manufacturer .brand .brand-desc {
        width: 100%;
        margin: 0 0 10px;
    }
}


/* ------------------ End Manufacture block --------------------- */


/* ------------------ Start Forget-password block --------------------- */

.ps-alert-error {
    margin-bottom: 0
}

.ps-alert-error .item,
.ps-alert-success .item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #ff4c4c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #ff4c4c;
    margin-bottom: 1rem
}

.ps-alert-error .item i,
.ps-alert-success .item i {
    border: 15px solid #ff4c4c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ps-alert-error .item i svg,
.ps-alert-success .item i svg {
    background-color: #ff4c4c;
    width: 24px;
    height: 24px
}

.ps-alert-error .item p,
.ps-alert-success .item p {
    background-color: #fff;
    margin: 0;
    padding: 18px 20px;
    width: 100%
}

.ps-alert-success {
    padding: .25rem .25rem 2.75rem;
}

.ps-alert-success .item {
    border-color: #4cbb6c;
    background-color: #4cbb6c;
    margin: 0;
    float: left;
}

.ps-alert-success .item i {
    border-color: #4cbb6c
}

.ps-alert-success .item i svg {
    background-color: #4cbb6c
}

@media (max-width: 543px) {

    .ps-alert-error .item p,
    .ps-alert-success .item p {
        font-size: 12px;
        padding: 18px 10px;
    }

    .ps-alert-error .item i svg,
    .ps-alert-success .item i svg {
        width: 20px;
    }
}


/* ------------------ End Forget-password block --------------------- */