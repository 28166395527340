// list
.ttproducthover {
    margin-top: 20px;
    display: inline-block;
}

.tt-producttitle {
    border-bottom: 1px solid #e5e5e5;
    color: $orange;
    font-size: 20px;
    font-weight: normal;
    line-height: 26px;
    margin: 0 auto 18px;
    padding: 0 0 10px;
    position: relative;
    text-align: left;
    text-transform: lowercase;
    font-weight: bold;
}

.tt-producttitle:first-letter {
    text-transform: uppercase;
}

.product-short-description {
    margin-top: 10px;
}

.product-manufacturer {
    float: right;

    @media (max-width: 575px) {
        display: none;
    }
}

.product-price {
    color: $orange;
    display: inline-block;
    margin: 0 !important;
    vertical-align: top;
    font-size: 32px;
    font-weight: 500;
    line-height: 25px;
}

.page-product span.discount.discount-percentage {
    position: absolute;
}

span.discount.discount-percentage {
    background: #f5f5f5;
    color: #111;
    display: inline-block;
    font-size: 14px;
    margin-left: 15px;
    padding: 0 12px;
}

.product-actions .add-to-cart {
    background: $orange;
    border: medium none;
    color: #fff;
    font: 400 14px/20px 'Work Sans', sans-serif;
    height: 41px;
    padding: 11px 20px;
    position: relative;
    text-transform: capitalize;
}

.product-actions .control-label {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    float: left;
}

.product-actions .btn.btn-primary.add-to-cart:disabled {
    background: #111;
    color: #fff;
}

.product-actions .product-variants-item>ul {
    padding: 0;
}

.product-actions .add-to-cart:active,
.product-actions .add-to-cart:focus,
.product-actions .add-to-cart:hover {
    background: #111;
    color: #fff;
}

.product-actions .btn-primary:disabled.add-to-cart:active,
.product-actions .btn-primary:disabled.add-to-cart:focus,
.product-actions .btn-primary:disabled.add-to-cart:hover {
    background: #111;
    color: #fff;
}

.product-quantity .qty {
    float: left;
    width: 80px;
    margin-bottom: 15px;
}

.product-quantity .add {
    float: left;
    margin-left: 30px;
}

.product-quantity #quantity_wanted {
    color: #111;
    background-color: white;
    height: 2.75rem;
    padding: 0.175rem 0.5rem;
    width: 3rem;
    text-align: center;
}

.product-quantity .input-group-btn-vertical {
    float: left;
}

.product-quantity .input-group-btn-vertical .btn {
    padding: 0.5rem 0.6875rem;
}

.product-quantity .input-group-btn-vertical .btn i {
    font-size: 1rem;
    top: 0.125rem;
    left: 0.1875rem;
}

.product-quantity .btn-touchspin {
    height: 1.4375rem;
}

.product-discounts {
    margin-bottom: 1.5rem;
}

.product-discounts>.product-discounts-title {
    font-weight: normal;
    font-size: 0.875rem;
}

.product-discounts>.table-product-discounts thead tr th {
    width: 33%;
    padding: 0.625rem 1.25rem;
    background: white;
    border: 0.3125rem #ebebeb solid;
    text-align: center;
}

.product-discounts>.table-product-discounts tbody tr {
    background: #e9e9e9;
}

.product-discounts>.table-product-discounts tbody tr:nth-of-type(even) {
    background: white;
}

.product-discounts>.table-product-discounts tbody tr td {
    padding: 0.625rem 1.25rem;
    text-align: center;
    border: 0.3125rem #ebebeb solid;
}

.product-prices {
    float: left;
    line-height: 28px;
    margin-bottom: 0;
    width: auto;
}

.product-prices div {
    float: left;
    margin: 0;
}

.product-prices .tax-shipping-delivery-label {
    color: #111;
    float: left;
    font-size: 13px;
    margin: 0;
    width: 100%;
    text-transform: uppercase;
}

.product-prices .tax-shipping-delivery-label .delivery-information {
    padding: 0 0 0 2px
}

.product-prices .tax-shipping-delivery-label .delivery-information:before {
    content: "-";
    padding: 0 2px 0 0
}

.product-attachments .attachment a {
    font-size: 14px;
    font-weight: normal;
    color: #111;
}

.product-discount {
    color: #777777;
}

.product-discount .regular-price {
    font-size: 17px;
    margin-right: 0.625rem;
    text-decoration: line-through;
}

.has-discount .discount {
    background: #f5f5f5;
    color: #111;
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
    padding: 0 12px;
}

.product-unit-price {
    font-size: 0.8125rem;
    margin-bottom: 0;
}

.ttproductpagetab {
    float: left;
    margin: 50px 0 70px;
    width: 100%;
}


/* quick view*/

.quickview .product-prices {
    margin-bottom: 15px;
}

//single
.manufacturer-logo {
    width: 150px;
}


/*--------------------------- Start Product page  --------------------------*/

.pb-left-column .images-container .js-qv-mask.mask {
    width: 100%;
    margin: 0 auto;
    position: relative;
    float: none;
    display: inline-block;
    vertical-align: top;
}

.pb-left-column .images-container {
    text-align: center;
}

.pb-left-column .images-container ul {
    float: left;
    width: 100%;
}

#wrapper .js-qv-mask.mask .owl-buttons div {
    height: 30px;
    width: 30px;
    top: 50px;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    cursor: pointer;
    float: left;
    margin: 0;
    position: absolute;
    vertical-align: top;
}

#wrapper .js-qv-mask.mask .owl-buttons div:hover {
    background: $orange;
    border-color: $orange;
}

#wrapper .js-qv-mask.mask .owl-buttons div.owl-prev {
    left: -30px;
}

#wrapper .js-qv-mask.mask .owl-buttons div.owl-next {
    right: -30px;
}

#wrapper .js-qv-mask.mask .owl-buttons div i {
    display: block;
    font-size: 22px;
    height: 30px;
    padding: 3px;
    vertical-align: top;
    width: 30px;
    color: #111;
}

#wrapper .js-qv-mask.mask .owl-buttons div:hover i {
    color: #fff;
}

#content .images-container .layer.hidden-sm-down {
    bottom: 10px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: auto;
    z-index: 1;
}

#content .images-container .layer.hidden-sm-down i {
    font-size: 28px;
    color: #666;
}

#content .images-container .layer.hidden-sm-down:hover i {
    color: #111;
}

#product #tab-content .product-description span {
    font-family: 'Work Sans', sans-serif;
    font-size: 13px !important;
    line-height: 24px;
}

#product .pb-right-column .product-prices {
    float: left;
    line-height: 28px;
    margin: 20px 0 13px;
    width: 100%;
}

@media (max-width: 1200px) {
    .pb-left-column .images-container .js-qv-mask.mask {
        //  width: 230px!important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .pb-left-column .images-container .js-qv-mask.mask {
        width: 215px !important;
    }
}

@media (max-width: 767px) {
    .pb-left-column .images-container .js-qv-mask.mask {
        //  width: 336px!important;
    }

    #product .pb-right-column {
        float: left;
        width: 100%;
    }
}

@media (max-width: 543px) {
    #product .images-container .js-qv-mask.mask {
        // width: 220px !important;
    }

    .tabs .nav-tabs li:last-child .nav-link {
        margin: 0;
    }

    .product-features>dl.data-sheet dd.value,
    .product-features>dl.data-sheet dt.name {
        padding: 10px 2px;
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .cat.row {
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        width: 100%;
    }

    .cat.row>[class*='col-'] {
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        flex-direction: column;
    }
}

#accordion {
    margin-top: 20px;
    display: inline-block;
    width: 100%;

    .title {
        padding: 0.625rem;
        cursor: pointer;
        float: left;
        width: 100%;
        margin: 0 !important;
        background: #f1f1f1;

        &:hover {}
    }

    .navbar-toggler i {
        color: #111;
        font-size: 20px;
        padding: 2px 0;
    }

    .navbar-toggler i.remove {
        display: none;
    }

    .title[aria-expanded="true"] .collapse-icons .remove {
        display: block;
    }

    .title[aria-expanded="true"] .collapse-icons .add {
        display: none;
    }

    .card-body {
        padding: 10px;
        display: inline-block;
        width: 100%;
        margin: 0;
    }
}


/*--------------------------- End Product page  --------------------------*/