.ttproductcountdown .count.curr,
.ttproductcountdown .ttcount.bottom {
    display: none;
}
.ttproductcountdown .hidden {
    display: none;
}
.ttpc-main {
	background: #f5f5f5;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	-moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
	display: inline-block;
	float: none;
	padding: 7px 0 5px 30px;
	position: relative;
	vertical-align: top;
	width: auto;
	margin: 0 !important;
}
.ttpc-main:before {
    color: #1e1e1e;
	content: "\E192";
	font-family: "Material Icons";
	font-size: 24px;
	left: 5px;
	position: absolute;
	right: auto;
	top: 8px;
	z-index: 1;
}
#index .ttproductcountdown {
    margin: 10px auto;
    position: relative;
    text-align: center;
    background: none;
    display: inline-block;
}
.ttpc-main .time {
    height: 20px;
    width: 38px;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    -khtml-border-radius: 15px;
    float: left;
    text-align: center;
    position: relative;
    max-width: 4em;
    overflow: hidden;
	margin: 0;
}
.ttpc-main .ttcount.next.top::after {
    color: #111111;
    content: ":";
    position: absolute;
    top: -2px;
	left:auto;
	right:-1px;
}
.ttpc-main .time:last-child .ttcount.next.top::after {
    content: "";
}
.ttpc-main .ttcount {
    background: #f5f5f5;
    color: #111;
    display: block;
    font: 400 15px/20px "poppins";
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.ttpc-main .seconds .ttcount {
    color: #f13340;
}
.ttpc-main .label {
    font-size: 11px;
    display: none;
    position: absolute;
    top: 20px;
    width: 100%;
    color: #202020;
    padding: 0;
    font-weight: normal;
    text-align: center;
}
#index .product-miniature.product-grid .thumbnail-container .ttproductcountdown {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-khtml-border-radius: 0;
	display: inline-block;
	float: left;
	left: 0;
	padding: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	vertical-align: top;
	width:auto;
}
#index .product-miniature.product-grid .thumbnail-container:hover .ttproductcountdown {
	display: none;
}
.pb-right-column .ttproductcountdown{
	margin: 0 0 10px;
}
.ttproductcountdown{
	display:none;
}
@media (min-width: 320px) and (max-width: 1599px) {
	#ttcategorytabs .ttcategory .ttpc-main .ttcount{
		font-size:14px;
	}
	#ttcategorytabs .ttcategory .ttpc-main .time{
		width:31px;
	}
	#ttcategorytabs .ttcategory .ttpc-main::before{
		font-size:23px;
	}
	#ttcategorytabs .ttcategory .ttpc-main{
		padding:7px 0 5px 28px;
	}
}
@media (max-width: 767px) {
    #index .product-miniature.product-grid .thumbnail-container:hover .ttproductcountdown {
		display: inline-block;
	}
	#index .product-miniature.product-grid .thumbnail-container .ttproductcountdown {
		bottom: 65px;
		top: auto;
		left: 0;
		right: auto;
	}
}