    /* fixed*/
    
    body.fixed {
        position: fixed !important
    }
    
    #search_block_nav {
        padding: 10px 0 10px 10px;
        width: 145px;
        float: right;
        position: relative;
    }
    
    #search_query_nav {
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        font-family: 'Poppins', sans-serif;
        font-size: 10px;
        color: #000;
        font-style: italic;
        line-height: 15px;
        cursor: pointer;
        margin: 0;
        /*background: url(../img/bkg_search.png) no-repeat top -1px right;*/
    }
    
    #search_query_nav:after {
        content: "\f002";
        position: absolute;
        right: 0;
        font-family: FontAwesome;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    
    #search_query_nav:hover:after,
    #search_query_nav:focus:after,
    #search_query_nav:active:after {
        -webkit-animation-name: tt-nav-social;
        animation-name: tt-nav-social;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        color: #000;
    }
    
    #search_block_top {
        display: inline-block;
        float: right;
        margin: 33px 50px 33px 0;
        text-align: center;
        vertical-align: top;
        padding: 0;
        width: auto;
    }
    
    @media (max-width: 1599px) {
        #search_block_top {
            float: none;
            margin: 33px 0;
        }
    }
    
    @media (min-width: 992px) and (max-width: 1199px) {
        #search_block_top {
            float: right;
            margin: 33px 15px 20px 0;
        }
    }
    
    @media (max-width: 991px) {
        #search_block_top {
            margin: 0 0 15px;
            display: none;
            position: absolute;
            width: 100%;
            left: 0;
            top: 162px;
        }
        header.fixed #search_block_top {
            position: fixed;
            top: 50px;
        }
    }
    
    #search_block_top.show {
        height: 155px !important;
        border-bottom: 5px solid #000;
        padding: 40px 15%;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }
    
    #search_block_top #searchbox {
        background: #fff;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        -khtml-border-radius: 5px;
        float: left;
        position: relative;
        width: 100%;
    }
    
    #search_block_top .btn.button-search {
        border: medium none;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        color: #fff;
        display: inline-block;
        float: right;
        line-height: 21px;
        padding: 12px 33px 11px;
        text-align: center;
        vertical-align: top;
        height: 45px;
    }
    
    #search_block_top .btn.button-search:before {
        content: "\E8B6";
        display: none;
        font-family: "Material Icons";
        font-size: 21px;
        width: 100%;
        text-align: center;
    }
    
    #search_block_top .btn.button-search:hover:before {
        color: #ffffff;
    }
    
    #search_block_top .btn.button-search:focus,
    #search_block_top .btn.button-search:hover {
        background: #333;
        color: #ffffff;
    }
    
    #close_search_query_nav {
        background: url("../img/bkg_close_search.png") no-repeat;
        position: absolute;
        right: 30px;
        top: 10px;
        text-decoration: none;
        z-index: 9999;
        display: none;
    }
    
    #close_search_query_nav span {
        color: #999;
        font-size: 0;
        font-family: Myriad Pro;
        text-transform: uppercase;
        position: relative;
        right: 50px;
        top: 20px;
    }
    
    #close_search_query_nav:hover span {
        color: #000;
    }
    
    #search_block_top #search_query_top {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        -khtml-border-radius: 4px;
        box-shadow: none;
        color: #000;
        display: block;
        float: left;
        font-size: 13px;
        height: 45px;
        line-height: 18px;
        margin-left: 0;
        margin-right: 0;
        width: 500px;
    }
    
    #ttsearch_eccept_data .items-list>li {
        cursor: pointer;
        margin-bottom: 5px;
        padding: 15px;
        position: relative;
        width: 50%;
    }
    
    @media (min-width: 1300px) and (max-width: 1599px) {
        #search_block_top #search_query_top {
            width: 320px;
        }
    }
    
    @media (max-width: 1300px) {
        #search_block_top #search_query_top {
            width: 180px;
        }
        #ttsearch_eccept_data .items-list>li {
            width: 100%;
        }
    }
    
    @media (max-width: 1199px) {
        #search_block_top #search_query_top {
            width: 120px;
        }
    }
    
    @media (max-width: 991px) {
        #search_block_top #search_query_top {
            width: 500px;
        }
    }
    
    #search_block_top #search_query_top::after {
        border-left: 1px solid #e5e5e5;
        content: "";
        height: 25px;
        right: 0;
        position: absolute;
        top: 5px;
    }
    
    #search_block_top #search_query_top:focus {
        outline: none;
    }
    
    #search_category {
        -moz-appearance: none;
        -o-appearance: none;
        -webkit-appearance: none;
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        color: #555;
        cursor: pointer;
        display: inline;
        float: left;
        font-size: 13px;
        height: 45px;
        outline: 0 none;
        padding: 5px 12px;
    }
    
    form#searchbox input#search_query_block {
        margin-right: 10px;
        max-width: 222px;
        margin-bottom: 10px;
        display: inline-block;
        float: left;
    }
    
    form#searchbox .button.button-small {
        float: left;
    }
    
    form#searchbox .button.button-small i {
        margin-right: 0;
    }
    /* Select style */
    
    #search_block_top .selector {
        width: 28% !important;
        float: left;
        height: 48px;
        background: none;
        border: none;
        padding: 0;
        background: #fff;
        margin-left: 2px;
    }
    
    #search_block_top .selector span {
        width: 100% !important;
        border-right: 2px solid #0166c3;
        line-height: 26px;
        padding-left: 15px;
        background: url("../img/select_search_bg.png") right 22px center no-repeat;
        margin-top: 10px;
        color: #999;
        font-family: Poppins;
        font-size: 15px !important;
    }
    
    #ttsearch_content_result .col_grid_5_2 {
        float: left;
    }
    
    #ttsearch_content_result .items-list .image .product_img_link>img {
        max-width: 100%;
        border: 1px solid #eeeeee;
    }
    
    #ttsearch_content_result {
        top: 44px;
        left: 0;
        position: absolute;
        width: 100%;
        background: #fff;
        overflow: hidden;
        z-index: 99999;
        padding: 15px;
        display: none;
        border: 1px solid #ededed;
    }
    
    .header-sticky #ttsearch_content_result {
        top: 48px !important;
        position: fixed !important;
        left: 0;
        padding-top: 0;
    }
    
    #ttsearch_content_result .over_light {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        left: 0;
        right: 0;
    }
    
    #ttsearch_content_result #ttsearch_eccept_data {
        position: relative;
        height: 100%;
        width: 100%;
        margin-bottom: 30px;
    }
    
    #ttsearch_content_result p.loanding {
        background: url('../img/loading.gif');
        height: 30px;
        width: 30px;
        margin: 10% auto;
    }
    
    #button_result_close {
        background: url("../img/close.png") no-repeat scroll 0 0 transparent;
        color: hsl(217, 97%, 71%);
        display: block;
        font-size: 14px;
        font-weight: 700;
        height: 29px;
        line-height: 14px;
        position: absolute;
        right: 0;
        top: 0;
        width: 29px;
        cursor: pointer;
    }
    
    #ttsearch_eccept_data .limit {
        text-align: center;
        margin-top: 20px;
    }
    
    #ttsearch_eccept_data .noresult {
        text-align: center;
        margin-top: 20px;
        color: #666;
        font-size: 16px;
        //margin-top: 50px;
        text-align: center;
    }
    
    #ttsearch_eccept_data .search-title {
        color: #000;
        text-align: center;
        margin: 20px 0;
        font-size: 14px;
        display: none;
    }
    
    #ttsearch_eccept_data .search-title span {
        font-size: 11px;
    }
    
    #ttsearch_eccept_data .title_showall_text {
        font-size: 12px;
        text-align: center;
    }
    
    #ttsearch_eccept_data .title_showall {
        text-align: center;
    }
    
    #ttsearch_eccept_data .title_showall a {
        background: #000 none repeat scroll 0 0;
        border: 1px solid #f2f2f2;
        color: #fff;
        font-size: 12px;
        padding: 10px 25px;
        text-transform: uppercase;
    }
    
    #ttsearch_eccept_data .title_showall a:hover {
        background: $orange;
        color: #000;
    }
    
    #ttsearch_eccept_data .items-list {
        overflow: hidden;
        padding: 0;
        margin: 0;
    }
    
    #ttsearch_eccept_data .items-list>li:hover {
        background: #f5f5f5;
    }
    
    #ttsearch_eccept_data .items-list li .product-name {
        color: #222;
        font: 400 14px/18px "Poppins", Helvetica, sans-serif;
        text-decoration: none;
        text-transform: lowercase;
        margin: 0 0 5px;
    }
    
    #ttsearch_eccept_data .items-list li .product-name::first-letter {
        text-transform: uppercase;
    }
    
    #ttsearch_eccept_data .items-list li .product-name:hover {
        color: #000;
    }
    
    #ttsearch_eccept_data .items-list li .image {
        text-align: center;
        padding: 0;
    }
    
    #ttsearch_eccept_data .items-list li .content_price {
        color: #ee0000;
        float: right;
        width: 100%;
    }
    
    #ttsearch_eccept_data .items-list li .review {
        margin-top: 10px;
        text-align: center;
        min-height: 22px;
    }
    
    #ttsearch_eccept_data .items-list li .review .star_content {
        display: inline-block;
    }
    
    #ttsearch_eccept_data .items-list li .review .nb-comments {
        vertical-align: 3px;
    }
    
    #ttsearch_eccept_data .items-list>li .tt-button-container {
        left: 20px;
    }
    
    #ttsearch_eccept_data .items-list>li:hover .tt-button-container {
        opacity: 1;
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
        top: 30px;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }
    
    #ttsearch_eccept_data .items-list .tt_size {
        margin-top: 5px;
        text-align: center;
    }
    
    #ttsearch_eccept_data .items-list .tt_size span {
        display: none;
    }
    
    #ttsearch_eccept_data .items-list .tt_size ul {
        display: inline;
    }
    
    #ttsearch_eccept_data .items-list .tt_size ul li {
        display: inline-block;
        margin: 0 5px;
    }
    
    #ttsearch_eccept_data .items-list .tt_color {
        margin-top: 5px;
        text-align: center;
    }
    
    #ttsearch_eccept_data .items-list .tt_color span {
        display: none;
    }
    
    #ttsearch_eccept_data .items-list .tt_color ul {
        display: inline;
    }
    
    #ttsearch_eccept_data .items-list .tt_color ul li {
        display: inline-block;
        float: none;
        width: 12px;
        height: 12px;
        margin: 0 5px;
        border-radius: 100%;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        -khtml-border-radius: 100%;
    }
    
    #ttsearch_eccept_data .content-ajax-search {
        overflow: auto;
        height: 100%;
    }
    
    #ttsearch_eccept_data .content-ajax-search::-webkit-scrollbar {
        width: 5px;
    }
    
    #ttsearch_eccept_data .content-ajax-search::-webkit-scrollbar-track {
        background-color: #f2f2f2;
        width: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    #ttsearch_eccept_data .content-ajax-search::-webkit-scrollbar-thumb {
        background-color: #000;
        cursor: pointer;
    }
    
    #ttsearch_eccept_data .content-ajax-search::-webkit-scrollbar-thumb:hover {
        background-color: #000;
    }
    
    #ttsearch_eccept_data .cat-name {
        display: block;
        margin: 0 0 5px;
        text-align: left;
        color: $orange;
    }
    
    #ttsearch_eccept_data .items-list .ttsearch-content {
        text-align: left;
    }
    
    .price.special-price,
    #search_block_top .product-price-and-shipping .price {
        color: $orange;
        font: 500 14px/18px "Poppins", Helvetica, sans-serif;
    }
    
    .sale-percentage {
        display: inline-block;
        margin: 0;
        font-size: 12px;
        vertical-align: top;
        color: #000;
        font-weight: 600;
        padding: 0 1px;
    }
    
    .old-price.regular-price {
        color: #777777;
        font: 400 14px/16px "Poppins", Helvetica, sans-serif;
        text-decoration: line-through;
    }
    
    #search_block_top .select-wrapper {
        float: left;
        position: relative;
        width: 150px;
        z-index: 1;
    }
    
    .ttsearch-main {
        float: left;
        border: none;
        /*
    border-radius: 5px 0 0 5px;
	-moz-border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -khtml-border-radius: 5px 0 0 5px;*/
    }
    
    #search_block_top .select-wrapper::after {
        content: "\E5CF";
        font-family: Material Icons;
        position: absolute;
        right: 10px;
        top: 14px;
        vertical-align: top;
        z-index: -1;
        cursor: pointer;
        color: #555;
    }
    
    #search_block_top .select-wrapper::before {
        border-right: 1px solid #cfcfcf;
        content: "";
        height: 22px;
        position: absolute;
        left: 0;
        top: 13px;
    }
    
    @media (max-width: 1200px) {
        #ttsearch_eccept_data .items-list {
            padding: 0;
        }
        #ttsearch_eccept_data .items-list li .image {
            padding: 0;
        }
    }
    
    @media (max-width: 1199px) {
        #search_block_top #searchbox {
            float: none;
            display: inline-block;
            vertical-align: top;
            width: auto;
        }
        #search_block_top .btn.button-search {
            padding: 12px 23px 11px;
        }
    }
    
    @media (max-width: 991px) {
        #search_block_top .select-wrapper::after {
            top: 12px;
        }
        #search_block_top .select-wrapper::before {
            top: 10px;
        }
        #ttsearch_content_result {
            top: 40px;
        }
        #search_block_top .btn.button-search:before {
            display: block;
        }
        #search_block_top .btn.button-search span {
            display: none;
        }
        #search_block_top #search_query_top {
            display: inline-block;
            float: none!important;
            vertical-align: top;
            width: auto;
            height: 40px;
        }
        #search_category {
            height: 40px;
        }
        #search_block_top .select-wrapper {
            display: inline-block;
            float: none!important;
            vertical-align: top;
            margin: 0 0 0 -3px;
            height: 40px;
        }
        #search_block_top .btn.button-search {
            height: 40px;
            padding: 10px;
        }
    }
    
    @media (max-width: 543px) {
        #search_block_top .select-wrapper {
            width: 120px;
        }
        #search_block_top #search_query_top {
            width: 166px;
        }
        #ttsearch_eccept_data .items-list li .image {
            padding: 0;
        }
        #ttsearch_eccept_data .items-list .ttsearch-content {
            padding: 0 10px;
        }
        #search_category {
            font-size: 12px;
        }
        #search_category {
            padding: 8px 30px 8px 10px;
        }
        #search_block_top #search_query_top {
            font-size: 12px;
        }
        #search_block_top #searchbox {
            width: auto;
        }
        #ttsearch_eccept_data .items-list li .product-name {
            font-size: 13px;
        }
        #ttsearch_eccept_data .items-list>li {
            padding: 10px;
        }
    }
    
    #header.header-sticky #search_block_top {
        position: fixed;
        top: 0px;
        margin: 0;
        z-index: 988;
        left: 22.5%;
    }
    
    #header.header-sticky #search_block_top #searchbox {
        border: none;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
    }
    
    #header.header-sticky #search_block_top .selector {
        height: 44px;
    }
    
    #header.header-sticky #search_category {
        height: 30px;
    }
    
    #header.header-sticky #search_block_top #search_query_top {
        height: 30px;
    }
    
    #header.header-sticky #search_block_top .btn.button-search {
        padding: 11px 0;
    }
    
    #header.header-sticky #search_block_top .selector span {
        margin-top: 8px;
    }
    
    #header.header-sticky .header-container.header-sticky #search_block_top #search_query_top {
        height: 41px;
    }