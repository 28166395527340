/*** HEADER ***/

a{
    color:$dark-gray;
}

.header-nav .currency-selector span{

    color:$dark-gray !important;

}

.top-nav {
    background: #f1f1f1;
    // height: 30px;
}

#header {
    color: #777777;
    position: relative;
    float: left;
    width: 100%;

    .left {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        font-size: 12px;
        padding-top: 6px;

        .item {
            margin-right: 20px;

            a{
                color:$dark-gray;
            }

        }

        .item.free-shipping {
            margin-left: 20px;
            font-weight: bold;

            span {
                color: $orange;
            }
        }
    }

    .right {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 991px) {
            -webkit-justify-content: space-around;
        }

        .item {
            padding-top: 6px;
            font-size: 12px;
            margin-right: 20px;
        }

        .item.free-shipping {
            font-weight: bold;
            margin-bottom: 5px;

            @media (max-width: 425px) {
                width: 100%;
                text-align: center;
            }

            span {
                color: $orange;
            }
        }
    }
}

.middle_nav {
    text-align: center;
    background: white;

    .wishlist {
        cursor: pointer;
        float: left;
        height: 40px;
        width: 40px;
        background: #111;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -khtml-border-radius: 50%;
        position: relative;
        margin-right: 20px;
    }

    .wishlist::after {
        background: transparent url(../img/sprite.png) no-repeat scroll -4px -317px;
        left: 0;
        right: 0;
        content: "";
        margin: 0 auto;
        position: absolute;
        height: 40px;
        width: 40px;
        transition: all 900ms ease-in-out 0s;
        -webkit-transition: all 900ms ease-in-out 0s;
        -moz-transition: all 900ms ease-in-out 0s;
        -o-transition: all 900ms ease-in-out 0s;
        -ms-transition: all 900ms ease-in-out 0s;
    }

    .wishlist:hover::after {
        background-position: -4px -317px;
        transform: rotateY(-180deg);
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
        -o-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
    }
}

@media (max-width: 991px) {
    #mobile_menu {
        .wishlist {
            width: 40px;
            height: 40px;
            float: left;
            position: relative;
            background: transparent url(../img/sprite.png) no-repeat scroll -64px -317px;
        }
    }

    #header .right {
        justify-content: center;
        flex-wrap: wrap;
    }

    .top-nav {
        height: auto;
    }
}

a.wishtlist_top {
    display: none;
}

@media(min-width: 1199px) {
    #header {
        min-height: 145px;
    }
}

#header .logo {
    max-width: 100%;
}

#header a:hover {
    text-decoration: none;
}

.header-nav {
    float: left;
    width: 100%;

    @media(max-width: 991px) {
        display: none
    }
}

.header-nav .language-selector,
.header-nav .currency-selector {
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    white-space: nowrap;
}

.header-nav .language-selector span,
.header-nav .currency-selector span {
    color: #777;
    text-transform: uppercase;
}

.language-selector .dropdown-menu {
    font-size: 12px;
    padding: 1px;
}

.header-nav .language-selector:hover span,
.header-nav .currency-selector:hover span {
    color: #111;
}

.language-selector-wrapper .dropdown-menu {
    left: auto;
    right: 0px;
    top: 29px;
    min-width: auto;
}

.currency-selector .dropdown-menu {
    right: auto;
    left: -17px;
    top: 49px;
}

#_desktop_language_selector .language-selector.dropdown.js-dropdown.open span,
#_desktop_language_selector .language-selector.dropdown.js-dropdown.open i,
#_desktop_currency_selector .currency-selector.dropdown.js-dropdown.open span,
#_desktop_currency_selector .currency-selector.dropdown.js-dropdown.open i {
    color: #111;
}

.currency-selector .dropdown-menu li,
.language-selector-wrapper .dropdown-menu li {
    line-height: 20px;
}

.currency-selector .dropdown-menu li.current a,
.language-selector-wrapper .dropdown-menu li.current a {
    color: #111 !important;
}

.cart-preview .shopping-cart {
    color: #111;
    display: none;
    width: 100%;
    height: 100%;
    font-weight: normal;
    vertical-align: middle;
}

.cart-preview .body {
    display: none;
}

#header .blockcart {
    float: right;
    //width: 100%;
    position: relative;
    padding: 30px 0;
}

#header .fixed .blockcart {
    @media (min-width: 991px) {
        padding: 10px 0;
    }
}

.blockcart .header a {
    cursor: pointer;
    display: block;
    float: left;
    height: 50px;
    padding: 15px 0 0 50px;
    position: relative;
    text-align: left;
    width: auto;
}

#header .blockcart .header a::after {
    background: rgba(0, 0, 0, 0) url("../img/sprite.png") no-repeat scroll -28px -168px;
    content: "";
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 50px;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
}

#header .blockcart .header a:hover::after {
    background: rgba(0, 0, 0, 0) url("../img/sprite.png") no-repeat scroll -28px -168px;
    transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
}

.blockcart span.cart-products-count {
    background: $orange;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    color: #fff;
    font-size: 12px;
    height: 16px;
    left: 18px;
    line-height: 14px;
    margin: 0 auto;
    padding: 1px;
    position: absolute;
    right: auto;
    text-align: center;
    top: 8px;
    width: 16px;
}

.blockcart.active.header a:hover {
    color: #fff;
}

#header .blockcart span.hidden-sm-down {
    color: #111;
    display: inline-block;
    float: none;
    font: 500 14px/16px 'Work Sans', sans-serif;
    text-transform: uppercase;
    width: auto;
}

@media (max-width: 1199px) {
    .blockcart .header a {
        padding: 0;
        height: 50px;
        width: 50px;
    }

    #header .blockcart span.hidden-sm-down {
        display: none;
    }
}

@media (max-width: 991px) {
    #header .blockcart {
        padding: 0;
    }

    #header .blockcart .header a::after {
        background: rgba(0, 0, 0, 0) url("../img/sprite.png") no-repeat scroll -28px -595px !important;
    }

    .blockcart span.cart-products-count {
        background: #111;
        color: #fff;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -khtml-border-radius: 50%;
        color: #fff;
        font-size: 9px;
        height: 10px;
        left: 23px;
        line-height: 10px;
        margin: 0 auto;
        padding: 1px;
        position: absolute;
        right: auto;
        text-align: center;
        top: 15px;
        width: 10px;
    }

    .blockcart .cart-products-count .cart-count {
        display: none;
    }
}

.header-nav .language-selector:hover .material-icons.expand-more,
.header-nav .language-selector:hover .material-icons.expand-less,
.header-nav .currency-selector:hover .material-icons.expand-more,
.header-nav .currency-selector:hover .material-icons.expand-less {
    color: #111;
}

.header-nav .language-selector.open .material-icons.expand-more,
.header-nav .language-selector.open .material-icons.expand-less,
.header-nav .currency-selector.open .material-icons.expand-more,
.header-nav .currency-selector.open .material-icons.expand-less {
    color: #111;
}

#header .header-top {
    float: left;
    width: 100%;
    background: #ffde01;
}

#header .header-top .container {
    position: relative;
}

#header .header-top .menu {
    padding: 0;
    margin-bottom: 0;
}

#header .header-top .menu>ul>li {
    display: inline-block;
    float: none;
    vertical-align: top;
}

#header .header-top .position-static {
    position: static;
}

#header .header-top a[data-depth="0"] {
    text-transform: capitalize;
    color: #ffffff;
}

#header .top-menu-link {
    margin-left: 1.25rem;
}

.popover {
    font-family: inherit;
}