
/***********************************************************/
/***********************************************************/
/***********************************************************/
/************* CHANGE ICONS FOR MATERIAL-ICONS *************/
/***********************************************************/
/***********************************************************/
/***********************************************************/

.ttuserheading,
.middle_nav .wishlist {
    &::after {
        content: none;
    }

    .material-icons {
        font-size: 24px;
        color: #fff;
        line-height: 38px;
    }

    .material-icons {
        font-size: 22px;
        line-height: 40px;
    }
}

#mobile_menu {

    .ttuserheading,
    .wishlist {
        .material-icons {
            font-size: 24px;
            color: $textcolor;
        }
    }
}

#header .blockcart {
    .header a {
        padding-top: 10px;
        padding-left: 0;

        &::after {
            content: none;
        }

        .shopping-cart {
            display: block;
            color: $textcolor;
            width: auto;
            height: auto;
            float: left;
            padding-right: 20px;
            font-size: 30px;
        }

        span.cart-products-count {
            top: 6px;
        }

        span.hidden-sm-down {
            line-height: 25px;
        }
    }
}


#products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.featured-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttfeatured-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.product-accessories .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttbestseller-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttnew-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.ttspecial-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.crossselling-product .thumbnail-container .ttproducthover div.quick-view-block .btn,
.category-products .thumbnail-container .ttproducthover div.quick-view-block .btn,
.view-product .thumbnail-container .ttproducthover div.quick-view-block .btn {
    background: #f5f5f5;
    background: transparent;
    color: $textcolor;

    i {
        display: block;
        text-align: center;
        line-height: 35px;
        margin: 0 auto;
        font-size: 22px;
    }

    &:hover {
        background: $textcolor;
        color: #fff;
    }
}

.ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default {
    padding-left: 15px;

    &::before {
        content: none;
    }

    span {
        float: left;
    }

    .material-icons {
        display: block;
        float: left;
        line-height: 20px;
        margin-right: 5px;
        font-size: 22px;
    }
}

.ttspecial-products .product-grid .thumbnail-container .ttproducthover {
    div.tt-button-container .btn.ajax_add_to_cart_button {
        background: $orange;
        color: #fff;

        span {
            color: #fff;
            float: left;
        }

        .material-icons {
            display: block;
            float: left;
            line-height: 18px;
            margin-right: 5px;
            position: relative;
            right: auto;
            top: auto;
        }
    }
}

.ttspecial-list .products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable {
    padding: 8px 10px 9px 15px;
    height: 36px;

    &:hover {
        background: $orange !important;
    }

    span {
        width: auto;
    }

    .material-icons {
        display: block;
        position: relative;
        right: auto;
        top: auto;
        font-size: 22px;
    }
}

#products .product-grid .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.featured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.product-accessories .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttfeatured-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.crossselling-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttbestseller-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttnew-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.ttspecial-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.category-products .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button,
.view-product .thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button {
    background: $orange;
    background: transparent;
    color: $orange;

    &:hover {
        background: $textcolor;
        background: $orange;
        color: #fff;
    }

    .material-icons {
        display: block;
    }
}

#products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable:hover,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable:hover,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
#products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
#product_comparison div.tt-button-container .btn.add-to-cart-disable {
    background: $orange !important;
}


.thumbnail-container .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button .material-icons {
    position: absolute;
    float: left;
    right: 5px;
    top: 7px;
    font-size: 22px;
}

.product-actions .product-add-to-cart .add-to-cart {
    text-transform: unset;

    .material-icons {
        font-size: 22px;
        line-height: 21px;
    }
}

#products .thumbnail-container .ttproducthover div.wishlist .btn,
.products .thumbnail-container .ttproducthover div.wishlist .btn,
.featured-products .thumbnail-container .ttproducthover div.wishlist .btn,
.ttfeatured-products .thumbnail-container .ttproducthover div.wishlist .btn,
.product-accessories .thumbnail-container .ttproducthover div.wishlist .btn,
.ttbestseller-products .thumbnail-container .ttproducthover div.wishlist .btn,
.ttnew-products .thumbnail-container .ttproducthover div.wishlist .btn,
.ttspecial-products .thumbnail-container .ttproducthover div.wishlist .btn,
.crossselling-product .thumbnail-container .ttproducthover div.wishlist .btn,
.category-products .thumbnail-container .ttproducthover div.wishlist .btn,
.view-product .thumbnail-container .ttproducthover div.wishlist .btn {
    background: #f5f5f5;
    background: transparent;
    color: $textcolor;

    &:hover {
        background: $textcolor;
        color: #fff;
    }

    .material-icons {
        display: block;
        font-size: 22px;
        margin: 0;
        float: left;
        position: relative;
        top: 7px;
        left: 6px;
    }

    &.checked {
        color: #fff;
    }
}


#products .thumbnail-container .ttproducthover div.compare .btn,
.products .thumbnail-container .ttproducthover div.compare .btn,
.featured-products .thumbnail-container .ttproducthover div.compare .btn,
.ttfeatured-products .thumbnail-container .ttproducthover div.compare .btn,
.product-accessories .thumbnail-container .ttproducthover div.compare .btn,
.ttbestseller-products .thumbnail-container .ttproducthover div.compare .btn,
.ttnew-products .thumbnail-container .ttproducthover div.compare .btn,
.ttspecial-products .thumbnail-container .ttproducthover div.compare .btn,
.crossselling-product .thumbnail-container .ttproducthover div.compare .btn,
.category-products .thumbnail-container .ttproducthover div.compare .btn,
.view-product .thumbnail-container .ttproducthover div.compare .btn {
    background: #f5f5f5;
    background: transparent;
    color: $textcolor;

    &:hover {
        background: $textcolor;
        color: #fff;
    }

    .material-icons {
        display: block;
        font-size: 20px;
        margin: 0;
        float: left;
        position: relative;
        top: 7px;
        left: 7px;
    }

    &.checked {
        color: #fff;
    }
}

.product-actions .product-add-to-cart .compare .add_to_compare::before,
.product-actions .product-add-to-cart .wishlist a::before {
    content: none;
}

#product_comparison .product-miniature .product-description .tt-button-container .btn.add-to-cart-disable,
.product-actions .btn.btn-primary.add-to-cart:disabled {
    color: #fff;
    background: $orange;
}

.block-social.header {
    padding: 15px 0 5px;
}

.block-social ul li {
    margin: 0 0 0 20px !important;

    a,
    a::before {
        float: left;
        height: auto;
        width: auto;
        font-family: "FontAwesome";
        font-size: 18px;
        color: #777;
        background: none !important;
    }

    a:hover::before {
        color: $orange;
    }

    &.facebook a::before {
        content: "\f09a";
    }

    &.twitter a::before {
        content: "\f099";
    }

    &.rss a::before {
        content: "\f09e";
    }

    &.youtube a::before {
        content: "\f167";
    }

    &.linkedin a::before {
        content: "\f0e1";
    }

    &.googleplus a::before {
        content: "\f0d5";
    }

    &.pinterest a::before {
        content: "\f231";
    }

    &.vimeo a::before {
        content: "\f27d";
    }

    &.instagram a::before {
        content: "\f16d";
    }
}

.remove i.material-icons.fa-trash::before {
    content: none;
}

#products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart,
#products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable {
    color: #fff;
    background: $orange !important;
    padding: 7px 20px;

    .material-icons {
        display: block;
        position: relative;
        top: 0;
    }

    span {
        float: left;
    }
}

#products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart:hover {
    background: $textcolor !important;
}

#products .product-list .thumbnail-container .tt-button-container .btn.ajax_add_to_cart_button.add-to-cart.btn-default::before {
    background: none;
    content: none;
}

#products .product-list .thumbnail-container .ttproducthover div.compare {
    float: left;
}

#products .product-list .ttproducthover div {

    &.compare,
    &.quick-view-block,
    .addToWishlist {
        margin-right: 5px;
    }
}

/***********************************************************/
/***********************************************************/
/***********************************************************/
/*********** END CHANGE ICONS FOR MATERIAL-ICONS ***********/
/***********************************************************/
/***********************************************************/
/***********************************************************/