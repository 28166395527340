.tt_category_feature {
    float: left;
    vertical-align: top;
    position: relative;
    margin: 0 0 40px;
    padding: 0;
}

.tt_category_feature .title_tab_hide_show {
    padding: 10px 20px 10px 20px;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    margin-right: 10px;
    color: #fff;
    background: #05a75c;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    text-transform: uppercase;
    float: left;
}

.tt_category_feature .tt_cat_content {
    background: #fff;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    -khtml-border-radius: 8px;
    float: left;
    overflow: hidden;
    width: 100%;
}

.tt_category_feature .list_carousel {
    clear: both;
}

.tt_category_feature ul.product-list li {
    float: left;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 35px;
    position: relative;
    height: auto;
    width: 240px;
    text-align: center;
    position: relative;
}

.tt_category_feature ul.product-list li ul li {
    float: left;
    margin-left: 0;
    margin: 5px 0;
    position: relative;
    height: auto;
    width: auto;
    text-align: left;
    clear: both;
}

.tt_category_feature ul.product-list li .cat-img {
    line-height: 8px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}

.tt_category_feature ul.product-list li .title {
    margin: 0;
    padding: 10px 0;
    text-align: left;
    position: absolute;
    bottom: 10px;
    left: 30px;
}

.tt_category_feature ul.product-list li .title a {
    position: relative;
    color: #fff;
    font-size: 24px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
}

.tt_category_feature ul.product-list li .title a img {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}

.tt_category_feature .cat-img a {
    display: inline-block;
    float: none;
    vertical-align: top;
    position: relative;
    width: auto;
}

#tt_category_feature .sub-cat ul {
    float: left;
    margin: 0;
    width: 100%;
    z-index: 1;
    padding: 0 10px;
}

.tt_category_feature .cat-img a img {
    display: block;
    width: 100%;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
}

#tt_cat_featured .content .sub-cat ul li a {
    color: #555;
    font: 300 14px/24px 'Work Sans', sans-serif;
    text-transform: capitalize;
    position: relative;
}

#tt_cat_featured .content .sub-cat ul li a::before {
    content: "\E5CC";
    font-family: "Material Icons";
    font-size: 17px;
    float: left;
    color: #555;
}

#tt_cat_featured .content .sub-cat ul li a:hover,
#tt_cat_featured .content .sub-cat ul li a:hover::before {
    color: #000000;
}

#tt_category_feature .wt-block-title h3 {
    font-size: 24px;
    color: #000;
    border-bottom: 3px solid #f12a43;
    padding-bottom: 7px;
    float: left;
    margin-bottom: 15px;
}

#tt_cat_featured .content .title a {
    color: #333;
    font: 500 16px/30px 'Work Sans', sans-serif;
    margin: 0 0 10px;
    padding: 0;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    width: 100%;
}

#tt_category_feature .content .cat-infor .title {
    color: #111;
    font-weight: normal;
    overflow: hidden;
    padding: 0 15px 6px;
    position: relative;
    text-overflow: ellipsis;
}

#tt_category_feature .cat-infor .title::after {
    background: #e5e5e5;
    bottom: 0;
    content: "";
    height: 1px;
    left: 15px;
    position: absolute;
    transition: width 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    width: 50px;
}

#tt_cat_featured .content {
    float: left;
    width: 100%;
    overflow: hidden;
}

#tt_cat_featured .item {
    float: left;
    width: 100%;
    border-right: 1px solid #e5e5e5;
}

#tt_category_feature #tt_cat_featured .item .content .ttcat-content {
    padding: 10px;
    text-align: left;
}

#tt_cat_featured .cat-img {
    padding: 0;
}

#tt_cat_featured .content:hover .title a,
#tt_category_feature .content:hover .cat-infor .title {
    color: #000;
}

.tt_category_feature .content .sub-cat .cat-more-menu {
    border: 2px solid $orange;
    background: $orange;
    color: #000;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    cursor: pointer;
    float: left;
    margin: 15px 0 0;
    padding: 4px 8px 5px 17px;
    width: auto;
}

#tt_cat_featured .cat-more .cat-more-menu .categories a {
    color: #fff !important;
    font-weight: 700;
}

#tt_cat_featured .cat-more .cat-more-menu:hover .categories a {
    color: #fff !important;
}

#tt_cat_featured .cat-more .cat-more-menu .categories a::before {
    display: none;
}

.tt_category_feature .content .sub-cat .cat-more-menu::before {
    content: "\E5CC";
    font-family: "Material Icons";
    font-size: 17px;
    float: right;
    color: #fff;
    padding: 3px 0 0 4px;
}

.tt_category_feature .content .sub-cat .cat-more-menu:hover::before {
    color: #fff;
}

.tt_category_feature .content .sub-cat .cat-more-menu:hover {
    border-color: #111;
    background: #111;
}

@media(min-width:543px) {
    #tt_cat_featured .item {
        margin: 0 0 0 2px;
    }
}

@media(max-width:543px) {
    #tt_category_feature #tt_cat_featured .item .content .ttcat-content {
        text-align: center;
    }

    #tt_category_feature .cat-infor .title::after {
        left: 0;
        margin: auto;
        right: 0;
    }

    #tt_cat_featured .content .sub-cat ul li a::before {
        float: none;
        vertical-align: bottom;
    }

    .tt_category_feature .content .sub-cat .cat-more-menu {
        float: none;
        width: 140px;
        margin: 15px auto;
    }
}

@media(max-width:1200px) {
    #tt_cat_featured .cat-img {
        float: left;
    }

    #tt_category_feature #tt_cat_featured .item .content .ttcat-content {
        float: left;
        padding: 10px 15px;
    }
}

@media(max-width:1199px) {
    .tt_category_feature {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tt_category_feature .caroufredsel_wrapper {
        height: 250px !important;
    }
}

@media (max-width: 767px) {
    .tt_category_feature .title_tab_hide_show {
        float: left;
    }

    #tt_cat_featured .content .sub-cat ul li a {
        font-size: 13px;
    }

    .tt_category_feature .content .sub-cat .cat-more-menu::before {
        padding: 3px 0 0 5px;
    }

    #tt_category_feature #tt_cat_featured .item .content .ttcat-content {
        padding: 10px;
    }

    #tt_cat_featured .item {
        border: none;
    }
}