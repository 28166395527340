#cms .tt-innerpagetitle {
    text-align: center;
}

#cms .page-content.page-cms {
    border: none;
}


/* CONTACTS*/

#contact {
    .contact-rich {
        border: none;
        padding: 0;
    }
    #content {
        PADDING: 0;
        border: 0;
    }
    h4.title {
        text-transform: uppercase;
        border-bottom: 1px solid $orange;
        padding-bottom: 15px;
        margin-bottom: 30px;
    }
    .contact-form .form-control-label {
        text-align: right;
    }
    div#content-wrapper {
        float: right;
    }
    #map {
        width: 100vw;
        margin-left: calc(-50vw + 50%);
        height: 600px;
        margin-bottom: 100px;
    }
}


/* BLOG */

#module-smartblog-category {
    .card {
        padding: 15px;
        margin: 0 2%;
        margin-bottom: 50px;
        -webkit-box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.3);
    }
    .sdstitle_block a {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 0;
        min-height: 50px;
        color: #111;
        letter-spacing: 0;
        text-transform: uppercase
    }
    .blogdetail {
        font-size: 11px;
        material-icons {
            font-size: 12px;
        }
    }
    .imageFeatured {
        margin: 20px 0;
        border: 2px solid $orange;
    }
    .sdsreadMore {
        margin-top: 40px;
        text-align: right;
        font-weight: bold;
        text-transform: uppercase;
        a {
            color: $orange
        }
    }
}

#module-smartblog-details {
    .page-item-title h1 {
        text-transform: uppercase;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: 30px 0;
    }
    #sdsblogArticle .post-info {
        text-align: center;
        margin: 42px 0 46px;
    }
}


/* todas as categorias*/

.cms-id-6 {
    h4, .h4 {
        font-size: 16px;
    }

    .container-fluid {
        padding: 0
    }
    .page-content.page-cms {
        padding: 0;
    }
    a.category-name {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    img.icon {
        width: 50px;
        height: auto;
        height: 50px;
    }
    h2 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        padding-left: 15px;
        text-align: left;
        text-transform: uppercase;
    }
    .all-categories {
        box-sizing: border-box;
        float: left;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .3);
    }
    li.home_categories {
        font-style: normal !important;
        /*width: 33.333%;
        width: 12.5%;*/
        width: 20%;
        display: table-cell;
        vertical-align: middle;
        float: left;
        height: 70px;
        border-bottom: 1px solid #f0f0f0;
        border-top: 0px;
        border-right: 1px solid #f0f0f0;
    }
    @media only screen and (min-width: 1200px) {
        li.home_categories {
            width: 16.666%;
            width: 12.5%;
        }
    }
    a[href="#1419"] {
        display: none!important;
    }
    ul.lista {
        column-count: 1;
        padding: 20px!important;
        background: #f2f2f2;
    }
    @media only screen and (min-width: 768px) {
        ul.lista {
            column-count: 2;
            -webkit-column-count: 2;
            /* Chrome, Safari, Opera */
            -moz-column-count: 2;
            /* Firefox */
        }
    }
    @media only screen and (min-width: 992px) {
        ul.lista {
            column-count: 3;
            -webkit-column-count: 3;
            /* Chrome, Safari, Opera */
            -moz-column-count: 3;
            /* Firefox */
        }
    }
    @media only screen and (min-width: 1200px) {
        ul.lista {
            column-count: 4;
            -webkit-column-count: 4;
            /* Chrome, Safari, Opera */
            -moz-column-count: 4;
            /* Firefox */
        }
    }
    .main-cat {
        width: 300px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin: 70px 0 20px;
        align-items: center;
    }
    h3 {
        font-size: 15px;
        font-weight: 500;
        margin: 0;
        padding-left: 15px;
        text-align: left;
        text-transform: uppercase;
    }
    .subcategory-name {
        color: $orange;
    }
    .block {
        margin: 10px 0;
    }
    #cat-menu.fixed {
        position: fixed;
        z-index: 999;
        top: 0;
        background: white;
        width: 100%;
        left: 0;
    }
    #cat-menu {
        .first.row {
            padding-top: 20px;
        }
        .second.row {
            padding: 10px 0 20px;
        }
    }
    select.select-cat {
        margin: 20px auto;
        width: 200px;
        display: block;
        padding: 5px;
    }
    .select-cat.wrapper {
        background: white;
        padding: 1px;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 111;
        right: 0;
    }
    .select-wrapper.fixed {
        padding: 20px;
        background: white;
        width: 100%;
        top: 0;
        position: fixed;
        z-index: 999;
        left: 0;
    }
}