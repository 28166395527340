#ttPopupnewsletter {
    text-align: center;
    float: left;
    width: 100%;
}

.ttPopupnewsletter-i .close {
    color: #ffffff;
    font-weight: normal;
    line-height: 16px;
    margin: auto;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 5px;
    width: auto;
    z-index: 99;
}

.ttPopupnewsletter-i .close i {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transition: all 0.5s ease-in-out 0ms;
    -webkit-transition: all 0.5s ease-in-out 0ms;
    -moz-transition: all 0.5s ease-in-out 0ms;
    -o-transition: all 0.5s ease-in-out 0ms;
    -ms-transition: all 0.5s ease-in-out 0ms;
}

.ttPopupnewsletter-i .close span {
    display: none;
}

.ttPopupnewsletter-i .close:hover i {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.ttpopupnewsletter {
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
}

#newsletter_block_popup {
    float: left;
    width: 100%;
}

#newsletter_block_popup .ttnewslatterpopup-img {
    padding: 0;
}

#newsletter_block_popup .ttnewslatterpopup-img img {
    max-width: 100%;
}

#newsletter_block_popup .ttnewslatterpopup-content {
    float: left;
    margin: 10% 0;
    text-align: center;
    background: #fff;
}

#newsletter_block_popup .block_content .newsletter_title h3 {
    color: #333333;
    font-size: 26px;
    font-weight: normal;
    margin: 0 0 15px;
    text-transform: capitalize;
}

#newsletter_block_popup .block_content .ttContent {
    color: #666666;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
}

#newsletter_block_popup .block_content .ttContent p {
    margin: 0;
}

#newsletter_block_popup .block_content .ttAlert {
    color: $orange;
    float: left;
    font-size: 14px;
    margin: 0 0 10px;
    text-align: center;
    width: 100%;
}

#newsletter_block_popup .block_content .inputNew {
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    height: 45px;
    padding: 5px 15px;
    width: 90%;
    margin-bottom: 10px;
    text-align: center;
    color: #666;
}

.checkbox-area-modal {
    margin-bottom: 20px;
}

[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

#newsletter_block_popup .block_content .send-reqest {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    // margin-bottom: 25px;
}

#newsletter_block_popup .newsletter_block_popup-bottom {
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: top;
    width: auto;
    margin-top: 20px;
}

#newsletter_block_popup .newsletter_block_popup-bottom .subscribe-bottom {
    float: left;
    height: 15px;
    margin: 2px 5px;
    //  width: 15px;
}

#newsletter_block_popup .newsletter_block_popup-bottom .tt_newsletter_dont_show_again {
    color: #666666;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    cursor: pointer;
}

#newsletter_block_popup .newsletter_block_popup-bottom .tt_newsletter_dont_show_again:hover {
    color: #000;
}

@media (max-width: 543px) {
    .ttpopupnewsletter {
        display: inline-block;
        float: none;
        max-width: 300px !important;
        /* position: relative;
        vertical-align: top;*/
        width: auto;
    }
    #newsletter_block_popup .ttnewslatterpopup-img {
        width: 100%;
        text-align: center;
        display: none;
    }
    #newsletter_block_popup .ttnewslatterpopup-content {
        padding: 30px 15px;
        width: 100%;
        margin: 0;
    }
}