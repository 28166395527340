#manufacturer-short_description {
    margin-bottom: 20px;
}

#manufacturer {
    h1 {
        text-align: center;
        margin-bottom: 30px;
    }
    .brand-holder {
        border: 1px solid #ccc;
        margin-bottom: 30px;
        padding: 40px 0;
    }
}

.text-center {
    text-align: center;
}

.brand-infos {
    text-align: center;
}

.brand_prod {
    display: block;
}

.brand_url {
    margin: 10px 0 90px 0;
}