#ttcategorytabs .categoryimage .category_img {
    max-width: 100%;
}

#ttcategorytabs .tab-content {
    float: left;
    margin: 20px 0;
    width: 100%;
}

#ttcategorytabs .tab-content .products {
    position: relative;
    margin-bottom: 0;
}

#ttcategorytabs {
    display: inline-block;
    float: none;
    margin: 0 auto 40px;
    padding: 0;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 100%;
}

div#ttcategorytabs .thumbnail-container {
    padding: 15px 0;
}

#ttcategorytabs .ttcategory .ttproduct-desc {
    padding: 10px 0;
}

#ttcategorytabs .ttcategory .thumbnail-container .hook-reviews {
    float: left;
    position: static;
    width: auto;
    margin: 3px 0 8px;
}

#ttcategorytabs .products .product-title {
    margin: 0 0 9px;
}

#ttcategorytabs .ttcategory .thumbnail-container:hover .hook-reviews {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

#ttcategorytabs .product-miniature.product-grid .thumbnail-container .ttproductcountdown {
    position: static;
    margin: 4px 0 10px;
    display: block!important;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    float: left;
    width: 100%;
    text-align: left;
    margin: 4px 0 14px;
}

#ttcategorytabs .ttcategory .products .product-grid .thumbnail-container .ttproducthover {
    position: static;
    float: left;
    /*opacity:0;
	-moz-opacity:0;
    -khtml-opacity:0;
    -webkit-opacity:0;*/
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    margin: 0;
}

#ttcategorytabs .ttcategory .products .product-grid .thumbnail-container:hover .ttproducthover {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}

@media (min-width: 699px) {
    #ttcategorytabs .products .product-miniature {
        margin: 0 0 30px 4px;
    }
    #ttcategorytabs .products .product-miniature:last-child {
        margin: 0 0 0 4px;
    }
}

@media (max-width:1599px) {
    #ttcategorytabs .ttcategory .ttproduct-desc {
        padding: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #ttcategorytabs .products .product-miniature {
        margin: 0 0 25px 4px;
    }
    #ttcategorytabs .products .product-miniature:last-child {
        margin: 0 0 0 4px;
    }
    #ttcategorytabs .tab-content {
        margin: 10px 0 0;
    }
}

@media (max-width: 1199px) {
    #index #ttcategorytabs .tt-title {
        float: none;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    #ttcategorytabs .customNavigation {
        top: 60px;
    }
}

@media (max-width: 991px) {
    #ttcategorytabs .tab-content .ttcategory {
        width: 100%;
        margin: 30px 0 0;
    }
    #ttcategorytabs .tab-pane .categoryimage {
        width: 100%;
    }
}

@media (max-width: 767px) {
    #ttcategorytabs .tab-content {
        margin: 0;
    }
    #ttcategorytabs .ttcategory .products .product-grid .thumbnail-container .ttproducthover {
        opacity: 1;
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
    }
}

@media (max-width: 699px) {
    #ttcategorytabs .products .product-miniature {
        margin: 0 0 20px;
        padding: 0;
    }
}

@media (max-width: 645px) {
    #ttcategorytabs {
        margin: 0 auto 10px;
    }
    #ttcategorytabs .products .product-miniature {
        border: medium none;
    }
    #ttcategorytabs .tab-content .ttcategory {
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 399px) {
    #ttcategorytabs .tab-content .products {
        float: left;
        margin: 0;
        width: 100%;
    }
    #ttcategorytabs .ttproduct-image {
        padding: 0 10px!important;
    }
    #ttcategorytabs .h3.product-title>a {
        font-size: 13px!important;
    }
    #ttcategorytabs .ttcategory .thumbnail-container .hook-reviews {
        margin: 0 0 3px;
    }
    #ttcategorytabs .product-description .product-price-and-shipping {
        padding: 0 0 5px;
    }
    #ttcategorytabs .product-miniature.product-grid .thumbnail-container .ttproductcountdown {
        margin: 4px 0 7px;
    }
    #ttcategorytabs .products .product-title {
        margin: 0 0 5px;
    }
}