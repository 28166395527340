.homeslider-container {
    margin-bottom: 50px;
    position: relative;
    float: left;
    width: 100%;
}

.homeslider {
    position: relative;
    list-style: none;
    overflow: hidden;
    margin: 0;
}

.homeslider li {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.homeslider img {
    display: block;
    position: relative;
    z-index: 1;
    height: auto;
    width: 100%;
    border: 0;
}

.homeslider .caption {
    display: block;
    position: absolute;
    z-index: 2;
    font-size: 1em;
    text-shadow: none;
    color: #fff;
    background: #000;
    background: rgba(0, 0, 0, .6);
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    margin: 0;
    max-width: none;
}

.homeslider_nav {
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 52%;
    left: 0;
    opacity: 0.7;
    z-index: 3;
    text-indent: -9999px;
    overflow: hidden;
    text-decoration: none;
    height: 61px;
    width: 38px;
    background: transparent url("../homeslider.gif") no-repeat left top;
    margin-top: -45px;
}

.homeslider_nav:active {
    opacity: 1.0;
}

.homeslider_nav.next {
    left: auto;
    background-position: right top;
    right: 0;
}

.carousel-control {
    border: 2px solid $orange;
    background: $orange;
    color: #fff;
}

.carousel-control:focus,
.carousel-control:hover {
    background: black;
    border-color: black;
}

.carousel .carousel-control .icon-next i,
.carousel .carousel-control .icon-prev i {
    color: #fff!important
}