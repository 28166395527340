.ei-slider {
    float: left;
    height: 700px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
}

.ei-slider-large {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.ei-slider-large li {
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 0!important;
}

.ei-slider-large li img {
    width: 100%!important;
    max-width: 100%;
    margin: 0!important;
    height: 100%!important;
}

.ei-title {
    position: absolute;
    right: 50%;
    margin-right: 13%;
    top: 30%;
}

.ei-title h2,
.ei-title h3 {
    text-align: right;
}

.ei-title h2 {
    font-size: 0;
    line-height: 50px;
    font-family: 'Playfair Display', serif;
    font-style: italic;
    color: #b5b5b5;
}

.ei-title h3 {
    font-size: 70px;
    line-height: 70px;
    font-family: 'Open Sans Condensed', sans-serif;
    text-transform: uppercase;
    color: #000;
}

.ei-slider-thumbs {
    height: 70px;
    margin: 0 auto;
    position: absolute;
    left: auto;
    right: 0;
    padding: 0;
    top: 0;
    bottom: auto;
    max-width: 9%!important;
    display: none!important;
}

.ei-slider-thumbs li {
    float: left;
    height: 100%;
    position: relative;
    width: 100% !important;
}

.ei-slider-thumbs li.ei-slider-element {
    background: #000;
    font-size: 0;
    height: 70px;
    left: 0;
    position: absolute;
    text-indent: -9000px;
    top: auto;
    width: 5px !important;
    z-index: 1;
}

.ei-slider-thumbs li a {
    display: block;
    border-left: 4px solid #ffde01;
    border-bottom: 2px solid #000000;
    background: #222222;
    width: 100%;
    height: 70px;
    color: #fff;
    cursor: pointer;
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    -o-transition: background 0.2s ease;
    -ms-transition: background 0.2s ease;
    transition: background 0.2s ease;
    text-align: center;
}

.ei-slider-thumbs li:last-child a {
    border-bottom: none;
}

.ei-slider-thumbs li a:hover {
    background-color: #222222;
}

.ei-slider-thumbs li img {
    cursor: pointer;
    position: absolute;
    top: 0;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    z-index: 1;
    height: 70px;
    width: 160px !important;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    transition: all 0.8s ease;
    left: auto;
    right: 0;
    -webkit-box-reflect: below 0px -webkit-gradient( linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(255, 255, 255, 0.3)));
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.ei-slider-thumbs li:hover img {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    right: 142px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.ei-slider-thumbs .ttcms {
    padding: 10px 0;
}

.ei-slider-thumbs .ttcms .ttcms-title .title1 {
    font-size: 16px;
    text-transform: uppercase;
}

.ei-slider-thumbs .ttcms .ttcms-title .title2 {
    color: #ffde01;
    text-transform: capitalize;
}


/*---------------------PREV-NEXT---------------------------*/

.direction .slide i {
    color: #fff;
    display: inline-block;
    float: none;
    font-size: 28px;
    height: 100%;
    padding: 0;
    line-height: 1.2;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.direction .slide {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 2px solid #fff;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    bottom: 0;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 40px;
    margin: auto;
    position: absolute;
    text-decoration: none;
    top: 0;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    width: 40px;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
}

.direction .slide:focus,
.direction .slide:hover {
    background: $orange;
    border-color: $orange;
    color: #000;
}

.direction .slide:focus i,
.direction .slide:hover i {
    color: #000;
}

#ei-slider:hover .direction .slide {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}

.left.slide {
    left: 70px;
    right: auto;
}

#ei-slider:hover .direction .slide.left {
    left: 50px;
}

.right.slide {
    right: 70px;
    left: auto;
}

#ei-slider:hover .direction .slide.right {
    right: 50px;
}

@media (max-width: 1599px) {
    .ei-slider {
        height: 600px;
    }
}

@media (max-width: 1200px) {
    .ei-slider {
        height: 560px;
    }
}

@media (max-width: 1199px) {
    .ei-slider {
        height: 450px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .ei-slider {
        height: 375px;
    }
}

@media (max-width: 767px) {
    .ei-slider {
        height: 300px;
    }
    .direction .slide {
        opacity: 1;
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
    }
    .left.slide {
        left: 30px;
    }
    #ei-slider:hover .direction .slide.left {
        left: 30px;
    }
    .right.slide {
        right: 30px;
    }
    #ei-slider:hover .direction .slide.right {
        right: 30px;
    }
}

@media (max-width: 543px) {
    .direction .slide {
        background: none!important;
        border: none!important;
    }
    .ei-slider {
        height: 270px;
    }
    .direction .slide i:focus,
    .direction .slide i:hover {
        color: #ffde01;
    }
    .direction .slide i {
        font-size: 32px;
    }
    .left.slide {
        left: 10px;
    }
    #ei-slider:hover .direction .slide.left {
        left: 10px;
    }
    .right.slide {
        right: 10px;
    }
    #ei-slider:hover .direction .slide.right {
        right: 10px;
    }
}

@media (max-width: 460px) {
    .ei-slider {
        height: 150px;
    }
}