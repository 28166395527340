#checkout {
    .unit-price-cart {
        display: none;
    }
    .product-price {
        font-size: 14px;
    }
    .cart-summary-line.cart-total {
        margin-bottom: 20px;
        .label {
            color: $orange;
            font-weight: 600;
            font-size: 16px;
        }
        .value {
            color: $orange;
            font-size: 16px;
            font-weight: 600;
        }
    }
}