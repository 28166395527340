/* >>>>> HP <<<<<<<<<<< */
.orange {
    color: $orange;
}

.right-nav {
    background: white !important;
}

.full-header {
    background: none;
}

/* menu*/

#header .header-top {
    background: $gray-lightest;
}

#tt-menu-horizontal {
    padding: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

#tttop-link-block ul li a {
    @media (min-width: 992px) {
        padding: 15px 20px;
    }

    @media (min-width: 1200px) {
        padding: 15px 30px;
    }
}

#tttop-link-block ul li:hover a {
    color: $orange;
}

.full-header::after {
    background: none;
}

#header .position-static {
    background: $gray-lightest;
}

.ttsearch-main {
    border: 1px solid #111 !important;
}

#search_block_top .btn.button-search {
    background: $orange;
    height: 42px;
}

.search-widget form button[type=submit] {
    background: url(../img/sprite.png) -61px -232px no-repeat $orange;
}

@media (min-width: 992px) {
    .tt-menu-horizontal .main-menu {
        top: 46px;
    }

    .title-menu-mobile {
        background: $orange;
    }

    #search_block_top .btn.button-search {
        background: $orange;
        height: 47px;
    }
}

#header .blockcart .header a::after {
    background: url(../img/sprite.png) -28px -112px no-repeat rgba(0, 0, 0, 0);
}

#header .blockcart .header a:hover::after {
    background: url(../img/sprite.png) -28px -112px no-repeat rgba(0, 0, 0, 0);
}

#ttcategorytabs .tt-title::after,
.tt-title::after,
.tthometab-title::after {
    border-bottom: 3px solid $orange;
}

/* on scroll*/

.logo {
    width: 173px;
    height: 60px;
}

.fixed {
    #_desktop_logo {
        margin: 15px 0;
    }

    .logo {
        width: 135px;
        height: 47px;
        transition: all 0.3s;
    }
}

.full-header.fixed #search_block_top {
    //margin: 15px 50px 15px 0;
    margin: 15px 0;
}

.full-header.fixed #_desktop_user_info {
    margin: 15px 25px;
}

#header .fixed .blockcart {
    @media(min-width: 991) {
        padding: 10px 0;
    }
}

.header-top.fixed {
    position: fixed;
    z-index: 111;
}

.hidden {
    display: none;
}

#_desktop_cart .product_list {
    height: auto;
    max-height: 333px;
    overflow-y: auto;
}

#mobile_menu .product_list {
    //height: 253px;
    overflow-y: auto;
}

.free-shipping {
    font-size: 16px;
    font-weight: 600;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
}

#authentication .block-categories {
    display: none
}

//modal
.modal-header {
    border-bottom: none;
}

.modal-footer.text-center {
    border-top: 0;
    margin-top: 30px;
}

.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
}

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width: inherit;
    max-width: inherit;
    /* For Bootstrap 4 - to avoid the modal window stretching full width */
    height: inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
}

#search_filters {
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-lightest;
    padding-bottom: 30px;

    h4 {
        // background: $gray-lighter;
        padding: 15px 40px 14px 20px;
        margin-bottom: 20px;
        position: relative;

        //color: $gray-darker;
        i {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

            &.up {
                display: none;
            }
        }

        &.collapsed {
            i {
                &.down {
                    display: none;
                }

                &.up {
                    display: block;
                }
            }
        }

        .clear-all-wrapper {
            font-size: 13px;
            text-transform: lowercase;
            margin-left: 15px;
            //color: $brand-primary;
            cursor: pointer;

            /*&:hover,
            &:focus {
                color: $gray-darker;
            }*/
        }
    }

    .filter-wrapper {
        .filter-inner {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            clear: both;
        }

        .facet {
            flex: 1 1 150px;
            max-width: 250px;
            font-size: 12px;
            line-height: 22px;
            padding-right: 30px;

            .facet_list {
                max-height: 132px;
                overflow: auto;
            }

            .collapse {
                display: block;
            }

            .facet-title {
                margin-bottom: 10px;
                text-transform: uppercase;
            }

            .facet-label {
                margin-bottom: 0;
                font-size: 12px;
                white-space: nowrap;

                a {
                    margin-top: 9px;
                    display: inline-block;

                    /*color: $gray;*/
                    /*&:hover {
                        color: $gray-darker;
                    }*/
                }

                /*&.active {
                    a {
                        color: $gray-darker;
                    }
                }*/
            }
        }
    }
}

div#js-active-search-filters {
    clear: both;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.teste {
    color: blue;
}


/***********************************************************/
/***********************************************************/
/***********************************************************/
/*********************** UPGRADES_V1 ***********************/
/***********************************************************/
/***********************************************************/
/***********************************************************/

p {
    margin-bottom: 8px;
}

$textcolor: #111;

textarea.form-control {
    min-height: 80px;
}

.footer-container .links h3,
.footer-container #block_myaccount_infos h3 a,
.footer-container .contact-rich h4,
#ttcmspaymentlogo .h3,
#contact h4.title,
.contact-rich h4,
.contact-form h3,
#contact .contact-form .form-control-label,
.block-category .h1.title-category,
#left-column .tt-title,
#left-column .text-uppercase,
#right-column .tt-title,
#right-column .text-uppercase,
#left-column .all-product-link,
#right-column .all-product-link,
#left-column .all-product-link i,
#right-column .all-product-link i {
    font-weight: 500;
    color: $textcolor;
    text-transform: initial !important;
}

#hometab .tabs .nav-tabs .nav-link.active .tab-title,
#ttcategorytabs.tabs .nav-tabs .nav-link.active .tab-title,
.tabs .nav-tabs .nav-link.active .tab-title,
#products .product-title a,
.products .product-title a,
.featured-products .product-title a,
.product-accessories .product-title a,
.ttfeatured-products .product-title a,
.crossselling-product .product-title a,
.category-products .product-title a,
.ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a,
.view-product .product-title a,
#product_comparison .product-miniature .product-description .product-title>a,
.tt-menu-horizontal ul li.level-1>a,
.tt-menu-horizontal ul li.level-1 .more-menu .categories,
.product-information,
a:hover,
.block-categories .category-sub-menu li a:hover,
#search_filters .facet .facet-title,
.pagination a,
#search_block_top #search_query_top,
#search_category,
table#product_comparison tbody tr td.td_empty,
table#product_comparison tbody tr td.feature-name,
table#product_comparison tbody tr.comparison_header,
table#product_comparison tbody tr td.td_empty>span,
.tt-menu-horizontal ul li.level-1:hover>a,
.tt-menu-horizontal ul li.level-1:hover .more-menu .categories {
    color: $textcolor;
}

#search_block_top .select-wrapper::before {
    border-color: $textcolor;
}

#newslatter {
    padding: 50px 100px 55px;

    .block_newsletter .tt-title {
        line-height: 70px;

        &::before {
            top: 7px;
        }
    }

    .checkbox-area-modal input[type="checkbox"] {
        margin-right: 5px;
        //margin-left: 20px;
    }

    .checkbox-area-modal {
        margin-left: 20px;
    }

    .block_newsletter form input[type=email] {
        background: #ffffff none repeat scroll 0 0;
        border: 1px solid #ffffff;
        border-radius: 0 25px 25px 0;
        -moz-border-radius: 0 25px 25px 0;
        -webkit-border-radius: 0 25px 25px 0;
        -khtml-border-radius: 0 25px 25px 0;
        float: left;
        padding: 0 125px 0 20px;
        width: 100%;
    }

    .block_newsletter .block_content,
    .checkbox-area-modal {
        margin-bottom: 0;

        .btn {
            border: solid 2px $orange;

            &:hover {
                border: solid 2px $orange;
            }
        }
    }

    .block_newsletter p.alert {
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
    }

    .alert-danger {
        background-color: #c84343;
        border-color: #c84343;
        color: #fff;
    }
}

.ttspecial-list .products .thumbnail-container {
    .ttproduct-image .product-flags {
        margin-left: 15px;
    }

    .product-title>a {
        border: none;
        color: $textcolor;
    }
}

#tt_cat_featured {
    .item {
        border: none;
    }

    .cat-more .cat-more-menu .categories a {
        font-weight: 500;
    }
}

.ttspecial-products {
    margin-bottom: 55px;
}

.brands #ttbrandlogo-carousel {
    margin-bottom: 25px;

    li {
        padding: 10px 25px 0;
    }
}

.ttuserheading,
.middle_nav .wishlist {
    &:hover {
        background: $orange;
    }

    &::after {
        transition: none;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
    }
}

#header .blockcart .header a {
    &::after {
        transition: none;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
    }
}

#header .blockcart .header a:hover::after {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
}

@media screen and (max-width: 768px) {
    .blockcart span.cart-products-count {
        height: 20px;
        width: 20px;
        left: 25px;
        top: 10px;
        line-height: 18px;
        font-size: 11px;
        background-color: $orange;
    }
}

#search_block_top {
    float: none;
    margin: 33px 0;

    @media screen and (min-width: 992px) {
        display: inline-block !important;
    }
}

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus,
:focus,
:active {
    outline: none;
}

#ttcategorytabs {
    .image-category-home {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        //min-height: 446px;
        //min-height: 400px;
    }

    .product-description {
        margin-top: 0;
    }

    .product-price-and-shipping {
        min-height: auto;
    }
}

a.add_to_compare.btn.btn-primary.checked {
    background-position: -37px -88px !important;
    background-color: $textcolor !important;
}

a.addToWishlist.btn.btn-primary.checked {
    background-position: -37px 4px !important;
    background-color: $textcolor !important;
}

.product-add-to-cart {
    a.add_to_compare.btn.btn-primary.checked {
        background-color: transparent !important;
    }

    a.addToWishlist.btn.btn-primary.checked {
        background-color: transparent !important;
    }
}

.footer-container li a,
.contact-rich .block .data,
.ttspecial-products .thumbnail-container .product-title>a {
    font-size: 14px;
}

#header .tt-menu-horizontal ul li.level-1 ul li>a:hover {
    color: $textcolor;
    font-weight: initial;
}

div#product_comments_block_extra {
    margin-bottom: 20px;
}

.product-reference.pro_extra_info {
    font-weight: 500;
}

#header .left .item.free-shipping {
    font-size: 12px;
}

.ttspecial-list {
    img.ttproduct-img1.js-qv-product-cover-img {
        max-width: 80%;
        margin-top: 10%;
    }

    .ttspecial-products .ttspecial-list .product-title {
        margin-bottom: 0 !important;

        &>a {
            font-size: 16px !important;
        }
    }

    .product-description {
        margin-top: 0;
    }
}

.ttfeatured-products .product-grid .thumbnail-container,
.products .product-grid .thumbnail-container .hook-reviews,
.featured-products .product-grid .thumbnail-container,
.category-products .product-grid .thumbnail-container {
    .ttproduct-desc {
        min-height: 90px;
    }

    .hook-reviews {
        position: relative !important;
        bottom: 0
    }

    .product-price-and-shipping {
        min-height: auto;
    }

    .product-description {
        margin-top: 10px;

        .product-title {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }
}

.products .thumbnail-container .ttproduct-image .product-flags {
    padding: 0;
}

#tttop-link-block ul li a {
    padding: 8px 30px;
    line-height: 30px;
}

#tt_cat_featured .content .title a,
#tt_cat_featured .content .sub-cat ul li a,
.tt-innerpagetitle {
    text-transform: initial;
}

#search_filters .filter-wrapper .facet {
    width: 100%;
    max-width: 100%;

    .facet_list {
        overflow: unset !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }
}

#goToTop {
    z-index: 0;
    border: solid 2px #fff;

    &:hover {
        border-color: #fff;
    }

    i {
        padding: 0;
        line-height: 35px;
    }
}

#product_comparison .product-miniature .product-description .tt-button-container .btn.add-to-cart-disable {
    color: $textcolor;
    background: $orange url(../img/product-sprite.png) no-repeat scroll -38px -40px;
}

.ttspecial-products .product-grid .thumbnail-container .ttproducthover .add-to-cart-disable:hover {
    background: $orange url(../img/product-sprite.png) no-repeat scroll -38px -40px !important;
}

#products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable:hover,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable:hover {
    background: $orange url(../img/product-sprite.png) no-repeat scroll -38px -40px !important;
}

.star_on::after {
    color: $orange;
}

.featured-products .all-product-link,
#left-column .all-product-link,
#right-column .all-product-link,
#left-column .all-product-link i,
#right-column .all-product-link i {
    color: $textcolor;
    width: auto;
    float: right !important;
    font-size: 14px;
}

#left-column .all-product-link:hover,
#right-column .all-product-link:hover,
.featured-products .all-product-link:hover {
    text-decoration: none;
    color: $orange;
}

.tt-menu-horizontal ul li.level-1>a span {
    font-weight: 500;
}

.tt-menu-horizontal .main-menu {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 0;
}

body.category-id-parent-1 #wrapper .breadcrumb li::after {
    display: none;
}

.brand-holder {
    .brand-infos {
        h3 {
            margin-top: 5px;
            min-height: 38px;
        }
    }

    .brand_info {
        display: inline-block;

        a {
            display: inline-block;
        }

        .view-products-brands {
            display: inline;
            /* padding-left: 15px; */
            font-weight: 500;
            color: $orange;
        }
    }
}

#js-active-search-filters.active_filters {
    background: transparent;

    .active-filter-title {
        font-weight: 500;
        color: $textcolor;
    }

    ul {
        padding-left: 0;
        display: block;
        margin-bottom: 0;
    }
}

.product_list_active_filters #js-active-search-filters.active_filters {
    display: none;
}

.page-mywishlist {

    #products img,
    .products img {
        width: 100%;
        max-width: 200px;
    }

    .product-miniature {
        margin-bottom: 15px;
    }
}

#ttsearch_eccept_data .title_showall a {
    background: $textcolor none repeat scroll 0 0;
    border: 1px solid $textcolor;
    border-radius: 50px;
    font-weight: 500;

    &:hover {
        background: $orange none repeat scroll 0 0;
        border: 1px solid $orange;
    }
}

.page-my-account {
    .card {
        border: none;
        padding: 40px 0;
    }

    #content .links a {
        width: calc(100% / 4);

        i {
            display: block;
            font-size: 2.6rem;
            width: 100%;
            color: $textcolor;
            padding-bottom: 3.4rem;
        }

        span.link-item {
            color: $textcolor;
            display: block;
            height: 100%;
            box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, .1);
            background: #fff;
            padding: 1rem;
            text-align: center;
            font-weight: 500;
        }

        &:hover {

            span,
            i {
                color: $orange;
            }
        }
    }


}

#products .product-grid .thumbnail-container .hook-reviews,
.products .product-grid .thumbnail-container .hook-reviews {
    position: relative;
    bottom: 0;
}

.product-miniature .thumbnail-container .ttproduct-desc {
    min-height: 95px;
}

.ttpc-main {
    .ttcount {
        font: 400 13px/24px 'Work Sans', sans-serif;
    }
}

#ttcategorytabs .ttcategory .products .product-grid .thumbnail-container .ttproducthover {
    margin-top: 10px;
}

span.discount-in-top-product {
    display: none;
}

body#checkout {
    section.checkout-step {
        .delivery-options {
            margin-bottom: 15px;

            .address-item {
                -webkit-flex: initial;
                -moz-flex: initial;
                -ms-flex: initial;
                flex: initial;
                padding: 5px 15px;
            }

            .delivery-option {
                border-top: none;
                margin-bottom: 0;
                padding: 20px 0;

                label {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    border-top: solid 1px #e5e5e5;
                }
            }

            .carrier-name,
            .carrier-delay,
            .carrier-price {
                margin-bottom: 0;
                line-height: 40px;
            }

            .custom-radio {
                margin-top: 9px;
            }
        }

        .payment-options {
            .additional-information {
                margin-top: 10px;
                margin-bottom: 25px;
            }
        }

        #conditions-to-approve {
            ul {
                margin-top: 20px;
                padding-left: 0;

                a {
                    color: $textcolor;
                    font-weight: 600;
                }
            }
        }

    }
}

.wishlist {
    display: block !important;
}

#accordion {
    .title .h3 {
        font-size: 15px;
        font-weight: 500;
    }

    .no-animation {
        -webkit-transition: height 0.001s;
        -moz-transition: height 0.001s;
        -ms-transition: height 0.001s;
        -o-transition: height 0.001s;
        transition: height 0.001s;
    }
}

.collapsing {
    -webkit-transition: height 0.001s;
    -moz-transition: height 0.001s;
    -ms-transition: height 0.001s;
    -o-transition: height 0.001s;
    transition: height 0.001s;
}

#_desktop_cart {
    cursor: auto;
}

.product-attachments .attachment a {
    font-weight: 500;

    &.attachment-link::after {
        content: "\e226";
        font-family: "Material Icons";
        font-size: 18px;
        position: relative;
        top: 4px;
    }

    &:hover {
        color: $orange;
    }
}

#ttcmsbottombanner,
#ttcmsbanner,
#ttcmssubbanner {
    img {
        height: auto;
    }
}

.ttspecial-products .thumbnail-container {
    .product-title>a {
        font-weight: 400 !important;
        font-size: 14px !important;
    }

    .ttproduct-image {
        border-right: none;
    }
}

#ttcmsfooter #ttfooter-desc {
    margin-bottom: 10px;
}

.product-prices .tax-shipping-delivery-label .delivery-information {
    text-transform: initial;
}

#ttsearch_eccept_data .items-list>li:hover {
    background: transparent;
}

.lista {
    .subcategorie {
        text-align: left;

        h4 {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    .block {
        margin-top: 5px;
    }
}

#ZEQUIPSTD,
#AC-ZEQUIPSTD {
    .product-features>dl.data-sheet {
        dt.name {
            display: none;
        }

        dd.value {
            width: 100%;
            flex: initial;
        }
    }
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
    background-color: transparent !important;
}

#search_block_top .select-wrapper {
    width: 160px;
}

.ttfeatured-products .product-grid .thumbnail-container .product-price-and-shipping {
    min-height: 44px;
}

.page-my-account #content .links a span.link-item {
    text-transform: initial;
    line-height: initial;
}

textarea {
    min-height: 70px;
}

.form-control-comment {
    text-align: right;
}

.quickview {
    .images-container {
        z-index: 999;
    }

    .arrows {
        position: relative;
        top: 0;
        z-index: 9999999;
        right: 65px;
        float: right;

        .material-icons {
            position: absolute;
        }
    }
}

#left-column,
#right-column {
    .products .product-miniature .thumbnail-container .product-description {
        margin-top: 0;
    }
}

.pagination a {
    color: $textcolor;
    height: 40px;
    width: 40px;
    line-height: 27px;
}

.pagination .previous {
    line-height: 27px;
}

#left-column,
#right-column {
    .block-categories .category-sub-menu li[data-depth="0"]>a {
        font-weight: 500;

        &:hover {
            color: $textcolor !important;
        }
    }

    .block-categories .category-sub-menu {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .block-categories .category-sub-menu li a {
        font-size: 13px !important;
    }

    .block-categories .category-sub-menu li a {
        line-height: 20px !important;
        padding: 10px 0 0;
    }

    .block-categories .category-sub-menu li[data-depth="1"],
    .block-categories .category-sub-menu li[data-depth="2"] {
        padding: 8px 0 0 8px;
    }

    .block-categories .category-sub-menu li {
        padding: 0 4px;
    }

    .block-categories .category-sub-menu .collapse-icons {
        right: 5px;
        top: 10px;
    }

    .block-categories .arrows .arrow-right,
    .block-categories .arrows .arrow-down {
        position: relative;
        top: 6px;
    }
}

.blockcart span.cart-products-count {
    z-index: 99;
}

.products-selection {
    .products-sort-order .dropdown-menu {
        padding: 5px;
    }
}

#left-column .tt-title,
#left-column .text-uppercase,
#right-column .tt-title,
#right-column .text-uppercase {
    margin-bottom: 0;
}

#products .product-list .thumbnail-container {
    .ttproducthover div.tt-button-container .btn.ajax_add_to_cart_button:hover {
        background: none;
    }

    .product-add-to-cart {
        margin-bottom: 20px;
    }

}

#header .full-header.fixed {
    float: left;
    left: 0;
    margin: 0;
    position: fixed !important;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    border: none;
}

#ttcmsbanner {
    margin-bottom: 65px;

    .ttbanner .ttbanner-img {
        a {
            display: inline-block;
            overflow: hidden;
            position: relative;
            vertical-align: top;
        }

        a::before {
            bottom: 0;
            content: "";
            left: 50%;
            opacity: 1;
            -moz-opacity: 1;
            -khtml-opacity: 1;
            -webkit-opacity: 1;
            position: absolute;
            right: 51%;
            top: 0;
            background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
        }

        &:hover a::before {
            left: 0;
            opacity: 0;
            -moz-opacity: 0;
            -khtml-opacity: 0;
            -webkit-opacity: 0;
            right: 0;
            transition: all 900ms ease-in-out 0s;
            -webkit-transition: all 900ms ease-in-out 0s;
            -moz-transition: all 900ms ease-in-out 0s;
            -o-transition: all 900ms ease-in-out 0s;
            -ms-transition: all 900ms ease-in-out 0s;
        }

        a::after {
            bottom: 50%;
            content: "";
            left: 0;
            opacity: 1;
            -moz-opacity: 1;
            -khtml-opacity: 1;
            -webkit-opacity: 1;
            position: absolute;
            right: 0;
            top: 50%;
            background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
        }

        &:hover a::after {
            bottom: 0;
            opacity: 0;
            -moz-opacity: 0;
            -khtml-opacity: 0;
            -webkit-opacity: 0;
            top: 0;
            transition: all 900ms ease-in-out 0s;
            -webkit-transition: all 900ms ease-in-out 0s;
            -moz-transition: all 900ms ease-in-out 0s;
            -o-transition: all 900ms ease-in-out 0s;
            -ms-transition: all 900ms ease-in-out 0s;
        }
    }
}

.products .product-list.product-miniature {
    padding: 30px 15px 0;
    margin-bottom: 0;
}

#products {
    .product-list .ttproduct-desc .product-description {
        border-right: none;
        margin-top: 0;
    }

    .product-thumbs .product-list .thumbnail-container .ttproduct-image .product-thumbnail {
        border: none;
    }
}

#identity,
#authentication {
    #main {
        .custom-checkbox {
            display: block;
            float: left;

            span.label {
                padding-left: 5px;
                width: 100%;
                text-align: left;
                color: $textcolor;
            }
        }

        .radio-inline {
            margin-right: 7px;
        }

        .custom-checkbox input[type="checkbox"],
        input[type="checkbox"] {
            margin-top: 0;
            width: 20px !important;
            height: 20px !important;
        }

        .custom-checkbox input[type="checkbox"]+span {
            min-width: 20px !important;
            min-height: 20px !important;
        }

        .custom-checkbox span.label p {
            float: left;
            display: contents;
            color: $textcolor;
        }
    }
}

#AC-manufacturer .continue.btn.btn-default {
    white-space: pre-wrap;
}

#search_filters {
    .filter-wrapper .facet #facet_list li {
        &:first-child {
            padding-top: 0;
        }

        padding-top: 10px;
    }

    .facet {
        padding: 5px 0px !important;
    }

    .filter-wrapper .facet .facet-label a {
        margin-top: 0px;
        width: 90%;
        float: left;
        white-space: normal;
    }
}

.tt-producttitle,
#wrapper .breadcrumb ol li:last-child {
    text-transform: none;
}

#categories .category-active {
    color: $orange !important;

    &:hover {
        color: $orange !important;
    }
}

a.back-to-parent-category {
    color: $orange;
    font-weight: 500;
    position: relative;
    margin-bottom: 10px;
    float: left;
    clear: both;
    text-transform: uppercase;

    &:before {
        content: "\E313";
        font-family: "Material Icons";
        vertical-align: top;
        margin-right: 2px;
        display: inline-block;
        transform: rotate(90deg);
        font-size: 18px;
    }
}

.category-id-2 a.back-to-parent-category {
    display: none;
}

ul.lista {
    column-count: auto;
    display: flex;
    flex-wrap: wrap;

    li {
        padding-bottom: 5px;
        text-align: left;
    }
}

.product-attachments img,
.manufacturer-logo {
    margin-left: 10px;
}

#newslatter .block_newsletter .block_content .btn:focus {
    border: solid 2px $orange;
}

.product-line-grid {
    .product-line-grid-right {

        .cart-line-product-actions .remove-from-cart,
        .product-price .remove-from-cart {
            display: inline-grid;
        }

        .cart-line-product-actions,
        .product-price {
            line-height: 30px;
            display: inline-table;
            float: left;
        }

        .cart-line-product-actions .remove-from-cart,
        .product-price .remove-from-cart {
            margin-top: 15px;
        }

        .text-xsright {
            text-align: right;
        }
    }

    .product-line-grid-left {
        margin-bottom: 1rem;
    }
}

#module-ttcompare-compare .products_block.table-responsive {
    margin-bottom: 30px;
}

.order-confirmation-table {
    font-weight: 500;
    color: $textcolor;
}

.definition-list dl,
#content-hook_payment_return .card-block dl {
    display: inline-flex;

    dt,
    dd {
        width: 50%;
        float: left;
    }
}

#order-confirmation .card.definition-list {
    margin: 0 0 30px;
}

#ttsearch_eccept_data .items-list {
    display: flex;
    flex-wrap: wrap;
}

.tt-menu-horizontal ul li:hover>a span {
    font-weight: 600;
}

#_desktop_user_info {
    margin: 35px 25px;
}

.full-header.fixed #_desktop_user_info {
    margin: 20px 25px;
}

#header .blockcart {
    padding: 30px 0;
}

.full-header.fixed #_desktop_cart .blockcart {
    padding-top: 15px;
}

#mywishlist .products {
    float: initial;
    margin-bottom: 15px;
}

#ttsearch_eccept_data {
    .title_showall_text {
        margin-bottom: 14px;
    }

    .items-list li .product-name {
        font: 400 14px/15px 'Work Sans', sans-serif;
        text-transform: inherit;
    }

    .content-ajax-search {
        overflow: initial;
        height: 100%;
    }

    .items-list .ttsearch-content {
        padding-right: 0;
    }
}

.table-labeled .label {
    &.label-pill.bright {
        color: #fff;
    }

    &.label-pill.dark {
        color: $textcolor;
    }
}

.page-mywishlist>.row {
    column-count: auto;
    display: inline-flex;
    flex-wrap: wrap;
}

#ttsearch_eccept_data .items-list {
    margin-bottom: 10px;
}

.block-social ul {
    padding-left: 0;

    li:first-of-type {
        margin: 0 !important;
    }
}

.usefulness_btn {
    top: -3px;
    position: relative;
}

#accordion {

    .title {
        margin-bottom: 3px !important;

        .h3 {
            top: 5px;
            position: relative;
        }
    }

    #AC-attachments .attachment a {
        font-size: 14px;
        color: $textcolor;
        font-weight: 500;

        &::after {
            content: "\e226";
            font-family: "Material Icons";
            font-size: 18px;
            position: relative;
            top: 4px;
        }
    }
}

.cat-name {
    text-transform: initial;
}

.product-features>dl.data-sheet dt.name {
    text-transform: inherit;
}

table#product_comparison .thumbnail-container .product-thumbnail {
    border: none;
}

#products .thumbnail-container,
.products .thumbnail-container,
.featured-products .thumbnail-container,
.product-accessories .thumbnail-container,
.ttfeatured-products .thumbnail-container,
.crossselling-product .thumbnail-container,
.ttbestseller-products .thumbnail-container,
.ttnew-products .thumbnail-container,
.ttspecial-products .thumbnail-container,
.category-products .thumbnail-container,
.view-product .thumbnail-container {
    padding: 10px;
}

ul.product-flags li {
    float: left;
    margin: 0 4px 4px 0;
}

.btn-secondary.addmoretocompare {
    margin-right: 10px;
    color: #fff !important;
}

#wrapper .breadcrumb ol li:last-child {
    display: initial;
}


.gdprModal {
    width: 0%;

    .gdprModal__placeholder {
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 9999999;

        .gdpr-consent-tabs {
            text-align: center;
            background-color: rgba(0, 0, 0, 0.6) !important;
            padding: 15px;

            .div_accept_moreinfo {
                text-align: left;
            }

            #accept-all-gdpr {
                color: $textcolor !important;
                background: #fff !important;
                border: none;
                margin-left: 10px;

                &:hover {
                    color: #fff !important;
                    background: $orange !important;
                }
            }

            .div_control_your_privacy p {
                color: #fff !important;
                margin-bottom: 0;
            }
        }

        .gdpr-consent-tabs-v2 {
            .div_control_your_privacy {
                display: inline-block;
            }

            .div_accept_moreinfo {
                display: inline-block;

            }
        }

        .link-condicoes {
            text-decoration: underline;
            color: white;
        }

        &:hover {
            color: white;
        }
    }
}

#newslatter .ps-17-newsletter-checkbox {
    margin-left: 20px;
    margin-top: 10px;
    display: inline-flex;

    p {
        padding: 0;
        padding-left: 10px;
        margin: 0 !important;
        margin-top: 0 !important;
        color: #fff;
        font-size: 14px;
        text-align: left;
    }

    input[type="checkbox"] {
        margin-top: 0;
        border: 1px solid #FFF;
        vertical-align: middle;
        margin-bottom: 2px;
        min-width: 15px;

        &:checked:after {
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
        }
    }
}

#products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
.products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
#product_comparison div.tt-button-container .btn.add-to-cart-disable {
    opacity: 0.5;
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    -webkit-opacity: 0.5;
}

/***************/
/* new filters */
/***************/

#search_filters .filter-wrapper .facet .facet-title {
    cursor: pointer;
    width: 100%;
}

#js-active-search-filters.active_filters {
    margin-bottom: 0;
}

#category #left-column #search_filters,
#category #right-column #search_filters {
    .facet ul {
        max-height: 100%;

        &.collapse {
            display: none;
        }

        &.collapse.in {
            display: block;
        }

        li:nth-child(n+6) {
            display: none;
        }

        &.active>li:nth-child(n+6) {
            display: block;
        }

        .show_less {
            display: none;
        }

        &.active {
            .show_less {
                display: block
            }

            .show_all {
                display: none;
            }
        }

        a.showall span {
            color: $orange;
        }
    }

    .outer-facet {
        display: inline-flex;
        width: 100%;

        .navbar-toggler {
            float: right;
            top: 5px;
            position: relative;
            cursor: pointer;

            &.collapse-icons .material-icons {
                font-size: 15px;
                color: $textcolor !important;
            }

            .remove {
                display: none;
            }

            &.collapse-icons[aria-expanded="true"] .add {
                display: none;
            }

            &.collapse-icons[aria-expanded="true"] .remove {
                display: inline-block;
            }
        }
    }
}

#ttcomment-modal .modal-dialog .modal-header h2 {
    font-weight: 400;
    color: #777777;
    font-size: 14px;
}

#products .product-title,
.products .product-title,
.featured-products .product-title,
.product-accessories .product-title,
.ttfeatured-products .product-title,
.crossselling-product .product-title,
.ttbestseller-products .product-title,
.ttnew-products .product-title,
.ttspecial-products .product-title,
.category-products .product-title,
.view-product .product-title {
    height: 75px;
}

#js-product-list .product-thumbs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

#tt_category_feature #tt_cat_featured {
    .item .content .ttcat-content {
        padding-top: 0;
    }

    .content .sub-cat ul li a {
        font-size: 13px;
        font-weight: 300;
    }

    .cat-infor .title,
    ul {
        padding-left: 0;
    }
}

.ttloading-bg {
    z-index: 99999;
}

.livro-reclamacao {
    img {
        width: 100px;
        height: auto;
        margin-top: 20px;
    }
}

#footer .footer-container #ttfooter-desc .block-social {
    text-align: left;
    margin-top: 10px;
}

#footer .footer-container .links {
    margin: 50px 0 20px;
}

#footer {
    .ttcontent {
        padding: 10px 0;

        .col-md-6.text-left {
            padding: 0;

            .block-social,
            p {
                text-align: left;
            }

            p {
                padding-top: 30px;
            }
        }

        .col-md-6.text-right {
            padding: 0;
            text-align: right;
        }
    }

}

.img-payments {
    img {
        height: auto;
        width: 300px;
        margin: 10px 0;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        transition: all ease 0.3s;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -ms-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
    }

    img:hover {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
    }
}

.footer-container .links h3,
.footer-container #block_myaccount_infos h3 a,
.footer-container .contact-rich h4,
#ttcmspaymentlogo .h3 {
    padding-left: 0;
}

.cms-id-6 .main-cat {
    width: 100%;
}

#left-column {
    padding-right: 0;
}

#amazzing_filter.block {

    .title {
        margin-bottom: 5px;
        background: #f1f1f1;
        color: $textcolor;
        font: 500 15px/25px 'Work Sans', sans-serif;
        padding: 0 !important;
        margin: 0;
        width: 100%;
        text-align: left;
        text-transform: initial !important;
    }

    .af_subtitle {
        color: $textcolor;
        font: 500 15px/20px 'Work Sans', sans-serif;

        &::before {
            border-color: $textcolor;
            top: 19px;
        }
    }

    .closed .af_subtitle:before {
        top: 15px;
    }

    .af_filter .name {
        padding-left: 0;
        color: #888888;
        display: inline-block;
        font-size: 13px;
        line-height: 20px;
        text-align: left;

        &:hover {
            color: $textcolor;
        }
    }

    .af_filter .count {
        left: -3px;
        font-size: 10px;
    }

    .af_filter .af-toggle-child {
        padding: 0;
    }

    .af_filter ul {
        padding-left: 0px;
    }

    .slider-container .back-bar .selected-bar {
        background: $orange;
    }

    .af_filter,
    .af_filter.type-3.closed {
        margin-bottom: 0;
        border: none;
    }

    .clearfix {
        float: left;
        width: 100%;
        padding: 0px 5px 10px;
    }

    .af_filter select {
        font-size: 12px;
    }

    .selectedFilters {
        margin: 10px 0;

        a.icon-eraser.all {
            padding-right: 5px;
        }

        .clearAll {
            margin-bottom: 5px;
        }

        &>div {
            background: transparent;
        }

        .cf {
            margin-left: 5px;
            margin-bottom: 5px;
        }
    }

    .af_filter .toggle-cut-off {
        position: relative;
    }

    .compact-toggle {
        left: -61px;
        top: 65%;
        font-size: 18px;
        border: solid 2px white;
    }

    #amazzing-collapse {
        float: left;
        width: 100%;
    }

    .af_filter .toggle-cut-off span {
        display: none;
    }

    /*.w-border {
        border-top: solid 1px #f1f1f1;
        margin-top: 10px;
    }*/
    .special {
        padding-bottom: 0;
        text-transform: uppercase;
    }
}

.show-filter #amazzing_filter.block .compact-toggle {
    left: -55px;
}

.has-compact-filter #amazzing_filter .compact-toggle {
    display: none !important;
}

.has-compact-filter.show-filter #amazzing_filter {
    width: 90%;
}

#AC-reviews .tab-pane {
    opacity: 1 !important;
}

#manufacturer.total-brands {
    #left-column {
        display: none;
    }

    #content-wrapper {
        width: 100% !important;
    }
}

.full-header {
    z-index: 4;
}

/* new prices */
.sale-percentage,
.product-price-and-shipping .discount-percentage.discount-product {
    color: $orange;
    font-weight: 500;
}

.price.special-price,
#search_block_top .product-price-and-shipping .price {
    font-weight: 500;
}

/* new prices end */

#ttcategorytabs {
    .product-price-and-shipping {
        min-height: 44px;
    }

    .product-miniature .hook-reviews {
        display: none;
    }
}

/* set up faqs */

#content.page-cms-7 {
    padding-top: 0;
}

.heading-primary {
    font-size: 16px;
    padding: 20px 10px;
    text-align: center;
}

.accordion dl,
.accordion-list {
    border: 1px solid #ddd;

    &:after {
        content: "";
        display: block;
        height: 1em;
        width: 100%;
    }
}

.accordion dd,
.accordion__panel {
    background-color: #eee;
    font-size: 1em;
    line-height: 1.5em;
}

.accordion p {
    padding: 1em 2em 1em 2em;
}

.accordion {
    position: relative;
    background-color: #eee;
}

.accordionTitle,
.accordion__Heading {
    font-weight: 500;
    padding: 10px;
    display: block;
    text-decoration: none;
    color: $textcolor;
    background-color: #f4f4f4;
    text-align: left;
    transition: background-color 0.5s ease-in-out;
    margin-top: 15px;
    position: relative;
    padding-right: 45px;
}

.accordionItem {
    height: auto;
    overflow: hidden;
    max-height: 50em;
    transition: max-height 1s;

    @media screen and (min-width:48em) {
        max-height: 15em;
        transition: max-height 0.5s
    }

}

.accordionItem.is-collapsed {
    max-height: 0;
}

.no-js .accordionItem.is-collapsed {
    max-height: auto;
}

.animateIn {
    animation: accordionIn 0.45s normal ease-in-out both 1;
}

.animateOut {
    animation: accordionOut 0.45s alternate ease-in-out both 1;
    -webkit-animation: accordionOut 0.45s alternate ease-in-out both 1;
}

.accordion-title {
    .material-icons {
        pointer-events: none;
        display: none;
        top: 7px;
        right: 10px;
        position: absolute;
    }

    &.is-collapsed {
        .material-icons.add {
            display: block;
        }
    }

    &.is-expanded {
        .material-icons.remove {
            display: block;
        }
    }

}

.accordionItem {
    &.is-collapsed {
        display: none;
    }

    &.is-expanded {
        display: block;
    }
}

dd.accordion-content {
    padding: 10px;
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
}

/* faqs end */

#manufacturer .brand-holder {
    padding: 40px 10px;

    @media screen and (max-width: 480px) {
        padding: 20px 10px;
    }
}

.product-unit-price.sub {
    font-size: 12px;
    padding-top: 3px;
    clear: both;
    text-transform: lowercase;
}

.product-line-grid-body>.product-line-info>.unit-price-cart {
    clear: both;
    font-size: 12px;
    padding-left: 0;
    color: #777;
    text-transform: lowercase;
}

.cart-overview .product-line-info .unit-price-cart {
    font-size: 12px;
    color: #777;
    line-height: inherit;
    clear: both;
    text-transform: lowercase;
}

.has-compact-filter .block_newsletter .block_content {
    padding: 0;

    form {
        padding-bottom: 0;
    }
}

#category .amazzing_filter.compact-toggle {
    width: 40px;
    height: 40px;
    line-height: 42px;
    text-align: center;
    font-size: 17px;
    padding: 0;
    box-shadow: none;
    //test
    background: #fff !important;
    left: auto;
    right: 20px;
    bottom: 100px !important;
    border: solid 2px $orange !important;
    transition: opacity 0.2s ease-out;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -ms-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    position: fixed;
    display: none;

    &::before {
        content: '\f1de';
        font-family: "FontAwesome";
        color: $orange;
        position: relative;
        top: -3px;
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}

#category.has-compact-filter.show-filter .amazzing_filter.compact-toggle:before {
    content: '\f105';
    font-family: "FontAwesome";
    border-color: $orange !important;
    width: 10px !important;
    height: 10px !important;
    margin: 13px 11px !important;
    position: relative;
    top: -2px;
    font-size: 20px;
}

#category.has-compact-filter .amazzing_filter.compact-toggle {
    display: block;
}

#category.show-filter .amazzing_filter.compact-toggle {
    left: 20px;
    z-index: 99999;
}

body#checkout section.checkout-step .payment-options label {
    text-align: left;
}

#hometab .tabs .nav-tabs .nav-link,
#ttcategorytabs .nav-tabs .nav-link,
.tabs .nav-tabs .nav-link {
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
}

#dvContainer {
    font-family: 'Work Sans', sans-serif;
}

#ttcomment-modal .product img {
    border: none;
}

.sort-by-col .sort-by-row {
    float: right;
}

.products-sort-order.dropdown {
    min-width: 220px;
    max-width: 220px;
    float: left;
    margin-left: 5px;
}

.products-selection .total-products {
    padding: 0;

    .pad-top-10 {
        padding-top: 10px;
    }

    [class^='col-lg'],
    [class^='col-md'] {
        padding-left: 0;
        display: flex;
    }

    .grid-list {
        display: inline;
    }

    .nice-select {
        border-radius: 0;
        padding: 8px 10px;
        color: #111;
        line-height: initial;
        width: 60px;
        display: inline-block;

        span {
            top: 3px;
            position: relative;
        }

        &.open::after {
            transform: rotate(0);
        }

        &::after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $textcolor;
            border-bottom: 0;
            margin-top: -3px;
            transform: initial;
        }
    }

    .nice-select:active,
    .nice-select.open,
    .nice-select:focus {
        border: solid 1px #e9e9e9;
    }

    .nice-select .list {
        margin: 0;
        transition: none;
        background: #e9e9e9;
        border: solid 1px #e9e9e9;
        border-radius: 0;
        padding: 5px !important;

        .option {
            padding-left: 10px;

            &.selected {
                font-weight: inherit;
                transition: none;
                -webkit-transition: none;
                -moz-transition: none;
                -ms-transition: none;
                -o-transition: none;
            }
        }
    }

    .nice-select .option:hover,
    .nice-select .option.selected.focus:hover {
        background: $textcolor;
        color: #fff;
    }

    .nice-select .option.focus,
    .nice-select .option.selected.focus {
        background: transparent;
        color: $textcolor;
    }

    .products-selection .total-products p {
        display: table;
    }

    .products-per-page {
        display: inline-block;
    }
}

.pagination .disabled {
    pointer-events: none;
}

.tt-menu-horizontal .menu-dropdown {
    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
}

.products-selection .total-products .nice-select.hidden {
    display: none;
}

#ttcmssubbanner {
    margin-bottom: 65px;
}

#ttcategorytabs {
    margin-bottom: 50px;
}

.ttcategory .owl-wrapper-outer .owl-wrapper .owl-item>ul,
#hometab {
    margin-bottom: 15px;
}

.brands {
    margin-bottom: 50px;
}

.ttproductpagetab {
    margin: 65px 0 40px;
}

input[type="checkbox"] {
    border: 1px solid #777;
}

.contact-form {
    .nice-select {
        border-radius: 0;
        padding: 8px 10px;
        color: #111;
        line-height: initial;
        width: 100%;
        display: inline-block;

        span {
            top: 3px;
            position: relative;
        }

        &.open::after {
            transform: rotate(0);
        }

        &::after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $textcolor;
            border-bottom: 0;
            margin-top: -3px;
            transform: initial;
        }
    }

    .nice-select:active,
    .nice-select.open,
    .nice-select:focus {
        border: solid 1px #e9e9e9;
    }

    .nice-select .list {
        margin: 0;
        transition: none;
        background: #e9e9e9;
        border: solid 1px #e9e9e9;
        border-radius: 0;
        padding: 5px !important;

        .option {
            padding-left: 10px;

            &.selected {
                font-weight: inherit;
            }
        }
    }

    .nice-select .option:hover,
    .nice-select .option.selected.focus:hover {
        background: $textcolor;
        color: #fff;
    }

    .nice-select .option.focus,
    .nice-select .option.selected.focus {
        background: transparent;
        color: $textcolor;
    }
}

#my-account .footer-top {
    margin-top: 65px;
}

#my-account #main .page-footer .text-sm-center a {
    padding: inherit;
    background: none;
}

.page-customer-account #content {
    border: none;
}

/* .ttloading-bg {
    display: none;
} */

.contact-form div#g-recaptcha>div {
    float: right;
}

.btn-danger {
    border-radius: 25px;
}

#mywishlist .wishlistLinkTop #showSendWishlist {
    margin-bottom: 30px;
}

#block-order-detail .product-container {
    padding: 15px;
    border: 1px solid #e5e5e5;
    float: left;

    .product-title {
        height: 40px;
    }

    .thumbnail-container {
        border: none;
    }

    .display-inline {
        display: inline-flex;

        .material-icons {
            line-height: 40px;
            margin-right: 15px;
        }
    }
}

#block-order-detail {
    .products>.row {

        display: table;

        article {
            display: table-cell;
            margin-bottom: 35px;
        }
    }
}

#tt-menu-horizontal.tt-menu-horizontal ul li.parent.empty>a:after {
    content: none;
}

/***********************************************************/
/***********************************************************/
/***********************************************************/
/********************* END UPGRADES_V1 *********************/
/******************* menu-all-categories *******************/
/***********************************************************/
/***********************************************************/
/***********************************************************/

#tt-menu-horizontal ul.menu-dropdown li:nth-child(n+12) {
    display: block;
}

@media screen and (min-width: 991px) {
    #tt-menu-horizontal.tt-menu-horizontal {

        .menu-dropdown.tt-sub-auto,
        li.level-2 .menu-dropdown {
            display: none;
        }

        ul li>a {
            color: #333;
            font: 400 13px/24px 'Work Sans', sans-serif;
            padding: 10px 20px;
            position: relative;
            float: left;
            width: 100%;
            text-align: left;
        }

        .level-2-ul {
            float: left;
            width: 340px;
            position: absolute;
            background: #e5e5e5;
            left: 303px;
            overflow-y: auto;
            top: 0;
            display: none;
            padding-left: 0;
            padding-right: 0;
            direction: rtl;

            &.active {
                display: block;
            }
        }

        .level-3-ul {
            float: left;
            width: 320px;
            position: absolute;
            background: #ccc;
            left: 613px;
            overflow-y: auto;
            top: 0;
            display: none;
            padding-left: 0;

            &.active {
                display: block;
            }
        }

        .level-4-ul {
            float: left;
            width: 320px;
            position: absolute;
            background: #aaa;
            left: 613px;
            overflow-y: auto;
            top: 0;
            display: none;
            padding-left: 0;

            &.active {
                display: block;
            }
        }

        ul li.parent>a:after {
            color: #b3b3b3;
            content: "\E313";
            font-family: "Material Icons";
            margin-left: 3px;
            vertical-align: top;
            display: inline-block;
            float: right;
            transform: rotate(270deg);
            font-size: 18px;
        }

        .level-1.parent.hover {
            background-color: #e5e5e5;
        }

        ul.level-2-ul li.hover {
            background-color: #ccc;
        }

        ul.level-2-ul li,
        ul.level-3-ul li,
        ul.level-4-ul li {
            display: none;
            float: left;
            width: 100%;

            &.active-li {
                display: block;
            }
        }

        ul.level-3-ul li.active-li {
            float: left;
            width:100%;
            
        }

        ul.level-4-ul li.active-li {
            float: left;
            width: 100%;
            
        }

    }
}

@media screen and (max-width: 991px) {
    #tt-menu-horizontal.tt-menu-horizontal {

        .level-2-ul,
        .level-3-ul,
        .level-4-ul {
            display: none;
        }
    }
}

#tt-menu-horizontal.tt-menu-horizontal {

    ul li.level-1>a,
    ul li.level-1 .more-menu .categories,
    ul li>a {
        padding: 7px 15px;
    }
}

/***********************************************************/
/***********************************************************/
/***********************************************************/
/***************** end menu-all-categories *****************/
/********************** StartCss Blog **********************/
/***********************************************************/
/***********************************************************/
/***********************************************************/


#module-smartblog-details {
    .float-left {
        float: left;

        &.pad-left-10 {
            padding-left: 10px;

            .name {
                font-weight: 600;
                color: $textcolor;
            }
        }
    }

    div#lipsum {
        text-align: center;
    }

    #sdsblogArticle .post-info {
        text-align: center;
        margin: 20px 0 20px;
    }

    div#articleComments {
        padding-top: 20px;
    }

    img.avatar {
        max-width: 40px;
    }

    ul.commentList {
        padding-left: 0;
        margin-bottom: 20px;

        li,
        .reply {
            clear: both;
        }

        .reply {
            margin-bottom: 20px;
        }

        ul.commentList {
            ul.commentList {
                padding-left: 30px;
            }
        }
    }
}

/***********************************************************/
/***********************************************************/
/***********************************************************/
/******************** End StartCss Blog ********************/
/************************* Newfont *************************/
/***********************************************************/
/***********************************************************/
/***********************************************************/

$grey-7: #777777;

#header .left .item.free-shipping {
    font-weight: 500;
}

#newsletter_block_popup .checkbox-area-modal span,
#newsletter_block_popup .newsletter_block_popup-bottom .tt_newsletter_dont_show_again {
    font-size: 12px;
    font-weight: 400;
    cursor: initial;
    color: #777777;
}

#newslatter .ps-17-newsletter-checkbox p {
    font-size: 14px;
    position: relative;
    top: -2px;
}

.tthometab-title,
#hometab .tab-title,
#ttcategory-tabs .nav-link .tab-title,
.tabs .nav-link .tab-title,
.tt-title,
.brands .products-section-title a {
    text-transform: none !important;
}

#products .product-miniature .product-flags .new,
#products .product-miniature .online-only,
#products .product-miniature .on-sale,
#products .product-miniature .discount-percentage,
.products .product-miniature .product-flags .new,
.products .product-miniature .online-only,
.products .product-miniature .on-sale,
.products .product-miniature .discount-percentage,
.featured-products .product-miniature .product-flags .new,
.featured-products .product-miniature .online-only,
.featured-products .product-miniature .on-sale,
.featured-products .product-miniature .discount-percentage,
.product-accessories .product-miniature .product-flags .new,
.product-accessories .product-miniature .online-only,
.product-accessories .product-miniature .on-sale,
.product-accessories .product-miniature .discount-percentage,
.ttfeatured-products .product-miniature .product-flags .new,
.ttfeatured-products .product-miniature .online-only,
.ttfeatured-products .product-miniature .on-sale,
.ttfeatured-products .product-miniature .discount-percentage,
.view-product .product-miniature .product-flags .new,
.view-product .product-miniature .online-only,
.view-product .product-miniature .on-sale,
.view-product .product-miniature .discount-percentage,
.crossselling-product .product-miniature .product-flags .new,
.crossselling-product .product-miniature .online-only,
.crossselling-product .product-miniature .on-sale,
.crossselling-product .product-miniature .discount-percentage,
.category-products .product-miniature .product-flags .new,
.category-products .product-miniature .online-only,
.category-products .product-miniature .on-sale,
.category-products .product-miniature .discount-percentage,
.ttbestseller-products .product-miniature .product-flags .new,
.ttbestseller-products .product-miniature .online-only,
.ttbestseller-products .product-miniature .on-sale,
.ttbestseller-products .product-miniature .discount-percentage,
.ttnew-products .product-miniature .product-flags .new,
.ttnew-products .product-miniature .online-only,
.ttnew-products .product-miniature .on-sale,
.ttnew-products .product-miniature .discount-percentage,
.ttbestseller-products .product-miniature .product-flags .new,
.ttbestseller-products .product-miniature .online-only,
.ttbestseller-products .product-miniature .on-sale,
.ttbestseller-products .product-miniature .discount-percentage,
.ttnew-products .product-miniature .product-flags .new,
.ttnew-products .product-miniature .online-only,
.ttnew-products .product-miniature .on-sale,
.ttnew-products .product-miniature .discount-percentage,
.ttspecial-products .product-miniature .product-flags .new,
.ttspecial-products .product-miniature .online-only,
.ttspecial-products .product-miniature .on-sale,
.ttspecial-products .product-miniature .discount-percentage,
.ttspecial-products .product-miniature .product-flags .new,
.ttspecial-products .product-miniature .online-only,
.ttspecial-products .product-miniature .on-sale,
.ttspecial-products .product-miniature .discount-percentage,
#products .product-miniature .discount,
.products .product-miniature .discount,
.featured-products .product-miniature .discount,
.product-accessories .product-miniature .discount,
.ttbestseller-products .product-miniature .discount,
.ttnew-products .product-miniature .discount,
.ttspecial-products .product-miniature .discount,
.ttfeatured-products .product-miniature .discount,
.crossselling-product .product-miniature .discount,
.category-products .product-miniature .discount,
.view-product .product-miniature .discount {
    font-weight: 500;
    font-size: 10px;
    height: 20px;
    letter-spacing: 0.5px;
    line-height: 20px;
}

#tt_category_feature #tt_cat_featured .cat-infor .title {
    line-height: inherit;
    padding-bottom: 0;
    margin-bottom: 0;
}

#tt_category_feature #tt_cat_featured ul {
    padding-right: 0;
}

#tt_category_feature .cat-infor .title::after {
    content: none;
}

.tt-menu-horizontal ul li.level-1>a span {
    font-weight: 400;
    text-transform: initial;
}

.tt-menu-horizontal ul li:hover>a span,
#tt-menu-horizontal.tt-menu-horizontal .level-1.parent.hover a span,
#tt-menu-horizontal.tt-menu-horizontal ul.level-2-ul li.hover a span {
    font-weight: 500;
}

.tt-menu-horizontal ul li.level-1.more>a,
.tt-menu-horizontal ul li.level-1 .more-menu .categories {
    font-weight: 600;
}

#left-column .tt-title,
#left-column .text-uppercase,
#right-column .tt-title,
#right-column .text-uppercase,
#amazzing_filter.block .af_subtitle {
    font-size: 14px;
}

#amazzing_filter.block .af_filter .name,
.af_filter .af-toggle-child {
    color: $grey-7;
}

.af_filter .af-toggle-child {
    font-weight: 400 !important;
    position: relative;
    top: 1px;
}

.nbrItemPage span {
    top: 2px;
    position: relative;
}

#amazzing_filter.block .special {
    text-transform: initial;
}

.page-my-account #content .links a span.link-item {
    font-size: 14px;
}

#identity #main .custom-checkbox span.label,
#authentication #main .custom-checkbox span.label,
#identity #main .custom-checkbox span.label p,
#authentication #main .custom-checkbox span.label p {
    font-size: 14px;

}

#main>.card,
#mywishlist>.card {
    border: none;
}

.page-my-account #content .links {
    padding: 0 8px;
}

.form-control-comment {
    font-size: 10px;
    color: $grey-7;
}

.tabs .nav-link .tab-title {
    text-transform: lowercase !important;

    &::first-letter {
        text-transform: uppercase !important;
    }
}

.h4 {
    font-weight: 500;
}

.form-control {
    font-family: 'Work Sans', sans-serif;
}

.page-addresses .addresses-footer {
    margin: 25px 10px 0;
}

#block-history {
    padding: 1.25rem;
    padding-top: 0;
}

.card,
#block-history {
    a {
        font-weight: 500;
    }
}

.tt-innerpagetitle {
    font-weight: 500;
}

.page-subheading {
    font-weight: 500;
}

.cms-id-6 {
    h2 {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-transform: inherit;
    }

    .subcategorie h4 {
        font-weight: 500;
    }

    .main-cat h3 a {
        text-transform: initial;
    }
}

/***********************************************************/
/***********************************************************/
/***********************************************************/
/********************* UltimateUpgrade *********************/
/***********************************************************/
/***********************************************************/
/***********************************************************/

.customNavigation a {
    border: none;
}

.customNavigation a:hover.prev {
    background-position: -10px -353px;
    background-color: transparent;
}

.customNavigation a:hover.next {
    background-position: -10px -386px;
    background-color: transparent;
}

.inline-quantity,
.inline-size {
    display: inline;
    float: left;
}

.inline-quantity .product-quantity.clearfix {
    display: inline-flex;
}

.product-variants-item {
    margin-right: 20px;

    .nice-select {
        border-radius: 0;
        padding: 8px 10px;
        color: #111;
        line-height: initial;
        width: 65px;
        display: inline-block;
        float: left;
        height: 44px;
        line-height: 20px;

        .option {
            line-height: 30px;
            min-height: 30px;
        }

        span {
            top: 3px;
            position: relative;
        }

        &.open::after {
            transform: rotate(0);
        }

        &::after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $textcolor;
            border-bottom: 0;
            margin-top: -3px;
            transform: initial;
        }
    }

    .nice-select:active,
    .nice-select.open,
    .nice-select:focus {
        border: solid 1px #e9e9e9;
    }

    .nice-select .list {
        margin: 0;
        transition: none;
        background: #e9e9e9;
        border: solid 1px #e9e9e9;
        border-radius: 0;
        padding: 5px !important;

        .option {
            padding-left: 10px;

            &.selected {
                font-weight: inherit;
            }
        }
    }

    .nice-select .option:hover,
    .nice-select .option.selected.focus:hover {
        background: $textcolor;
        color: #fff;
    }

    .nice-select .option.focus,
    .nice-select .option.selected.focus {
        background: transparent;
        color: $textcolor;
    }
}

.bootstrap-touchspin input.form-control,
.bootstrap-touchspin .btn-touchspin {
    border: solid 1px #e8e8e8;
}

.stock-alert,
.stock-alert-image {
    margin: 15px 0;
    padding-left: 22px;
    position: relative;

    span {
        position: relative;

        &.green-stock {
            color: #84b84c;

            &::before {
                content: "";
                position: absolute;
                left: -20px;
                top: 1px;
                width: 16px;
                height: 16px;
                background-color: #27ae60;
                border: solid 2px white;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                -ms-border-radius: 100%;
                -o-border-radius: 100%;
            }
        }

        &.red-stock {
            color: #c84343;

            &::before {
                content: "";
                position: absolute;
                left: -20px;
                top: 1px;
                width: 16px;
                height: 16px;
                background-color: #c84343;
                border: solid 2px white;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                -ms-border-radius: 100%;
                -o-border-radius: 100%;
            }
        }
    }
}

.stock-alert-image {
    margin: 0px;
    top: -25px;
    left: -6px;
    float: right;
}

#product .product-cover {
    .product-manufacturer {
        margin: 0 auto;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 99999;

        .manufacturer-logo {
            width: 100px;
        }
    }
}

.stock-alert-image {
    display: inline-block;

    /* Tooltip text */
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        right: 0;
        top: -30px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    &:hover .tooltiptext {
        visibility: visible;
    }
}

.price.ttproduct-price {
    padding: 0;
}

.products-selection .sort-by,
.products-selection .total-products p,
.nbrItemPage span {
    font-size: 12px;
}

.catalog_div {
    padding-bottom: 25px;
}

.product-miniature .thumbnail-container:hover .ttproduct-image .second_image {
    background: #fff;
}

.product-images .thumb-container {
    margin: 0 auto;
}

#wrapper .js-qv-mask.mask .owl-buttons div {
    top: 35px;
}

#products .thumbnail-container,
.products .thumbnail-container,
.featured-products .thumbnail-container,
.product-accessories .thumbnail-container,
.ttfeatured-products .thumbnail-container,
.crossselling-product .thumbnail-container,
.ttbestseller-products .thumbnail-container,
.ttnew-products .thumbnail-container,
.ttspecial-products .thumbnail-container,
.category-products .thumbnail-container,
.view-product .thumbnail-container {
    border-color: transparent;

    &:hover {
        border-color: #eaeaea;
    }
}

.quickview {

    .mask {
        height: 425px;
        margin: 0;
    }

    .arrows {
        right: 85px;

        .arrow-up {
            margin-top: -40px;
        }

        .arrow-down {
            position: absolute;
            bottom: -20px;
            cursor: pointer;
        }
    }
}

#newslatter .block_newsletter .tt-title {
    text-align: left;
}

#block-reassurance li .block-reassurance-item {
    display: inline-flex;

    img {
        float: left;
    }

    p {
        float: left;
    }

    .h6 {
        font-size: 14px;
        font-weight: normal;
        line-height: 18px;
        margin-bottom: 0;
        margin-top: 4px;
    }
}

.product-refresh.ps-hidden-by-js {
    display: none;
}

.amazzing_filter.compact-toggle {
    display: none;
}

.product-cover {
    border: none;
}

#product #content-wrapper #content {
    border: 1px solid #e5e5e5;
    padding-left: 1px;
}

.cart-info .productname>a {
    color: $textcolor;
}

li.product-flag {
    font-size: 10px;
    letter-spacing: 0.5px;
}

.product-discount .regular-price {
    font-size: 15px;
}

.product-price {
    font-size: 24px;
}

.ps-hidden-by-js {
    display: none;
}


#checkout-personal-information-step {
    .custom-checkbox {
        display: block;
        float: left;

        span.label {
            padding-left: 5px;
            width: 100%;
            text-align: left;
            color: $textcolor;
            display: table;
            border: none;
            background: none;

            &:hover {
                border: none;
                background: none;
            }
        }
    }

    .radio-inline {
        margin-right: 7px;
    }

    .custom-checkbox input[type="checkbox"],
    input[type="checkbox"] {
        margin-top: 0;
        width: 20px !important;
        height: 20px !important;
    }

    .custom-checkbox input[type="checkbox"]+span {
        min-width: 20px !important;
        min-height: 20px !important;
    }

    .custom-checkbox span.label p {
        float: left;
        display: contents;
        color: $textcolor;
    }

}

#customer-form,
#checkout-login-form {
    margin-top: 20px;
}

.orange-link {
    color: $orange;
}

#checkout-personal-information-step {
    .nav-link:not(.active) {
        color: $orange;
        font-weight: 500;
    }
}

body#checkout section.checkout-step .radio-block {
    display: flex;
}

#checkout-addresses-step {

    #delivery-addresses,
    #invoice-addresses {
        br {
            margin: 0px 0 10px;
        }

        .address {
            line-height: 25px;
        }
    }
}

.ttproductcountdown,
.ttspecial-products .product-miniature .ttproductcountdown {
    display: none !important;
}

#blockcart-modal .cart-products-count {
    font-size: inherit;
    color: $textcolor;
}

.cart-prices.col-md-12 {
    clear: both;
}

.buy-more-text.notfree {
    color: $textcolor;
}

div#delivery label {
    text-align: left;
}

.form-fields>.form-group.row:first-child {
    margin-bottom: 0;
}

.product-price-and-shipping {
    .discount-percentage.discount-product {
        display: none !important;
    }
}

#amazzing_filter.block .clearfix.no-available-items {
    display: none;
}

div#carousel.homeslider.homeslider-container {
    margin-bottom: 50px;
}

section.brands.container {
    margin: 0 auto;
    display: block;
    text-align: center;
}


@media screen and (max-width: 480px) {
    section.brands.container {
        margin-bottom: 20px;
    }
}

span.copy-big,
.price-big .copy {
    font-size: 14px;
    top: -3px;
    position: relative;
}

.price-small {
    font-size: 12px;
    letter-spacing: 0.5px;
}

.brands #ttbrandlogo-carousel {
    padding: 0;
    max-height: 130px;
    overflow: hidden;
}

.brands #ttbrandlogo-carousel li {
    width: calc(100% / 8) !important;
}

.brands #ttbrandlogo-carousel .owl-item li {
    width: 100% !important;
}

html {
    opacity: 1;
}

#header .blockcart .header a span.cart-products-count{
    background: transparent;
    color: $orange;
    width: auto;
    top:0!important;
    left: 8px;
}


@media screen and (max-width: 991px) {
    #header .blockcart .header a span.cart-products-count{
        float: right;
        left: auto;
        right: 0;
    }
}

.modal-dialog {
    margin: 15% auto;
}

/*steps css */
.product-quantity {
    .qty {
        width: 100%;
    }

    #quantity_wanted {
        width: 80px;
    }
}

.product-line-grid-right {
    .bootstrap-touchspin {
        width: auto;
        float: left !important;
    }

    .bootstrap-touchspin>.form-control,
    .bootstrap-touchspin>.input-group {
        width: 80px;
        text-align: center;
    }
}

/*steps css */

#cart .product-line-grid .product-line-info{
    display: inline-flex;
    padding-top: 10px;
    .current-price .price {
        min-height: auto;
        padding: 0 5px 0;
    }
}

.col-price .product-price {
    padding-left: 20px;
    strong{
        line-height: 40px;
        min-height: 40px;
    }
}

.qntbox {
    position: absolute;
    bottom: 4px;
    left: 4px;
    padding: 2px 4px;
    background: #fff;
    font-size: 10px;
    z-index: 99;
}

.pt2020{
    margin-top: 35px;
    width: 100%;
}

#accordion .title span {
    text-transform: lowercase !important;
    float: left;

    &::first-letter {
        text-transform: uppercase !important;
    }
}

/*====CUSTOM STYLE FOR MENU====*/

.activemenumobile{display:flex !important;}

/*=====CUSTOM STYLE FOR INPUT EMAIL ===*/

form{

    input[type=email]{

        text-transform:lowercase !important;

    }

}

/*=====Geral===*/
//html{scroll-behavior: smooth;}

/*===New Custom Layout for All Category Page====*/

.nobottom{margin-bottom:0 !important;}
.extrabottom{margin-bottom:2% !important;}
.categorylist{display:flex !important;transition:all 3s ease-in;}
.activesubcategory{display:flex !important;transition:all 3s ease-in;}
.activelistproduct{display:flex !important;transition:all 3s ease-in;}
.activelink{color:$orange;}
.embreve{color:#333;font-weight:bold;}
.embrevesub{margin-top:1.5%;}
.linkhoversubcat{background:$orange !important;color:$white !important;} 

/*======RESPONSIVE=====*/

/*=====max 991====*/

@media (max-width:991px){
  
    /* .linkhoversubcat{background:#fff !important;color:#f90 !important;} */ 
    
     .subcategorylist{
      width:100vw !important;
      max-width:100%;
    }
    
    .subcategorylist li{
      width:100vw !important;
      max-width:100%;
      padding:0;
    }
    
      .subcategorylist li a{
      display:flex;
      width:100vw !important;
      max-width:100%;
      padding:1%;
    }
    
    .boxsubitenscategory{
      margin-left:0 !important;
      margin-top:-2.5px !important;
      width:100vw !important;
      max-width:100%;
      position:static !important;
      z-index:0 !important;
      display:flex;
    }
    
    .boxsubitenscategory li{
      display:flex;
      flex-direction:column !important;
      width:100vw !important;
      max-width:100%;
      max-height:100% !important;
    }
    
      .boxsubitenscategory li a{
      //width:100vw !important;
      max-width:100%;
    }
    
    .subcategorylistproducts{
      display:flex;
      flex-direction:column !important;
      width:100vw !important;
      max-width:100%;
      margin-left:0 !important;
      margin-top:0 !important;
      position:static !important;
      z-index:0 !important;
      background:#f7f7f7;
      min-height:200px;
    }

    .subcategorylistproducts li{
        max-width:100% !important;
    }
    
    .productallcategory{
      width:100vw !important;
      max-width:100% !important;
    }
    
     .productallcategory img{
      display:none;
    }

    .productallcategory h5{
        text-align: left !important;
    }

    .boxsubitenscategory .subsubcategorie .linksubsubcategorie{
      width:85% !important;
      float:left;
    }

    .subcategorietitle h4 a{
        width:85% !important;
        float:left;
    }
    
    .mobileseta{
      color:#666;
      width:15%;
      z-index:1;
      position:absolute;
      right:0;
      display:flex;
      justify-content:flex-end;

        &:hover{
            cursor: pointer;
        }


    }
    
    .mobileseta.fa-angle-up{
        color:$orange;
    }
      
    
  }

/*======992 a 1400px===*/
@media screen and (min-width:992px) and (max-width:1400px){
  
    body{
      overflow-x:hidden;
    }
    
    .embreve{
      margin-top:10px;
    }
    
    .boxsubitenscategory{
      margin-left:320px !important;
      margin-top:-20px !important;
    }
    
    .boxsubitenscategory li:first-child{
      margin-top:0;
    }
    
    .boxsubitenscategory li{
      min-height:6.5vh;
      width:30vw;
      margin-top:-1.5%;
      max-height:80px;
    }
    
     .boxsubitenscategory li a{
      height:inherit;
      width:25vw !important;
      word-wrap:break-word;
      text-align:left;
      display:flex;
      flex-direction:column;
      align-self:flex-start;
       padding:2% !important;
    }
    
     .subcategorylistproducts{
      display:flex;
      flex-direction:column !important;   
      max-height:20vh !important;
      width:27.5vw !important;
    }
    
    .subcategorylistproducts li{
      min-height:5vh;
      width:25vw !important;
      margin-bottom:20px;
    }
    
    .subcategorylistproducts li .productallcategory{
      width:inherit !important;
    }
    
    
    .subcategorylistproducts li:nth-of-type(2){
      margin-top:-35px;
    }
    
    .subcategorylistproducts li:nth-of-type(3){
      margin-top:-35px;
    }
    
    .subcategorylistproducts li:nth-of-type(4){
      margin-top:-35px;
    }
    
    .productallcategory img{
      display:none;
    }

    .linkproduct{}
    
  }
  

/*===1900px===*/

@media (min-width: 1900px ){

    .boxsubitenscategory li a.linksubsubcategorie{
        width:70% !important;
    }

}


/*=====MIXIN====*/

@mixin flexstructure($margintop, $marginbottom, $marginleft){

    display:none;
    flex-direction: column;
    margin-top: $margintop;
    margin-bottom:$marginbottom;
    margin-left:$marginleft;

}


.categorysitens{

    margin-bottom:-25px;


    .categorytitle{

        background-color:$orange;
    
        img{
            background-color: $white;
            padding-right:.2%;    }
    
        h3{

            color:$white;
            text-transform:uppercase !important;

            a{

                color:$orange;
                
            }
            
        }

        &:hover{

            cursor: pointer;
            opacity:.85;

        }

    
    }
    
    .boxcentrallist{

        @include flexstructure(2%, 2.5%, 0);
    
        .subcategorylist{

            display:flex;
            flex-direction:column;
    
            li{
                
                border:none;
                background:none;
                padding-bottom:0;
                max-height:50px;


                &:last-child{
                    margin-bottom:50px;
                }

    
            }
            
                .boxsubitenscategory{

                    @include flexstructure(-15px, 40px, 340px);

                    z-index:1;
                    position:relative;
                    width:50vw;
                    min-height: 200px;

                    li{
                        display:flex;
                        max-height:60px;

                        a.linksubsubcategorie{

                            width:85%;

                            &:hover{

                                cursor: pointer;
                                

                            }


                        }

                    }
                    
                }

                .subcategorylistproducts{

                    display: none;
                    flex-direction:row;
                    flex-wrap:wrap;
                    margin-left: 330px;
                    margin-top: -5px;
                    height:100%;
                    min-height:230px;
                    width:40vw;
                    z-index:2;
                    position:absolute;

                    

                        li{

                            display:flex;
                            max-width: 185px;

                            .productallcategory{

                                display: flex;
                                flex-direction:column;
                                width:100%;
                                height:auto;
                                padding:2%;
                                margin-right:15px;

                               

                                    img{
                                        object-fit:fill;
                                    }

                                    h5{

                                        text-align:center;
                                        font-size:.75rem;
                                        text-transform:uppercase;

                                    }


                                    &:hover{

                                        cursor: pointer;
            
                                        h5{
            
                                            color:$orange !important;
                                            transition:color .5s ease-in-out;
                                        
                                        }
            
            
                                    }  


                            }

                        }
 

                }
                
    
        }
    
    
    
    }


}

//REMOVE ARROW THE LEVEL 3 THE ALL CATEGORY PAGE

.subcategorylistproducts li div i{
    display:none;
  }  

 //CUSTOM STYLE FOR ACESSIBILITY
 
 #ttcmsfooter #ttfooter-desc, .navfooteritem a, .data, .ttcontent p, .expand-more, .expand-less, .language, #desktop_country_selector, .no-item, .tab-title, .af-checkbox-label .name{color:$dark-gray !important;}

 .brand-infos h2{color:$dark-gray !important;margin-top: 5px;min-height: 38px;font-size: 1.125rem;}

 .brand-link{

    &:hover{
        cursor: pointer;

        .brand-infos h2{

          color:#999 !important;

        }

    }


 }

 .product-title{font: 400 14px/18px 'Work Sans',sans-serif}
 .ttproduct-desc, .ttproduct-price, .viewall{position:static;z-index:1;}

 .ttproducthover{
    display:flex;
    flex-direction:row;
    width:100%;
    height:auto;
    position:static;
    z-index:1;
  }
   