@media (min-width: 1200px) {
    .container {
        max-width: 1150px;
    }
}

@media (min-width: 1300px) {
    .container {
        max-width: 1270px;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1400px;
    }

    #left-column,
    #right-column {
        width: 22%;
    }
}

@media screen and (max-width: 1650px) {
    #ttcategorytabs .product-price-and-shipping {
        min-height: 64px;
    }
}

@media screen and (max-width: 1599px) {
    #ttcategorytabs .image-category-home {
        min-height: 400px;
    }

    #ttcategorytabs .ttcategory .ttproduct-desc {
        padding: 0 5px;
    }

    #ttcategorytabs .products .product-title {
        min-height: 60px;
    }

    #amazzing_filter.block .selectedFilters .cf {
        font-size: 12px;
    }
}

@media screen and (max-width: 1530px) {
    #index .tt-menu-horizontal .main-menu {
        display: none;
    }
}

@media screen and (max-width: 1366px) {

    .tt-menu-horizontal ul li.level-1>a,
    .tt-menu-horizontal ul li.level-1 .more-menu .categories,
    .tt-menu-horizontal ul li.level-1 ul li>a {
        padding: 6px 20px;
    }

    .tt-menu-horizontal .menu-dropdown {
        height: auto;
        min-height: 432px;
    }


    #search_block_top .select-wrapper {
        width: 170px;
    }

    #ttsearch_eccept_data .items-list>li {
        margin: 0 1% 8px 1%;
        padding: 0 0 3px 0px;
        width: 48%;
    }

    #ttcategorytabs .product-price-and-shipping {
        min-height: 44px;
    }
}

@media screen and (max-width: 1300px) {

    #ttsearch_eccept_data li.item:nth-child(n+5),
    #ttsearch_eccept_data .title_showall_text {
        display: none;
    }

    #search_block_top #search_query_top {
        width: 200px;
    }
}

@media screen and (max-width: 1299px) {

    .owl-wrapper-outer .owl-wrapper .owl-item .product-miniature,
    #js-product-list .product-miniature.product-grid {
        padding: 0 10px;
    }

    #left-column,
    #right-column {
        .block-categories .category-sub-menu li {
            padding: 0 5px;

            a {
                font-size: 12px !important;
            }
        }
    }

    .pagination>div:first-child {
        line-height: 20px;
    }

    #ttcategorytabs .image-category-home {
        min-height: 430px;
    }
}

@media screen and (max-width: 1200px) {

    #left-column .products .product-miniature .thumbnail-container,
    #right-column .products .product-miniature .thumbnail-container {

        .product-description {
            margin-top: 0;

            .product-price-and-shipping {
                padding-top: 0;
            }
        }

        .ttproduct-desc .product-description .product-title {
            height: auto;
        }

        .ttproduct-image a img {
            width: 60px;
            height: 60px;
        }
    }

    .featured-products .all-product-link,
    #left-column .all-product-link,
    #right-column .all-product-link,
    #left-column .all-product-link i,
    #right-column .all-product-link i {
        text-align: right;
    }

    .tabs .nav-link .tab-title {
        font-size: 12px;
        padding: 12px;
    }

    .quickview .arrows {
        right: 82px;
    }

    #search_block_top #search_query_top {
        width: 190px;
    }

    #search_block_top .select-wrapper {
        width: 160px;
    }

    .cart-grid-body .cart-overview {
        padding: 10px;
    }

    .gdprModal .gdprModal__placeholder .gdpr-consent-tabs .div_control_your_privacy p {
        margin-bottom: 10px;
    }

    /*menu all categories */
    #tt-menu-horizontal .menu-dropdown.cat-drop-menu.tt-sub-auto>div {
        column-count: 2;
    }

    .tt-menu-horizontal ul li.level-1>a,
    .tt-menu-horizontal ul li.level-1 .more-menu .categories,
    .tt-menu-horizontal ul li.level-1 ul li>a {
        padding: 6px 10px;
    }

    .brand-holder .brand_info .view-products-brands {
        clear: both;
        float: left;
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
    }
}


@media screen and (max-width: 1199px) {

    #left-column .block-categories .category-sub-menu li,
    #right-column .block-categories .category-sub-menu li {
        padding: 0px;
    }

    #left-column {
        padding-right: 0;
    }

    #left-column .block-categories .category-sub-menu li[data-depth="1"],
    #left-column .block-categories .category-sub-menu li[data-depth="2"],
    #right-column .block-categories .category-sub-menu li[data-depth="1"],
    #right-column .block-categories .category-sub-menu li[data-depth="2"] {
        padding: 8px 0 0 4px;
    }

    #left-column .block-categories .arrows .arrow-right,
    #left-column .block-categories .arrows .arrow-down,
    #right-column .block-categories .arrows .arrow-right,
    #right-column .block-categories .arrows .arrow-down {
        position: relative;
        top: 3px;
    }

    #left-column .block-categories .category-sub-menu li a,
    #right-column .block-categories .category-sub-menu li a {
        font-size: 10px !important;
    }

    .products-selection {
        .total-products p {
            font-size: 12px;
        }

        .sort-by {
            white-space: unset;
            font-size: 12px;
        }

    }

    .products-per-page span:not(.current) {
        font-size: 12px;
    }

    #newslatter .block_newsletter .tt-title {
        line-height: 30px;
        margin-top: 20px
    }

    #newslatter .block_newsletter .tt-title::before {
        top: 3px;
    }

    a.back-to-parent-category {
        font-size: 10px;

        &::before {
            font-size: 14px;
        }
    }

    .blockcart .header a {
        width: auto;
        height: auto;
    }

    #header .blockcart .header a .shopping-cart {
        padding-right: 0;

    }

    .featured-products .all-product-link,
    #left-column .all-product-link,
    #right-column .all-product-link,
    #left-column .all-product-link i,
    #right-column .all-product-link i {
        font-size: 13px;
    }

    #products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
    .products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable,
    #product_comparison div.tt-button-container .btn.add-to-cart-disable {
        background: $orange !important;
    }

    .ttspecial-list .products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable .material-icons,
    .ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default .material-icons {
        margin: 0;
        position: absolute;
        left: 6px;
        top: 8px;
    }

    .ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default {
        background: $orange;
    }

    .top-nav .container {
        max-width: 100%;
    }

    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart,
    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable {
        padding: 7px 12px;
    }

    .products-sort-order.dropdown {
        min-width: 160px;
    }

    .grid-list #ttlist.active,
    .grid-list #ttgrid.active {
        display: none;
    }

    .grid-list {
        width: initial;
    }

    .grid-list #ttgrid,
    .grid-list #ttlist {
        margin: 0;
    }
}

@media screen and (max-width: 1024px) {

    #left-column .products .product-miniature .thumbnail-container,
    #right-column .products .product-miniature .thumbnail-container {
        .ttproduct-image a img {
            width: 50px;
            height: 50px;
        }
    }

    #blockcart-modal .modal-dialog {
        width: 95%;
    }

    #search_filters {
        .filter-wrapper .facet .facet-label a {
            margin-top: 0;
            font-size: 11px;
        }
    }

    .tt-menu-horizontal ul li.level-1>a,
    .tt-menu-horizontal ul li.level-1 .more-menu .categories,
    .tt-menu-horizontal ul li.level-1 ul li>a {
        font-size: 12px;
    }

    #hometab .tthometab-title,
    #index #ttcategorytabs .tt-title {
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 30px;
        float: none;
    }

    .cart-grid-body.col-xs-12.col-lg-8 {
        padding-right: 0;
    }

    .ttspecial-products .product-grid .thumbnail-container .tt-button-container .button.ajax_add_to_cart_button.add-to-cart.btn-default {
        background: $orange;

        .material-icons {
            margin: 0;
            position: absolute;
            left: 6px;
            top: 8px;
        }
    }

    .ttspecial-list .products .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable .material-icons {
        margin: 0;
        position: absolute;
        left: 6px;
        top: 8px;
    }

    #ttcategorytabs .image-category-home {
        min-height: 400px;
    }

    #ttsearch_eccept_data .items-list>li {
        margin: 0 0 5px 0;
        padding: 15;
        width: 100%;
    }

    #cart .product-line-grid-body .product-line-info {
        .price {
            font-size: 15px;
            padding-top: 0;
            min-height: auto;
        }

        .unit-price-cart {
            font-size: 20px;
        }
    }

    #header {

        .middle_nav,
        .position-static {
            .container {
                max-width: 100%;
                margin: 0 !important;
            }
        }
    }

}

@media screen and (max-width: 991px) {
    .tt-menu-horizontal .main-menu {
        z-index: 999;
        box-shadow: none;
    }

    #header .top-nav .right {
        padding: 5px 0;
    }

    #ttcmsheader {
        display: none;
    }

    .footer-container .links .title:hover {
        background: transparent;
    }

    #left-column .tt-title,
    #left-column .text-uppercase,
    #right-column .tt-title,
    #right-column .text-uppercase {
        background: transparent;
    }

    .block-categories ul#categories {
        margin-top: 10px;
    }

    section.special-products #special-products .products {
        padding-top: 0px;
        margin-bottom: 0;
    }

    #left-column .products .product-miniature .thumbnail-container .ttproduct-image a img,
    #right-column .products .product-miniature .thumbnail-container .ttproduct-image a img {
        width: 90px;
        height: 90px;
    }

    #left-column .products .product-miniature,
    #right-column .products .product-miniature {
        width: 50%;
        padding: 0 2.5%;
        margin: 20px 0 !important;
    }

    #left-column .products .product-miniature .thumbnail-container,
    #right-column .products .product-miniature .thumbnail-container {
        border: none;
    }

    #left-column .products .product-miniature:nth-child(even) {
        border-left: solid 1px #e5e5e5;
    }

    #left-column .products .product-miniature .thumbnail-container,
    #right-column .products .product-miniature .thumbnail-container {
        padding: 10px 0;
    }

    #left-column .all-product-link {
        margin-bottom: 10px;
    }

    #products .ttproducthover .wishlist {
        display: block !important;
    }

    #category #left-column #search_filters .facet,
    #category #right-column #search_filters .facet {
        border-bottom: none;
    }

    #left-column .block-categories .category-sub-menu li a,
    #right-column .block-categories .category-sub-menu li a {
        font-size: 12px !important;
    }

    #left-column .block-categories .category-sub-menu li[data-depth="1"],
    #left-column .block-categories .category-sub-menu li[data-depth="2"],
    #right-column .block-categories .category-sub-menu li[data-depth="1"],
    #right-column .block-categories .category-sub-menu li[data-depth="2"] {
        padding: 10px 0 0 4px;
    }

    .tt-menu-horizontal .main-menu .js-top-menu-bottom {
        border-top: none;
    }

    #products .thumbnail-container .ttproducthover div,
    .products .thumbnail-container .ttproducthover div,
    .featured-products .thumbnail-container .ttproducthover div,
    .ttfeatured-products .thumbnail-container .ttproducthover div,
    .product-accessories .thumbnail-container .ttproducthover div,
    .ttbestseller-products .thumbnail-container .ttproducthover div,
    .ttnew-products .thumbnail-container .ttproducthover div,
    .ttspecial-products .thumbnail-container .ttproducthover div,
    .crossselling-product .thumbnail-container .ttproducthover div,
    .category-products .thumbnail-container .ttproducthover div,
    .view-product .thumbnail-container .ttproducthover div {
        margin: 0 2px;

        &.quick-view-block {
            display: none !important;
        }
    }

    #header .fixed .blockcart {
        padding: 0;
    }

    .tt-menu-horizontal .menu-dropdown {
        min-height: auto;
    }

    #ttcmsfooter .title {
        .navbar-toggler i.remove {
            display: block;
        }

        .navbar-toggler i.add {
            display: none;
        }

        &[aria-expanded="true"] .collapse-icons .add {
            display: none;
        }

        &[aria-expanded="true"] .collapse-icons .remove {
            display: block;
        }

        &[aria-expanded="false"] .collapse-icons .add {
            display: block;
        }

        &[aria-expanded="false"] .collapse-icons .remove {
            display: none;
        }

    }

    #hometab .tabs .nav-tabs .nav-link.active .tab-title,
    #ttcategorytabs.tabs .nav-tabs .nav-link.active .tab-title,
    .tabs .nav-tabs .nav-link.active .tab-title {
        border: solid 2px transparent;
        border-top: 2px solid $orange;
        border-left: 2px solid $orange;
    }

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        float: left;
    }

    #hometab .tabs .nav-tabs .nav-item,
    #ttcategory-tabs.nav-tabs .nav-item,
    .tabs .nav-tabs .nav-item {
        width: auto;
        display: inline;
    }

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        padding: 0 !important;
    }

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        width: auto;
    }

    #ttcategorytabs .customNavigation {
        top: auto;
        position: relative;
    }

    .tt-titletab {
        border: none;
    }

    .tthometab-title,
    #index #ttcategorytabs .tt-title {
        margin-bottom: 20px;
    }

    #newslatter .block_newsletter .tt-title {
        line-height: 70px;
        margin-top: 0;
    }

    .tt-menu-horizontal ul li.level-1>a,
    .tt-menu-horizontal ul li.level-1 .more-menu .categories,
    .tt-menu-horizontal ul li.level-1 ul li>a {
        font-size: 13px;
    }

    #hometab .tthometab-title,
    #index #ttcategorytabs .tt-title {
        width: 100%;
    }

    .ttuserheading:hover {
        background: transparent;
    }

    #mobile_menu .wishlist {
        background: none;
    }

    #mobile_menu .wishlist .material-icons {
        top: 8px;
        position: relative;
    }

    #header .blockcart {
        .header a {
            padding-top: 13px;
            padding-left: 4px;

            .shopping-cart {
                font-size: 24px;
            }
        }

        span.cart-products-count {
            font-size: 12px;
            line-height: 12px;
            height: 15px;
            width: 15px;
        }
    }

    #header .blockcart {
        padding: 0;
    }

    .blockcart span.cart-products-count {
        background: $orange;
    }

    #search_block_top {
        top: 142px;
    }

    header.fixed #search_block_top {
        top: 30px;
    }

    #history.page-history.page-customer-account {
        #content {
            border: none;

            .orders .order {
                a h3 {
                    color: $textcolor;
                }

                .col-xs-2 {
                    div a i {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    #search_filters .filter-wrapper .facet {
        flex: auto;
        max-width: 50%;
    }

    li.tttoplink.active a {
        color: $orange;
    }

    #amazzing_filter.block .title {
        padding: 5px !important;
    }

    .products-selection .total-products p {
        float: left;
    }

    .products-selection .col-md-6 {
        width: 50%;
    }

    .sort-by-col .sort-by-row {
        float: left;
    }

    .products-selection .total-products [class^='col-lg'],
    .products-selection .total-products [class^='col-md'] {
        padding-left: 15px;
    }

    .products-selection .sort-by {
        padding-left: 0;
    }

    .sort-by-col .sort-by-row {
        padding-top: 10px;
    }

    #tt-menu-horizontal .menu-dropdown.cat-drop-menu.tt-sub-auto>div {
        column-count: 1;
    }

    .tt-menu-horizontal ul li ul {
        padding: 0;
    }

    #search_block_top #search_query_top {
        width: 500px;
    }


    #products .thumbnail-container,
    .products .thumbnail-container,
    .featured-products .thumbnail-container,
    .product-accessories .thumbnail-container,
    .ttfeatured-products .thumbnail-container,
    .crossselling-product .thumbnail-container,
    .ttbestseller-products .thumbnail-container,
    .ttnew-products .thumbnail-container,
    .ttspecial-products .thumbnail-container,
    .category-products .thumbnail-container,
    .view-product .thumbnail-container {
        border-color: #eaeaea;
    }

    .menu-dropdown.cat-drop-menu {
        height: 100% !important;
    }

}

@media (max-width: 991px) and (min-width: 768px) {

    #ttcategorytabs .products .product-miniature {
        margin: 0 0 25px 0px;
    }

    #ttcategorytabs .products .product-miniature:last-child {
        margin: 0;
    }
}

@media screen and (max-width: 768px) {

    .tthometab-title,
    #ttcategorytabs .tt-title {
        margin-bottom: 30px;
    }

    .homeslider {
        margin-bottom: 50px;
    }

    .block-categories .category-sub-menu li[data-depth="1"] {
        padding-right: 0;
    }

    #ttcategorytabs .tab-content .ttcategory {
        margin-top: 0;
    }

    #identity {

        .form-control-label,
        .form-control-comment {
            text-align: left;
        }
    }

    #ttcategorytabs .products .product-title {
        margin: 0 0 9px;
        min-height: 70px;
    }

    .cart-grid-body.col-xs-12.col-lg-8 {
        padding-right: 15px;
    }

    #index .tt-title {
        width: 100%;
    }

    #newslatter .block_newsletter .tt-title {
        width: auto;
    }

    .page-my-account #content .links a {
        width: calc(100% / 3);
    }

    .ttspecial-list .product-miniature .thumbnail-container .ttproduct-desc {
        padding: 0 5px;
    }

    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart,
    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable {
        padding: 7px 8px;
    }

    #products .product-list .thumbnail-container .price.ttproduct-price.col-md-4.col-sm-4.col-xs-12 {
        padding-right: 0;
        padding-left: 0;
    }

    .sort-by-col .sort-by-row {
        float: left;
    }

    #search_block_top #search_query_top {
        width: 400px;
    }

    #blockcart-modal .modal-body .cart-content-btn {
        margin-top: 15px;
    }
}

@media screen and (max-width: 767px) {
    #newslatter {
        padding: 50px 15px 55px;
    }

    #newslatter .block_newsletter .tt-title {
        line-height: initial;
    }

    #newslatter .block_newsletter .tt-title {
        padding-left: 0;
    }

    #newslatter .block_newsletter .tt-title::before {
        content: none;
    }

    #hometab .tabs .nav-tabs .nav-item,
    #ttcategory-tabs.nav-tabs .nav-item,
    .tabs .nav-tabs .nav-item {
        width: auto;
        display: contents;
    }

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        border: medium none;
        float: left;
        padding: 0 !important;
        text-align: center;
    }

    #identity,
    #authentication {
        #main {

            .form-control-label,
            .form-control-comment {
                text-align: left;
            }
        }
    }

    #amazzing_filter.block {
        .float-xs-right {
            display: none;
        }

        .title {
            padding: 10px 0 !important;

            span {
                padding-left: 15px;
                font: 500 15px/25px 'Work Sans', sans-serif;
            }
        }
    }

    #amazzing-collapse {
        display: block !important;
    }

    #footer .ttcontent .col-md-6.text-right,
    #footer .ttcontent .col-md-6.text-left .block-social,
    #footer .ttcontent .col-md-6.text-left p {
        text-align: center;
    }

    .products-selection .sort-by {
        padding: 10px 5px 0 0;
    }

    .sort-by-row {
        float: left;
    }
}

@media screen and (max-width: 699px) {
    #ttcategorytabs .products .product-miniature {
        padding: 10px;
    }
}

@media screen and (max-width: 690px) {
    #amazzing_filter.block #amazzing-collapse {
        padding-bottom: 130px;
    }
}

@media screen and (max-width: 630px) {

    #products .product-list .thumbnail-container .ttproducthover div .btn.ajax_add_to_cart_button.add-to-cart.btn-default span,
    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable span {
        display: none;
    }

    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart,
    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable {
        padding: inherit;
        width: 36px;

        i {
            left: 5px;
        }
    }

    #products .product-list .thumbnail-container .ttproducthover {
        float: left;
        display: flex;
    }

    #products .product-list .ttproducthover div.compare,
    #products .product-list .ttproducthover div .addToWishlist {
        margin-right: 2px;
    }
}


@media screen and (max-width: 545px) {
    #newslatter .block_newsletter .tt-title::before {
        display: none;
    }

    #left-column .products .product-miniature,
    #right-column .products .product-miniature {
        width: 100%;
        padding: 0;
        margin: 20px 0 !important;
        float: left;
    }

    #left-column .products .product-miniature .thumbnail-container,
    #right-column .products .product-miniature .thumbnail-container {
        border: none;
        border-bottom: 1px solid #e5e5e5;

        .product-description {
            margin-top: 0;
        }

        .product-title {
            height: 45px;
        }
    }

    .product-actions .product-add-to-cart .wishlist a {
        padding: 0 0 10px 0;
    }

    #products .product-list .ttproducthover div {
        float: left;
    }

    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart,
    #products .product-list .thumbnail-container .ttproducthover div.tt-button-container .btn.add-to-cart-disable {
        padding: inherit;
        display: inline-block;

        .material-icons {
            left: 6px;
        }
    }

    #ttcmsbanner .ttbanner .ttbanner-img {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 543px) {
    #newsletter_block_popup .ttnewslatterpopup-content {
        padding-top: 55px;
    }

    .ttPopupnewsletter-i .close i {
        top: 13px;
        right: 13px;
        position: relative;
    }

    #search_block_top #searchbox {
        width: 90%;

        .ttsearch-main {
            width: 85%;
        }

        .btn.button-search {
            width: 15%;
        }

        #search_query_top {
            width: 100%;
        }
    }

    .product-actions .product-add-to-cart .wishlist,
    .product-actions .product-add-to-cart .compare {
        padding: 10px 0;
    }
}

@media screen and (max-width: 480px) {
    .tt-menu-horizontal .main-menu {
        width: 100vw;
        margin: 0 -15px;
    }

    #index .product-miniature.product-grid .thumbnail-container .ttproductcountdown {
        bottom: 0;
        position: relative;
    }

    #hometab .tabs .nav-tabs,
    #ttcategory-tabs {
        width: 100%;
        display: block;
    }

    #hometab .tabs .nav-tabs .nav-item,
    #ttcategory-tabs.nav-tabs .nav-item {
        width: 100%;
    }

    #hometab .tab-title,
    #ttcategory-tabs .nav-link .tab-title,
    .tabs .nav-link .tab-title {
        border: 2px solid transparent;
    }

    .tt-titletab {
        border: none;
    }

    .tt-title,
    .tthometab-title,
    #ttcategorytabs .tt-title {
        margin-top: 15px;
    }

    #tt_category_feature .sub-cat ul {
        text-align: left;
    }

    .tt_category_feature {
        margin-bottom: 0;
    }

    section.brand {
        margin-bottom: 40px;
    }

    #ttcategorytabs .products .product-miniature {
        width: 92%;
        margin: 0 4%;
        margin-bottom: 20px;
        float: left;
    }

    .block-categories .category-sub-menu {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .modal-open .modal {
        padding: 20px 0 !important;
    }

    .quickview .modal-body {
        padding: 0 10px;
    }

    #products .product-list .ttproducthover div {
        float: left;
    }

    .pagination {
        padding: 10px 5px;
    }

    .page-addresses #content .address {
        margin-bottom: 20px;
    }

    #AC-manufacturer .product-attachments p {
        display: inline-grid;
        display: -moz-inline-grid;
        display: -ms-inline-grid;

        z-index: -1;
        position: relative;

        a {
            text-align: center;

            img {
                float: none !important;
                margin: 0 auto;
            }
        }
    }

    #blockcart-modal .modal-body {
        .divide-right {
            margin-bottom: 0;
        }

        .cart-content-btn {
            margin-top: 15px;
        }
    }

    #ttcategorytabs .products .product-title {
        min-height: 60px;
    }

    div#block-history td,
    div#block-history th {
        min-width: 100px;
    }

    table#product_comparison {
        td {
            border-left: 1px solid #e5e5e5;

            &.feature-name {
                font-size: 12px;
            }
        }

        tr {
            border-bottom: 1px solid #e5e5e5;

            &.product-block {
                padding: 15px;
            }
        }

        tbody tr td.comparison_infos {
            vertical-align: middle;
        }

        .thumbnail-container .product-thumbnail img {
            max-width: 100px;
        }
    }

    #cart .product-price strong {
        margin-left: 20px;
    }

    #search_block_top #search_query_top {
        width: 150px;
    }

    .page-my-account #content .links a {
        width: calc(100% / 1);
    }

    #mobile_menu .wishlist {
        display: none !important;
    }

    .ttspecial-products {

        .ttspecial-list img.ttproduct-img1.js-qv-product-cover-img {
            max-width: 100%;
            margin-top: 0;
        }

        .thumbnail-container {

            .ttproduct-image,
            .ttproduct-desc {
                padding: 0;
            }

        }
    }

    .product-features>dl.data-sheet dd.value,
    .product-features>dl.data-sheet dt.name {
        -webkit-flex: 1 0 100%;
        -moz-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
    }

    .form-footer .ps-newsletter-checkbox {
        display: flex;

        input[type="checkbox"] {
            min-width: 15px;
        }

        label p {
            text-align: left;
            padding-left: 10px;
        }
    }

    #search_filters .filter-wrapper .facet {
        width: 100%;
        max-width: 100%;
    }

    .ttspecial-list .products .thumbnail-container .ttproduct-image .product-flags {
        margin-left: 0;
    }

    .owl-wrapper-outer .owl-wrapper .owl-item .product-miniature,
    #js-product-list .product-miniature.product-grid {
        padding: 15px;
    }

    #products #js-product-list .product-miniature.product-grid {
        margin-bottom: 15px;
    }

    .livro-reclamacao img {
        margin-top: 0;
    }

    .product-features>dl.data-sheet dd.value:nth-of-type(even),
    .product-features>dl.data-sheet dt.name:nth-of-type(even) {
        background: transparent;
    }

    .product-features>dl.data-sheet dt.name {
        background: #f5f5f5 !important;
    }

    .tt-menu-horizontal .main-menu {
        min-height: 100vh;
        border: none;
        padding: 15px 0 35px 0;
    }

    #countryModal .modal-content {
        max-width: 300px;
        border-radius: 0;
        width: 100%;
    }

    #search_block_top #searchbox {
        #search_query_top {
            width: 100%;
        }
    }

    .pagination>div:first-child {
        padding-bottom: 10px;
    }

    #left-column .block-categories {
        display: none;
    }

    #amazzing_filter.block .af_subtitle {
        width: 95%;
    }

    .af_subtitle:before {
        right: -10px;
    }

    .products-selection .col-md-6,
    .sort-by-col .sort-by-row {
        width: 100%;
        padding-top: 10px;
    }

    #products .product-title,
    .products .product-title,
    .featured-products .product-title,
    .product-accessories .product-title,
    .ttfeatured-products .product-title,
    .crossselling-product .product-title,
    .ttbestseller-products .product-title,
    .ttnew-products .product-title,
    .ttspecial-products .product-title,
    .category-products .product-title,
    .view-product .product-title {
        height: 95px;
    }

    #ttcategorytabs {
        .products .product-title {
            height: 75px;
        }

        .ttproducthover {
            margin-top: 0px;
        }
    }

    .buy-more-text.notfree {
        text-align: left;
    }

    .cart-summary-line {
        padding: 10px 0;
    }

    body#checkout section.checkout-step .delivery-options {
        margin-bottom: 0px;
    }

    .contact-form div#g-recaptcha>div {
        float: left;
    }

    .product-price-and-shipping {
        display: inline-grid;
    }

    #product #content-wrapper .products.row {
        margin-left: -15px;
        margin-right: -15px;
    }

    #blockcart-modal .modal-title {
        text-align: left !important;
    }

    #ttsearch_eccept_data .items-list .ttsearch-content {
        padding-top: 25px;
        padding-left: 10px;
    }

}

@media screen and (max-width: 430px) {
    .header-top.fixed .logo-mobile {
        width: 100px;
        padding-top: 8px;
    }
}

@media screen and (max-width: 330px) {
    .header-top.fixed .logo-mobile {
        width: 90px;
        padding-top: 11px;
    }

    #search_block_top #search_query_top {
        width: 120px;
    }

    #search_block_top .select-wrapper {
        width: 120px;
    }

    #products .thumbnail-container .ttproducthover div,
    .products .thumbnail-container .ttproducthover div,
    .featured-products .thumbnail-container .ttproducthover div,
    .ttfeatured-products .thumbnail-container .ttproducthover div,
    .product-accessories .thumbnail-container .ttproducthover div,
    .ttbestseller-products .thumbnail-container .ttproducthover div,
    .ttnew-products .thumbnail-container .ttproducthover div,
    .ttspecial-products .thumbnail-container .ttproducthover div,
    .crossselling-product .thumbnail-container .ttproducthover div,
    .category-products .thumbnail-container .ttproducthover div,
    .view-product .thumbnail-container .ttproducthover div {
        margin: 0 3px 5px 0;

        &.product-add-to-cart {
            margin: 0;
        }
    }

    #products .product-title a,
    .products .product-title a,
    .featured-products .product-title a,
    .product-accessories .product-title a,
    .ttfeatured-products .product-title a,
    .crossselling-product .product-title a,
    .category-products .product-title a,
    .ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a,
    .ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a,
    .ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a,
    .ttbestseller-products .products .product-miniature .thumbnail-container .product-description .product-title a,
    .ttnew-products .products .product-miniature .thumbnail-container .product-description .product-title a,
    .ttspecial-products .products .product-miniature .thumbnail-container .product-description .product-title a,
    .view-product .product-title a,
    #product_comparison .product-miniature .product-description .product-title>a {
        font-size: 13px;
        line-height: 15px;
    }

    .products .thumbnail-container .ttproduct-image .product-flags>li {
        font-size: 8px;
    }

    #search_block_top #searchbox {
        #search_query_top {
            width: 100%;
        }
    }
}


@media screen and (max-height: 900px) {

    #tt-menu-horizontal.tt-menu-horizontal ul li.level-1>a,
    #tt-menu-horizontal.tt-menu-horizontal ul li.level-1 .more-menu .categories,
    #tt-menu-horizontal.tt-menu-horizontal ul li>a {
        padding: 2px 15px;
    }

    #tt-menu-horizontal.tt-menu-horizontal ul li>a {
        font-size: 12px;
    }

    #tt-menu-horizontal.tt-menu-horizontal {
        ul.level-3-ul li.active-li {
            width: 100%;
        }
    }
}